import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DataTransferService } from '../../services/data-transfer/data-transfer.service';


@Component({
  selector: 'inbrit-model-dialog',
  templateUrl: './model-dialog.component.html',
  styleUrls: ['./model-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModelDialogComponent implements OnInit {

  modalData: any;

  constructor(public dialogRef: MatDialogRef<ModelDialogComponent>,
    private dataService: DataTransferService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.modalData = this.dataService.getData();
  }

  onClick(result: string): void {
    this.dialogRef.close(result);
  }

}
