import { Component, OnInit } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import {
  ProfileStatusConstants, KycStatusConstants, UserRoleConstants,
  GridColumnType,
  GridActionType,
  Pagination
} from "../constants/constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../services/encryption/encryption.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BlockUnblockUserComponent } from "../shared/block-unblock-user/block-unblock-user.component";
import { FormGroup, FormBuilder } from '@angular/forms';
import { TitleCasePipe } from "@angular/common";
import { UserInfoService } from '../services/userInfo/user-info.service';
import { CacheService } from '../services/cache-service/cache.service';

@Component({
  selector: 'inbrit-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  customerList: [any];
  ProfileStatusConstants = ProfileStatusConstants;
  KycStatusConstants = KycStatusConstants;
  UserRoleConstants = UserRoleConstants;
  filterCustomerListForm: FormGroup;
  totalFiltersApplied: number;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  masterTierList: [any];
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Name', dataField: 'fullName', type: GridColumnType.DATA, },
      { title: 'Email', dataField: 'email', type: GridColumnType.DATA, },
      { title: 'Mobile Number', dataField: 'mobileNumber', type: GridColumnType.DATA, },
      { title: 'Registration Status', dataField: 'status', type: GridColumnType.DATA, },
      { title: 'KYC Status', dataField: 'kycStatus', type: GridColumnType.DATA, },
      { title: 'Tier', dataField: 'tierName', type: GridColumnType.DATA, },
      {
        title: 'Action', dataField: 'email', type: GridColumnType.ACTION, actions: [
          { event: 'edit', type: GridActionType.ICON, title: 'Edit Profile', class: 'edit' },
          {
            event: 'kycStatus', type: GridActionType.ICON, title: 'Edit Kyc', class: 'how_to_reg',
            conditionalDisplay: { dataField: 'isShowEditKyc', value: true }
          },
          {
            event: 'kycStatus', type: GridActionType.ICON, title: 'View Kyc', class: 'how_to_reg',
            conditionalDisplay: { dataField: 'isShowViewKyc', value: true }
          },
          {
            event: 'blockCustomer', type: GridActionType.ICON, title: 'Block', class: 'block',
            conditionalDisplay: { dataField: 'isBlocked', value: false }
          },
          {
            event: 'unblockCustomer', type: GridActionType.ICON, title: 'UnBlock', class: 'check_circle',
            conditionalDisplay: { dataField: 'isBlocked', value: true }
          },
          {
            event: 'viewLoginHistory', type: GridActionType.ICON, title: 'Login History', class: 'timelapse',
            conditionalDisplay: { dataField: 'roleId', value: UserRoleConstants.CUSTOMER }
          },
        ]
      }
    ]
  filterDialogRef: MatDialogRef<{}, any>;
  pageTitle: string = '';
  filter: string = '';
  userRoleIds = [];
  uniqueCacheKey: string = '';

  constructor(private httpService: HttpService, private router: Router,
    public encryptionService: EncryptionService, private dialog: MatDialog,
    private formBuilder: FormBuilder, private userInfoService: UserInfoService,
    private cacheService: CacheService) {

      if (this.router.url == '/user/customer-list') {
        this.userRoleIds = [UserRoleConstants.CUSTOMER];
        this.pageTitle = "Customer";
        this.uniqueCacheKey = 'CustomerListComponent-customer-filter';
      }
      else if (this.router.url == '/user/user-list') {
        this.userRoleIds = [UserRoleConstants.USER];
        this.pageTitle = "User";
        var kycStatusColumn = this.columns.filter(m=> {return m.dataField == 'kycStatus'});
        if(kycStatusColumn.length == 1) {
          this.columns.splice(this.columns.indexOf(kycStatusColumn[0]), 1);
        }
        this.uniqueCacheKey = 'CustomerListComponent-user-filter';
      }
  }

  ngOnInit() {
    this.initializeFilterForm();
    this.getAllTiers();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterCustomerListForm = this.formBuilder.group({
      registrationStatusIds: [cachedFilter != null ? cachedFilter.value.registrationStatusIds.value : '', []],
      kycStatusIds: [cachedFilter != null ? cachedFilter.value.kycStatusIds.value : '', []],
      userTypeIds: [cachedFilter != null ? cachedFilter.value.userTypeIds.value : '', []],
      tierIds: [cachedFilter != null ? cachedFilter.value.tierIds.value : '', []],
    });
  }
  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterCustomerListForm.controls; }

  getAllTiers() {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": 0,
      "SearchText": ""
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_getAllTierMaster, param).subscribe(res => {
      if (res['success'] == true) {
        this.masterTierList = res['data'];
        this.getCustomers();
      }
    });
  }

  clearFilter(): void {
    this.filterCustomerListForm.reset();
    this.getCustomers();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  filterFormSubmit(): void {
    this.getCustomers();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterCustomerListForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  getCustomers(): void {

    var userStatusIds = [];
    var userKycStatusIds = [];
    var userTierIds = [];

    this.totalFiltersApplied = 0;

    if (this.filterFormFields.registrationStatusIds.value && this.filterFormFields.registrationStatusIds.value != 0) {
      this.totalFiltersApplied++;
      this.filterFormFields.registrationStatusIds.value.forEach(item => {
        userStatusIds.push(item);
      });
    }

    if (this.filterFormFields.kycStatusIds.value && this.filterFormFields.kycStatusIds.value != 0) {
      this.totalFiltersApplied++;
      this.filterFormFields.kycStatusIds.value.forEach(item => {
        userKycStatusIds.push(item);
      });
    }

    if (this.filterFormFields.tierIds.value && this.filterFormFields.tierIds.value != 0) {
      this.totalFiltersApplied++;
      this.filterFormFields.tierIds.value.forEach(item => {
        userTierIds.push(item);
      });
    }


    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "StatusIds": userStatusIds,
      "KycStatusIds": userKycStatusIds,
      "TierIds": userTierIds,
      "RoleIds": this.userRoleIds,
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_getTierUsers + "?searchText=" + this.filter, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];

        this.dataCount = res['count'];
        data.forEach(item => {
          item['fullName'] = item['title'] + ' ' + this.titleCasePipe.transform(item['firstName']) + ' ' +
            this.titleCasePipe.transform(item['lastName']);
          item['mobileNumber'] = item['mobileCode'] + ' ' + item['mobileNumber'];
          item['status'] = this.getProfileStatusText(item['statusId']);
          item['kycStatus'] = this.getKycStatusText(item['kycStatusId']);

          var result = this.masterTierList.find(tier => tier.tierId == item['tierId'])
          item['tierName'] = result == undefined ? 'NA' : result.description;

          item['blockedYN'] = item['isBlocked'];
          item['textInfo'] = {
            fullName: item['isBlocked'] == true ? '<a title="Blocked"><i class="material-icons mr-2 text-danger">block</i></a>'
              : '<a title="Active"><i class="material-icons mr-2 text-success">check</i></a>'
          }
          item['class'] = {
            status: this.userInfoService.getColorClass(item['statusId']),
            kycStatus: this.userInfoService.getColorClass(item['kycStatusId'])
          };
          item['isShowEditKyc'] = this.userRoleIds.includes(UserRoleConstants.CUSTOMER) && item['kycStatusId'] != KycStatusConstants.KYC_APPROVAL_PENDING;
          item['isShowViewKyc'] = this.userRoleIds.includes(UserRoleConstants.CUSTOMER) && item['kycStatusId'] == KycStatusConstants.KYC_APPROVAL_PENDING;
        });
        this.customerList = data;
      }
    });
  }

  editCustomer(customerId: number): void {
    if (this.router.url == '/user/customer-list') {
      this.router.navigate(['user/customer-list', this.encryptionService.encrypt(customerId)]);
    }
    else if (this.router.url == '/user/user-list') {
      this.router.navigate(['user/user-list', this.encryptionService.encrypt(customerId)]);
    }
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'edit':
        {
          this.editCustomer(evt.data.id);
          break;
        }
      case 'kycStatus':
        {
          this.editKyc(evt.data.id);
          break;
        }
      case 'blockCustomer':
      case 'unblockCustomer':
        {
          this.confirmUserBlock(evt.data.id, !evt.data.isBlocked);
          break;
        }
      case 'viewLoginHistory':
        {
          this.router.navigate(['user/customer-list/login-history', this.encryptionService.encrypt(evt.data.id)]);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getCustomers();
  }

  editKyc(customerId: number): void {
    this.router.navigate(['user/customer-list/kyc-details', this.encryptionService.encrypt(customerId)]);
  }

  confirmUserBlock(customerId: number, isBlocked: boolean): void {
    const dialogRef = this.dialog.open(BlockUnblockUserComponent, {
      width: '450px',
      data: { customerId: customerId, isBlocked: isBlocked }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.blockOrUnblockUser(customerId, isBlocked, result);
      }
    });
  }

  blockOrUnblockUser(customerId: number, isBlocked: boolean, blockComments: string): void {
    var param = {
      "Id": customerId, "IsBlocked": isBlocked,
      "BlockComments": blockComments
    };
    this.httpService.httpPost(RouteConstants.ACCOUNT_blockOrUnblockUser, param).subscribe(res => {
      if (res['success'] == true) {
        var customer = this.customerList.filter(function (item) { return item.id == customerId })[0];
        customer['isBlocked'] = isBlocked;
        customer['textInfo'] = {
          fullName: isBlocked == true ? '<a title="Blocked"><i class="material-icons mr-2 text-danger">block</i></a>'
            : '<a title="Active"><i class="material-icons mr-2 text-success">check</i></a>'
        }
      }
    });
  }

  getKycStatusText(kycStatusId: number): string {
    var result = KycStatusConstants.KYC_STATUSES.find(m => m.id == kycStatusId);
    if (result != undefined) {
      return result.description;
    }
    return "";
  }

  getProfileStatusText(profileStatusId: number): string {
    var result = ProfileStatusConstants.PROFILE_STATUSES.find(m => m.id == profileStatusId);
    if (result != undefined) {
      return result.description;
    }
    return "";
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

}
