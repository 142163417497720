import { Component, OnInit } from '@angular/core';
import { RouteConstants } from "../../constants/route-constants";
import { Pagination, GridColumnType, GridActionType, GridColumnDataType, UserRoleConstants, DateConstants } from "../../constants/constants";
import { HttpService } from "../../services/http/http.service";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { DatePipe } from "@angular/common"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';
import { element } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'quotation-report',
  templateUrl: './quotation-report.component.html',
  styleUrls: ['./quotation-report.component.scss']
})
export class QuotationReportComponent implements OnInit {
  user: any;
  pageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  datePipe = new DatePipe('en-US');
  filterQuotationForm: FormGroup;
  toggleFilterVisiblility: boolean = false;
  quotationHistory: [any];
  dataCount: number;
  submitted: boolean = false;
  DateConstants = DateConstants;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Quotation', dataField: 'quotationNumber', type: GridColumnType.DATA, },
      { title: 'User', dataField: 'createdByName', type: GridColumnType.DATA, },
      { title: 'From', dataField: 'fromName', type: GridColumnType.DATA, },
      { title: 'To', dataField: 'toNames', type: GridColumnType.DATA, },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },
      { title: '', dataField: 'bookingDone', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      {
        title: 'Action', dataField: 'quotationId', type: GridColumnType.ACTION, actions: [
          { event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility' }
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  UserRoleConstants = UserRoleConstants;
  uniqueCacheKey: string = 'QuotationReportComponent-filter';

  constructor(private httpService: HttpService, private formBuilder: FormBuilder,
    private router: Router, private encryptionService: EncryptionService,
    private dialog: MatDialog, private userInfoService: UserInfoService,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.initializeForm();
    this.getQuotations();
    var user = this.userInfoService.getLoggedInUser();
    if (user != undefined && user != null && user != 'null') {
      this.user = user;
    }
  }

  initializeForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterQuotationForm = this.formBuilder.group({
      fromDate: [cachedFilter != null ? cachedFilter.value.fromDate.value : new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      toDate: [cachedFilter != null ? cachedFilter.value.toDate.value : new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterQuotationForm.controls; }

  getQuotations(): void {
    var param = {
      FromDate: this.datePipe.transform(this.filterFormFields.fromDate.value, "yyyy-MM-dd"),
      ToDate: this.datePipe.transform(this.filterFormFields.toDate.value, "yyyy-MM-dd"),
    }

    this.httpService.httpPost(RouteConstants.QUOTATION_getAllQuotations + "?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        data.forEach(element => {
          element['bookingDone'] = element.bookingId != null ? 'Booked' : '';
          element['class'] = {
            bookingDone: 'is-green p-2 font-bold'
          }
        });
        this.quotationHistory = data;
        this.dataCount = res['count'];
      }
    });
  }

  clearFilter(): void {
    this.filterQuotationForm.patchValue({
      fromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      toDate: new Date(moment().format("M/D/YYYY").toString())
    })
    this.pageIndex = 1;
    this.getQuotations();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  filterFormSubmit(): void {
    this.submitted = true;
    if (!this.filterQuotationForm.valid) {
      return;
    }
    this.getQuotations();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterQuotationForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewQuotationDetail(evt.data.quotationId);
          break;
        }
    }
  }
    
  exportToExcel() {
    const payload = {
      "FromDate": null, //optional (if null will fetch all records)
      "ToDate": null //optional 
    }
    this.httpService.httpPost(RouteConstants.AUTO_GEN_DATA, payload).subscribe((resp: any) => {
                var headerName1 = ['Quotation no', 'User', 'From', 'To', 'Request Date', 'Commodity', 'Shipping Line', 'Effective date', 'Expiry date', 'Type','Rate','VGM', 'Free time in days', 'Telex charges', 'Amedement Fee', 'Doc charges', 'Comments', 'Trade notice', 'Booked'];
      var paramObj1 = ['quotationNumber', 'user', 'from', 'to', 'requestDate', 'commodityName', 'shippingLine', 'effectiveDate', 'expiryDate', 'type','rate', 'vgm', 'freeTimeInDays', 'telexCharges', 'amendemnentFee','docCharges', 'comments', 'tradeNotice', 'booked']
      var paramObj = ['quotationNumber', 'user', 'from', 'to', 'requestDate', 'commodityName', 'booked'];
      var tempArray = [];
      let tempObj: any = {};   
      let empObj = {};
        if (resp && resp.data) {
        resp.data.forEach((each, index) => {  
          if (each.rates.length) {
            each.rates.forEach((rate, i) => {
              for (let param of paramObj) {     
                each.rates[i][param] = i == 0 ?  each[param] : '';
              }
              tempArray.push(each['rates'][i]);      
            });
            // for (let param of paramObj) {
            //   empObj[param] = '';
            // }
            // tempArray.push(empObj);
          } else {
            for (let param of paramObj) {
              tempObj[param] = each[param];
              // empObj[param] = '';
            }
            // each['rates'].push(tempObj);
            // tempArray.push(each['rates'][0]);
            tempObj["shippingLine"] = "No Record Found";
            tempArray.push(tempObj);
          }
        })
        // finalResp = resp;
        // console.log(finalResp);
      }
      let csvData = this.convertToCSV(tempArray, paramObj1, headerName1);
      this.downLoadFile(csvData, 'Quotation Report');
    })
  }

  convertToCSV(objArray, headerNames, originalHeders = []) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let headerList = headerNames;
    let str = '';
    let row:string = '';
    if(originalHeders.length) {
      for (let index in originalHeders) {
        row += originalHeders[index] + ',';
      }
    } else {
      for (let index in headerList) {
        row += headerList[index] + ',';
      }
    }

    row = row.slice(0, -1);
    str += row + "\n";
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        array[i][head] = array[i][head] == undefined ? ' ' : array[i][head];
        array[i]['booked'] = (array[i]['booked'] == false || array[i]['booked'] == 'false') ? ' ' : "Booked";
        if (typeof (array[i][head]) == 'string') {
          array[i][head] = array[i][head].replaceAll(',', '-')
        }
        line += array[i][head] + ',';
      }
      
      line = line.split(",").splice(0,headerList.length).join(",");
      str += line +"\n";
    }
    return str;
  }

 downLoadFile(csvData, fileName) {
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf(
      'Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", fileName + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getQuotations();
  }

  viewQuotationDetail(quotationId: number): void {
    if (this.user != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        this.router.navigate(['customer/quotations-report', this.encryptionService.encrypt(quotationId)]);
      }
      else {
        this.router.navigate(['user/quotations-report', this.encryptionService.encrypt(quotationId)]);
      }
    }
  }

}