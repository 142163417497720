import { AbstractControl, ValidatorFn } from '@angular/forms';

export class TypeaheadValidator {
    static typeaheadValidator(list: any, keyToMatch: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (list != undefined && list.filter(m => { return m[keyToMatch] == control.value }).length == 0) {
                return { 'invalidTypeaheadValue': { value: control.value } }
            }
            return null  /* valid option selected */
        }
    }
}