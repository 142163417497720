import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { FreightConstants, SpotConstants, DateFormats, DisplayModeConstants } from 'src/app/constants/constants';
import { ModalDialogService } from "../../services/modal-dialog/model-dialog.service";
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';
import { DateConstants } from "src/app/constants/constants";
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AddNewSellRateComponent } from '../add-new-sell-rate/add-new-sell-rate.component';
import { Utils } from 'src/app/helpers/utils';

@Component({
  selector: 'inbrit-spot-sell-create',
  templateUrl: './spot-sell-create.component.html',
  styleUrls: ['./spot-sell-create.component.scss']
})
export class SpotSellCreateComponent implements OnInit {
  spotRateSellForm: FormGroup;
  spotRateSellMarginForm: FormGroup;
  spotBuyRate: [any];
  countries: [any];
  carrier: [any];
  isEdit: boolean = false;
  isReadonly: boolean = false;
  submitted: boolean;
  rateSheet: Array<any> = [];
  rateSheetOriginal: Array<any>;
  rateSheetEdit: Array<any> = [];
  countryIds: Array<any> = [];
  carrierIds: Array<any> = [];
  commodityIds: Array<any> = [];
  loadpointIds: Array<any> = [];
  fromPortIds: Array<any> = [];
  toPortIds: Array<any> = [];
  dischargeIds: Array<any> = [];
  containerSizeIds: Array<any> = [];
  containerTypeIds: Array<any> = [];
  groupedFreightRates = Array<{ freightRateId: number, margin: string, backColor: string, freightRates: any }>();
  gridBackColors = ['#e6ffe6', '#ffff99'];
  datePipe = new DatePipe('en-US');
  spotSellTierId: number = 0;
  carriers: Array<any> = [];
  commodities: Array<any> = [];
  loadpoints: Array<any> = [];
  fromPorts: Array<any> = [];
  toPorts: Array<any> = [];
  discharge: Array<any> = [];
  containerSizes: Array<any> = [];
  containerTypes: Array<any> = [];
  countryCode: string;
  carrierId: number = 0;
  spotBuyRateId: number = 0;
  sposellrateId: number = 0;
  FreightConstants = FreightConstants;
  spotStatusId: number = 0;
  spotStatus: number = 0;
  filterCarrierID: Array<any> = [];
  filterCommodityIds: Array<any> = [];
  filterLoadpointIds: Array<any> = [];
  filterFromPortIds: Array<any> = [];
  filterToPortIds: Array<any> = [];
  filterDischargeIds: Array<any> = [];
  filterContainerSizeIds: Array<any> = [];
  filterContainerTypeIds: Array<any> = [];
  margin: string = "+0";
  marginOld: string = "+0";
  user: any;
  appearance: string = 'outline';
  isDisabled: boolean = false;
  DateConstants = DateConstants;
  updatedStatusId: number;
  isUpdateSpotStatus: boolean = false;
  today: Date = new Date();
  displayMode: string = DisplayModeConstants.view;
  DisplayModeConstants = DisplayModeConstants;
  @ViewChild('btnSubmitRateSheetForm') btnSubmitRateSheetForm: ElementRef;
  freightRateContractNo: string = '';
  customSellRatePopupData: { contractNo: string, commodity: string } = { contractNo: '', commodity: '' };
  localFilters: Array<{
    loadpoints: Array<any>, loadpointIds: Array<number>, fromPorts: Array<any>, fromPortIds: Array<number>,
    toPorts: Array<any>, toPortIds: Array<number>, discharge: Array<any>, dischargeIds: Array<number>,
    containerSizes: Array<any>, containerSizeIds: Array<number>, containerTypes: Array<any>, containerTypeIds: Array<number>,
    freightRates: Array<any>
  }>;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private encryptionService: EncryptionService, private router: Router, private modalDialogService: ModalDialogService,
    private userInfoService: UserInfoService, private toastMessageService: ToastMessageService,
    private dialog: MatDialog) {

    var sposellrateId = "", displayMode = "";
    this.activatedRoute.params.subscribe(params => { sposellrateId = params['id'] });
    this.activatedRoute.params.subscribe(params => { displayMode = params['displayMode'] });
    this.user = this.userInfoService.getLoggedInUser();

    if (displayMode != undefined && displayMode != "") {
      this.displayMode = this.encryptionService.decrypt(displayMode);
    }
    else {
      this.displayMode = DisplayModeConstants.edit;
    }

    if (sposellrateId != undefined && sposellrateId != "") {
      this.sposellrateId = parseInt(this.encryptionService.decrypt(sposellrateId));
      this.isEdit = true;
    }

  }

  // convenience getter for easy access to form fields
  get regForm() { return this.spotRateSellForm.controls; }

  ngOnInit() {
    this.today = new Date(this.today.setHours(0, 0, 0, 0));
    this.spotRateSellForm = this.formBuilder.group({
      buyRate: ['', []],
      country: [{ value: this.user.countryOfRegistration, disabled: true }, [Validators.required]],
      carrier: ['', [Validators.required]],
      spotRateName: ['', [Validators.required, Validators.minLength(4)]],
      effectiveDate: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      comments: ['', []]
    });

    this.getCountries();

    this.getCarrier();
    this.getAllBuyRate();

    if (this.isEdit == true) {
      this.getSpotSellRateEditData();
    }
  }

  getSpotSellRateEditData() {
    var param = {
      "SpotId": this.sposellrateId
    }

    this.httpService.httpGet(RouteConstants.FREIGHT_getSpotSellChartRates, param).subscribe(res => {

      if (res['success'] == true) {

        var tierMasterData = res['data'];
        this.rateSheetOriginal = res['data']['spotRateChartList'];
        this.rateSheet = Object.assign([], this.rateSheetOriginal);
        this.rateSheetEdit = this.rateSheet;
        this.initializeFilters();

        this.spotStatusId = tierMasterData.status;
        this.spotStatus = tierMasterData.status;
        // this.margin = tierMasterData.margin;
        // this.marginOld = tierMasterData.margin;
        this.isDisabled = this.displayMode == DisplayModeConstants.view ? true : [SpotConstants.APPROVAL_REJECTED, SpotConstants.BLOCKED, SpotConstants.EXPIRED].includes(tierMasterData.status) ? true : false;
        this.spotRateSellForm.patchValue({
          spotRateName: tierMasterData.rateName,
          buyRate: tierMasterData.spotBuyRateId,
          country: tierMasterData.countryId,
          carrier: tierMasterData.carrierId,
          effectiveDate: tierMasterData.effectiveDate,
          expiryDate: tierMasterData.expiryDate,
          comments: tierMasterData.comments
        });

        // this.margin = tierMasterData.margin;

        this.rateSheet.forEach(item => {
          item['sellRateOriginal'] = this.rateSheetEdit.filter(rateedit => { return rateedit.freightRateChartId == item.freightRateChartId && rateedit.freightRateId == item.freightRateId }).map(sellrate => sellrate.sellRate);
        });

        this.initializeFilterDDLs();

        if (this.spotStatusId != SpotConstants.PENDING_APPROVAL || this.displayMode == DisplayModeConstants.view) {
          this.isReadonly = true;
          this.disableFormfields();
        }

        this.disableFormfields();
        this.customSellRatePopupData = {
          contractNo: this.rateSheetOriginal[0]['contractNo'],
          commodity: this.rateSheetOriginal[0]['commodityId']
        };

      }
    });
  }


  initializeFilters(): void {
    this.filterCarrierID = this.rateSheet.map(m => m.carrierCode).filter((n, i) => this.rateSheet.map(m => m.carrierCode).indexOf(n) === i);
    this.filterCommodityIds = this.rateSheet.map(m => m.commodityName).filter((n, i) => this.rateSheet.map(m => m.commodityName).indexOf(n) === i);
    this.filterLoadpointIds = this.rateSheet.map(m => m.loadpointName).filter((n, i) => this.rateSheet.map(m => m.loadpointName).indexOf(n) === i);
    this.filterFromPortIds = this.rateSheet.map(m => m.fromPortName).filter((n, i) => this.rateSheet.map(m => m.fromPortName).indexOf(n) === i);
    this.filterToPortIds = this.rateSheet.map(m => m.toPortName).filter((n, i) => this.rateSheet.map(m => m.toPortName).indexOf(n) === i);
    this.filterDischargeIds = this.rateSheet.map(m => m.dischargeName).filter((n, i) => this.rateSheet.map(m => m.dischargeName).indexOf(n) === i);
    this.filterContainerSizeIds = this.rateSheet.map(m => m.containerSize).filter((n, i) => this.rateSheet.map(m => m.containerSize).indexOf(n) === i);
    this.filterContainerTypeIds = this.rateSheet.map(m => m.containerType).filter((n, i) => this.rateSheet.map(m => m.containerType).indexOf(n) === i);

    this.carrierIds = Array.from(new Set(this.filterCarrierID));
    this.commodityIds = Array.from(new Set(this.filterCommodityIds));

    this.loadpointIds = Array.from(new Set(this.filterLoadpointIds));
    var isNullLoadPoints = this.loadpointIds.filter(m => m == null);
    if (isNullLoadPoints) {
      this.loadpointIds = this.loadpointIds.filter(m => m != null);
      this.loadpointIds.splice(0, 0, 'null');
    }

    this.fromPortIds = Array.from(new Set(this.filterFromPortIds));
    var isNullFromPorts = this.fromPortIds.filter(m => m == null);
    if (isNullFromPorts) {
      this.fromPortIds = this.fromPortIds.filter(m => m != null);
      this.fromPortIds.splice(0, 0, 'null');
    }

    this.toPortIds = Array.from(new Set(this.filterToPortIds));
    var isNullToPorts = this.toPortIds.filter(m => m == null);
    if (isNullToPorts) {
      this.toPortIds = this.toPortIds.filter(m => m != null);
      this.toPortIds.splice(0, 0, 'null');
    }

    this.dischargeIds = Array.from(new Set(this.filterDischargeIds));
    var isNullDischargeCodes = this.dischargeIds.filter(m => m == null);
    if (isNullDischargeCodes) {
      this.dischargeIds = this.dischargeIds.filter(m => m != null);
      this.dischargeIds.splice(0, 0, 'null');
    }

    this.containerSizeIds = Array.from(new Set(this.filterContainerSizeIds));
    this.containerTypeIds = Array.from(new Set(this.filterContainerTypeIds));
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
    });
  }

  getCarrier() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null, false).subscribe(res => {
      this.carrier = res['data'];
    });
  }

  onBuyContractChange() {

    var param = {
      "SpotId": this.spotRateSellForm.controls['buyRate'].value
    }


    this.httpService.httpPost(RouteConstants.FREIGHT_getSpotBuyRate, param).subscribe(res => {
      if (res['success'] == true) {

        var spotBuyRateData = res['data'][0];

        this.spotRateSellForm.patchValue({
          //buyRate:this.spotBuyRateId,
          country: spotBuyRateData.countryId,
          carrier: spotBuyRateData.carrierId,

        });

        this.countryCode = this.countries.filter(m => m.id == this.spotRateSellForm.controls['country'].value)[0].id;
        this.carrierId = this.carrier.filter(m => m.id == this.spotRateSellForm.controls['carrier'].value)[0].id;
        this.spotBuyRateId = this.spotBuyRate.filter(m => m.id == this.spotRateSellForm.controls['buyRate'].value)[0].id;

      }
    });


  }

  getAllBuyRate() {
    this.httpService.httpGet(RouteConstants.FREIGHT_getallSpotBuyRate, null, false).subscribe(res => {
      this.spotBuyRate = res['data'];
    });
  }

  onFormSubmit(): void {
    this.submitted = true;
  }

  getRateSheets(): void {
    this.btnSubmitRateSheetForm.nativeElement.click();
    if (this.spotRateSellForm.invalid) {
      return;
    }
    var countryId = this.countries.filter(m => m.id == this.spotRateSellForm.controls['country'].value)[0].id;
    this.carrierId = this.carrier.filter(m => m.id == this.spotRateSellForm.controls['carrier'].value)[0].id;
    this.countryCode = countryId;
    var param = { 'CountryId': countryId, 'CarrierId': this.carrierId }
    if (this.regForm.buyRate.value != '' && this.regForm.buyRate.value != null) {
      param['SpotId'] = parseInt(this.regForm.buyRate.value);
    }
    else {
      param['SpotId'] = 0;
    }
    this.httpService.httpPost(RouteConstants.FREIGHT_getSpotSellTierFreightRates, param).subscribe(res => {

      this.rateSheetOriginal = res['data'];
      if (this.isEdit) {
        this.rateSheetOriginal.map(item => { item.tierMasterId = this.spotSellTierId });
      }
      this.rateSheet = Object.assign([], this.rateSheetOriginal);
      var operation = this.margin[0];
      var margin = this.margin.substr(1, this.margin.length - 1);

      if (this.rateSheet && this.rateSheet.length > 0) {
        this.rateSheet.filter(m => { m.sellRate = Utils.calculateSellRate(m.sellRate, margin, operation); });
        this.initializeFilters();
        this.initializeFilterDDLs();
        this.rateSheet.forEach(item => {
          item['sellRateOriginal'] = this.rateSheetEdit.filter(rateedit => { return rateedit.freightRateChartId == item.freightRateChartId && rateedit.freightRateId == item.freightRateId }).map(sellrate => sellrate.sellRate);
        });
      }

      if (this.isEdit) {
        this.filterGrid();
      }
    });
  }


  filterGrid(): void {
    this.rateSheet = this.rateSheetOriginal.filter(m => {
      return (this.countryIds != undefined && this.countryIds.length > 0 ? this.countryIds.indexOf(m.country) != -1 : true) &&
        (this.carrierIds != undefined && this.carrierIds.length > 0 ? this.carrierIds.indexOf(m.carrierCode != null ? m.carrierCode : 'null') != -1 : true) &&
        (this.commodityIds != undefined && this.commodityIds.length > 0 ? this.commodityIds.indexOf(m.commodityName) != -1 : true) &&
        (this.loadpointIds != undefined && this.loadpointIds.length > 0 ? this.loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
        (this.fromPortIds != undefined && this.fromPortIds.length > 0 ? this.fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
        (this.toPortIds != undefined && this.toPortIds.length > 0 ? this.toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
        (this.dischargeIds != undefined && this.dischargeIds.length > 0 ? this.dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&
        (this.containerSizeIds != undefined && this.containerSizeIds.length > 0 ? this.containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
        (this.containerTypeIds != undefined && this.containerTypeIds.length > 0 ? this.containerTypeIds.indexOf(m.containerType) != -1 : true)
    });

    this.initializeGroupedRateSheets();
  }

  resetFilter(): void {
    this.rateSheet = Object.assign([], this.rateSheetOriginal);
    this.initializeFilters();
    this.filterGrid();
  }

  deleteGroups(): void {
    let selectedGroups = this.groupedFreightRates.filter(m => { return m['checked'] == true });
    let _thisRef = this;

    if (selectedGroups.length == this.groupedFreightRates.length) {
      let buttons = [
        { 'title': 'OK', 'result': 'yes', 'type': 'accent' }
      ];
      this.modalDialogService.openDialog('Error', 'You cannot delete all the ratesheets', buttons).subscribe((res) => { });
    }
    else if (selectedGroups.length == 0) {
      let buttons = [
        { 'title': 'OK', 'result': 'yes', 'type': 'accent' }
      ];
      this.modalDialogService.openDialog('Error', 'No item selected for deletion', buttons).subscribe((res) => { });
    }
    else {
      let buttons = [
        { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
        { 'title': 'No', 'result': 'no', 'type': 'default' }
      ];
      this.modalDialogService.openDialog('Confirm', 'Are you sure you want to delete ratesheets?', buttons).subscribe((res) => {
        if (res == 'yes') {
          selectedGroups.forEach(item => {
            let deleteIndex = _thisRef.groupedFreightRates.indexOf(item);
            _thisRef.groupedFreightRates.splice(deleteIndex, 1);
            _thisRef.rateSheet = _thisRef.rateSheet.filter(m => { return m.freightRateId != item.freightRateId });
            _thisRef.rateSheetOriginal = _thisRef.rateSheetOriginal.filter(m => { return m.freightRateId != item.freightRateId });
            _thisRef.localFilters.splice(deleteIndex, 1);
          });
        }
      });
    }
  }

  resetLocalFilter(index: number, group: any): void {
    let groupFreightRates = Object.assign([], this.localFilters[index].freightRates);
    this.localFilters[index].loadpointIds = [];
    this.localFilters[index].fromPortIds = [];
    this.localFilters[index].toPortIds = [];
    this.localFilters[index].dischargeIds = [];
    this.localFilters[index].containerSizeIds = [];
    this.localFilters[index].containerTypeIds = [];
    this.localFilters[index].freightRates = groupFreightRates;

    this.filterLocalGrid(index, group);
  }

  filterLocalGrid(index: number, group: any): void {
    let groupRates = this.rateSheet.filter(m => { return m.freightRateId == group.freightRateId });

    // Remove all items
    groupRates.forEach(item => {
      let deleteIndex = this.rateSheet.indexOf(item);
      if (deleteIndex != -1) {
        this.rateSheet.splice(deleteIndex, 1);
      }
    });

    // Add filtered Items
    group.freightRates = this.localFilters[index].freightRates.filter(m => {
      return (this.localFilters[index].loadpointIds != undefined && this.localFilters[index].loadpointIds.length > 0 ? this.localFilters[index].loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
        (this.localFilters[index].fromPortIds != undefined && this.localFilters[index].fromPortIds.length > 0 ? this.localFilters[index].fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
        (this.localFilters[index].toPortIds != undefined && this.localFilters[index].toPortIds.length > 0 ? this.localFilters[index].toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
        (this.localFilters[index].dischargeIds != undefined && this.localFilters[index].dischargeIds.length > 0 ? this.localFilters[index].dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&
        (this.localFilters[index].containerSizeIds != undefined && this.localFilters[index].containerSizeIds.length > 0 ? this.localFilters[index].containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
        (this.localFilters[index].containerTypeIds != undefined && this.localFilters[index].containerTypeIds.length > 0 ? this.localFilters[index].containerTypeIds.indexOf(m.containerType) != -1 : true)
    });

    group.freightRates.forEach(item => {
      this.rateSheet.push(item);
    });

  }

  toggleAllSelection(control: string, values: Array<string>, isSelectAll: boolean) {
    if (isSelectAll) {
      switch (control) {
        case 'loadpointIds':
        case 'fromPortIds':
        case 'toPortIds':
        case 'dischargeIds':
          this[control] = values.map((value, index) => { return value['title'] });
          break;
        default:
          this[control] = values;
          break;
      }
    }
    else {
      this[control] = [];
    }
    this.filterGrid();
  }

  initializeGroupedRateSheets(): void {
    this.groupedFreightRates = new Array<{ freightRateId: number, margin: string, backColor: string, freightRates: any }>();
    var groupedFreightRateIds = Array.from(new Set(this.rateSheet.map(m => m.freightRateId)));
    this.localFilters = [];

    groupedFreightRateIds.forEach((id, index) => {
      index += 1;
      var freightRates = this.rateSheet.filter(m => { return m.freightRateId == id });
      if (freightRates.length > 0) {
        this.groupedFreightRates.push({
          freightRateId: id,
          margin: '+0',
          backColor: this.gridBackColors[index % 2],
          freightRates: this.rateSheet.filter(m => { return m.freightRateId == id })
        });
      }

      // Create/Reset local filters
      let loadpoints = Array.from(new Set(freightRates.map(m => m.loadpointName))).map((value, index) => { return { title: value, hidden: false } });
      var isNullLoadPoints = loadpoints.filter(m => m.title == null);
      if (isNullLoadPoints) {
        loadpoints = loadpoints.filter(m => m.title != null);
        loadpoints.sort();
        loadpoints.splice(0, 0, { title: 'null', hidden: false });
      }

      let fromPorts = Array.from(new Set(freightRates.map(m => m.fromPortName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullFromPorts = fromPorts.filter(m => m.title == null);
      if (isNullFromPorts) {
        fromPorts = fromPorts.filter(m => m.title != null);
        fromPorts.sort();
        fromPorts.splice(0, 0, { title: 'null', hidden: false });
      }

      let toPorts = Array.from(new Set(freightRates.map(m => m.toPortName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullToPorts = toPorts.filter(m => m.title == null);
      if (isNullToPorts) {
        toPorts = toPorts.filter(m => m.title != null);
        toPorts.sort();
        toPorts.splice(0, 0, { title: 'null', hidden: false });
      }

      let discharge = Array.from(new Set(freightRates.map(m => m.dischargeName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullDischargePorts = discharge.filter(m => m.title == null);
      if (isNullDischargePorts) {
        discharge = discharge.filter(m => m.title != null);
        discharge.sort();
        discharge.splice(0, 0, { title: 'null', hidden: false });
      }

      let containerSizes = Array.from(new Set(freightRates.map(m => m.containerSize)));
      let containerTypes = Array.from(new Set(freightRates.map(m => m.containerType)));
      if (index == 0) {
        this.localFilters = [{
          loadpoints: loadpoints, loadpointIds: [],
          fromPorts: fromPorts, fromPortIds: [],
          toPorts: toPorts, toPortIds: [],
          discharge: discharge, dischargeIds: [],
          containerSizes: containerSizes, containerSizeIds: [],
          containerTypes: containerTypes, containerTypeIds: [],
          freightRates: freightRates
        }]
      }
      else {
        this.localFilters.push({
          loadpoints: loadpoints, loadpointIds: [],
          fromPorts: fromPorts, fromPortIds: [],
          toPorts: toPorts, toPortIds: [],
          discharge: discharge, dischargeIds: [],
          containerSizes: containerSizes, containerSizeIds: [],
          containerTypes: containerTypes, containerTypeIds: [],
          freightRates: freightRates
        });
      }

    });
  }

  getGroupDetails(freightRateId: number) {
    var group = this.rateSheetOriginal.filter(m => { return m.freightRateId == freightRateId });
    if (group.length > 0) {
      return group[0].carrierName + "<br>" +
        "Effective Date : " + this.datePipe.transform(group[0].effectiveDate, DateFormats.STANDARD_DATE) + "<br>" +
        "Expiry Date : " + this.datePipe.transform(group[0].expiryDate, DateFormats.STANDARD_DATE) + "<br>" +
        "Commodity : " + group[0].commodityName + "<br>" +
        "Created Date : " + this.datePipe.transform(group[0].createdDate, DateFormats.STANDARD_DATE) +
        ((group[0].remarks == null || group[0].remarks.length == 0) ? "" : "<br> Remarks : " + group[0].remarks);
    }
  }

  initializeFilterDDLs(): void {

    this.carriers = Array.from(new Set(this.rateSheet.map(m => m.carrierCode)));
    this.commodities = Array.from(new Set(this.rateSheet.map(m => m.commodityName)));

    this.loadpoints = Array.from(new Set(this.rateSheet.map(m => m.loadpointName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullLoadPoints = this.loadpoints.filter(m => m.title == null);
    if (isNullLoadPoints) {
      this.loadpoints = this.loadpoints.filter(m => m.title != null);
      this.loadpoints.sort();
      this.loadpoints.splice(0, 0, { title: 'null', hidden: false });
    }

    this.fromPorts = Array.from(new Set(this.rateSheet.map(m => m.fromPortName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullFromPorts = this.fromPorts.filter(m => m.title == null);
    if (isNullFromPorts) {
      this.fromPorts = this.fromPorts.filter(m => m.title != null);
      this.fromPorts.sort();
      this.fromPorts.splice(0, 0, { title: 'null', hidden: false });
    }

    this.toPorts = Array.from(new Set(this.rateSheet.map(m => m.toPortName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullToPorts = this.toPorts.filter(m => m.title == null);
    if (isNullToPorts) {
      this.toPorts = this.toPorts.filter(m => m.title != null);
      this.toPorts.sort();
      this.toPorts.splice(0, 0, { title: 'null', hidden: false });
    }

    this.discharge = Array.from(new Set(this.rateSheet.map(m => m.dischargeName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullDischargePorts = this.discharge.filter(m => m.title == null);
    if (isNullDischargePorts) {
      this.discharge = this.discharge.filter(m => m.title != null);
      this.discharge.sort();
      this.discharge.splice(0, 0, { title: 'null', hidden: false });
    }


    this.containerSizes = Array.from(new Set(this.rateSheet.map(m => m.containerSize)));
    this.containerTypes = Array.from(new Set(this.rateSheet.map(m => m.containerType)));
    this.initializeGroupedRateSheets();
  }

  getFilterCount(): number {
    var filterCount = 0;
    filterCount += this.carrierIds.length > 0 ? 1 : 0;
    filterCount += this.commodityIds.length > 0 ? 1 : 0;
    filterCount += this.loadpointIds.length > 0 ? 1 : 0;
    filterCount += this.fromPortIds.length > 0 ? 1 : 0;
    filterCount += this.toPortIds.length > 0 ? 1 : 0;
    filterCount += this.dischargeIds.length > 0 ? 1 : 0;
    filterCount += this.containerSizeIds.length > 0 ? 1 : 0;
    filterCount += this.containerTypeIds.length > 0 ? 1 : 0;
    return filterCount;
  }

  showFiltersApplied(): boolean {
    var isFilterApplied = !(this.carrierIds.length == this.carriers.length && this.commodityIds.length == this.commodities.length &&
      this.loadpointIds.length == this.loadpoints.length && this.fromPortIds.length == this.fromPorts.length &&
      this.toPortIds.length == this.toPorts.length && this.dischargeIds.length == this.discharge.length &&
      this.containerSizeIds.length == this.containerSizes.length && this.containerTypeIds.length == this.containerTypes.length);

    if (this.getFilterCount() > 0 && isFilterApplied) {
      return true;
    }
    else {
      return false;
    }
  }

  getGroupCarrierDetails(freightRateId: number) {
    var group = this.rateSheetOriginal.filter(m => { return m.freightRateId == freightRateId });
    if (group.length > 0) {
      return 'assets/images/carriers/' + group[0].carrierCode + '/' + group[0].carrierCode + '1.png';
    }
  }


  saveSpotTierRates(): void {
    this.submitted = true;
    if (this.spotRateSellForm.invalid) {
      return;
    }

    let customRates = this.rateSheet.filter(m => { return m.freightRateId == 0 });
    let groupedRates = Utils.groupBy(customRates, function (group) {
      return [
        group.loadpointId, group.fromPortId, group.toPortId,
        group.dischargeId, group.containerType, group.containerSize
      ];
    });
    let duplicateItems = [];
    groupedRates.forEach(item => {
      if (item.length > 1) {
        duplicateItems.push(item[0]);
      }
    });

    if (duplicateItems.length > 0) {
      let buttons = [{ 'title': 'Ok', 'result': 'yes', 'type': 'accent' }];
      let message = 'Cannot add duplicate rates with <ul>';
      duplicateItems.forEach(duplicateItem => {
        if (duplicateItem != null) {
          message += `<li> load point : ${duplicateItem.loadpointName},
          from port : ${duplicateItem.fromPortName}, to port : ${duplicateItem.toPortName}
          ${duplicateItem.dischargeId != 0 ? 'discharge port : ' + duplicateItem.dischargeName : ''}
          size : ${duplicateItem.containerSize} and type : ${duplicateItem.containerType} </li>`;
        }
      });
      message += '</ul>';
      this.modalDialogService.openDialog('Error', message, buttons);
      return;
    }

    this.countryCode = this.countries.filter(m => m.id == this.spotRateSellForm.controls['country'].value)[0].id;
    this.carrierId = this.carrier.filter(m => m.id == this.spotRateSellForm.controls['carrier'].value)[0].id;
    this.spotBuyRateId = this.spotBuyRate.filter(m => m.id == this.spotRateSellForm.controls['buyRate'].value).length == 0 ? 0 : this.spotBuyRate.filter(m => m.id == this.spotRateSellForm.controls['buyRate'].value)[0].id;

    var tierParam = {
      'Id': this.sposellrateId,
      'SpotBuyRateId': this.spotBuyRateId == undefined ? 0 : this.spotBuyRateId,
      'RateName': this.spotRateSellForm.controls['spotRateName'].value,
      'CountryId': this.countryCode,
      'CarrierId': this.carrierId,
      'effectiveDate': this.datePipe.transform(this.spotRateSellForm.controls['effectiveDate'].value, 'yyyy-MM-dd'),
      'expiryDate': this.datePipe.transform(this.spotRateSellForm.controls['expiryDate'].value, 'yyyy-MM-dd'),
      'Margin': this.margin,
      'IsCustomRatesheet': false,
      'ContractNo': this.freightRateContractNo,
      'CountryCode': this.countries.filter(m => m.id == this.spotRateSellForm.controls['country'].value)[0].countryCode,
      'Comments': this.spotRateSellForm.controls['comments'].value
    }

    var tierSellRate = [];

    this.rateSheet.forEach(item => {
      tierSellRate.push({
        'SpotRateSellId': item.spotRateSellId,
        'FreightRateId': item.freightRateId,
        'FreightRateChartId': item.id,
        'LoadpointId': item.loadpointId,
        'FromPortId': item.fromPortId,
        'ToPortId': item.toPortId,
        'DischargeId': item.dischargeId,
        'CommodityId': item.commodityId,
        'CarrierId': item.carrierId,
        'ContainerType': item.containerType,
        'ContainerSize': item.containerSize,
        'AllInUsd': item.allInUsd,
        'SellRate': item.sellRate,
        'sellRateOriginal': item.sellRate,
        'SailingDay': item.sailingDay,
        'Route': item.route,
        'FreeTime': item.freeTime,
        'TelexCharges': item.telexCharges,
        'AmendmentFees': item.amendmentFees,
        'DocFees': item.docFees,
        '_40stdFee': item._40stdFee,
        'WarRisk': item.warRisk,
        'Ecu': item.ecu,
        'Lsc': item.lsc,
        'Wha': item.wha,
        'NycPortFee': item.nycPortFee,
        'Total': item.total,
        'Vgm': item.vgm,
        'Comments': item.comments,
      });
    });

    tierParam['SpotRateChartList'] = tierSellRate;
    this.httpService.httpPost(RouteConstants.FREIGHT_saveSpotFreightRates, tierParam).subscribe(res => {

      if (res['success'] == true) {
        if (this.isUpdateSpotStatus) {
          this.isUpdateSpotStatus = false;
          this.updateSpotRateStatusStatus();
        }
        else {
          this.router.navigate(['/user/spot-sell']);
          return true;
        }
      }
      else
        return false;
    });
  }

  deleteRatesheet(freightRateId: number, deleteIndex: number): void {
    var _thisRef = this;
    var buttons = [
      { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
      { 'title': 'No', 'result': 'no', 'type': 'default' }
    ];

    this.modalDialogService.openDialog('Confirm', 'Are you sure you want to delete ratesheet?', buttons).subscribe((res) => {
      if (res == 'yes') {
        var filteredGroups = this.groupedFreightRates.filter(m => { return m.freightRateId == freightRateId });
        if (filteredGroups.length == 1) {
          this.groupedFreightRates.splice(this.groupedFreightRates.indexOf(filteredGroups[0]), 1);
          _thisRef.rateSheet = _thisRef.rateSheet.filter(m => { return m.freightRateId != freightRateId });
          _thisRef.rateSheetOriginal = _thisRef.rateSheetOriginal.filter(m => { return m.freightRateId != freightRateId });
        }
        this.localFilters.splice(deleteIndex, 1);
      }
    });
  }

  approveRejectFreight(statusId: number): void {

    this.updatedStatusId = statusId;

    this.isUpdateSpotStatus = true;
    this.saveSpotTierRates();///check if page has changed
  }

  updateSellRate(isFieldValid: boolean, addToMargin: boolean): void {

    if (isFieldValid && this.margin.length > 0) {

      var buttons = [
        { 'title': 'Yes', 'result': 'yes', 'type': 'primary' },
        { 'title': 'No', 'result': 'no', 'type': 'accent' }
      ];

      this.modalDialogService.openDialog(
        'Confirm',
        'Are you sure you want to update all margin?',
        buttons).subscribe((res) => {
          if (res == 'yes') {

            let marginText = this.margin;
            if (marginText.length > 0) {
              let operation = marginText[0];
              let margin = marginText.substr(1, marginText.length - 1);
              this.groupedFreightRates.filter(m => { m.margin = marginText });
              this.marginOld = this.margin;

              if (['+', '-', '*'].includes(operation)) {
                this.rateSheet.forEach(item => {
                  if (parseFloat(item['sellRate']) > 0) {
                    item['sellRate'] = Utils.calculateSellRate(addToMargin ? item['sellRate'] : item['total'], margin, operation);
                  }
                });
              }
            }
            this.toastMessageService.toast('top', 'center', 'success', 'Global margin applied successfully');
          } else {
            this.margin = this.marginOld;

          }
        });
    }

  }


  updateMarginSellRate(freightRateId: number, margin: string, isFieldValid: boolean, addToMargin: boolean): void {

    var marginText = margin;
    if (isFieldValid && marginText.length > 0) {
      var operation = marginText[0];
      var margin = marginText.substr(1, marginText.length - 1);

      if (['+', '-', '*'].includes(operation)) {
        this.rateSheet.filter(m => { return m.freightRateId == freightRateId }).forEach(item => {
          if (parseFloat(item['sellRate']) > 0) {
            item['sellRate'] = Utils.calculateSellRate(addToMargin ? item['sellRate'] : item['total'], margin, operation);
          }
        });
      }

      this.toastMessageService.toast('top', 'center', 'success', 'Margin applied successfully');
    }
  }

  updateSpotRateStatusStatus(): boolean {

    var param = {
      'SpotId': this.sposellrateId,
      "ListStatusId": [this.updatedStatusId]
    }
    this.httpService.httpPost(RouteConstants.FREIGHT_changeSpotRateStatus, param).subscribe(res => {
      if (res['success'] == true) {
        this.router.navigate(['/user/spot-sell']);
        return true;
      }
      else
        return false;
    });
    return false;
  }

  returnStatusText(status: number) {
    if (status > 0)
      return SpotConstants.SPOT_STATUSES.find(m => m.id == status).description;

  }

  addCustomRates(): void {
    this.btnSubmitRateSheetForm.nativeElement.click();
    if (this.spotRateSellForm.invalid) {
      return;
    }

    if (this.customSellRatePopupData.commodity == '') {
      let customRate = this.rateSheet.find(m => { return m.freightRateId == 0 });
      if (customRate != undefined) {
        this.customSellRatePopupData = { contractNo: '', commodity: customRate.commodityId }
      }
    }

    this.disableFormfields();
    const dialogRef = this.dialog.open(AddNewSellRateComponent, {
      width: '60%',
      data: this.customSellRatePopupData// Send ContractNo, Commodity
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.customSellRatePopupData = { contractNo: result.contractNo, commodity: result.commodity };
        this.addRatesheetNewRow(result);
      }
    });
  }

  addRatesheetNewRow(data: any): void {
    if (this.rateSheetOriginal == undefined) {
      this.rateSheetOriginal = [];
    }

    this.freightRateContractNo = data.contractNo;

    var newRatesheetLocations = data['locations'] as Array<any>;
    if (newRatesheetLocations.length > 0) {
      newRatesheetLocations.forEach(loc => {
        this.rateSheetOriginal.push({
          id: 0,
          spotRateSellId: this.sposellrateId,
          freightRateId: 0, // Newly added custom rates must always have freightRateId as zero
          freightRateChartId: 0,
          loadpointId: loc.loadPoint,
          loadpointName: loc.loadpointName,
          fromPortId: loc.fromPort,
          fromPortName: loc.fromPortName,
          toPortId: loc.toPort,
          toPortName: loc.toPortName,
          dischargeId: loc.dischargePort,
          dischargeName: loc.dischargeName,
          effectiveDate: this.datePipe.transform(this.regForm.effectiveDate.value, 'dd-MMM-yyyy'),
          expiryDate: this.datePipe.transform(this.regForm.expiryDate.value, 'dd-MMM-yyyy'),
          carrierId: this.regForm.carrier.value,
          carrierCode: this.carrier.filter(m => { return m.id == this.regForm.carrier.value })[0].carrierCode,
          carrierName: this.carrier.filter(m => { return m.id == this.regForm.carrier.value })[0].carrierName,
          commodityId: data.commodity,
          commodityName: data.commodityName,
          containerType: data.type,
          containerSize: data.size,
          allInUsd: data.allInUsd,
          sellRate: data.allInUsd,
          sailingDay: "",
          route: "",
          telexCharges: data.telexCharges,
          amendmentFees: data.amendmentFees,
          docFees: data.docFees,
          _40stdFee: 0,
          warRisk: 0,
          ecu: 0,
          lsc: 0,
          wha: 0,
          nycPortFee: 0,
          total: data.allInUsd,
          freeTime: data.freeTime,
          vgm: 0,
          comments: '',
          createdDate: new Date()
        });
      });
    }
    this.rateSheet = Object.assign([], this.rateSheetOriginal);

    this.initializeFilters();
    this.initializeFilterDDLs();
  }

  disableFormfields(): void {
    // Disable the form fields
    Object.keys(this.spotRateSellForm.controls).forEach(key => {
      this.spotRateSellForm.get(key).disable();
    });
    this.appearance = 'fill';
  }
}
