
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../services/encryption/encryption.service";
import { ActivatedRoute } from "@angular/router";
import {
  ProfileStatusConstants, Pagination, GridColumnType,
  GridColumnDataType, GridActionType, FreightConstants
} from "../constants/constants";
import { TitleCasePipe } from "@angular/common";
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'inbrit-freight-difference',
  templateUrl: './freight-difference.component.html',
  styleUrls: ['./freight-difference.component.scss']
})
export class FreightDifferenceComponent implements OnInit {
  @Input() rowData: any;
  filterFreightListForm: FormGroup;
  @ViewChild('formSubmitButton') formSubmitButton;
  freightId: number;
  freightList: [any];
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  setGreenColor: any;
  setRedColor: any;
  setYellowColor: any;
  setLineThrough: any;
  FreightConstants = FreightConstants;
  isRateSheetForApproval: boolean;
  freightStatusId: number;

  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      // { title: 'Loadpoint', dataField: 'loadpoint', type: GridColumnType.DATA, },
      { title: 'From Port', dataField: 'fromPortCode', type: GridColumnType.DATA, },
      { title: 'To Port', dataField: 'toPortCode', type: GridColumnType.DATA, },
      { title: 'Size', dataField: 'size', type: GridColumnType.DATA, },
      { title: 'Type', dataField: 'type', type: GridColumnType.DATA, },
      { title: 'AllInUSD', dataField: 'allInUsd', type: GridColumnType.DATA, },
    ]

  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder) {
    // this.activatedRoute.params.subscribe(params => { this.freightId = params['id'] });
  }

  ngOnInit() {
    this.freightId = this.rowData.id;

    this.filterFreightListForm = this.formBuilder.group({
      filterFreightIds: ['', []],
    });

    this.getFreightRateDiff();
  }


  get regForm() { return this.filterFreightListForm.controls; }


  getFreightRateDiff(): void {
    var param = { 'FreightId': this.freightId, 'CompFreightId': '0' };
    this.httpService.httpPost(RouteConstants.FREIGHT_getFreightRateDiff, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.isRateSheetForApproval = true;
        this.dataCount = res['count'];
        this.freightList = data;
      }
    });
  }

  clearFilter(): void {
    this.filterFreightListForm.reset();

  }

  filterFormSubmit(): void {

  }


  approveRejectFreight(statusId: number): void {


    this.freightStatusId = statusId;
    if (this.updateFreightStatus()) {
      this.router.navigate(['user/freights']);
    }

    this.formSubmitButton.nativeElement.click();

  }


  updateFreightStatus(): boolean {
    var param = {
      'Id': this.freightId,
      'Status': FreightConstants.APPROVED
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_changeFreightStatus, param).subscribe(res => {

      if (res['success'] == true) {
        return true;
      }
      else
        return false;


    });

    return false;

  }





}
