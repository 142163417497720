import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { HttpService } from "../../services/http/http.service";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";
import { RouteConstants } from "../../constants/route-constants";
import { UserRoleConstants, SpotConstants } from '../../constants/constants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';


@Component({
  selector: 'inbrit-spot-sell-user',
  templateUrl: './spot-sell-user.component.html',
  styleUrls: ['./spot-sell-user.component.scss']
})
export class SpotSellUserComponent implements OnInit {
  spotList: [any];
  unAssignedCustomers = [];
  customerList: [any];
  pageIndex = 1;
  spotContainers = [];
  spotUserMappingDelta = [];
  spotId: number = 0;
  titleCasePipe = new TitleCasePipe();
  defaultSpotId: number = 0;
  filterDialogRef: MatDialogRef<{}, any>;
  totalFiltersApplied: number = 0;
  filterSpotUserLinkForm: FormGroup;
  SpotConstants = SpotConstants;
  uniqueCacheKey: string = 'SpotSellUserComponent-filter';
  spotRateSearchText: string = '';
  customerSearchText: string = '';
  spotStatusIds = [];
  masterTierList: Array<any> = [];
  break:string=  '<br>';
  toggle={};
  toggleTier={};
  selectedCustomer: Array<any> = [];

  constructor(private httpService: HttpService, private toastMessageService: ToastMessageService,
    private formBuilder: FormBuilder, private dialog: MatDialog, private userInfoService: UserInfoService,
    private cacheService: CacheService) { }

  get filterFormFields() { return this.filterSpotUserLinkForm.controls; }

  ngOnInit() {
    this.initializeFilterForm();
    this.getAllSpots();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterSpotUserLinkForm = this.formBuilder.group({
      spotrateStatusIds: [cachedFilter != null ? cachedFilter.value.spotrateStatusIds.value : [], []]
    });
  }


  getAllSpots() {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": 0,
      "ListStatusId": [SpotConstants.APPROVED, SpotConstants.BLOCKED, SpotConstants.APPROVAL_REJECTED, SpotConstants.PENDING_APPROVAL],
      "SearchText": ""
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_getAllSpotRate, param).subscribe(res => {
      if (res['success'] == true) {
        this.spotList = res['data'];
        this.getCustomers();
      }
    });
  }

  getAllTiers(): void {
    var param = { "PageIndex": this.pageIndex, "PageSize": 0, "SearchText": "" }
    this.httpService.httpPost(RouteConstants.FREIGHT_getAllTierMaster, param, false).subscribe(res => {
      if (res['success'] == true) {
        this.masterTierList = res['data'];
        this.extractTierUsers();
      }
    });
  }

  getCustomers(): void {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": 0,
      "StatusIds": [],
      "KycStatusIds": [],
      "RoleIds": [UserRoleConstants.CUSTOMER]
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_getUnassignedCustomer, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];

        data.forEach(item => {
          item['fullName'] = this.titleCasePipe.transform(item['firstName'] + ' ' + item['lastName']);
          item['class'] = '';
          item['isHidden'] = false
        });
        this.customerList = data;
        this.extractSpotUsers();
        this.getAllTiers();
      }
    });
  }
 

  // showBtn=0;
  openAccordion(index){ 
    this.toggle[index]=!this.toggle[index];
  }
  openAccordionTier(indexT){ 
    this.toggleTier[indexT]=!this.toggleTier[indexT];
  }

  toggleSelection(customer: any, id = "tier") {
    var check:boolean = true;
    if(id == "tier"){
      if(this.selectedCustomer.length > 0){
        check = this.selectedCustomer.every(m =>{return m.id == "tier"});}
    }
    else{
      if(this.selectedCustomer.length > 0){
        check = this.selectedCustomer.every(m =>{return  m.id != "tier"});}
    }
    if(check){
      customer['class'] = customer['class'] == '' ? 'selected' : '';
      if (customer['class'] != "") {
        this.selectedCustomer.push({"customer": customer,"id":id});
      }
      else {
        var index = this.selectedCustomer.findIndex(cust => cust['customer'] == customer);
        this.selectedCustomer.splice(index, 1);
      }
    }
  }

  getstatus(strStatus: number) {
    return SpotConstants.SPOT_STATUSES.find(m => m.id == strStatus).description;
  }

  getClassColor(strStatus: number) {

    return this.userInfoService.getColorClass(strStatus);
  }

  extractSpotUsers() {

    this.unAssignedCustomers = this.customerList.filter(m => { return m.spotId == null || m.spotId == 0 });
    this.unAssignedCustomers.forEach(customer => {
      customer['isHidden'] = false;
    });

    // this.spotContainers.push('cdk-drop-list-0');
    this.spotList.forEach(spot => {
      this.spotContainers.push('spot-cdk-drop-list-' + spot.id);
      spot['spotContainerName'] = "spot-cdk-drop-list-" + spot.id;
      spot['customerList'] = this.customerList.filter(m => { return m.spotId == spot.id });
      spot['isHidden'] = false;
    });


    this.applyFilters();
  }

  extractTierUsers() {
    // this.unAssignedCustomers = this.customerList.filter(m => { return m.tierId == null || m.tierId == 0 });
    this.masterTierList.forEach(tier => {
      // this.spotContainers.push('tier-master-cdk-drop-list-' + tier.tierId.toString());
      tier['customerList'] = this.unAssignedCustomers.filter(m => { return m.tierId == tier.tierId });
      tier['isHidden'] = false;
    });
  }
  

 mapUnAssignedTierUsers() {
    this.masterTierList.forEach(tier => {
      tier['customerList'] = this.unAssignedCustomers.filter(m => { m.class='';
        return m.tierId == tier.tierId });
      tier['isHidden'] = false;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    let trimtext: any = event.container.id;
    this.toggle[trimtext.split("-").pop()] = true;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Check if user has selected multiple items to drag
      this.selectedCustomer.forEach(m => {
        if (!event.previousContainer.data.includes(m.customer) && this.selectedCustomer.every(m =>{ return m.id.slice(0,4) == event.previousContainer.id.slice(0,4)})) {
          event.previousContainer.data.push(m.customer)
        }
      })
      var selectedItems = event.previousContainer.data.filter(m => { return m['class'] == 'selected' });

      if (selectedItems.length > 0) {
        var prevItemIndex = 0;

        selectedItems.forEach(item => {
          prevItemIndex = event.previousContainer.data.indexOf(item);

          // Update the delta list first and then move the item
          this.updateMappingDelta(event.container.id, item);

          transferArrayItem(event.previousContainer.data,
            event.container.data,
            prevItemIndex,
            event.currentIndex);
          // removed the selection
          item['class'] = '';
        });

      }
      else {

        // Update the delta list first and then move the item
        this.updateMappingDelta(event.container.id, event.previousContainer.data[event.previousIndex]);

        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }

      if (event.previousContainer.id.toString().startsWith("tier-master")) {

        event.container.data.forEach(customer => {
          if (this.unAssignedCustomers.some(m => { return m.id == customer['id'] })) {
            var itemToDelete = this.unAssignedCustomers.find(m => { return m.id == customer['id'] });
            this.unAssignedCustomers.splice(this.unAssignedCustomers.indexOf(itemToDelete), 1);
          }
        })
      }


      if (event.previousContainer.id.toString().startsWith("spot-cdk-drop-list")) {
        this.selectedCustomer.forEach(cust => {
          this.spotList.forEach(spotMaster => {
            spotMaster.customerList.forEach(m => m.class='')
            if (spotMaster.spotContainerName == cust.id) {
              var index = spotMaster.customerList.filter(m => { return m.id == cust.customer['id'] });
              if(index.length > 0)
                spotMaster.customerList.splice(spotMaster.customerList.indexOf(index[0]),1);
            }
          });
        });
      }

      this.mapUnAssignedTierUsers();
    }
    this.selectedCustomer.forEach(m =>{
      m.customer.class ="";
    })
    this.selectedCustomer = [];
  }
  
  updateMappingDelta(containerId: string, customer: any): void {
    var containerId = containerId;
    var arr = containerId.split('-');
    var newSpotId = parseInt(arr[arr.length - 1]);
    var newItem = { 'SpotId': newSpotId, 'UserId': customer.id }
    var existingData = this.spotUserMappingDelta.filter(m => { return m.UserId == customer.id });

    if (existingData.length > 0) {
      this.spotUserMappingDelta.splice(this.spotUserMappingDelta.indexOf(existingData[0]), 1);
    }

    this.spotUserMappingDelta.push(newItem);
  }

  saveSpotUserMappings(): void {

    if (this.spotUserMappingDelta.length == 0) {
      this.toastMessageService.toast('bottom', 'center', 'danger', "No change detected in maapings.");
      return;
    }

    var param = { 'Users': this.spotUserMappingDelta.map(m => { return { 'Id': m.UserId, 'SpotId': m.SpotId } }) };
    this.httpService.httpPost(RouteConstants.FREIGHT_saveSpotUserMapping, param).subscribe(res => {
      this.spotUserMappingDelta = [];
    });
  }


  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  filterFormSubmit() {
    this.spotRateSearchText = '';
    this.applyFilters();
    // this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterSpotUserLinkForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.filterSpotUserLinkForm.reset();
    this.filterSpotUserLinkForm.patchValue({
      spotrateStatusIds: []
    });
    this.applyFilters();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  applyFilters(): void {
    this.totalFiltersApplied = 0;
    this.spotStatusIds = [];
    if (this.filterFormFields.spotrateStatusIds.value.length > 0) {
      this.totalFiltersApplied++;
      this.filterFormFields.spotrateStatusIds.value.forEach(item => {
        this.spotStatusIds.push(item);
      });
    }

    this.spotList.forEach(spot => {
      if (this.spotStatusIds.indexOf(spot.status) == -1 && this.spotStatusIds.length > 0) {
        spot['isHidden'] = true;
      } else {
        spot['isHidden'] = false;
      }
    });

  }

  searchSpotRates(): void {
    var searchText = this.spotRateSearchText;
    this.spotList.filter(m => { return (this.spotStatusIds.length > 0 ? this.spotStatusIds.includes(m.status) : true) }).forEach(spot => {
      if (!spot.rateName.toLowerCase().includes(searchText.toLowerCase())) {
        spot['isHidden'] = true;
      }
      else {
        spot['isHidden'] = false;
      }
    });
  }

  searchCustomers(): void {
    var searchText = this.customerSearchText;
    this.spotList.forEach(spot =>
      spot.customerList.forEach(customer => {
        customer['isHidden'] = false;
        if (!(customer.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          customer['isHidden'] = true;
        }
      })
    );

    this.unAssignedCustomers.forEach(customer => {
      customer['isHidden'] = false;
      if (!(customer.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchText.toLowerCase()))
      ) {
        customer['isHidden'] = true;
      }
    });
  }

  removeCustomer(spotMaster: any, customer: any): void {
    customer['class'] = '';
    var deleteIndex = this.selectedCustomer.findIndex(cust => cust['customer'] == customer);
    this.selectedCustomer.splice(deleteIndex, 1);
    deleteIndex = spotMaster.customerList.indexOf(customer);
    spotMaster.customerList.splice(deleteIndex, 1);
    this.updateMappingDelta('cdk-drop-list-0', customer);
    this.unAssignedCustomers.push(customer);
    this.mapUnAssignedTierUsers();
  }
}
