import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { ActivatedRoute } from "@angular/router";
import { UserRoleConstants, ProfileStatusConstants, KycStatusConstants } from "../../constants/constants";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { Location } from "@angular/common";
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Component({
  selector: 'inbrit-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userProfileForm: FormGroup;
  countries: [any];
  states: [any];
  userId: number = 0;
  submitted: boolean;
  isLoading: boolean = false;
  confirmPasswordError: boolean;
  userTitles: Array<{ id: number, title: string, isActive: boolean }> = [];
  userRoles: Array<{ id: number, role: string, description: string }> = [];
  tierMasters = [];
  ProfileStatusConstants = ProfileStatusConstants;
  UserRoleConstants = UserRoleConstants;
  KycStatusConstants = KycStatusConstants;
  @ViewChild('formSubmitButton') formSubmitButton;
  salesPersons = [];
  countryId: number;
  countryCode: String;
  stateId: number;
  stateCode: String;
  statusId: number;
  @Input() isProfileForApproval: boolean = false;
  kycDueInDays: number = 0;
  registrationStatus: string = '';
  isEditDisabled: boolean = false;
  user: any;
  userDetails: any;
  isEdit: boolean = false;
  appearance: string = 'fill';
  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private encryptionService: EncryptionService, private activatedRoute: ActivatedRoute,
    private location: Location, private userInfoService: UserInfoService) {
    this.activatedRoute.params.subscribe(params => { this.userId = params['id'] });

    if (this.userId != 0)
      this.isEdit = true;
    this.appearance = 'outline';
  }

  ngOnInit() {
    this.user = this.userInfoService.getLoggedInUser();

    this.userRoles = UserRoleConstants.USER_ROLES;

    this.initializeForm();
    this.getCountries();
    this.getUserTitles();
    this.getUserDetials();
    // this.getAllTierMasters();
  }

  initializeSalesPersonFilters() {

    this.userProfileForm.controls['salesPersonId'].valueChanges.subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        var param = {
          'RoleIds': [UserRoleConstants.USER]
        }
        this.httpService.httpPost(RouteConstants.ACCOUNT_getUsers + "?searchText=" + val, param).subscribe(res => {
          this.salesPersons = res['data'];
        });
      }
    });
  }

  initializeTierFilters() {

    this.userProfileForm.controls['tierId'].valueChanges.subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        this.httpService.httpGet(RouteConstants.MASTER_getApprovedTierMaster, { 'searchText': val }).subscribe(res => {
          this.tierMasters = res['data'];
        });
      }
    });
  }


  // convenience getter for easy access to form fields
  get regForm() { return this.userProfileForm.controls; }


  initializeForm() {

    this.userProfileForm = this.formBuilder.group({
      countryOfRegistration: [{ value: '', disabled: true }, []],
      id: ['', []],
      roleId: [{ value: '', disabled: true }, []],
      title: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      // userName: [{ value: '', disabled: true }, []],
      password: [{ value: '', disabled: true }, []],
      confirmPassword: [{ value: '', disabled: true }, []],
      firstName: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      lastName: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      email: [{ value: '', disabled: true }, []],
      profilePhoto: ['', []],
      mobileCode: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      mobileNumber: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      registrationStatus: [{ value: '', disabled: true }, []],
      companyName: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      companyDepartment: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      companyWebsite: [{ value: '', disabled: this.isEditDisabled }],
      companyAddress: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      country: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      state: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      city: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      zipCode: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      landlineCode: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      landlineNumber: [{ value: '', disabled: this.isEditDisabled }, [Validators.required]],
      statusId: ['', []],
      tierId: ['', []],
      salesPersonId: ['', []],
      comments: ['', []]
    });
  }

  getUserDetials(): void {
    this.httpService.httpGet(RouteConstants.ACCOUNT_getUser, { 'userId': this.encryptionService.decrypt(this.userId) }).subscribe(res => {
      if (res['success'] == true) {
        this.userDetails = res['data'];
        var companyDetails = res['data']['userCompanyDetail'];

        if (this.userDetails.kycStatusId == ProfileStatusConstants.CONFIRMED ||
          this.userDetails.roleId == UserRoleConstants.USER || this.isProfileForApproval) {
          this.isEditDisabled = true;
          this.userProfileForm.controls['country'].disable();
          this.userProfileForm.controls['state'].disable();
          this.userProfileForm.controls['title'].disable();
          this.userProfileForm.controls['mobileCode'].disable();
          this.userProfileForm.controls['landlineCode'].disable();
          this.appearance = 'fill';
        }
        this.initializeForm();

        this.userProfileForm.patchValue({
          // User Details
          countryOfRegistration: this.userDetails.countryOfRegistration,
          id: this.userDetails.id,
          roleId: this.userDetails.roleId,
          title: this.userDetails.titleId,
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          email: this.userDetails.email,
          profilePhoto: this.userDetails.profilePhoto,
          mobileCode: this.userDetails.mobileCode,
          mobileNumber: this.userDetails.mobileNumber,
          registrationStatus: this.getRegistrationStatus(this.userDetails.statusId),
          tierId: this.userDetails.tierId,
          salesPersonId: this.userDetails.salesPersonId,
          statusId: this.userDetails.statusId,
          comments: this.userDetails.statusReason,

          // Company Details
          companyName: companyDetails.companyName,
          companyDepartment: companyDetails.companyDepartment,
          companyWebsite: companyDetails.website,
          companyAddress: companyDetails.companyAddress,
          country: companyDetails.countryId,
          state: companyDetails.stateId,
          city: companyDetails.city,
          zipCode: companyDetails.zipCode,
          landlineCode: companyDetails.landlineCode,
          landlineNumber: companyDetails.landlineNumber
        });
        this.getStates(companyDetails.countryId, companyDetails.stateId);
        this.setStateCode(companyDetails.stateId);

        if (this.userDetails.tierId != null && this.userDetails.tierId > 0) {
          this.tierMasters.push({ 'tierId': this.userDetails.tierId, 'tierCode': this.userDetails.tierName });
        }
        if (this.userDetails.salesPersonId != null && this.userDetails.salesPersonId > 0) {
          this.salesPersons.push({ 'id': this.userDetails.salesPersonId, 'firstName': this.userDetails.salesPersonName, 'lastName': '' });
        }
        this.kycDueInDays = this.userDetails.kycDueInDays;
        this.initializeTierFilters();
        this.initializeSalesPersonFilters();

        if (this.userDetails.roleId == UserRoleConstants.CUSTOMER)
          this.registrationStatus = this.getRegistrationStatus(this.userDetails.statusId);
      }
    });
  }


  getUserTitles() {
    this.httpService.httpGet(RouteConstants.MASTER_getUserTitleMasters, null, false).subscribe(res => {
      this.userTitles = res['data'];
    });
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
    });
  }

  getStates(countryId: number, defaultState?: number) {
    if (this.countries != undefined && this.countries.length > 0) {
      var selectedCountry = this.countries.filter(function (item) { return item.id == countryId });
      if (selectedCountry.length > 0) {
        this.countryCode = selectedCountry[0].countryCode;
        this.userProfileForm.patchValue({
          landlineCode: selectedCountry[0].callingCode
        });
      }
    }

    this.httpService.httpGet(RouteConstants.MASTER_getAllStates, { 'countryId': countryId }).subscribe(res => {
      this.states = res['data'];
      if (!this.states.length) {
        this.setDefaultState()
      }
      else {
        this.userProfileForm.patchValue({
          state: defaultState
        });
      }
    });
  }

  setDefaultState() {
    this.states.push({ 'id': 0, 'stateName': 'No states found' });
    this.userProfileForm.patchValue({
      state: 0,
    });
    this.stateCode = 'NA';
  }

  setStateCode(stateId: number): void {
    if (this.states != undefined && this.states.length > 0) {
      var selectedState = this.states.filter(function (item) { return item.id == stateId });
      if (selectedState.length > 0) {
        this.stateCode = selectedState[0].stateCode;
      }
    }
  }


  setMandatoryFields(approvalStatusId: number): void {
    if (approvalStatusId == this.ProfileStatusConstants.PENDING_CONFIRMATION ||
      approvalStatusId == this.ProfileStatusConstants.CONFIRMED) {
      this.userProfileForm.get('tierId').setValidators([Validators.required]);
      this.userProfileForm.get('salesPersonId').setValidators([Validators.required]);
      this.userProfileForm.get('comments').setValidators([]);
    }
    else if (approvalStatusId == this.ProfileStatusConstants.APPROVAL_REJECTED ||
      approvalStatusId == this.ProfileStatusConstants.CONFIRMATION_REJECTED) {
      this.userProfileForm.get('tierId').setValidators([]);
      this.userProfileForm.get('salesPersonId').setValidators([]);
      this.userProfileForm.get('comments').setValidators([Validators.required]);
    }

    this.userProfileForm.get('tierId').updateValueAndValidity();
    this.userProfileForm.get('salesPersonId').updateValueAndValidity();
    this.userProfileForm.get('comments').updateValueAndValidity();

    this.statusId = approvalStatusId;
    this.formSubmitButton.nativeElement.click();

  }

  formSubmitApproveReject(): void {
    this.submitted = true;
    if (this.userProfileForm.valid) {
      this.approveRejectUser();
    }

  }

  formSubmitUpdateProfile() {
    this.submitted = true;
    if (!this.userProfileForm.valid) {
      return
    }

    if (this.countryCode == undefined || this.countryCode == null) {
      var selectedCountry = this.countries.filter(function (item) { return item.id == this.regForm.country.value });
      if (selectedCountry.length > 0) {
        this.countryCode = selectedCountry[0].countryCode;
      }
    }

    if (this.stateCode == undefined || this.stateCode == null) {
      this.setStateCode(this.regForm.state.value);
    }

    this.isLoading = true;
    var param = {
      'Id': this.encryptionService.decrypt(this.userId),
      'CountryOfRegistration': this.regForm.countryOfRegistration.value,
      'TitleId': this.regForm.title.value,
      'FirstName': this.regForm.firstName.value,
      'LastName': this.regForm.lastName.value,
      'Email': this.regForm.email.value,
      'MobileCode': this.regForm.mobileCode.value,
      'MobileNumber': this.regForm.mobileNumber.value,
      'UserCompanyDetail': {
        'CompanyName': this.regForm.companyName.value,
        'CompanyDepartment': this.regForm.companyDepartment.value,
        'Website': this.regForm.companyWebsite.value,
        'CompanyAddress': this.regForm.companyAddress.value,
        'CountryId': this.regForm.country.value,
        'CountryCode': this.countryCode,
        'StateCode': this.stateCode,
        'StateId': this.regForm.state.value,
        'City': this.regForm.city.value,
        'ZipCode': this.regForm.zipCode.value,
        'LandlineCode': this.regForm.landlineCode.value,
        'LandlineNumber': this.regForm.landlineNumber.value
      }
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_updateUserDetails, param).subscribe(res => {
      this.isLoading = false;
    });
  }

  approveRejectUser(): void {
    this.isLoading = true;
    var param = {
      "Id": this.encryptionService.decrypt(this.userId),
      "Email": this.regForm.email.value,
      "StatusId": this.statusId,
      "TierId": this.regForm.tierId.value,
      "SalesPersonId": this.regForm.salesPersonId.value,
      "StatusReason": this.regForm.comments.value
    };
    this.httpService.httpPost(RouteConstants.ACCOUNT_updateUser, param).subscribe(res => {
      this.isLoading = false;
      if (res['success'] == true) {
        this.location.back();
      }
    });
  }

  tierTypeaheadDisplay(tierId: number) {
    if (tierId != null && tierId != undefined && tierId != 0) {
      return this.tierMasters.filter(m => { return m.tierId == tierId })[0].tierCode;
    }
  }

  salesPersonTypeaheadDisplay(salesPersonId: number) {
    if (this.salesPersons != undefined && this.salesPersons.length > 0) {
      if (salesPersonId != null && salesPersonId != undefined && salesPersonId != 0) {
        var spoc = this.salesPersons.filter(m => { return m.id == salesPersonId })[0];
        return spoc.firstName + ' ' + spoc.lastName;
      }
    }
  }

  getRegistrationStatus(statusId: number): string {
    var statusText = "";
    var status = ProfileStatusConstants.PROFILE_STATUSES.filter(m => { return m.id == statusId });
    if (status.length > 0) {
      statusText = status[0].description;
    }
    return statusText;
  }

  navigateBack(): void {
    this.location.back();
  }
}
