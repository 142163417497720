// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window['__env']['production'] || false,
  baseUrl: window['__env']['baseUrl'] || 'https://inbritbe.esldevs.com/',
  firebase: {
    apiKey: window['__env']['firebase_apiKey'] || "AIzaSyBbM50rsccuFB7NMzJBwhIvezlmyqpCNRc",
    authDomain: window['__env']['firebase_authDomain'] || "inbrit-development.firebaseapp.com",
    databaseURL: "https://" + (window['__env']['firebase_databaseURL'] || "inbrit-development.firebaseio.com"),
    projectId: window['__env']['firebase_projectId'] || "inbrit-development",
    storageBucket: window['__env']['firebase_storageBucket'] || "inbrit-development.appspot.com",
    messagingSenderId: window['__env']['firebase_messagingSenderId'] || "417893900521",
    appId: window['__env']['firebase_appId'] || "1:417893900521:web:7d2c834efbc9a2455d1078",
    measurementId: window['__env']['firebase_measurementId'] || "G-T55W3Y3XND"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
