import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { HttpService } from "../../services/http/http.service";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";
import { UserRoleConstants, KycStatusConstants, SpotConstants, ProfileStatusConstants } from "../../constants/constants";
import { RouteConstants } from "../../constants/route-constants";
import { DatePipe, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Router, ActivatedRoute } from "@angular/router";
import { TypeaheadValidator } from "../../shared/custom-validators/typeahead-validator";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BlankCalendarHeaderComponent } from 'src/app/shared/blank-calendar-header/blank-calendar-header.component';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { RemarkPopupComponent } from 'src/app/shared/remark-popup/remark-popup.component';


const moment = _moment;

let formatFactory = () => {
  var MY_FORMATS = {
    parse: {
      dateInput: 'DD-MMM-YYYY',
    },
    display: {
      dateInput: 'DD-MMM-YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
  }

  var user = new UserInfoService(new EncryptionService()).getLoggedInUser();
  if (user != undefined && user != null && user != 'null') {
    var parsedUser = user;
    if (parsedUser.countryOfRegistration == 146) // UK
    {
      MY_FORMATS.parse.dateInput = 'MMM-YYYY';
      MY_FORMATS.display.dateInput = 'MMM-YYYY';
    }
  }
  return MY_FORMATS;
};


@Component({
  selector: 'inbrit-quotation-request',
  templateUrl: './quotation-request.component.html',
  styleUrls: ['./quotation-request.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useFactory: formatFactory },
    /*
    Used factory provider because the format may change at each load of component 
    based on the login data. useClass OR useValue initialize the provider only once.
    */
  ],
})

export class QuotationRequestComponent implements OnInit, AfterViewChecked {
  quoteRequestForm: FormGroup;
  containerDetailsForm: FormGroup;
  user: any;
  userDetails: any;
  minDate: Date = new Date();
  maxDate: Date = new Date(new Date().getFullYear() + 1, 11, 31);
  showPendingKycNote: boolean = false;
  showApprovalPendingKycNote: boolean = false;
  showUserApprovalPendingNote: boolean = false;
  kycDueInDays: number = 0;
  fromPortList = [];
  toPortList = [];
  commodityList = [];
  customerList = [];
  tierList = [];
  spotRateList = [];
  containerTypes = [];
  containerSizes = [20, 40, 45];
  carrierList = [];
  checkedCarrierList = [];
  customerId: number = 0;
  customerName: string;
  tierCode: string;
  commodityName: string;
  spotRateName: string;
  containerDetails: Array<{ size: string, type: string, weight: string }> =
    [{ size: '', type: '', weight: '' }];
  @ViewChild('checkRateSubmitBtn') checkRateSubmitBtn;
  @ViewChild('containerDetailsFormSubmitBtn') containerDetailsFormSubmitBtn;
  @ViewChild('scrollSection') quoteResponseSection: ElementRef;
  submitted: boolean = false;
  UserRoleConstants = UserRoleConstants;
  datePipe = new DatePipe('en-US');
  fromPortCodeDisplay: string;
  toPortCodeDisplay: string;
  quotationRequest: any = {
    fromPortCode: '', toPortCode: '',
    customerName: '', commodityName: '', tierCode: ''
  };
  quotationResponse: any;
  isLoading: boolean = false;
  oldQuotationId: number;
  bookingId: number = 0;
  isEditBookingDetails: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  toPortChipList: any[] = [];
  isCheckRatesClicked: boolean = false;
  ProfileStatusConstants = ProfileStatusConstants;
  customHeader = BlankCalendarHeaderComponent;
  isFromLoading: boolean = false;
  isToLoading: boolean = false;
  isTierLoading: boolean = false;
  isSpotrateLoading: boolean = false;
  httpCallCount: number = 0;
  typeaheadDebounce: number = 500;
  maxWeightAllowed = 28000;
  regStatus: string;
  containerType: any;
  validRate: boolean = false;
  cid :any;
  cname : any;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService,
    private translate: TranslateService, private router: Router, private location: Location,
    private toastMessageService: ToastMessageService, private userInfoService: UserInfoService,
    private loaderService: LoaderService, private dialog: MatDialog,
  ) {
    var id = '';
    this.activatedRoute.params.subscribe(params => { id = params['id'] });
    if (id != undefined && id != '') {
      this.bookingId = this.encryptionService.decrypt(id);
      this.getBookingDetails();
    }
  }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();
    if (user != null) {
      this.user = user;
      if (this.user.countryOfRegistration == 147) {
        this.translate.use('en-us');
        this.maxWeightAllowed = 61730;
      }
      else if (this.user.countryOfRegistration == 146) {
        this.translate.use('en-uk');
        this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        this.maxWeightAllowed = 28000;
      }
      else {
        this.translate.use('en-default');
      }
    }

    var initialPath = '/user/';
    if (this.user.roleId == UserRoleConstants.CUSTOMER) {
      initialPath = '/customer/'
    }

    this.loaderService.display(true);
    this.getUserDetials();
    this.initializeForm();
    this.initializeTypeAheads();
    this.getContainerTypes();
    this.getCommodityList();
    this.initializeContainerDetailsForm();
  }

  getUserDetials(): void {
    this.httpCallCount += 1;
    this.httpService.httpGet(RouteConstants.ACCOUNT_getUser, { 'userId': this.user.id }, false).subscribe(res => {
      if (res['success'] == true) {
        this.userDetails = res['data'];
        this.kycDueInDays = this.userDetails.kycDueInDays;
        this.httpCallCount -= 1;
        this.hideLoader();
        this.displayKycNote();
      }
    });
  }

  hideLoader(): void {
    if (this.httpCallCount == 0)
      this.loaderService.display(false);
  }

  ngAfterViewChecked() {

    if (this.isCheckRatesClicked) {
      this.scrollToBottom();
    }
  }

  initializeForm() {

    this.quoteRequestForm = this.formBuilder.group({
      fromPort: ['', []],
      toPort: ['', []],
      commodity: ['', [Validators.required]],
      monthOfSailing: new FormControl(null, []),
      spotRateSellId: new FormControl('', []),
      customerName: ['', []],
      tierId: ['', []]
    });

    if (this.user != undefined && this.user.roleId == UserRoleConstants.USER) {
      this.quoteReqForm.tierId.setValidators(Validators.required);
      this.quoteReqForm.tierId.updateValueAndValidity();
    }
  }

  // convenience getter for easy access to form fields
  get quoteReqForm() { return this.quoteRequestForm.controls; }

  initializeContainerDetailsForm() {

    this.containerDetailsForm = this.formBuilder.group({
      cntDetArray: this.formBuilder.array([])
    });

    let cntDetArray = this.containerDetailsForm.get('cntDetArray') as FormArray;
    cntDetArray.push(this.formBuilder.group({
      size: ['', [Validators.required]],
      type: ['', [Validators.required]],
      weight: ['27000', [Validators.required, Validators.max(this.maxWeightAllowed)]],
    }));
  }

  displayKycNote(): void {

    if (this.userDetails != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        if (this.userDetails.kycStatusId == KycStatusConstants.KYC_PENDING) {
          this.showPendingKycNote = true;
        }
        else if (this.userDetails.kycStatusId == KycStatusConstants.KYC_APPROVAL_PENDING) {
          this.showApprovalPendingKycNote = true;
        }
        else if (this.userDetails.statusId == ProfileStatusConstants.PENDING_APPROVAL) {
          this.showUserApprovalPendingNote = true;
        }
      }
    }
  }

  getValidContainerTypes(containerDetail: FormGroup) {
    var size = containerDetail.controls['size'].value;
    var type = containerDetail.controls['type'].value;
    if (size == 20 && (type == '' || type == 'HC')) {
      containerDetail.patchValue({
        type: ''
      });
      return this.containerTypes.filter(m => { return m.containerCode != 'HC' });
    }
    return this.containerTypes;
  }

  getContainerTypes() {
    this.httpCallCount += 1;
    this.httpService.httpGet(RouteConstants.MASTER_getAllContainerTypes, null, false).subscribe(res => {
      this.containerTypes = res['data'];
      this.httpCallCount -= 1;
      this.hideLoader();
    });
  }

  getCommodityList() {
    this.httpCallCount += 1;
    this.httpService.httpGet(RouteConstants.MASTER_getAllCommodity, null, false).subscribe(res => {
      this.commodityList = res['data'];
      this.httpCallCount -= 1;
      this.hideLoader();
    });
  }

  setContainerType(commodityId: number,commodityName: string): void {
    let cntDetail = this.containerDetailsForm.get('cntDetArray')['controls'];
    this.commodityName = commodityName;
    // Check if Commodity is "Waste Paper"
    if (commodityId == 2) {
      this.containerType = { size: 40, type: 'HC' };
      cntDetail.forEach(function (formGroup) {
        formGroup.patchValue({
          size: 40,
          type: 'HC'
        });
      });
    }
    else if (commodityId == 1) {
      this.containerType = { size: 20, type: 'STD' };
      cntDetail.forEach(function (formGroup) {
        formGroup.patchValue({
          size: 20,
          type: 'STD'
        });
      });
    }
    else {
      this.containerType = undefined;
      cntDetail.forEach(function (formGroup) {
        formGroup.patchValue({
          size: '',
          type: ''
        });
      });
    }
    this.cid=commodityId;
    this.cname=commodityName;
  }

  initializeTypeAheads() {

    // Initialize From Port codes
    this.quoteRequestForm.controls['fromPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => { 
      this.quotationResponse = undefined
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val };
        this.isFromLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllLocations, param, false).subscribe(res => {
          this.fromPortList = res['data'];
          this.isFromLoading = false;
          this.quoteRequestForm.get('fromPort').setValidators([TypeaheadValidator.typeaheadValidator(this.fromPortList, 'id')]);
        });
      }
    });

    // Initialize To Port codes
    this.quoteRequestForm.controls['toPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      this.quotationResponse = undefined
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val, 'isRequestForFromPorts': false };
        this.isToLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllPortcode, param, false).subscribe(res => {
          this.toPortList = res['data'];
          this.isToLoading = false;
          // this.quoteRequestForm.get('toPort').setValidators([TypeaheadValidator.typeaheadValidator(this.toPortList, 'portCode')]);
        });
      }
    });

    // Initialize Customers
    this.quoteRequestForm.controls['customerName'].valueChanges.subscribe(val => {
      this.quotationResponse = undefined
      if (typeof val === 'string' && val.length >= 3) {
        this.customerId = 0;
        this.httpService.httpPost(RouteConstants.QUOTATION_getcustomerwithtier + "?searchCustomerName=" + val, null).subscribe(res => {
          this.customerList = res['data'];
        });
      }
      else {
        if (typeof val != 'number') {
          this.customerName = "";
        }
        else {
          this.customerTypeAheadDisplay(val);
        }
      }
      if (this.customerId == 0) {
        this.regStatus = null;
        this.quoteRequestForm.patchValue({
          tierId: null
        });
      }
    });

    // Initialize Tiers
    this.quoteRequestForm.controls['tierId'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      this.quotationResponse = undefined
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val };
        this.isTierLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getApprovedTierMaster, param, false).subscribe(res => {
          this.isTierLoading = false;
          this.tierList = res['data'];
          //this.quoteRequestForm.get('tierId').setValidators([TypeaheadValidator.typeaheadValidator(this.tierList, 'tierId')]);
        });
      }
    });

    // Initialize Spot Rates
    this.quoteRequestForm.controls['spotRateSellId'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      this.quotationResponse = undefined
      if (typeof val === 'string' && val.length >= 3) {
        this.isSpotrateLoading = true;
        var param = { "SearchText": val, "ListStatusId": [SpotConstants.APPROVED] };
        this.httpService.httpPost(RouteConstants.FREIGHT_getAllSpotRate, param, false).subscribe(res => {
          this.isSpotrateLoading = false;
          this.spotRateList = res['data'];
        });
      }
    });
  }

  // loadpointTypeAheadDisplay(id: number) {
  //   if (id != null && id != undefined && id != 0) {
  //     var name = this.loadingPointList.filter(m => { return m.id == id })[0].loadingName;
  //     // this.loadpointName = name;
  //     return name;
  //   }
  // }

  fromPortTypeAheadDisplay(locationId: number) {

    if (locationId != null && locationId != undefined && locationId != 0) {

      var selectedPort = this.fromPortList.filter(m => { return m.id == locationId })[0];
      this.fromPortCodeDisplay = selectedPort.locationName + ' (' + selectedPort.locationCode + ')';

      return this.fromPortCodeDisplay;
    }
  }

  toPortTypeAheadDisplay(toPortId: number) {

    if (toPortId != null && toPortId != undefined && toPortId != 0) {
      var selectedPort = this.toPortList.filter(m => { return m.id == toPortId })[0];
      this.toPortCodeDisplay = selectedPort.portName + ' (' + selectedPort.portCode + ')';

      if (this.toPortChipList.length < 4) {
        const index = this.toPortChipList.filter(m => { return m.id == toPortId });

        if (index.length == 0) {
          this.toPortChipList.push(selectedPort);
        }
      }
      else {
        this.toastMessageService.toast('bottom', 'center', 'danger', 'Only 4 POD are allowed.');
      }

      this.quoteRequestForm.patchValue({
        toPort: null
      });

      if (this.toPortChipList.length > 0) {
        this.quoteReqForm.toPort.setValidators([]);
        this.quoteReqForm.toPort.updateValueAndValidity();
      }

      return this.toPortCodeDisplay;
    }
  }

  customerTypeAheadDisplay(customerId: number) {
    this.customerId = customerId;
    if (customerId != null && customerId != undefined && customerId != 0) {
      var customers = this.customerList.filter(m => { return m.customerId == customerId });
      if (customers.length > 0) {
        // Display Customer Tier
        if (customers[0].tierId != null && customers[0].tierId != 0) {
          this.tierList = [{ 'tierId': customers[0].tierId, 'tierCode': customers[0].tierName }];
          this.quoteRequestForm.patchValue({
            tierId: customers[0].tierId
          });
        }
        else {
          this.tierList = [];
          this.quoteRequestForm.patchValue({
            tierId: null
          });
          this.regStatus = null;
        }
        // Display Customer's Spot rate
        if (customers[0].spotSellRateId != null && customers[0].spotSellRateId != 0) {
          this.spotRateList = [{ 'id': customers[0].spotSellRateId, 'rateName': customers[0].spotSellRateName }];
          this.quoteRequestForm.patchValue({
            spotRateSellId: customers[0].spotSellRateId
          });
        }
        else {
          this.spotRateList = [];
          this.quoteRequestForm.patchValue({ spotRateSellId: null });
        }

        this.regStatus = ProfileStatusConstants.PROFILE_STATUSES.filter(m => { return m.id == customers[0].statusId })[0].description;
        this.customerName = customers[0].customerName;

        this.quoteReqForm.tierId.updateValueAndValidity();

        return customers[0].customerName
      }
      return "";
    }
  }

  tierTypeAheadDisplay(tierId: number) {

    if (tierId != null && tierId != undefined && tierId != 0) {
      var tierCode = this.tierList.filter(m => { return m.tierId == tierId })[0].tierCode;
      this.tierCode = tierCode;
      return tierCode;
    }
  }

  spotRateTypeAheadDisplay(spotSellRateId: number) {

    if (spotSellRateId != null && spotSellRateId != undefined && spotSellRateId != 0) {
      var spotRate = this.spotRateList.filter(m => { return m.id == spotSellRateId })[0].rateName;
      this.spotRateName = spotRate;
      return spotRate;
    }
  }

  deleteToPortCode(port: any) {

    const index = this.toPortChipList.indexOf(port);
    if (index >= 0) {
      this.toPortChipList.splice(index, 1);
    }
  }

  addNewContainerDetail(): void {

    let cntDetArray = this.containerDetailsForm.get('cntDetArray') as FormArray;
    // for (let i = 0; i <= this.containerDetails.length - 1; i++) {
    let size = this.containerType != undefined ? this.containerType.size : '';
    let type = this.containerType != undefined ? this.containerType.type : '';
    cntDetArray.push(this.formBuilder.group({
      size: [size, [Validators.required]],
      type: [type, [Validators.required]],
      weight: ['27000', [Validators.required, Validators.max(this.maxWeightAllowed)]],
    }));
    // 
  }

  removeContainerDetail(rowIndex: number): void {

    let cntDetArray = this.containerDetailsForm.get('cntDetArray') as FormArray;
    cntDetArray.removeAt(rowIndex);

  }

  submitForms(): void {
    this.submitted = true;
    this.checkRateSubmitBtn.nativeElement.click();
    this.containerDetailsFormSubmitBtn.nativeElement.click();
  }

  getRates(): void {
    if (typeof this.quoteReqForm.fromPort.value == 'string' && this.quoteReqForm.fromPort.value != '') {
      this.quoteRequestForm.get('fromPort').setErrors({ 'invalidTypeaheadValue': { value: this.quoteReqForm.fromPort.value } });
    }
    else {
      this.quoteRequestForm.get('fromPort').setErrors(null);
    }

    var isFromPortAvailable = !(this.quoteReqForm.fromPort.value == null || this.quoteReqForm.fromPort.value == '');

    if (!isFromPortAvailable) {

      this.quoteReqForm.fromPort.setValidators([Validators.required]);
      this.quoteReqForm.fromPort.updateValueAndValidity();
    }

    if (this.toPortChipList.length == 0) {
      this.quoteReqForm.toPort.setValidators([Validators.required]);
      this.quoteReqForm.toPort.updateValueAndValidity();
    }

    if (typeof this.quoteReqForm.toPort.value == 'string' && this.quoteReqForm.toPort.value != '') {
      this.quoteRequestForm.get('toPort').setErrors({ 'invalidTypeaheadValue': { value: this.quoteReqForm.toPort.value } });
    }
    else {
      this.quoteRequestForm.get('toPort').setErrors(null);
    }

    if (typeof this.quoteReqForm.tierId.value == 'string' && this.quoteReqForm.tierId.value != '') {
      this.quoteRequestForm.get('tierId').setErrors({ 'invalidTypeaheadValue': { value: this.quoteReqForm.tierId.value } });
    }

    if (typeof this.quoteReqForm.spotRateSellId.value == 'string' && this.quoteReqForm.spotRateSellId.value != '') {
      this.quoteRequestForm.get('spotRateSellId').setErrors({ 'invalidTypeaheadValue': { value: this.quoteReqForm.spotRateSellId.value } });
    }

    if (typeof this.quoteReqForm.customerName.value == 'string' && this.quoteReqForm.customerName.value != '' && this.customerId == 0) {
      this.quoteRequestForm.get('customerName').setErrors({ 'invalidTypeaheadValue': { value: this.quoteReqForm.customerName.value } });
    }
    else {
      this.quoteRequestForm.get('customerName').setErrors(null);
    }

    this.quoteReqForm.monthOfSailing.setValidators([Validators.required]);
    this.quoteReqForm.monthOfSailing.updateValueAndValidity();

    if (!this.quoteRequestForm.valid || !this.containerDetailsForm.valid) {
      return;
    }
    this.isLoading = true;
    var containerDetailsReq = [];
    let cntDetail = this.containerDetailsForm.get('cntDetArray')['controls'];

    cntDetail.forEach(function (formGroup) {
      containerDetailsReq.push({
        'Size': formGroup['controls']['size'].value.toString(),
        'Type': formGroup['controls']['type'].value,
        'Weight': parseInt(formGroup['controls']['weight'].value)
      });
    });

    var param = {
      'QuotationId': this.oldQuotationId,
      "FromPortId": this.quoteReqForm.fromPort.value,
      "ToPortIdList": this.toPortChipList.map(m => { return m.id }),
      "CommodityId": this.quoteReqForm.commodity.value,
      "CustomerId": Number(this.customerId),
      "CustomerName": this.customerName,
      "RequestDetails": containerDetailsReq,
      "TierId": this.quoteReqForm.tierId.value == "" ? 0 : this.quoteReqForm.tierId.value,
      "SpotRateSellId": this.quoteReqForm.spotRateSellId.value == "" ? 0 : this.quoteReqForm.spotRateSellId.value,
    };

    if (this.user.countryOfRegistration != 146) {
      param["ShipmentDate"] = this.datePipe.transform(this.quoteReqForm.monthOfSailing.value, 'yyyy-MM-dd');
    }
    else {
      param["ShipmentMonth"] = this.datePipe.transform(this.quoteReqForm.monthOfSailing.value, 'yyyy-MM-dd');
    }

    var shippingDate = this.quoteReqForm.monthOfSailing.value;
    if (shippingDate < new Date(this.minDate.getFullYear(),this.minDate.getMonth(),this.minDate.getDate())) {
      param["PastDate"] = true;
      this.validRate = false;
    }
    else if (shippingDate >= new Date(this.minDate.getFullYear(),this.minDate.getMonth(),this.minDate.getDate())) {
      param["PastDate"] = false;
      this.validRate = true;
    }

    this.quotationRequest = {
      fromPortCode: this.fromPortCodeDisplay,
      toPortList: this.toPortChipList,
      customerName: this.customerName,
      commodityName: this.commodityName,
      shipmentDate: this.datePipe.transform(this.quoteReqForm.monthOfSailing.value, (this.user.countryOfRegistration == 146 ? 'MM/yyyy' : 'd/M/yyyy')),
      tierCode: this.tierCode,
      spotRateName: this.spotRateName
    };
    this.quotationResponse = undefined;
    this.carrierList = [];
    this.httpService.httpPost(RouteConstants.QUOTATION_request, param).subscribe(res => {
      if (res['success'] == true) {
        this.quotationResponse = res['data'];

        this.quotationResponse.CommodityName=this.cname;
        this.quotationResponse.commodityId=this.cid;

        this.quotationResponse.responseDetails.forEach(res => {
          this.carrierList.push(res['carrierCode']);
        });
        this.quotationResponse.id = this.bookingId == 0 ? null : this.bookingId;
        this.isCheckRatesClicked = true;
      }
      this.isLoading = false;
    });

  }

  scrollToBottom(): void {
    try {
      this.quoteResponseSection.nativeElement.scrollTop = this.quoteResponseSection.nativeElement.scrollHeight;
      this.isCheckRatesClicked = false;
    } catch (err) { }
  }

  chosenYearHandler(normalizedYear: _moment.Moment) {
    const ctrlValue = this.quoteReqForm.monthOfSailing.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.quoteReqForm.monthOfSailing.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: _moment.Moment, datepicker: MatDatepicker<_moment.Moment>) {
    const ctrlValue = this.quoteReqForm.monthOfSailing.value;
    ctrlValue.month(normalizedMonth.month());
    this.quoteReqForm.monthOfSailing.setValue(ctrlValue);
    datepicker.close();
  }

  naviagteBack(): void {
    this.location.back();
  }

  // Edit methods *START*
  getBookingDetails(): void {

    this.isEditBookingDetails = true;
    //this.isShowBookingDetailsLoading = true;
    this.httpCallCount += 1;
    this.httpService.httpPost(RouteConstants.BOOKING_getBookingById + "?bookingId=" + this.bookingId, null, false).subscribe(res => {
      if (res['success'] == true) {
        var quotationResponse = res['data']['requestDetails'];
        this.oldQuotationId = res['data'].quotationId;
        this.containerDetailsForm = this.formBuilder.group({
          cntDetArray: this.formBuilder.array([])
        });

        quotationResponse.forEach(item => {
          let cntDetArray = this.containerDetailsForm.get('cntDetArray') as FormArray;
          cntDetArray.push(this.formBuilder.group({
            size: [parseInt(item.size), [Validators.required]],
            type: [item.type, [Validators.required]],
            weight: [item.weight, [Validators.required, Validators.max(this.maxWeightAllowed)]],
          }));
        });

        this.quotationRequest = {
          'customerName': res['data'].customerName,
          'fromPortCode': res['data'].fromPortName,
          'toPortList': res['data'].toPortList,
          'commodityName': res['data'].commodityName,
          'shipmentDate': this.user.countryOfRegistration == 147 ? this.datePipe.transform(res['data'].shipmentDate, 'dd-MMM-yyyy') : this.datePipe.transform(res['data'].shipmentDate, 'MMM-yyyy'),
          'tierCode': res['data'].tierName,
          'spotRateName': res['data'].spotRateName
        }
        this.quotationResponse = res['data'];
        this.quotationResponse.CommodityName=this.cname;
        this.quotationResponse.commodityId=this.cid;

      }
      this.httpCallCount -= 1;
      this.hideLoader();
    });



  }

  enableEditEvent(evt: any) {
    this.isEditBookingDetails = false;
    var param = {
      'FromPortSearchText': this.quotationResponse.fromPortName.substring(0, this.quotationResponse.fromPortName.indexOf("(") - 1),
      'CommoditySearchText': this.quotationResponse.commodityName,
      'TierSearchText': this.quotationResponse.tierName
    }

    this.httpService.httpPost(RouteConstants.MASTER_getBookingDetailMasters, param).subscribe(resp => {
      if (resp['success'] == true) {
        this.fromPortList = resp['data']['fromPortMasters'];
        this.commodityList = resp['data']['commodityMasters'];
        this.tierList = resp['data']['tierMasters'];

        var fromportname: string;
        fromportname = this.quotationResponse.fromPortName.substring(0, (this.quotationResponse.fromPortName).indexOf("(") - 1);

        this.quoteRequestForm.patchValue({

          fromPort: this.quotationResponse.fromPortId,
          commodity: this.quotationResponse.commodityId,
          monthOfSailing: _moment(new Date(this.quotationResponse.shipmentDate)),
          tierId: this.quotationResponse.tierId
        });

        this.toPortChipList = this.quotationResponse.toPortList;

        this.quotationRequest = undefined;
        this.quotationResponse = undefined;
      }
    });

  }

  viewQuotation(): void {

    if (this.user != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        this.router.navigate(['customer/quotations-report', this.encryptionService.encrypt(this.quotationResponse.quotationId)]);
      }
      else {
        this.router.navigate(['user/quotations-report', this.encryptionService.encrypt(this.quotationResponse.quotationId)]);
      }
    }
  }

  sendMail(){
    let dialogRef =this.dialog.open(RemarkPopupComponent, {width: '450px',
    data: { heading: "Enter Email id's with comma seperated" , label: "Email Id's"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.Success) {
        var containerDetailsReq = [];
        let cntDetail = this.containerDetailsForm.get('cntDetArray')['controls'];
    
        cntDetail.forEach(function (formGroup) {
          containerDetailsReq.push({
            'Size': formGroup['controls']['size'].value.toString(),
            'Type': formGroup['controls']['type'].value,
            'Weight': parseInt(formGroup['controls']['weight'].value)
          });
        });
        
        let cmd = this.commodityList.find(m => m.id == this.quoteReqForm.commodity.value);
        var param = {
          'QuotationId': this.oldQuotationId,
          "FromPortId": this.quoteReqForm.fromPort.value,
          "ToPortIdList": this.toPortChipList.map(m => { return m.id }),
          "CommodityId": this.quoteReqForm.commodity.value,
          "CommodityName": cmd != undefined && cmd != null ? cmd.commodityName : "",
          "CustomerId": Number(this.customerId),
          "CustomerName": this.customerName,
          "RequestDetails": containerDetailsReq,
          "TierId": this.quoteReqForm.tierId.value == "" ? 0 : this.quoteReqForm.tierId.value,
          "SpotRateSellId": this.quoteReqForm.spotRateSellId.value == "" ? 0 : this.quoteReqForm.spotRateSellId.value,
        };
    
        if (this.user.countryOfRegistration != 146) {
          param["ShipmentDate"] = this.datePipe.transform(this.quoteReqForm.monthOfSailing.value, 'yyyy-MM-dd');
        }
        else {
          param["ShipmentMonth"] = this.datePipe.transform(this.quoteReqForm.monthOfSailing.value, 'yyyy-MM-dd');
        }
    
        var shippingDate = this.quoteReqForm.monthOfSailing.value;
        if (shippingDate < this.minDate) {
          param["PastDate"] = true;
          this.validRate = false;
        }
        else if (shippingDate >= this.minDate) {
          param["PastDate"] = false;
          this.validRate = true;
        }
        param["mails"] = result.message;
        // param["quotationResponse"] = this.quotationResponse;
        this.httpService.httpPost(RouteConstants.QUOTATION_sendCheckRateMail, param,false).subscribe(res => {
          if(!res["success"]){
            this.toastMessageService.toast('bottom', 'center', 'danger', 'Internal Error, kindly Contact Support Desk.');
          }
        });
        this.toastMessageService.toast('bottom', 'center', 'success', 'Email will be Received Shortly');
      }
    });
  }

  // Edit methods *END*
}
