import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination, GridColumnType, GridActionType, GridColumnDataType } from "../../constants/constants";
import { MatDialogRef, MatDialog } from '@angular/material';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import {DateConstants} from "src/app/constants/constants";
import { CacheService } from 'src/app/services/cache-service/cache.service';

@Component({
  selector: 'inbrit-invite-dashboard',
  templateUrl: './invite-dashboard.component.html',
  styleUrls: ['./invite-dashboard.component.scss']
})
export class InviteDashboardComponent implements OnInit {
  filterInviteCustomerForm: FormGroup;
  totalFiltersApplied: number = 0;
  filter: string = "";
  inviteCustomerList: [any];
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  submitted: boolean;
  datePipe = new DatePipe('en-US');
  DateConstants = DateConstants;
  
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [

      { title: 'Customer Name', dataField: 'customerName', type: GridColumnType.DATA, },
      // { title: 'Country', dataField: 'countryName', type: GridColumnType.DATA, },
      { title: 'Email', dataField: 'email', type: GridColumnType.DATA },
      { title: 'Invited On', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },
      { title: 'Invited By', dataField: 'createUserName', type: GridColumnType.DATA },

      {
        title: 'Action', dataField: 'inviteId', type: GridColumnType.ACTION, actions: [
          { event: 'viewCustomer', type: GridActionType.ICON, title: 'View Customer', class: 'visibility' },

        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'InviteDashboardComponent-filter';

  constructor(private router: Router,private formBuilder: FormBuilder, private dialog: MatDialog, private httpService: HttpService,
    private cacheService: CacheService, private encryptionService: EncryptionService, ) { }

  ngOnInit() {
    this.initializeFilterForm();
    this.getCustomer();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterInviteCustomerForm = this.formBuilder.group({
      CustomerFromDate: [cachedFilter != null ? cachedFilter.value.CustomerFromDate.value : new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      CustomerToDate: [cachedFilter != null ? cachedFilter.value.CustomerToDate.value : new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]
    });
  }

  get filterFormFields() { return this.filterInviteCustomerForm.controls; }

  clearFilter(): void {
    this.filterInviteCustomerForm.reset();
    this.filterInviteCustomerForm.patchValue({
      CustomerFromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      CustomerToDate: new Date(moment().format("M/D/YYYY").toString())
    })
    this.getCustomer();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  filterFormSubmit() {

    this.submitted = true;
    if (!this.filterInviteCustomerForm.valid) {
      return;
    }
    else {
      this.getCustomer();
      this.filterDialogRef.close();
    }
    var filters = this.cacheService.getFormValues(this.filterInviteCustomerForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getCustomer();
  }

  getCustomer(): void {

    this.totalFiltersApplied = 0;
    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "StartDate": this.datePipe.transform(this.filterFormFields.CustomerFromDate.value, 'yyyy-MM-dd') ,// this.filterFormFields.CustomerFromDate.value,
      "EndDate": this.datePipe.transform(this.filterFormFields.CustomerToDate.value, 'yyyy-MM-dd')//this.filterFormFields.CustomerToDate.value,
    };
    
    if (this.filterFormFields.CustomerFromDate.value != null && this.filterFormFields.CustomerFromDate.value != "" && this.filterFormFields.CustomerToDate.value != null && this.filterFormFields.CustomerToDate.value != "") {     
      this.totalFiltersApplied++;
    }    

    this.httpService.httpPost(RouteConstants.ACCOUNT_getInviteCustomer + "?searchText=" + this.filter, param).subscribe(res => {
      if (res['success'] == true) {

        var data = res['data'];       
        this.dataCount = res['count'];

        this.inviteCustomerList = data;
      }
    });

  }


  viewCustomer(customerId: number): void {
    this.router.navigate(['user/invite-customer', this.encryptionService.encrypt(customerId)]);
  }

  gridEventHandler(evt): void {

    switch (evt.event) {
      case 'viewCustomer':
        {
          this.viewCustomer(evt.data.inviteId);
          break;
        }
    }
  }


  






}
