import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { UserInfoService } from "../../services/userInfo/user-info.service";
import { RouteConstants } from '../../constants/route-constants';
import { ModalDialogService } from 'src/app/services/modal-dialog/model-dialog.service';

@Component({
  selector: 'inbrit-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfilePhotoComponent implements OnInit {
  @Input() userId: number;
  @Input() imagePath: string;
  @Input() isEditPhoto: boolean;
  @ViewChild('fileUploadButton') fileUploadButton;
  fileToUpload: File = null;
  base64Image: string = '';
  isShowActions: boolean = false;
  defaultImagePath: string = "./assets/images/avatars/profile.jpg";

  constructor(private httpService: HttpService, private userInfoService: UserInfoService,
    private modalDialogService: ModalDialogService) {
  }

  ngOnInit() {
    if (this.imagePath == undefined || this.imagePath == '' || this.imagePath == 'null') {
      this.imagePath = this.defaultImagePath;
    }
  }

  updateProfilePhoto(files: FileList) {
    this.fileToUpload = files.item(0);

    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(this.fileToUpload);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    var base64textString = "data:image/png;base64," + btoa(binaryString);
    this.base64Image = base64textString;
    this.isShowActions = true;
  }

  cancelFileUpload(): void {
    this.base64Image = "";
    this.fileToUpload = null;
    this.isShowActions = false;
  }

  deleteProfilePhoto(): void {
    if (this.imagePath != this.defaultImagePath) {
      var buttons = [        
        { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
        { 'title': 'No', 'result': 'no', 'type': '' }
      ];

      this.modalDialogService.openDialog('Confirm', 'Are you sure you want to delete profile photo?', buttons).subscribe((res) => {
        if (res == 'yes') {
          const formData: FormData = new FormData();
          formData.append('Id', this.userId.toString());
          this.postSaveRequest(formData);
        }
      });
    }
    else {
      var buttons = [
        { 'title': 'Ok', 'result': 'yes', 'type': 'accent' }
      ];

      this.modalDialogService.openDialog('Alert', 'No profile photo available.', buttons);
    }
  }

  saveProfilePhoto(): void {

    if (this.fileToUpload == null) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('Id', this.userId.toString());
    formData.append('FileWrapper.File', this.fileToUpload, this.fileToUpload.name);
    this.postSaveRequest(formData);
  }

  postSaveRequest(formData: FormData): void {
    this.httpService.httpPostFormData(RouteConstants.ACCOUNT_updateUserProfilePhoto, formData).subscribe(res => {
      if (res['success'] == true) {
        this.imagePath = res['data'].profilePhoto == null || res['data'].profilePhoto == '' ? this.defaultImagePath : res['data'].profilePhoto;
        this.updateLoggedInProfilePhotoUrl();
      }
      this.cancelFileUpload();
    });
  }


  triggerFileUpload() {
    this.fileUploadButton.nativeElement.click();
  }

  updateLoggedInProfilePhotoUrl(): void {
    var user = this.userInfoService.getLoggedInUser();
    if (user.id == this.userId) {
      user['profilePhoto'] = this.imagePath;
      this.userInfoService.setUser(user);
    }
  }
}
