import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserRoleConstants, BookingStatusConstants,CountryConstants, Commodity } from "../../constants/constants";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { RouteConstants } from '../../constants/route-constants';
import { HttpService } from "../../services/http/http.service";
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestContainersSettingsComponent } from "../request-containers-settings/request-containers-settings.component";

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { ModalDialogService } from 'src/app/services/modal-dialog/model-dialog.service';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'inbrit-quotation-response',
  templateUrl: './quotation-response.component.html',
  styleUrls: ['./quotation-response.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class QuotationResponseComponent implements OnInit {
  @Input() quotationResponseServiceData;
  @Input() quotationRequestData;
  @Input() isViewOnly: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() validRate: boolean = false;
  @Output() enableQuotEdit = new EventEmitter<any>();
  @Input() carrierList;
  dummy: any;
  filteredData: Array<any> = [];
  quotationResponse: any;
  user: any;
  UserRoleConstants = UserRoleConstants;
  isShowProceedButton: boolean = false;
  bookingInformationData: any;
  customerList = [];
  bookingRequestForm: FormGroup;
  minDate: Date = new Date();
  submitted: boolean = false;
  isLoading: boolean = false;
  isShowAllRateNotFoundError: boolean = false;
  isBIReadOnly: boolean = false;
  datePipe = new DatePipe('en-US');
  isSpotApplied: boolean = false;
  private bookingSection: ElementRef;
  notice :any;
  appearance: string = 'outline';
  @ViewChild('bookingSection') set content(ele: ElementRef) {
    this.bookingSection = ele;
    if (this.bookingSection != undefined && this.isEdit == false) {
      this.bookingSection.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  comments: string = '';
  companyaddress: string = '';
  customername: string = "";
  quotationnumber : string ="";
  filterDialogRef: MatDialogRef<{}, any>;
  filterCarrierForm: FormGroup;
  carrierSelected: any;
  declarationAccepted: boolean = false;
  CommodityMisdeclaration: Array<string> = ["Forest Products","Metal Scrap","Waste Paper", "Wood logs"];

  constructor(private httpService: HttpService, private formBuilder: FormBuilder,
    private translate: TranslateService, private router: Router,
    private dialog: MatDialog, private userInfoService: UserInfoService,
    private modalDialogService: ModalDialogService) { }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();
    if (user != undefined && user != null && user != 'null') {
      this.user = user;

      this.customername=((this.quotationRequestData.customerName != undefined && this.quotationRequestData.customerName != "") && this.quotationRequestData.customerName !="--") ? this.quotationRequestData.customerName :user.firstName + ' ' + user.lastName;
      
      if (this.user.countryOfRegistration == 147) {
        this.translate.use('en-us');
      }
      else if (this.user.countryOfRegistration == 146) {
        this.translate.use('en-uk');
      }
      else {
        this.translate.use('en-default');
      }

      if (this.user.countryOfRegistration == CountryConstants.US) {
        this.companyaddress = "EAGLE TRANS SHIPPING & LOGISTICS LLC \nSUITE 301 \nHOBOKEN BUSINESS CENTRE \n50 HARRISON STREET HOBOKEN NJ 07030 \nTEL: +1.201.216.0171 FAX: +1.201.216.5725 ";
      }
      else {
        this.companyaddress = "INBRIT LOGISTICS LIMITED \nUNIT 3 SOVEREIGN PARK \nCORONATION ROAD \nPARK ROYAL LONDON NW10 7QP \nTEL: 020 8838 8800 FAX: 020 8838 8379 \n";

      }
    }
    this.isBIReadOnly = this.isEdit;

    this.appearance = this.isEdit == true ? 'fill' : 'outline';
    this.minDate = new Date(this.minDate.setHours(0, 0, 0, 0));
    this.initializeForm();
    this.dummy = JSON.parse(JSON.stringify(this.quotationResponseServiceData))
    this.bindQuotationResponse(this.quotationResponseServiceData);
    this.quotationnumber=this.quotationResponseServiceData.quotationNumber == undefined ? this.quotationResponseServiceData.jobId : this.quotationResponseServiceData.quotationNumber ;
    var cmdIds = [Commodity.Metal_Scrap,Commodity.Forest_Products,Commodity.Waste_Paper,Commodity.Wood_logs];
    if(cmdIds.includes(this.quotationResponseServiceData.commodityId))
    {
      this.declarationAccepted = false;
      this.notice="Important Notice: In accordance with FMC regulations, rates offered herein are for " + this.quotationResponseServiceData.CommodityName +" (Exempt) cargo only. Commodities categorized as non-exempt as per FMC and the carrier such as Alloys and other forms of metals that are not scrap, cannot be shipped under these rates. Misdeclaration of commodity will be subject to heavy fines and tariff rates."
    }
    else
    {
      this.declarationAccepted = true;
      this.notice="";
    }

    let carrierListnew = this.carrierList.filter((c, index) => {
      return this.carrierList.indexOf(c) === index;
  });

  carrierListnew = carrierListnew.filter(function (el) {
    return el != null;
  });

  this.carrierList=carrierListnew;
  
  }

  bindQuotationResponse(responseData: any): void {
    if (this.isEdit) {

      if (responseData.spotRateSellId != 0 && responseData.spotRateSellId != undefined) {
        this.isSpotApplied = true;
      }
      var quotationResponse = responseData['requestDetails'];
      quotationResponse.forEach(item => {
        item['isRateFound'] = true
      });
      responseData = {
        'responseDetails': quotationResponse,
        'requestContainerDetails': responseData['requestContainerDetails'],
        'requestDetails': responseData['responseDetails']
      };
      this.bookingRequestForm.patchValue({
        customerReference: this.quotationResponseServiceData.custRefNo,
        latestShippingDate: this.quotationResponseServiceData.latestShipingDate
      });
      this.comments = this.quotationResponseServiceData.comments;
      
    }
    responseData.responseDetails = responseData.responseDetails == null ? [] : responseData.responseDetails;
    var groupedData = [];

    var distinctCarrierIds = Array.from(new Set(responseData.responseDetails.map(m => m.carrierId)));
    var distinctLoadpointIds = Array.from(new Set(responseData.responseDetails.map(m => m.loadpointId)));
    var distinctFromPortIds = Array.from(new Set(responseData.responseDetails.map(m => m.fromPortId)));
    var distinctToPortIds = Array.from(new Set(responseData.responseDetails.map(m => m.toPortId)));
    var distinctDischargeIds = Array.from(new Set(responseData.responseDetails.map(m => m.dischargeId)));


    var groupCarriers = [], groupLoadpoints = [],
      groupFromPorts = [],
      groupToPorts = [],
      groupDischarges = [];

    var groupId = 0;

    distinctCarrierIds.forEach(function (carrierId) {
      if (carrierId != 0) {
        groupCarriers = responseData.responseDetails.filter(test => test.carrierId == carrierId);

        distinctLoadpointIds.forEach(function (loadPointId) {
          groupLoadpoints = groupCarriers.filter(test => test.loadpointId == loadPointId);

          distinctFromPortIds.forEach(function (fromPortId) {
            groupFromPorts = groupLoadpoints.filter(test => test.fromPortId == fromPortId);

            distinctToPortIds.forEach(function (toPortId) {
              groupToPorts = groupFromPorts.filter(test => test.toPortId == toPortId);

              distinctDischargeIds.forEach(function (dischargeId) {
                groupDischarges = groupToPorts.filter(test => test.dischargeId == dischargeId);

                if (groupDischarges.length > 0) {
                  groupedData.push({ values: groupDischarges, key: groupId, selected: false });
                }
              });
              groupId++;
            });
          });
        });
      }
    });

    groupedData.forEach(grp => {
      let sizeAndTypeGrouped = this.groupBy(grp.values, function (group) {
        return [group.size, group.type];
      });
      sizeAndTypeGrouped.forEach(group => {
        let groupId = parseInt((Math.random() * 100000).toFixed(0));
        let rdoGroupId = 'rdo_' + groupId.toFixed(0).toString();
        var grpdValues = group as Array<any>;
        if (grpdValues.length > 1) {
          grpdValues.forEach(item => {
            groupId += groupId;
            item.rdoGroupId = rdoGroupId;
            item.selected = '';
            item.rdoId = groupId;
          });
        }
        else {
          grpdValues[0].rdoGroupId = '';
          grpdValues[0].selected = groupId;
          grpdValues[0].rdoId = groupId;
        }
      });
    });

    this.quotationResponse =
    {
      'quotationId': responseData.quotationId,
      'fromPortCode': responseData.fromPortCode,
      'toPortCode': responseData.toPortCode,
      'commodityName': responseData.commodityName,
      'shipmentDate': responseData.shipmentDate,
      'responseDetails': groupedData,
      'requestDetails': responseData.requestDetails
    };

    this.isShowAllRateNotFoundError = responseData.responseDetails.filter(m => m.isRateFound == false).length > 0;

    if (this.isEdit == true) {
      groupedData[0].selected = true;
      this.isShowProceedButton = true;
      this.proceedBooking(this.quotationResponse.responseDetails[0]);
    }
  }

  checkBoxChanged(index: number, event: any): void {
    this.quotationResponse.responseDetails.forEach(item => {
      item['selected'] = false;
    });
    this.quotationResponse.responseDetails[index].selected = event.checked;

    this.isShowProceedButton = this.quotationResponse.responseDetails.filter(m => m.selected == true).length > 0;

    this.bookingInformationData = undefined;
  }

  proceedBooking(responseDetails: any): void {
    // Check if any rate is selected (if multiple rates are available from tier and from spot)
    let sizeAndTypeGrouped = this.groupBy(responseDetails.values, function (group) {
      return [group.rdoGroupId];
    });
    let isRateSelected = true;
    sizeAndTypeGrouped.forEach(group => {
      var grpdValues = group as Array<any>;
      if (isRateSelected) {
        isRateSelected = grpdValues.some(m => { return m.selected != '' });
      }
    });

    if (isRateSelected) {
      let data = Object.assign({}, responseDetails);
      data.values = data.values.filter(m => { return m.selected != '' && m.sellRate > 0 });
      if (data.values.length > 0) {
        this.bookingInformationData = data; //this.quotationResponse.responseDetails.filter(m => { return m.selected == true })[0];
        this.initializeBookingDetailsForm();
      }
      else {
        let buttons = [{ 'title': 'Ok', 'result': 'yes', 'type': 'accent' }];
        this.modalDialogService.openDialog('Information', 'Selected rate(s) are not valid', buttons).subscribe((res) => { });
      }
    }
    else {
      let buttons = [{ 'title': 'Ok', 'result': 'yes', 'type': 'accent' }];
      this.modalDialogService.openDialog('Information', 'Please select rate first', buttons).subscribe((res) => { });
    }
  }

  rateSelected(evt, group, item): void {
    let rates = group.values.filter(m => { return m.rdoGroupId == item.rdoGroupId });
    rates.forEach(rate => {
      rate.selected = '';
    });
  }

  groupBy(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  isValidRatesAvailable(rates: Array<any>): boolean {
    return rates.some(m => { return m.sellRate > 0 });
  }

  initializeForm() {
    this.bookingRequestForm = this.formBuilder.group({
      customerReference: [{ value: '', disabled: this.isBIReadOnly }, []],
      latestShippingDate: [{ value: '', disabled: this.isBIReadOnly }, []]
    });

    this.filterCarrierForm = this.formBuilder.group({
      carrier: ['', []]
    })
  }

  get bookingReqFormFields() { return this.bookingRequestForm.controls }

  enableBIEdit() {
    this.isBIReadOnly = !this.isBIReadOnly;
    if (this.isBIReadOnly) {
      this.bookingRequestForm.disable();
      this.appearance = 'fill';
    }
    else {
      this.bookingRequestForm.enable();
      this.appearance = 'outline';
    }
  }

  enableFirstPageEdit(): void {
    this.enableQuotEdit.emit();
  }

  initializeBookingDetailsForm() {
    var requestContainerDetails = this.quotationResponseServiceData['requestContainerDetails'];
    this.bookingInformationData.values.forEach((bookingDetailForm, index) => {
      bookingDetailForm['containerDetails'] = [];
      if (this.isEdit == true && requestContainerDetails != undefined) {
        var containerDetails = requestContainerDetails.filter(m => { return m.bookingDetailsId == bookingDetailForm.id });
        containerDetails.forEach(item => {
          bookingDetailForm['containerDetails'].push({
            id: bookingDetailForm.id,
            quantity: item.quantity,
            weight: item.weight,
            cutOffDate: item.cutoff,
            loadDate: item.loadDateTime,
            loadAddress: item.loadAddress,
            loadReference: item.loadReference
          });
        });
      }
      else {
        bookingDetailForm['containerDetails'] = [{ id: bookingDetailForm.id, quantity: '', weight: '', cutOffDate: '', loadDate: '', loadAddress: '', loadReference: '' }];
      }
    });


  }

  containerRequestSettings(rateItems: any): void {
    const dialogRef = this.dialog.open(RequestContainersSettingsComponent, {
      width: '700px',
      data: { 'bookingDetailId': rateItems.id }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {

        if (result.retainPreviousEntries == false) {
          rateItems.containerDetails = [];
        }

        if (this.user.countryOfRegistration == 146) {
          // UK
          var start = result.startTime;
          var end = result.endTime;
          do {
            rateItems.containerDetails.push({
              id: rateItems.id, quantity: result.quantity,
              weight: result.weight, cutOffDate: result.cutOffDate,
              loadDate: result.loadDate, loadAddress: result.loadAddress,
              loadReference: result.loadReference
            });
            start.setMinutes(start.getMinutes() + parseInt(result.frequency));
          } while (start <= end);
        }
        else {
          // USA
          rateItems.containerDetails.push({
            id: rateItems.id, quantity: result.quantity,
            weight: '', cutOffDate: result.cutOffDate,
            loadDate: '', loadAddress: '', loadReference: ''
          });
        }
      }
    });
  }

  addNewBookingDetail(rateItems: any): void {
    rateItems.containerDetails.push({ id: rateItems.id, quantity: '', weight: '', cutOffDate: '', loadDate: '', loadAddress: '', loadReference: '' });
  }

  removeBookingDetail(rateItems: any, rowIndex: number): void {
    rateItems.containerDetails.splice(rowIndex, 1)
  }

  bookingRequest() {
    var bookingContainerDetails = [];
    this.bookingInformationData.values.forEach(bookingDetailForm => {
      if (bookingDetailForm.isRateFound == true) {
        bookingDetailForm.containerDetails.forEach(containerDetail => {
          bookingContainerDetails.push({
            'Id': containerDetail.id,
            'Quantity': parseInt(containerDetail.quantity),
            'Weight': parseFloat(containerDetail.weight),
            'LoadDateTime': this.user.countryOfRegistration == 146 ? this.datePipe.transform(containerDetail.loadDate, 'yyyy-MM-dd HH:mm') : null,
            'Cutoff': this.user.countryOfRegistration == 147 ? this.datePipe.transform(containerDetail.cutOffDate, 'yyyy-MM-dd') : null,
            'LoadAddress': containerDetail.loadAddress,
            'LoadReference': containerDetail.loadReference,
            'Size': bookingDetailForm.size,
            'Type': bookingDetailForm.type
          })
        });
      }
    });

    var param = {
      'Id': this.quotationResponseServiceData.id,
      'QuotationId': this.quotationResponseServiceData.quotationId,
      'UserBookingDate': this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      'CustRefNo': this.bookingReqFormFields.customerReference.value,
      'LatestShipingDate': this.datePipe.transform(this.bookingReqFormFields.latestShippingDate.value, 'yyyy-MM-dd'),
      'Status': this.isEdit ? BookingStatusConstants.BOOKING_AMENDED : BookingStatusConstants.PENDING_CONFIRMATION,
      'Comments': this.comments,
      'RequestDetails': this.bookingInformationData.values.filter(m => { return m.isRateFound == true }),
      'RequestContainerDetails': bookingContainerDetails
    };

    this.isLoading = true;
    this.httpService.httpPost(RouteConstants.BOOKING_saveBooking, param).subscribe(res => {
      if (res['success'] == true) {
        this.bookingRequestForm.reset();
        this.initializeBookingDetailsForm();
        if (this.user.roleId == UserRoleConstants.CUSTOMER) {
          this.router.navigate(['customer/bookings-report']);
        }
        else {
          this.router.navigate(['user/bookings']);
        }

      }
      this.isLoading = false;
      this.submitted = false;
    });
  }
  DownloadPdf(): void {
    window.print();
  }

  openFilter(filterForm,event): void {
    let targetAttr = event.target.getBoundingClientRect();
    this.filterDialogRef = this.dialog.open(filterForm, {
      position:{
        top: targetAttr.y - targetAttr.height - targetAttr.height - targetAttr.height  + "px",
        left: targetAttr.x - targetAttr.width - 20 + "px"
      },
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  clearFilter() {
    this.filterCarrierForm.reset();
    this.filteredData = [];
    this.quotationResponseServiceData = JSON.parse(JSON.stringify(this.dummy));
    this.bindQuotationResponse(this.dummy)
  }

  filterFormSubmit() {
    this.filteredData = [];
    this.filterDialogRef.close();
    this.dummy.responseDetails.forEach(res => {
      if(this.filterCarrierForm.controls['carrier'].value.some(m => m == res['carrierCode'])){
        this.filteredData.push(res);
      }
    });
    this.quotationResponseServiceData.responseDetails = this.filteredData;
    this.bindQuotationResponse(this.quotationResponseServiceData);
  }
}
