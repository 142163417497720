import { Component, OnInit } from '@angular/core';
import { GridColumnType, GridActionType, Pagination, GridColumnDataType, DisplayModeConstants } from "../../constants/constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmBoxComponent } from "../../shared/confirm-box/confirm-box.component"
import { FormGroup, FormBuilder } from '@angular/forms';
import { TierConstants } from 'src/app/constants/constants';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';


@Component({
  selector: 'inbrit-tier-dashboard',
  templateUrl: './tier-dashboard.component.html',
  styleUrls: ['./tier-dashboard.component.scss']
})
export class TierDashboardComponent implements OnInit {
  tierMasterList: [any];
  dataCount: number;
  pageIndex = 1;
  TierConstants = TierConstants;
  filterTierListForm: FormGroup;
  pageSize = Pagination.PageSize;
  totalFiltersApplied: number = 0;
  tierType: string = "";
  filter: string = "";
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, class?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Code', dataField: 'tierCode', type: GridColumnType.DATA, },
      { title: 'Created By', dataField: 'createdby', type: GridColumnType.DATA, },
      { title: 'Last Modification On', dataField: 'modifiedDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },
      { title: 'Status', dataField: 'tierStatus', type: GridColumnType.DATA, },
      {
        title: 'Action', dataField: 'email', type: GridColumnType.ACTION, actions: [
          {
            event: 'edit', type: GridActionType.ICON, title: 'Edit', class: 'edit',
            conditionalDisplay: { dataField: 'isEditTier', value: true }
          },
          {
            event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility',
            // conditionalDisplay: { dataField: 'isViewTier', value: true }
          },
          {
            event: 'deActivateTier', type: GridActionType.ICON, title: 'Block', class: 'block',
            conditionalDisplay: { dataField: 'active', value: 'Approved' }
          },
          {
            event: 'activateTier', type: GridActionType.ICON, title: 'Unblock', class: 'check_circle',
            conditionalDisplay: { dataField: 'active', value: 'Blocked' }
          }
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'TierDashboardComponent-filter';

  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private dialog: MatDialog,
    private formBuilder: FormBuilder, private userInfoService: UserInfoService,
    private cacheService: CacheService) { }

  ngOnInit() {
    let routeArr = this.router.url.split('/');
    this.tierType = routeArr[routeArr.length - 1];
    this.initializeFilterForm();
    this.getTierMasters();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterTierListForm = this.formBuilder.group({
      isActive: [cachedFilter != null ? cachedFilter.value.isActive.value : '', []],
      tierStatusIds: [cachedFilter != null ? cachedFilter.value.tierStatusIds.value : '', []],

    });
  }
  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterTierListForm.controls; }

  filterFormSubmit() {
    this.getTierMasters();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterTierListForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.filterTierListForm.reset();
    this.getTierMasters();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  getTierMasters(): void {

    var tierStatusIds = [];
    this.totalFiltersApplied = 0;

    if (this.filterFormFields.tierStatusIds.value) {
      this.totalFiltersApplied += 1;
      this.filterFormFields.tierStatusIds.value.forEach(item => {
        tierStatusIds.push(item);
      });
    }

    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "SearchText": this.filter,//this.filterFormFields.tierCode.value != null ? this.filterFormFields.tierCode.value : "",
      "StatusIds": tierStatusIds,
    }


    if (this.filterFormFields.isActive.value != null && this.filterFormFields.isActive.value != "") {
      param["IsActive"] = this.filterFormFields.isActive.value == "true" ? true : false;
    }

    var api = this.tierType != 'tier-report' ? RouteConstants.FREIGHT_getAllTierMaster : RouteConstants.FRIEGHT_getAllTierArchiveMaster;

    this.httpService.httpPost(api, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.dataCount = res['count'];
        data.forEach(item => {
          item['createdby'] = item['createdByName'];
          item['active'] = this.tierType == 'tier-report' ? "" : this.returnTierStatusText(item['status']);
          item['tierStatus'] = this.returnTierStatusText(item['status']);
          item['isEditTier'] = this.tierType == 'tier-report' ? false : item['status'] == TierConstants.PENDING_APPROVAL || item['status'] == TierConstants.APPROVED;
          item['isViewTier'] = !item['isEditTier'];
          item['class'] = {
            tierStatus: this.userInfoService.getColorClass(item['status'])
          };
        });

        this.tierMasterList = data;
      }
    });
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewTierRates(evt.data.tierId);
          break;
        }
      case 'edit':
        {
          this.editTierRates(evt.data.tierId);
          break;
        }
      case 'deActivateTier':
        {
          this.confirmTierActivateDeactivate(evt.data.tierId, false);
          break;
        }
      case 'activateTier':
        {
          this.confirmTierActivateDeactivate(evt.data.tierId, true);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getTierMasters();
  }
  
  viewTierRates(tierId: number): void {
    let navRoute = this.tierType == "tier-report" ? 'user/tier-report' : 'user/tiers'
    this.router.navigate([navRoute, this.encryptionService.encrypt(tierId), this.encryptionService.encrypt(DisplayModeConstants.view)]);
  }

  editTierRates(tierId: number): void {
    this.router.navigate(['user/tiers', this.encryptionService.encrypt(tierId), this.encryptionService.encrypt(DisplayModeConstants.edit)]);
  }

  confirmTierActivateDeactivate(tierId: number, isActive: boolean): void {
    const dialogRef = this.dialog.open(ConfirmBoxComponent, {
      width: '450px',
      data: { tierId: tierId, isBlocked: isActive, message: "The tier will be " + (isActive == true ? "Activated" : "Deactivated") }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.activateOrDeactivateTierMaster(tierId, isActive);
      }
    });
  }

  activateOrDeactivateTierMaster(tierId: number, isActive: boolean): void {
    var param = {
      "TierId": tierId, "IsActive": isActive
    };
    this.httpService.httpPost(RouteConstants.FREIGHT_updateTierMaster, param).subscribe(res => {
      if (res['success'] == true) {
        var tierMaster = this.tierMasterList.filter(function (item) { return item.tierId == tierId })[0];
        tierMaster['isActive'] = isActive;
        tierMaster['active'] = this.returnTierStatusText(res['data'].status);
        tierMaster['class'] = {
          active: this.userInfoService.getColorClass(res['data'].status)
        };

        tierMaster['isEditTier'] = res['data'].status == TierConstants.PENDING_APPROVAL || res['data'].status == TierConstants.APPROVED;
        tierMaster['isViewTier'] = !tierMaster['isEditTier'];
      }
    });
  }

  returnTierStatusText(status: number) {

    return TierConstants.TIER_STATUSES.find(m => m.id == status).description;
  }

}
