import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared-module/shared-module.module';

import { LoginComponent } from '../account/login/login.component';
import { HomeComponent } from '../home/home.component';
import { ForgotPasswordComponent } from "../account/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "../account/reset-password/reset-password.component";
import { LoginHistoryComponent } from "../account/login-history/login-history.component";
import { NotifyComponent } from "../shared/notify/notify.component";
import { AdminLayoutComponent } from "../layouts/admin-layout/admin-layout.component";
import { ProfilePhotoComponent } from "../account/profile-photo/profile-photo.component";
import { AuthLayoutComponent } from "../layouts/auth-layout/auth-layout.component";
import { FooterComponent } from "../shared/footer/footer.component";
import { NavbarComponent } from "../shared/navbar/navbar.component";
import { SidebarComponent } from "../shared/sidebar/sidebar.component";
import { RegistrationComponent } from '../account/registration/registration.component';
import { AddCustomerComponent } from "../add-customer/add-customer.component";
import { UserRegistrationComponent } from "../account/user-registration/user-registration.component";
import { KycDetailsComponent } from '../customer-kyc/kyc-details/kyc-details.component';
import { CustomerListComponent } from '../customer-list/customer-list.component';
import { CustomerDetailComponent } from "../customer-detail/customer-detail.component";
import { FocusFirstInvalidControlDirective } from "../directives/focusFirstInvalidControl/focus-first-invalid-control.directive";
import { NumbersOnlyDirective } from "../directives/numbers-only/numbers-only.directive";
import { PendingProfilesComponent } from "../pending-profiles/pending-profiles.component";
import { UserProfileComponent } from "../account/user-profile/user-profile.component";
import { ProfileApprovalComponent } from "../account/profile-approval/profile-approval.component";
import { KycApprovalComponent } from "../customer-kyc/kyc-approval/kyc-approval.component";
import { PaginationComponent } from "../shared/pagination/pagination.component";
import { PaginationBackForthComponent } from "../shared/pagination-back-forth/pagination-back-forth.component";
import { TableComponent } from "../shared/table/table.component";
import { FilterPipe } from "../directives/filter/filter.pipe";

// Guards *START*
import { AuthGuard } from "../helpers/auth-guard/auth.guard";
import { UserRoleGuard } from "../helpers/role-guard/user-role-guard";
import { CustomerRoleGuard } from "../helpers/role-guard/customer-role-guard";
// Guards *END*
import { PendingKycComponent } from "../pending-kyc/pending-kyc.component";
import { InviteCustomerComponent } from "../invite-customer/invite-customer.component";
import { TextEllipsisPipe } from "../directives/text-ellipsis/text-ellipsis.pipe";
import { DynamicComponentContainerDirective } from "../directives/dynamic-component-container/dynamic-component-container.directive";

// Freights *START*
import { TierSetupComponent } from "../freights/tier-setup/tier-setup.component";
import { TierDashboardComponent } from "../freights/tier-dashboard/tier-dashboard.component";
import { TierUserMappingComponent } from "../freights/tier-user-mapping/tier-user-mapping.component";
import { QuotationRequestComponent } from "../freights/quotation-request/quotation-request.component";
import { QuotationResponseComponent } from "../freights/quotation-response/quotation-response.component";
import { FreightRatePendingComponent } from "../freight-rate-pending/freight-rate-pending.component";
import { FreightRateApprovalComponent } from "../freight-rate-approval/freight-rate-approval.component";
import { CompareRatesheetsComponent } from "../freights/compare-ratesheets/compare-ratesheets.component";
import { SpotBuyCreateComponent } from "../spot/spot-buy-create/spot-buy-create.component";
import { SpotBuyDashboardComponent } from '../spot/spot-buy-dashboard/spot-buy-dashboard.component';
import { SpotSellCreateComponent } from '../spot/spot-sell-create/spot-sell-create.component';
import { SpotSellDashboardComponent } from '../spot/spot-sell-dashboard/spot-sell-dashboard.component';
import { SpotSellUserComponent } from '../spot/spot-sell-user/spot-sell-user.component';

import { QuotationDashboardComponent } from "../freights/quotation-dashboard/quotation-dashboard.component";
import { QuotationReportComponent } from "../reports/quotation-report/quotation-report.component";
import { BookingReportComponent } from "../reports/booking-report/booking-report.component";
import { QuotationDetailsComponent } from "../freights/quotation-details/quotation-details.component";
import { BookingHistoryComponent } from "../freights/booking-history/booking-history.component";
import { FreightBookingComponent } from "../freights/freight-booking/freight-booking.component";
import { FreightMasterComponent } from "../freights/freight-master/freight-master.component";
import { RequestContainersSettingsComponent } from "../freights/request-containers-settings/request-containers-settings.component";
import { FtpHandlersComponent } from "../freights/ftp-handlers/ftp-handlers.component";
// Freights *END*

// Ratesheets *START*
import { ProcessRatesheetComponent } from "../rate-sheets/process-ratesheet/process-ratesheet.component";
import { RateSheetDashboardComponent } from "../rate-sheets/rate-sheet-dashboard/rate-sheet-dashboard.component";
// Ratesheets *END*

import { NavBarNotificationComponent } from "../notifications/nav-bar-notification/nav-bar-notification.component";
import { NotificationDashboardComponent } from "../notifications/notification-dashboard/notification-dashboard.component";
import { HighlightSearch } from "../directives/highlight/highlight.directive";
import { TransformDatePipe } from "../directives/transform-date/transform-date.pipe";

import { InviteDashboardComponent } from '../invite-customer/invite-dashboard/invite-dashboard.component';
import { BlankCalendarHeaderComponent } from '../shared/blank-calendar-header/blank-calendar-header.component';
import { LoginTraceDetailsComponent } from '../account/login-trace-details/login-trace-details.component';
import { AddNewSellRateComponent } from '../spot/add-new-sell-rate/add-new-sell-rate.component';
import { CustomRateLocationComponent } from '../spot/custom-rate-location/custom-rate-location.component';
import { SortPipe } from '../directives/sort/sort.pipe';

const appRoutes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth', component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'registration', component: UserRegistrationComponent },
    ],
  },
  {
    path: 'user', component: AdminLayoutComponent, canActivate: [AuthGuard],
    canActivateChild: [UserRoleGuard],
    children: [
      { path: 'dashboard', component: HomeComponent },
      { path: 'customer-list', component: CustomerListComponent },
      { path: 'customer-list/:id', component: CustomerDetailComponent },
      { path: 'customer-list/add-customer/:id', component: AddCustomerComponent },
      { path: 'user-list', component: CustomerListComponent },
      { path: 'user-list/:id', component: CustomerDetailComponent },
      { path: 'user-list/add-user/:id', component: AddCustomerComponent },
      { path: 'customer-list/kyc-details/:id', component: KycDetailsComponent },
      { path: 'customer-list/login-history/:id', component: LoginHistoryComponent },
      { path: 'pending-approval', component: PendingProfilesComponent },
      { path: 'pending-confirmation', component: PendingProfilesComponent },
      { path: 'pending-approval/:id', component: ProfileApprovalComponent },
      { path: 'pending-confirmation/:id', component: ProfileApprovalComponent },
      { path: 'user-profile/:id', component: UserProfileComponent },
      { path: 'login-history/:id', component: LoginHistoryComponent },
      
      { path: 'tiers', component: TierDashboardComponent },
      { path: 'tier-report', component: TierDashboardComponent },
      { path: 'tiers/users', component: TierUserMappingComponent, pathMatch: 'full' },
      { path: 'tiers/tier-setup', component: TierSetupComponent, pathMatch: 'full' },
      // { path: 'tiers/:id', component: TierSetupComponent, pathMatch: 'full' },
      { path: 'tiers/:id/:displayMode', component: TierSetupComponent, pathMatch: 'full' },
      { path: 'tier-report/:id/:displayMode', component: TierSetupComponent, pathMatch: 'full' },

      { path: 'pending-kyc', component: PendingKycComponent },
      { path: 'pending-kyc/:id', component: KycApprovalComponent },
      { path: 'quotation-request', component: QuotationRequestComponent },
      { path: 'quotations', component: QuotationDashboardComponent },
      { path: 'quotations/:id', component: QuotationDetailsComponent },
      { path: 'quotations-report', component: QuotationReportComponent },
      { path: 'quotations-report/:id', component: QuotationDetailsComponent },
      { path: 'bookings-report', component: BookingReportComponent },
      { path: 'bookings-report/:id', component: QuotationRequestComponent },
      { path: 'invite-customer', component: InviteDashboardComponent },
      { path: 'invite-customer/new', component: InviteCustomerComponent },
      { path: 'invite-customer/:id', component: InviteCustomerComponent },
      { path: 'freights', component: FreightRatePendingComponent },
      { path: 'freight-approval/:id', component: FreightRateApprovalComponent },
      { path: 'freights/compare', component: CompareRatesheetsComponent },
      
      { path: 'spot-buy', component: SpotBuyDashboardComponent },
      { path: 'spot-buy/new', component: SpotBuyCreateComponent },
      // { path: 'spot-buy/:id', component: SpotBuyCreateComponent },
      { path: 'spot-buy/:id/:displayMode', component: SpotBuyCreateComponent, pathMatch: 'full' },

      { path: 'spot-sell', component: SpotSellDashboardComponent },
      { path: 'spot-sell/users', component: SpotSellUserComponent, pathMatch: 'prefix' },
      { path: 'spot-sell/new', component: SpotSellCreateComponent, pathMatch: 'prefix' },
      // { path: 'spot-sell/:id', component: SpotSellCreateComponent },
      { path: 'spot-sell/:id/:displayMode', component: SpotSellCreateComponent, pathMatch: 'full' },

      { path: 'bookings', component: BookingHistoryComponent },
      { path: 'bookings/:id', component: QuotationRequestComponent },
      { path: 'freight-booking/:id', component: FreightBookingComponent },
      { path: 'notifications', component: NotificationDashboardComponent },
      { path: 'process-ratesheets', component: RateSheetDashboardComponent },
      { path: 'process-ratesheets/new', component: ProcessRatesheetComponent },
      { path: 'process-ratesheets/:id', component: ProcessRatesheetComponent },

      { path: 'invite-customer-dashboard', component: InviteDashboardComponent },
    ],
  },
  {
    path: 'customer', component: AdminLayoutComponent, canActivate: [AuthGuard],
    canActivateChild: [CustomerRoleGuard],
    children: [
      { path: 'dashboard', component: HomeComponent },
      { path: 'kyc-details', component: KycDetailsComponent },
      { path: 'user-profile/:id', component: UserProfileComponent },
      { path: 'quotation-request', component: QuotationRequestComponent },
      { path: 'bookings-report', component: BookingReportComponent },
      { path: 'bookings-report/:id', component: QuotationRequestComponent },
      { path: 'notifications', component: NotificationDashboardComponent },
      { path: 'quotations-report', component: QuotationReportComponent },
      { path: 'quotations-report/:id', component: QuotationDetailsComponent },
      { path: 'login-history/:id', component: LoginHistoryComponent }
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'auth/login' }

];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(appRoutes),
  ],
  exports: [
    RouterModule,
    SharedModule
  ],
  declarations: [
    AdminLayoutComponent, AuthLayoutComponent,
    LoginComponent, ForgotPasswordComponent, ResetPasswordComponent,
    RegistrationComponent, AddCustomerComponent, UserRegistrationComponent,
    HomeComponent, KycDetailsComponent, CustomerListComponent,
    CustomerDetailComponent, PendingProfilesComponent, UserProfileComponent,
    ProfileApprovalComponent, KycApprovalComponent,
    FooterComponent, NavbarComponent, SidebarComponent, NotifyComponent,
    TierSetupComponent, TierDashboardComponent, TierUserMappingComponent,
    InviteCustomerComponent, PendingKycComponent, FreightRatePendingComponent,
    FreightRateApprovalComponent, CompareRatesheetsComponent, SpotBuyCreateComponent,
    SpotBuyDashboardComponent, SpotSellCreateComponent, SpotSellDashboardComponent,
    SpotSellUserComponent, QuotationRequestComponent, QuotationResponseComponent,
    QuotationDashboardComponent, QuotationDetailsComponent, BookingHistoryComponent,
    QuotationReportComponent, BookingReportComponent,
    ProcessRatesheetComponent, FreightBookingComponent, FreightMasterComponent,
    ProfilePhotoComponent, NavBarNotificationComponent, NotificationDashboardComponent, RateSheetDashboardComponent,
    InviteDashboardComponent, LoginHistoryComponent,

    FocusFirstInvalidControlDirective, NumbersOnlyDirective, TableComponent, PaginationComponent, PaginationBackForthComponent,
    TextEllipsisPipe, DynamicComponentContainerDirective, RequestContainersSettingsComponent, FtpHandlersComponent,
    HighlightSearch,LoginTraceDetailsComponent, TransformDatePipe,BlankCalendarHeaderComponent, AddNewSellRateComponent,
    CustomRateLocationComponent, FilterPipe,SortPipe
  ],
  entryComponents: [LoginTraceDetailsComponent]
})

export class AppRouterModule { }
