import { Component, OnInit } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../services/encryption/encryption.service";
import { UserInfoService } from "../services/userInfo/user-info.service";
import {
  FreightConstants, Pagination, GridColumnType,
  GridColumnDataType, GridActionType, DateConstants
} from "../constants/constants";
import { TitleCasePipe } from "@angular/common";
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CacheService } from '../services/cache-service/cache.service';

@Component({
  selector: 'inbrit-freight-rate-pending',
  templateUrl: './freight-rate-pending.component.html',
  styleUrls: ['./freight-rate-pending.component.scss']
})
export class FreightRatePendingComponent implements OnInit {
  FreightConstants = FreightConstants;
  statusId: number;
  totalFiltersApplied: number = 2;
  filterFreightListForm: FormGroup;
  freightList: [any];
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  datePipe = new DatePipe('en-US');
  todayDate: Date = new Date();
  DateConstants = DateConstants;
  carrierMasters: Array<any> = [];
  commodityList: Array<any> = [];
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, rowChild?: { component: any, show: boolean },
    actions?: Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Carrier', dataField: 'carrierCode', type: GridColumnType.DATA, },
      { title: 'Country', dataField: 'country', type: GridColumnType.DATA, },
      { title: 'Commodity', dataField: 'commodity', type: GridColumnType.DATA },
      { title: 'Effective Date', dataField: 'effectiveDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      { title: 'Expiry Date', dataField: 'expiryDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      { title: 'Uploaded By', dataField: 'emailFrom', type: GridColumnType.DATA },
      { title: 'Status', dataField: 'statusDesc', type: GridColumnType.DATA },
      { title: 'Active', dataField: 'active', type: GridColumnType.DATA },
      { title: 'Pending Since', dataField: 'uploadDate', type: GridColumnType.DATA },
      {
        title: 'Action', dataField: 'id', type: GridColumnType.ACTION, actions: [
          { event: 'viewFreight', type: GridActionType.ICON, title: 'View Freight', class: 'visibility' },
          {
            event: 'deactivateRatesheet', type: GridActionType.ICON, title: 'Deactivate', class: 'block',
            conditionalDisplay: { dataField: 'showActivateButton', value: true }
          },
          {
            event: 'activateRatesheet', type: GridActionType.ICON, title: 'Activate', class: 'check_circle',
            conditionalDisplay: { dataField: 'showDeactivateButton', value: true }
          }
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'FreightRatePendingComponent-filter';

  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private formBuilder: FormBuilder,
    private userInfoService: UserInfoService, private dialog: MatDialog,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.initializeFilterForm();
    this.getFreight();
    this.getCommodityList();
    this.getAllCarrierMaster();
  }

  // convenience getter for easy access to form fields
  get regForm() { return this.filterFreightListForm.controls; }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterFreightListForm = this.formBuilder.group({
      ratechartStatusIds: [cachedFilter != null ? cachedFilter.value.ratechartStatusIds.value : [FreightConstants.PENDING_APPROVAL], []],
      carrierIds: [cachedFilter != null ? cachedFilter.value.carrierIds.value : [], []],
      commodityIds: [cachedFilter != null ? cachedFilter.value.commodityIds.value : [], []],
      toDate: [],
      fromDate: [],
    });

  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterFreightListForm.controls; }

  clearFilter(): void {
    this.pageIndex = 1;

    //setting all FREIGHT_STATUSES to get entire data
    var rateStatusIds = [];
    FreightConstants.FREIGHT_STATUSES.forEach(item => {
      rateStatusIds.push(item.id);
    });
    this.filterFreightListForm.patchValue({
      ratechartStatusIds: rateStatusIds,
      carrierIds: [],
      commodityIds: [],
      toDate: '',
      fromDate: ''
    });

    this.getFreight();

    //clearing variable to not reflect on UI
    this.filterFreightListForm.patchValue({
      ratechartStatusIds: ''
    });
    this.totalFiltersApplied = 0;
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  getFreight(): void {
    var freightStatusIds = [];
    this.totalFiltersApplied = this.filterFormFields.ratechartStatusIds.value.length;
    this.totalFiltersApplied += this.filterFormFields.carrierIds.value.length;
    this.totalFiltersApplied += this.filterFormFields.commodityIds.value.length;

    if (this.filterFormFields.ratechartStatusIds.value) {
      this.filterFormFields.ratechartStatusIds.value.forEach(item => {
        freightStatusIds.push(item);
      });
    }

    let param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "StatusIds": freightStatusIds,
      "CarrierIds": this.filterFormFields.carrierIds.value,
      "CommodityIds": this.filterFormFields.commodityIds.value,
      "FromDate": this.datePipe.transform(this.filterFormFields.fromDate.value, 'yyyy-MM-dd'),
      "ToDate": this.datePipe.transform(this.filterFormFields.toDate.value, 'yyyy-MM-dd'),
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_getFreightRate, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.dataCount = res['count'];

        data.forEach(item => {
          item['isPending'] = item['status'] == FreightConstants.PENDING_APPROVAL;
          item['active'] = item['isActive'] == true ? 'Yes' : 'No';
          item['uploadDate'] = item['status'] == FreightConstants.PENDING_APPROVAL ? (this.isNullOrEmpty(item['modifiedDate']) ? this.getFormattedHours(item['createdDate']) : this.getFormattedHours(item['modifiedDate']) ): "--";
          item['showActivateButton'] = item['status'] == FreightConstants.APPROVED && item['isActive'] == true;
          item['showDeactivateButton'] = item['status'] == FreightConstants.APPROVED && item['isActive'] == false;
          item['class'] = {
            statusDesc: this.userInfoService.getColorClass(item.status),
            active: item['isActive'] == true ? 'p-2 font-bold is-green' : 'p-2 font-bold is-red'
          };

          if (item['status'] == FreightConstants.APPROVED) {
            item['statusDesc'] = 'Approved';
          }
          else if (item['status'] == FreightConstants.EXPIRED) {
            item['statusDesc'] = 'Expired';
          }
          else if (item['status'] == FreightConstants.APPROVAL_REJECTED) {
            item['statusDesc'] = 'Rejected';
          }
          else if (item['status'] == FreightConstants.PENDING_APPROVAL) {
            item['statusDesc'] = 'Pending';
          }
        });

        this.freightList = data;
      }
    });
  }

  isNullOrEmpty(val: any): boolean{
    if(val == null || val == undefined || val == ""){
      return true;
    }
    return false;
  }

  viewFreight(freightId: number): void {
    this.router.navigate(['user/freight-approval', this.encryptionService.encrypt(freightId)]);
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'viewFreight':
        {
          this.viewFreight(evt.data.id);
          break;
        }
      case 'activateRatesheet':
        {
          this.activateRatesheet(evt.data.id, evt.data.status, true);
          break;
        }
      case 'deactivateRatesheet':
        {
          this.activateRatesheet(evt.data.id, evt.data.status, false);
          break;
        }
    }
  }

  activateRatesheet(freightId: number, freightStatusId: number, isActive: boolean): void {
    let param = {
      'Id': freightId,
      'Status': freightStatusId,
      'IsActive': isActive
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_changeFreightStatus, param).subscribe(res => {
      if (res['success'] == true) {
        let freightRate = this.freightList.find(function (item) { return item.id == freightId });
        freightRate['isActive'] = isActive;
        freightRate['active'] = isActive ? 'Yes' : 'No';
        freightRate['showActivateButton'] = freightRate['status'] == FreightConstants.APPROVED && isActive == true;
        freightRate['showDeactivateButton'] = freightRate['status'] == FreightConstants.APPROVED && isActive == false;
        freightRate['class']['active'] = isActive ? 'p-2 font-bold is-green' : 'p-2 font-bold is-red';
      }
    });
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getFreight();
  }

  getFormattedHours(createdDate: any): string {
    return this.userInfoService.getLocalizedFormattedHours(createdDate);
  }

  filterFormSubmit(): void {
    this.getFreight();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterFreightListForm);
    this.cacheService.set(this.uniqueCacheKey, filters);

  }

  getAllCarrierMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null).subscribe(res => {
      this.carrierMasters = res['data'];
    });
  }

  getCommodityList() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCommodity, null, false).subscribe(res => {
      this.commodityList = res['data'];
    });
  }

}
