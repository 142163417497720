import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FormGroup } from '@angular/forms';

interface CacheContent {
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
  }

  get(key: string): CacheContent {
    if(this.has(key)) {
      return this.storage.get(key);
    }
    return null;
  }

  set(key: string, value: any): void {
    this.storage.set(key, {value : value})
  }

  has(key: string): boolean {
    return this.storage.has(key);
  }

  delete(key: string): void {
    this.storage.remove(key);
  }

  deleteAll(): void {
    this.storage.clear();
  }

  getFormValues(formGroup: FormGroup) : any {
    var keyValuePairObject = {};
    Object.keys(formGroup.controls).forEach(key => {
      keyValuePairObject[key] = { value: formGroup.get(key).value }
    });
    return keyValuePairObject;
  }
}