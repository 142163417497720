import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModelDialogComponent } from '../../shared/model-dialog/model-dialog.component';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ModalDialogService {

  statusConfirmDialogRef: MatDialogRef<ModelDialogComponent>;
  modalData: any;

  constructor(private dialog: MatDialog, private dataService: DataTransferService) { }

  openDialog(title: string, message: string, buttons: Array<{ 'title': string, 'result': string, 'type': string }>): Observable<Object> {
    this.modalData = { 'title': title, 'message': message, 'buttons': buttons };
    this.dataService.setData(this.modalData);
    this.statusConfirmDialogRef = this.dialog.open(ModelDialogComponent, {
      width: '450px'
    });
    return this.statusConfirmDialogRef.afterClosed();
  }

}
