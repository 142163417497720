import { Component, OnInit } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../services/encryption/encryption.service";
import { ActivatedRoute } from "@angular/router";
import {
  ProfileStatusConstants, Pagination, GridColumnType,
  GridColumnDataType, GridActionType, KycStatusConstants
} from "../constants/constants";
import { TitleCasePipe } from "@angular/common"

@Component({
  selector: 'inbrit-pending-kyc',
  templateUrl: './pending-kyc.component.html',
  styleUrls: ['./pending-kyc.component.scss']
})
export class PendingKycComponent implements OnInit {
  customerList: [any];
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  filter:string='';
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Name', dataField: 'fullName', type: GridColumnType.DATA, },
      { title: 'Company', dataField: 'companyName', type: GridColumnType.DATA, },
      { title: 'Due Date', dataField: 'kycDueDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      { title: 'Submitted On', dataField: 'modifiedDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      {
        title: 'Action', dataField: '', type: GridColumnType.ACTION, actions: [
          { event: 'viewKycDetail', type: GridActionType.ICON, title: 'Approve/Reject KYC', class: 'visibility' },
        ]
      }
    ]

  constructor(private httpService: HttpService, private router: Router,
    private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService) {
  }

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers(): void {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "KycStatusIds": [KycStatusConstants.KYC_APPROVAL_PENDING],

    };
    this.httpService.httpPost(RouteConstants.ACCOUNT_getUsers+ "?searchText=" + this.filter, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.dataCount = res['count'];
        data.forEach(item => {
          item['fullName'] = this.titleCasePipe.transform(item['firstName'] + ' ' + item['lastName']);
          item['companyName'] = this.titleCasePipe.transform(item['userCompanyDetail']['companyName']);
        });
        this.customerList = data;
      }
    });
  }

  viewKycDetail(customerId: number): void {
    this.router.navigate(['user/pending-kyc', this.encryptionService.encrypt(customerId)]);
  }

  gridEventHandler(evt): void {
    this.viewKycDetail(evt.data.id);
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getCustomers();
  }
}
