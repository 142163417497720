import { Component, OnInit } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { DatePipe, Location } from "@angular/common";
import { UserRoleConstants, DateFormats } from 'src/app/constants/constants';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';


@Component({
  selector: 'inbrit-quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.scss']
})
export class QuotationDetailsComponent implements OnInit {
  quotationId: number = 0;
  quotationRequestData: any;
  quotationResponse: any;
  datePipe = new DatePipe('en-US');
  quotationNumber: string = '';
  user: any;
  carrierList: Array<string> = [];

  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute,
    private encryptionService: EncryptionService, private router: Router,
    private userInfoService: UserInfoService, private location: Location) {
    var id = '';
    this.activatedRoute.params.subscribe(params => { id = params['id'] });
    if (id != '') {
      this.quotationId = this.encryptionService.decrypt(id);
    }
  }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();;
    if (user != null) {
      this.user = user;
    }
    this.getQuotatinResponse();

    var initialPath = '/user/';
    if (this.user.roleId == UserRoleConstants.CUSTOMER) {
      initialPath = '/customer/'
    }
  }

  getQuotatinResponse(): void {
    this.httpService.httpPost(RouteConstants.QUOTATION_getQuotationById + "?quotationId=" + this.quotationId, null).subscribe(res => {
      if (res['success'] == true) {
        this.quotationResponse = res['data'];
        var reqData = res['data'];
        this.quotationNumber = reqData.quotationNumber;
        this.quotationRequestData = {
          'quotationNumber': reqData.quotationNumber,
          'customerName': reqData.customerName,
          // 'fromPortCode': reqData.fromPortCode != '' ? reqData.fromPortName + ' (' + reqData.fromPortCode + ')' : '',
          'fromPortCode': reqData.fromPortName != '' ? reqData.fromPortName : '',
          'toPortList': reqData.toPortList,
          'commodityName': reqData.commodityName,
          'shipmentDate': this.user.countryOfRegistration == 147 ? this.datePipe.transform(reqData.shipmentDate, DateFormats.STANDARD_DATE) : this.datePipe.transform(reqData.shipmentMonth, 'MMM-yyyy'),
          'tierCode': reqData.tierName,
          'spotRateName': reqData.spotRateName
        }
        this.carrierList = reqData.responseDetails.map(m => m.carrierCode);
      }
    });
  }

  naviagteBack():void{
    this.location.back();
  }
}
