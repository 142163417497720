import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from "../../constants/route-constants";
import {
  Pagination, GridColumnType,
  GridColumnDataType, GridActionType, DateConstants
} from "../../constants/constants";
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import * as saveAs from 'file-saver';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ModalDialogService } from 'src/app/services/modal-dialog/model-dialog.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';


@Component({
  selector: 'inbrit-rate-sheet-dashboard',
  templateUrl: './rate-sheet-dashboard.component.html',
  styleUrls: ['./rate-sheet-dashboard.component.scss']
})
export class RateSheetDashboardComponent implements OnInit {
  rateSheetList: any;
  rateSheetListOriginal: [any];
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  filterRateProcessingForm: FormGroup;
  totalFiltersApplied: number = 2;
  datePipe = new DatePipe('en-US');
  carrierIds = [];
  countryIds = [];
  countries: [any];
  carriers: [any];
  submitted: boolean;
  DateConstants = DateConstants;
  uniqueCacheKey: string = 'RateSheetDashboardComponent-filter';
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Country', dataField: 'countryName', type: GridColumnType.DATA, },
      { title: 'Carrier', dataField: 'carrierName', type: GridColumnType.DATA },
      { title: 'Upload File Name', dataField: 'fileName', type: GridColumnType.DATA, },
      { title: 'Upload Date', dataField: 'createdDate',  type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME  },
      { title: 'Upload By', dataField: 'createdBy', type: GridColumnType.DATA, },
      {
        title: 'Uploaded File', dataField: 'uploadFileName', type: GridColumnType.ACTION, actions: [
          {
            event: 'viewUploadFile', type: GridActionType.ICON, title: 'View Uploaded File', class: 'get_app',
          },
        ]
      },
      {
        title: 'Processed File', dataField: 'exportFileName', type: GridColumnType.ACTION, actions: [
          {
            event: 'viewExportFile', type: GridActionType.ICON, title: 'View Processed File', class: 'get_app',
            conditionalDisplay: { dataField: 'ExportFileYN', value: 'Yes' }
          },
        ]
      },
      {
        title: 'Error(s)', dataField: 'id', type: GridColumnType.ACTION, actions: [
          { event: 'viewRatesheet', type: GridActionType.ICON, title: 'View Ratesheet', class: 'visibility' },
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;

  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private formBuilder: FormBuilder,
    private dialog: MatDialog, private modalDialogService: ModalDialogService,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.initializeFilterForm();
    this.getCountries();
    this.getCarriers();
    this.getSnapshotTransaction();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterRateProcessingForm = this.formBuilder.group({
      carrierIds: [cachedFilter != null ? cachedFilter.value.carrierIds.value : '', []],
      countryIds: [cachedFilter != null ? cachedFilter.value.countryIds.value : '', []],
      FromDate: [cachedFilter != null ? cachedFilter.value.FromDate.value : new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      ToDate: [cachedFilter != null ? cachedFilter.value.ToDate.value : new Date(moment().format("M/D/YYYY").toString()), [Validators.required]],
    });
  }

  get filterFormFields() { return this.filterRateProcessingForm.controls; }

  getSnapshotTransaction(): void {
    this.totalFiltersApplied = 1;

    var param = {
      "CarrierIds": this.filterFormFields.carrierIds.value == "" ? [] : this.filterFormFields.carrierIds.value,
      "CountryIds": this.filterFormFields.countryIds.value == "" ? [] : this.filterFormFields.countryIds.value,
      "FromDate": this.datePipe.transform(this.filterFormFields.FromDate.value, 'yyyy-MM-dd'),
      "ToDate": this.datePipe.transform(this.filterFormFields.ToDate.value, 'yyyy-MM-dd')
    };
    if (this.filterFormFields.carrierIds.value != null && this.filterFormFields.carrierIds.value.length > 0) {
      this.totalFiltersApplied++;
    }
    if (this.filterFormFields.countryIds.value != null && this.filterFormFields.countryIds.value.length > 0) {
      this.totalFiltersApplied++;
    }

    this.httpService.httpPost(RouteConstants.RATESHEET_PROCESSOR_dashboard, param).subscribe(res => {
      if (res['success'] == true) {

        this.rateSheetListOriginal = res['data'];

        this.rateSheetListOriginal.forEach(function (item) {
          item['ExportFileYN'] = item["exportFilePath"] == null ? 'No' : 'Yes';
          item['uploadFileName'] = item['fileName'];
          item['colStyle'] = {
            uploadFileName: {
              'color': 'green',
            },
            exportFileName: {
              'color': 'green',
            }
          };
        });
        this.dataCount = res['count'];

        this.paginationEventHandler({ 'pageIndex': this.pageIndex, 'pageSize': this.pageSize });
      }
    });
  }

  viewUploadFile(id: number): void {
    var file_path = this.rateSheetList.filter(rate => rate.id == id).map(rate => rate.filePath)[0];
    saveAs(file_path, file_path.split('/').pop());
  }

  viewExportFile(id: number): void {
    var file_path = this.rateSheetList.filter(rate => rate.id == id).map(rate => rate.exportFilePath)[0];
    saveAs(file_path, file_path.split('/').pop());
  }

  viewProcessRatesheet(data: any): void {
    if (data.snapshotTransactionErrors.length > 0) {
      var errors = 'Snapshot data is not available due to errors :';
      errors += '<ul class="mt-3">';
      data.snapshotTransactionErrors.forEach(error => {
        errors += '<li class="is-red">' + error.errorMessage + '</li>';
      });
      errors += '</ul>'
      var buttons = [{ title: 'Ok', result: 'yes', type: 'accent' }];
      this.modalDialogService.openDialog('Error', errors, buttons).subscribe((res) => {
      });
    }
    else {
      this.router.navigate(['user/process-ratesheets', this.encryptionService.encrypt(data.id)]);
    }
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'viewUploadFile':
        {
          this.viewUploadFile(evt.data.id);
          break;
        }
      case 'viewExportFile':
        {
          this.viewExportFile(evt.data.id);
          break;
        }
      case 'viewRatesheet':
        {
          this.viewProcessRatesheet(evt.data);
          break;
        }
    }
  }

  paginationEventHandler(evt: any) {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    var startItem = ((this.pageIndex - 1) * this.pageSize);
    var endItem = (this.pageSize * this.pageIndex);
    this.rateSheetList = this.rateSheetListOriginal.slice(startItem, endItem);
  }

  filterFormSubmit() {
    this.submitted = true;

    this.getSnapshotTransaction();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterRateProcessingForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.filterRateProcessingForm.reset();
    this.carrierIds = [];
    this.countryIds = [];
    this.filterRateProcessingForm.patchValue({
      FromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      ToDate: new Date(moment().format("M/D/YYYY").toString())
    });
    this.getSnapshotTransaction();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
    });
  }

  getCarriers() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null, false).subscribe(res => {
      this.carriers = res['data'];
    });
  }

}
