import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { RouteConstants } from "../../constants/route-constants";
import { Pagination, GridColumnType, GridActionType, GridColumnDataType, BookingStatusConstants } from "../../constants/constants";
import { Location } from "@angular/common";

@Component({
  selector: 'inbrit-freight-booking',
  templateUrl: './freight-booking.component.html',
  styleUrls: ['./freight-booking.component.scss']
})
export class FreightBookingComponent implements OnInit {
  freightId: string;
  pageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  bookingHistory: [any];
  dataCount: number;

  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Quotation', dataField: 'quotationNumber', type: GridColumnType.DATA, },
      { title: 'User', dataField: 'createdByName', type: GridColumnType.DATA, },
      { title: 'From Port', dataField: 'fromName', type: GridColumnType.DATA, },
      { title: 'To Port', dataField: 'toName', type: GridColumnType.DATA, },
      { title: 'Shipment Date', dataField: 'shipmentDate', type: GridColumnType.DATA },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA },
      { title: 'Status', dataField: 'statusText', type: GridColumnType.DATA, }, {
        title: 'Action', dataField: 'quotationId', type: GridColumnType.ACTION, actions: [
          { event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility' }
        ]
      }
    ]

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService,
    private encryptionService: EncryptionService, private router: Router, private location: Location ) { 
      this.activatedRoute.params.subscribe(params => { this.freightId = params['id'] }) }

  ngOnInit() {
    this.getBookingFreight();

  }

  getBookingFreight(): void {

    var param = {
      "TransactionId": this.encryptionService.decrypt(this.freightId),
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_getBookingFreightRate, param).subscribe(res => {

      if (res['success'] == true) {
        var data = res['data'];
        data.forEach(item => {
          var status = BookingStatusConstants.BOOKING_STATUSES.filter(m => { return m.id == item.status });
          item['statusText'] = status.length > 0 ? status[0].description : "";
        });

        this.bookingHistory = data;
        this.dataCount = res['count'];

      }
    });

  }


  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewBookingDetail(evt.data.bookingId);
          break;
        }
    }
  }

  viewBookingDetail(bookingId: number): void {
    this.router.navigate(['user/booking-details', this.encryptionService.encrypt(bookingId)]);
  }

  naviagteBack():void{
    this.location.back();
  }

}
