import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../services/loader/loader.service";
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { } from "rxjs";
import { DataTransferService } from "../../services/data-transfer/data-transfer.service";

@Component({
  selector: 'inbrit-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  submitted = false;
  error = { 'isError': false, 'message': '' };
  returnUrl: string;
  showPassword: boolean = false;
  countries: [any];

  constructor(private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private loaderService: LoaderService,
    private httpService: HttpService, private dataTransferService: DataTransferService) { }

  ngOnInit() {
    this.forgotPassForm = this.formBuilder.group({
      countryOfRegistration: ['', [Validators.required]],
      userName: ['', Validators.required],
    });
    this.getCountries();
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null).subscribe(res => {
      this.countries = res['data'];
    });
  }

  // convenience getter for easy access to form fields
  get formFields() { return this.forgotPassForm.controls; }

  public onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotPassForm.invalid) {
      return;
    }
    this.requestPasswordReset();
  }

  requestPasswordReset(): void {
    this.loaderService.display(true);
    var dataParam =
    {
      "CountryOfRegistration": this.formFields.countryOfRegistration.value,
      'CountryCode': this.countries.find(cnt => cnt.id == this.formFields.countryOfRegistration.value).countryCode,
      "Email": this.formFields.userName.value,
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_requestResetPassword, dataParam).subscribe(res => {

      var data = res['data'];
      if (data != null && res['success'] == true) {
        this.dataTransferService.setData(data);
        this.router.navigate(['/auth/reset-password']);
      }
      else {
        this.error.isError = true;
        this.error.message = res['message'];
      }
      this.loaderService.display(false);
    });
  }
}
