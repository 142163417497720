import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TierConstants, ProfileStatusConstants, KycStatusConstants, FreightConstants } from 'src/app/constants/constants';
import { EncryptionService } from '../encryption/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private subject = new Subject<any>();

  constructor(private encryptionService: EncryptionService) { }

  setUser(userObj: any) {
    localStorage.setItem('currentUser', this.encryptionService.encrypt(JSON.stringify(userObj)));
    this.subject.next({ text: userObj });
  }

  getLoggedInUser(): any {
    var user = localStorage.getItem('currentUser'); 
    if (user != undefined && user != 'undefined' && user != null && user != 'null') {
      var decryptUserObj = this.encryptionService.decrypt(user);
      return JSON.parse(decryptUserObj);
    }
    return null;
  }

  getUserChanges(): Observable<any> {
    return this.subject.asObservable();
  }

  getLocalizedFormattedHours(createdDate: any): string {
    var pendingSince = "";
    var requestedDate = new Date(createdDate).toString();
    var dateNow = new Date();
    var diffFromUtc = dateNow.getTimezoneOffset()
    var minutes = dateNow.getMinutes();
    dateNow.setMinutes(minutes + diffFromUtc);
    var diffInMs = Date.parse(dateNow.toString()) - Date.parse(requestedDate);
    const diffInHours = diffInMs / 1000 / 60 / 60;
    const days = parseInt((diffInHours / 24).toString());
    const hours = parseInt((diffInHours % 24).toString());
    if (days > 0) {
      pendingSince = days.toString() + " day" + (days > 1 ? "s" : "") + (hours > 0 ? (", " + hours.toString() + " hours") : "");
    }
    else {
      var mins = Math.floor((diffInMs / 1000 / 60) % 60);
      pendingSince = hours.toString() + " hours, " + mins.toString() + " mins";
    }
    return pendingSince;
  }

  getColorClass(status: number): string {
    var colorClass = '';
    switch (status) {
      case FreightConstants.EXPIRED:
      case TierConstants.APPROVAL_REJECTED:
      case TierConstants.BLOCKED:
      case TierConstants.EXPIRED:
      case ProfileStatusConstants.APPROVAL_REJECTED:
      case ProfileStatusConstants.CONFIRMATION_REJECTED:
      case KycStatusConstants.KYC_SUSPENDED:
      case KycStatusConstants.KYC_APPROVAL_REJECTED:
        colorClass = 'is-red';
        break;
      case TierConstants.APPROVED:
      case ProfileStatusConstants.CONFIRMED:
      case KycStatusConstants.KYC_CONFIRMED:
        colorClass = 'is-green';
        break;
      case TierConstants.PENDING_APPROVAL:
      case ProfileStatusConstants.PENDING_APPROVAL:
      case ProfileStatusConstants.PENDING_CONFIRMATION:
      case ProfileStatusConstants.PENDING_EMAIL_VERIFICATION:
      case KycStatusConstants.KYC_APPROVAL_PENDING:
      case KycStatusConstants.KYC_PENDING:
        colorClass = 'is-yellow';
        break;
      default:
        colorClass = 'is-gray';
        break;
    }
    return colorClass + ' p-2 font-bold';
  }
}
