import { Component, OnInit, OnDestroy } from '@angular/core';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { Router, ResolveEnd } from '@angular/router';
import { UserInfoService } from "../../services/userInfo/user-info.service";
import { Subscription } from 'rxjs';
import { UserManagementService } from 'src/app/services/user-firebase-subscription/user-firebase-subscription.service';
import { MenuItems, RouteInfo } from './menu-items';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: Array<RouteInfo>;
  public user: any;
  subscription: Subscription;
  routerSubscription: Subscription;

  constructor(private encryptionService: EncryptionService, private router: Router, private userInfoService: UserInfoService,
    private userManagementService: UserManagementService) {
    this.subscription = this.userInfoService.getUserChanges().subscribe(userObj => {
      if (userObj) {
        var user = this.userInfoService.getLoggedInUser();
        this.user = user;
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();
    this.user = user;
    var menuItemsObj = new MenuItems(this.encryptionService, this.userInfoService);
    this.menuItems = menuItemsObj.ROUTES.filter(menuItem => menuItem);
    this.subscribeToRouteChange();
    this.highlightMenu();
  }

  Logout() {
    this.userManagementService.logoutUser();
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  subscribeToRouteChange(): void {
    this.routerSubscription = this.router.events.filter(event => event instanceof ResolveEnd).subscribe(e => {
      this.highlightMenu();
    });
  }

  highlightMenu(): void {
    var url = this.router.url;
    var isMenuFound = false;
    this.menuItems.forEach(parentMenu => {
      parentMenu.children.forEach(subMenu => {
        if(subMenu.path == url) {
          parentMenu.showChildren = true;
          isMenuFound = true;
        }
      });
    });

    // check if detailed page loaded eg. user/customer-list/{someId} and url not found in menu/submenu list
    var urlParts = url.split("/");
    if(urlParts.length > 3 && !isMenuFound) {
      urlParts.splice(urlParts.length - 1, 1);
      url = "/" + urlParts[1] + "/" + urlParts[2];
      this.menuItems.forEach(parentMenu => {
        parentMenu.children.forEach(subMenu => {
          if(subMenu.path == url) {
            parentMenu.showChildren = true;
            isMenuFound = true;
          }
        });
      });

    }
  }
}
