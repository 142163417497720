import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { UserInfoService } from '../userInfo/user-info.service';
import { FSCollections } from 'src/app/constants/constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  afsSubscription: any;
  private ignoreSelf: boolean = false;
  constructor(private afs: AngularFirestore, private userInfoService: UserInfoService, private router: Router) {
  }

  subscribeToFirebase(): void {
    var user = this.userInfoService.getLoggedInUser();
    if (this.afsSubscription != undefined) {
      this.afsSubscription.unsubscribe();
    }
    if (user != null) {
      // Set firebase value to null for Fail safe (Previous logout unhandled.)
      this.afs.doc(`${FSCollections.USERS}/${user.id.toString()}`).update({
        "logoutComment": null
      });

      this.afsSubscription = this.afs.collection<any>(FSCollections.USERS).doc(user.id.toString()).valueChanges().subscribe(data => {
        if (data['logoutComment'] != null && data['logoutComment'] == 'logout') {
          if (!this.ignoreSelf) {
            this.logoutUser();
          }
          else {
            this.ignoreSelf = false;
          }
        }
      });
    }
  }

  private unsubscribeToFirebase(): void {
    if (this.afsSubscription != undefined) {
      this.afsSubscription.unsubscribe();
    }
  }

  logoutFromAllDevices(userId: string, isLogoutThisDevice: boolean): void {
    this.ignoreSelf = !isLogoutThisDevice;
    this.afs.doc(`${FSCollections.USERS}/${userId}`).update({
      "logoutComment": 'logout'
    });
  }

  logoutUser(): void {
    var user = this.userInfoService.getLoggedInUser();
    localStorage.removeItem('currentUser');
    if (user != null) {
      this.afs.doc(`${FSCollections.USERS}/${user.id.toString()}`).update({
        "logoutComment": null
      });
    }

    this.unsubscribeToFirebase();
    this.router.navigate(['/auth/login']);
  }
}
