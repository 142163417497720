import { Injectable } from '@angular/core';
import { NotifyComponent } from "../../shared/notify/notify.component";
import { MatSnackBar } from '@angular/material';
import { DataTransferService } from "../../services/data-transfer/data-transfer.service";
// declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private snackbar: MatSnackBar, private dataTransferService: DataTransferService) { }

  toast(from, align, type, message) {
    this.dataTransferService.setData(message);
    this.snackbar.openFromComponent(NotifyComponent, {
      duration: 5000, verticalPosition: from,
      horizontalPosition: align, // Possible values could be 'start' | 'center' | 'end' | 'left' | 'right';
      panelClass: type == 'success' ? ['alert', 'alert-success'] : ['alert', 'alert-danger'],
      politeness: 'polite',
    });
  }
}
