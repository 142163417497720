import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'inbrit-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss']
})
export class CkEditorComponent implements OnInit {
  // public htmlContent: string = 'We have applied a new rate(s) to your contract, please <a href="{{ViewLink}}">click here</a> to view and book on this rate(s).<br> These rate(s) are subject to validity and equipment availability and vessel space at time of booking.';
  htmlContent: string = '<b>We have applied a new rate(s) to your contract</b>.<br> These rate(s) are subject to validity and equipment availability and vessel space at time of booking.';

  constructor(public dialogRef: MatDialogRef<CkEditorComponent>) {
    dialogRef.disableClose = true;
   }

  ngOnInit() {
  }

  saveContent() {
    this.dialogRef.close(this.htmlContent);
  }

  closePopup(): void {
    this.dialogRef.close(null);
  }
}
