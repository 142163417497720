import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataTransferService } from "../../services/data-transfer/data-transfer.service";

@Component({
  selector: 'inbrit-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NotifyComponent implements OnInit {
  message: String = "";

  constructor(private dataTransferService: DataTransferService) { }

  ngOnInit() {
    this.message = this.dataTransferService.getData();
  }

}
