import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'inbrit-blank-calendar-header',
  templateUrl: './blank-calendar-header.component.html',
  styleUrls: ['./blank-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlankCalendarHeaderComponent<D> implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();
  constructor() { }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

}
