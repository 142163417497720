import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[inbritNumbersOnly]'
})
export class NumbersOnlyDirective implements AfterViewInit {

  constructor(private _el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit() {
    // Mark the input type as number
    this.renderer.setProperty(this._el.nativeElement, 'type', 'number');
  }

  // @HostListener('input', ['$event']) onInputChange(event) {
  //   const initalValue = this._el.nativeElement.value;
  //   this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
  //   if (initalValue !== this._el.nativeElement.value) {
  //     event.stopPropagation();
  //   }
  // }
}
