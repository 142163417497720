import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { HttpService } from "../../services/http/http.service";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { LoaderService } from '../../services/loader/loader.service';
import { RouteConstants } from "../../constants/route-constants";
import {
  KycAddressTypeConstants, KycStatusConstants,
  ProfileStatusConstants, UserRoleConstants,
} from "../../constants/constants";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent } from "../../shared/confirm-box/confirm-box.component";
import { MatOption } from '@angular/material';
import { Location } from "@angular/common";
import { debug } from 'util';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'inbrit-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.scss']
})
export class KycDetailsComponent implements OnInit {
  kycDetailsForm: FormGroup;
  approvalForm: FormGroup;
  tradeReferencesForm: FormGroup;
  questionnaireForm: NgForm;
  tradeReferences: Array<{
    id?: number, contactName: string, contactNumber: string,
    contactEmail: string, address: string
  }> = [
      { contactName: '', contactNumber: '', contactEmail: '', address: '' }
    ];
  questionnaireQuestions: Array<{ id: number, question: string, isActive: boolean, response: number }>;
  questionnaireResponses: Array<{ id: number, response: string, isActive: boolean }>;
  countries: [any];
  currencies: [any];
  regAddStates: [any];
  tradAddStates: [any];
  userId: number = 0;
  encryptedUserId: string = "";
  submitted: boolean;
  isLoading: boolean = false;
  regAddCountryId: number;
  regAddStateId: number;
  tradAddCountryId: number;
  preferredCurrency: number;
  kycDetailsId: number;
  regAddId: number;
  tradAddId: number;
  @ViewChild('formSubmitButton') formSubmitButton;
  @ViewChild('tradeRefFormSubmitButton') tradeRefFormSubmitButton;
  @ViewChild('tradeRefForm') tradeRefForm;
  KycStatusConstants = KycStatusConstants;
  ProfileStatusConstants = ProfileStatusConstants;
  UserRoleConstants = UserRoleConstants;
  kycStatusId: number;
  kycStatus: string = '';
  @Input() isKycForApproval: boolean = false;
  @ViewChild('approvalFormSubmitButton') approvalFormSubmitButton;
  @ViewChild('questionnaireFormSubmitButton') questionnaireFormSubmitButton;
  tierMasters = [];
  salesPersons = [];
  carrierMasters: [any];
  datePipe = new DatePipe('en-US');
  showNotifyCustomer: boolean = false;
  user: any;
  kycApprovalStatusId: number;
  today: Date = new Date();
  @ViewChild('selectAllCarrierOption') private selectAllCarrierOption: MatOption;
  routeUrl: string = '';
  appearance: string = 'outline';
  isTierLoading: boolean = false;
  isSalesPersonLoading: boolean = false;
  typeaheadDebounce: number = 500;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private encryptionService: EncryptionService, private activatedRoute: ActivatedRoute,
    private dialog: MatDialog, private loaderService: LoaderService,
    private toastMessageService: ToastMessageService, private router: Router,
    private userInfoService: UserInfoService, private location: Location) {
    this.activatedRoute.params.subscribe(params => { this.encryptedUserId = params['id'] });
  }

  ngOnInit() {
    this.user = this.userInfoService.getLoggedInUser();
    if (this.encryptedUserId != "" && this.encryptedUserId != undefined) {
      this.userId = this.encryptionService.decrypt(this.encryptedUserId);
      this.showNotifyCustomer = this.user.roleId == UserRoleConstants.USER && this.user.id != this.userId;
    }
    else {
      if (this.user != null && this.user != undefined) {
        this.userId = this.user.id;
      }
    }

    this.initializeForm();
    this.initializeTradeRefForm();
    this.getKycMasters();

    // if (this.isKycForApproval || this.kycStatusId == KycStatusConstants.KYC_CONFIRMED) {
    //   // this.getAllInbritUsers();
    //   // this.getAllTierMasters();
    //   this.getAllCarrierMaster();
    // }

    if (this.router.url.includes('pending-kyc')) {
      this.routeUrl = "/user/pending-kyc";
    }
    else if (this.router.url.includes('customer-list/kyc-details')) {
      this.routeUrl = "/user/customer-list";
    }
  }

  initializeSalesPersonFilters() {
    this.approvalForm.controls['salesPersonId'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        this.isTierLoading = true;
        var param = {
          'RoleIds': [UserRoleConstants.USER]
        }
        this.httpService.httpPost(RouteConstants.ACCOUNT_getUsers + "?searchText=" + val, param, false).subscribe(res => {
          this.salesPersons = res['data'];
          this.isTierLoading = false;
        });
      }
    });
  }

  initializeTierFilters() {
    this.approvalForm.controls['tierId'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        this.isSalesPersonLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getApprovedTierMaster, { 'searchText': val }, false).subscribe(res => {
          this.tierMasters = res['data'];
          this.isSalesPersonLoading = false;
        });
      }
    });
  }

  initializeForm() {
    this.kycDetailsForm = this.formBuilder.group({
      countryOfRegistration: [{ value: '', disabled: this.isKycForApproval }, []],
      firstName: [{ value: '', disabled: this.isKycForApproval }, []],
      lastName: [{ value: '', disabled: this.isKycForApproval }, []],
      email: [{ value: '', disabled: this.isKycForApproval }, []],
      mobileCode: [{ value: '', disabled: this.isKycForApproval }, []],
      mobileNumber: [{ value: '', disabled: this.isKycForApproval }, []],
      kycStatusId: [{ value: '', disabled: true }, []],

      companyName: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      vatRegistrationNumber: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      companyNumber: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      regAddAddress: [{ value: '', disabled: this.isKycForApproval }, []],
      regAddCountry: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      regAddState: [{ value: '', disabled: this.isKycForApproval }, []],
      regAddCity: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      regAddPinCode: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      isTradAddSameAsRegAdd: [{ value: true, disabled: this.isKycForApproval }, []],
      tradAddAddress: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      tradAddCountry: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      tradAddState: [{ value: '', disabled: this.isKycForApproval }, []],
      tradAddCity: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      tradAddPinCode: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      dateOfIncorporation: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      contactPersonFinance: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      contactPersonCommercial: [{ value: '', disabled: this.isKycForApproval }, []],
      preferredContact: [{ value: '', disabled: this.isKycForApproval }, []],
      emailAddress: [{ value: '', disabled: this.isKycForApproval }, [Validators.required, Validators.email]],
      telephoneNumber: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      paymentTerms: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      creditLimit: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      preferredCurrency: [{ value: '', disabled: this.isKycForApproval }, [Validators.required]],
      notifyCustomer: [{ value: false, disabled: this.isKycForApproval }, []],
    });

    this.approvalForm = this.formBuilder.group({
      tierId: ['', []],
      salesPersonId: ['', []],
      carrierIds: ['', []],
      rejectionComments: ['', []],
    })
  }

  initializeTradeRefForm() {
    this.tradeReferencesForm = this.formBuilder.group({
      tradeRefArray: this.formBuilder.array([])
    });

    let tradeRefArray = this.tradeReferencesForm.get('tradeRefArray') as FormArray;
    for (let i = 0; i <= this.tradeReferences.length - 1; i++) {
      tradeRefArray.push(this.formBuilder.group({
        id: [this.tradeReferences[i].id, []],
        contactName: [{ value: this.tradeReferences[i].contactName, disabled: this.isKycForApproval }, [Validators.required]],
        contactNumber: [{ value: this.tradeReferences[i].contactNumber, disabled: this.isKycForApproval }, []],
        contactEmail: [{ value: this.tradeReferences[i].contactEmail, disabled: this.isKycForApproval }, [Validators.required, Validators.email]],
        address: [{ value: this.tradeReferences[i].address, disabled: this.isKycForApproval }, [Validators.required]]
      }))
    }
  }

  // convenience getter for easy access to form fields
  get kycForm() { return this.kycDetailsForm.controls; }

  get kycApprovalForm() { return this.approvalForm.controls; }

  getKycDetails(): void {
    this.loaderService.display(true);
    var _thisRef = this;
    this.httpService.httpPost(RouteConstants.CustomerKyc_getKycDetails, { 'UserId': this.userId }).subscribe(res => {
      // If customer has previously saved the data in Kyc Details, override the value 
      if (res['success'] == true && res['data'] != null) {
        var userDetails = res['data']['user'];
        var companyDetail = res['data'];
        var registeredAddress = res['data']['registeredAddress'];
        var tradingAddress = res['data']['tradingAddress'];
        var tradingReferenceList = res['data']['tradingReferences'];
        var questionnaireList = res['data']['kycQuestionnaires'];
        this.kycDetailsId = companyDetail.id;
        this.regAddId = registeredAddress.id;
        this.tradAddId = tradingAddress.id;



        if (this.user.roleId == UserRoleConstants.USER) {
          if (userDetails.kycStatusId == KycStatusConstants.KYC_APPROVAL_PENDING) {
            this.isKycForApproval = true;
            Object.keys(this.kycDetailsForm.controls).forEach(key => {
              this.kycDetailsForm.get(key).disable();
            });
            this.appearance = 'fill';
          }
          this.getAllCarrierMaster();
          this.initializeSalesPersonFilters();
          this.initializeTierFilters();
        } else {
          if (userDetails.kycStatusId != KycStatusConstants.KYC_PENDING) {
            this.isKycForApproval = true;
            Object.keys(this.kycDetailsForm.controls).forEach(key => {
              this.kycDetailsForm.get(key).disable();
            });
            this.appearance = 'fill';
          }
        }


        // Check if kyc already approved/rejected
        // if (userDetails.kycStatusId == KycStatusConstants.KYC_CONFIRMED ||
        //   userDetails.kycStatusId == KycStatusConstants.KYC_APPROVAL_REJECTED) {
        //   this.isKycForApproval = false;
        // }

        if (userDetails.tierId != null && userDetails.tierId > 0) {
          this.tierMasters.push({ 'tierId': userDetails.tierId, 'tierCode': userDetails.tierName });
        }
        if (userDetails.salesPersonId != null && userDetails.salesPersonId > 0) {
          this.salesPersons.push({ 'id': userDetails.salesPersonId, 'firstName': userDetails.salesPersonName, 'lastName': '' });
        }

        if (userDetails.userCarriers != null && userDetails.userCarriers.length > 0) {
          this.approvalForm.controls.carrierIds
            .patchValue(userDetails.userCarriers.map(item => item.carrierId));
        }

        this.approvalForm.patchValue({
          tierId: userDetails.tierId,
          salesPersonId: userDetails.salesPersonId,
          rejectionComments: userDetails.kycStatusReason
        });

        if (this.user.roleId == UserRoleConstants.USER && this.isKycForApproval) {
          // Populate user details
          this.kycDetailsForm.patchValue({
            // userName: userDetails.userName,
            countryOfRegistration: userDetails.countryOfRegistration,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            mobileCode: userDetails.mobileCode,
            mobileNumber: userDetails.mobileNumber
          });


        }

        // Populate Company Details
        this.kycDetailsForm.patchValue({
          companyName: companyDetail.companyName,
          companyNumber: companyDetail.companyNumber,
          vatRegistrationNumber: companyDetail.vatRegistrationNumber,
          dateOfIncorporation: companyDetail.companyDateOfIncorporation,
          contactPersonFinance: companyDetail.contactPersonFinance,
          contactPersonCommercial: companyDetail.contactPersonCommercial,
          preferredContact: companyDetail.preferredContact,
          emailAddress: companyDetail.emailAddress,
          telephoneNumber: companyDetail.telephoneNumber,
          paymentTerms: companyDetail.paymentTerms,
          creditLimit: companyDetail.creditLimit,
          preferredCurrency: companyDetail.preferredCurrency,
          kycStatusId: companyDetail.kycStatusId
        });

        // Populate Registered Address
        this.kycDetailsForm.patchValue({
          regAddAddress: registeredAddress.address,
          regAddCountry: registeredAddress.countryId,
          regAddState: registeredAddress.stateId,
          regAddCity: registeredAddress.city,
          regAddPinCode: registeredAddress.pinCode,
        });
        this.getStates(registeredAddress.countryId, 'reg');

        // Populate Trading Address
        this.kycDetailsForm.patchValue({
          tradAddAddress: tradingAddress.address,
          tradAddCountry: tradingAddress.countryId,
          tradAddState: tradingAddress.stateId,
          tradAddCity: tradingAddress.city,
          tradAddPinCode: tradingAddress.pinCode,
        });
        this.getStates(tradingAddress.countryId, 'trad');

        // Populate Trading References
        _thisRef.tradeReferences = [];
        tradingReferenceList.forEach(function (trad) {
          _thisRef.tradeReferences.push({
            id: trad.id,
            contactName: trad.contactName, contactNumber: trad.contactNumber,
            contactEmail: trad.contactEmail, address: trad.address
          });
        });
        if (tradingReferenceList.length == 0) {
          this.addNewTradReference();
        }
        this.initializeTradeRefForm();

        // Populate Questionnaires
        questionnaireList.forEach(function (ques) {
          var question = _thisRef.questionnaireQuestions.filter(function (item) { return item.id == ques.questionId })
          if (question.length > 0) {
            question[0].response = ques.responseId;
          }
        });


        // if (this.user.roleId == UserRoleConstants.USER &&
        //   userDetails.kycStatusId == KycStatusConstants.KYC_CONFIRMED) {
        //   this.getAllCarrierMaster();

        //   // this.approvalForm.controls.carrierIds
        //   //   .patchValue([userDetails.userCarrierList.map(item => item.id), 0]);
        // }

        this.kycStatusId = userDetails.kycStatusId;
        this.kycStatus = KycStatusConstants.KYC_STATUSES.filter(m => { return m.id == userDetails.kycStatusId })[0].description;


      }





      this.loaderService.display(false);
    });
  }

  getKycMasters() {
    this.httpService.httpGet(RouteConstants.MASTER_getKycMasters, null).subscribe(res => {
      this.questionnaireQuestions = res['data'].questionMasters;
      this.questionnaireResponses = res['data'].responseMasters;
      this.countries = res['data'].countryMasters;
      this.currencies = res['data'].currencyMasters;
      this.getKycDetails();
    });
  }

  getStates(countryId: number, addressType: string) {
    this.httpService.httpGet(RouteConstants.MASTER_getAllStates, { 'countryId': countryId }, false).subscribe(res => {
      if (addressType == '') {
        this.regAddStates = this.tradAddStates = res['data'];
      }
      else if (addressType == 'reg') {
        this.regAddStates = res['data'];
      }
      else if (addressType == 'trad') {
        this.tradAddStates = res['data'];
      }
      if (!res['data'].length) {
        this.setDefaultState(addressType);
      }
    });
  }

  setDefaultState(addressType: string) {
    var defaultState = { 'id': 0, 'stateName': 'No states found' };
    if (addressType == '') {
      this.regAddStates.push(defaultState);
      this.kycDetailsForm.patchValue({
        regAddState: 0,
      });

    }
    else if (addressType == 'reg') {
      this.regAddStates.push(defaultState);
      this.kycDetailsForm.patchValue({
        regAddState: 0,
      });

    }
    else if (addressType == 'trad') {
      this.tradAddStates.push(defaultState);
      this.kycDetailsForm.patchValue({
        tradAddState: 0,
      });
    }
  }

  addNewTradReference(): void {
    if (this.tradeReferences.length < 3) {
      var tradeRef = this.tradeReferencesForm.get('tradeRefArray')['controls'];

      for (var i = 0; i < this.tradeReferences.length; i++) {
        this.tradeReferences[i].contactName = tradeRef[i]['controls']['contactName'].value;
        this.tradeReferences[i].contactNumber = tradeRef[i]['controls']['contactNumber'].value;
        this.tradeReferences[i].contactEmail = tradeRef[i]['controls']['contactEmail'].value;
        this.tradeReferences[i].address = tradeRef[i]['controls']['address'].value;
      }

      this.tradeReferences.push({ contactName: '', contactNumber: '', contactEmail: '', address: '' });
      this.initializeTradeRefForm();
    }
  }

  removeTradReference(rowIndex: number): void {
    this.tradeReferences.splice(rowIndex, 1);
    this.initializeTradeRefForm();
  }

  countryChanged(countryId: number, addressType: string) {
    this.kycDetailsForm.patchValue({
      regAddState: null,
      tradAddState: null
    });
    this.getStates(countryId, addressType);
  }

  onFormSubmit(): void {
    this.submitted = true;
  }

  saveKycDetailsAndApprovals() {

    var kycDetailsParam = this.getKycDetailSaveParam();
    var kycApprovalsParam = this.getKycDetailApprovalParam();
    var param = {
      "Id": this.userId,
      "KycStatusId": KycStatusConstants.KYC_CONFIRMED,
      "UserKycDetail": kycDetailsParam,

      "KycStatusReason": kycApprovalsParam.KycStatusReason,
      "TierId": kycApprovalsParam.TierId,
      "SalesPersonId": kycApprovalsParam.SalesPersonId,
      "UserCarriers": kycApprovalsParam.UserCarriers
    };
    this.isLoading = true;
    this.httpService.httpPost(RouteConstants.ACCOUNT_updateUserKycStatus, param).subscribe(res => {
      this.isLoading = false;
    });
  }

  saveKycDetails(): void {
    this.formSubmitButton.nativeElement.click();
    this.tradeRefFormSubmitButton.nativeElement.click();
    this.questionnaireFormSubmitButton.nativeElement.click();

    if (!this.kycDetailsForm.valid || !this.tradeReferencesForm.valid || !this.questionnaireForm.valid) {

      if (this.kycDetailsForm.valid && !this.tradeReferencesForm.valid) {
        var invalidElements = this.tradeRefForm.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
          invalidElements[0].scrollIntoView();
        }
      }
      return;
    }

    var _thisRef = this;
    var param = this.getKycDetailSaveParam();

    this.isLoading = true;
    this.httpService.httpPost(RouteConstants.CustomerKyc_saveKycDetails, param).subscribe(res => {
      if (res['success'] == true) {
        _thisRef.kycDetailsId = res['data']['id'];
      }
      this.isLoading = false;
    });

  }

  getKycDetailSaveParam() {
    var _thisRef = this;
    var tradingReferences = [];
    let tradeRef = this.tradeReferencesForm.get('tradeRefArray')['controls'];

    tradeRef.forEach(function (formGroup) {
      tradingReferences.push({
        'Id': formGroup['controls']['id'].value == null ? 0 : formGroup['controls']['id'].value,
        'KycDetailsId': _thisRef.kycDetailsId,
        'ContactName': formGroup['controls']['contactName'].value,
        'ContactNumber': formGroup['controls']['contactNumber'].value,
        'ContactEmail': formGroup['controls']['contactEmail'].value,
        'Address': formGroup['controls']['address'].value
      });
    });

    var questionnaires = [];
    this.questionnaireQuestions.forEach(function (ques) {

      if (ques.response != undefined) {

        questionnaires.push({
          'Id': ques.id,
          'KycDetailsId': _thisRef.kycDetailsId,
          'QuestionId': ques.id,
          'ResponseId': ques.response
        });
      }
    });

    var param = {
      'Id': this.kycDetailsId,
      'UserId': this.userId,
      'CompanyName': this.kycForm.companyName.value,
      'CompanyNumber': this.kycForm.companyNumber.value,
      'VatRegistrationNumber': this.kycForm.vatRegistrationNumber.value,
      'CompanyDateOfIncorporation': this.datePipe.transform(this.kycForm.dateOfIncorporation.value, 'yyyy-MM-dd'),
      'ContactPersonFinance': this.kycForm.contactPersonFinance.value,
      'ContactPersonCommercial': this.kycForm.contactPersonCommercial.value,
      'PreferredContact': this.kycForm.preferredContact.value,
      'EmailAddress': this.kycForm.emailAddress.value,
      'TelephoneNumber': this.kycForm.telephoneNumber.value,
      'PaymentTerms': this.kycForm.paymentTerms.value,
      'CreditLimit': this.kycForm.creditLimit.value,
      'PreferredCurrency': this.kycForm.preferredCurrency.value,
      'RegisteredAddress': {
        'Id': this.regAddId,
        'Address': this.kycForm.regAddAddress.value,
        'CountryId': this.kycForm.regAddCountry.value,
        'StateId': this.kycForm.regAddState.value,
        'City': this.kycForm.regAddCity.value,
        'PinCode': this.kycForm.regAddPinCode.value,
        'AddressType': KycAddressTypeConstants.REGISTERED
      },
      'TradingAddress': {
        'Id': this.tradAddId,
        'Address': this.kycForm.tradAddAddress.value,
        'CountryId': this.kycForm.tradAddCountry.value,
        'StateId': this.kycForm.tradAddState.value,
        'City': this.kycForm.tradAddCity.value,
        'PinCode': this.kycForm.tradAddPinCode.value,
        'AddressType': KycAddressTypeConstants.TRADING
      },
      'TradingReferences': tradingReferences,
      'KycQuestionnaires': questionnaires,
      'NotifyCustomer': this.kycForm.notifyCustomer.value
    };
    return param;
  }

  getKycDetailApprovalParam() {
    this.submitted = true;
    this.isLoading = true;
    // Ensure that Sales Person and Tier are selected from list
    if (this.salesPersons.filter(m => m.id == this.approvalForm.controls['salesPersonId'].value).length == 0) {
      this.approvalForm.patchValue({
        salesPersonId: null
      })
    }

    if (this.tierMasters.filter(m => m.tierId == this.approvalForm.controls['tierId'].value).length == 0) {
      this.approvalForm.patchValue({
        tierId: null
      })
    }

    if (!this.approvalForm.valid) {
      this.isLoading = false;
      return null;
    }

    var userCarrierList = [];
    var selectedCarriers = this.kycApprovalForm.carrierIds.value;

    if (selectedCarriers != undefined && typeof selectedCarriers != "string") {
      selectedCarriers.forEach(carrier => {
        // Check for Select all option's value. 
        if (carrier != 0) {
          userCarrierList.push({
            "UserId": this.userId,
            "CarrierId": carrier
          });
        }
      });
    }

    var param = {
      "Id": this.userId,
      "KycStatusId": this.kycApprovalStatusId,
      "KycStatusReason": this.kycApprovalForm.rejectionComments.value,
      "TierId": this.kycApprovalForm.tierId.value,
      "SalesPersonId": this.kycApprovalForm.salesPersonId.value,
      "UserCarriers": userCarrierList
    };
    return param;
  }

  sendForApproval(): void {
    this.formSubmitButton.nativeElement.click();
    this.tradeRefFormSubmitButton.nativeElement.click();
    this.questionnaireFormSubmitButton.nativeElement.click();

    if (!this.kycDetailsForm.valid || !this.tradeReferencesForm.valid || !this.questionnaireForm.valid) {

      if (this.kycDetailsForm.valid && !this.tradeReferencesForm.valid) {
        var invalidElements = this.tradeRefForm.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
          invalidElements[0].scrollIntoView();
        }
      }
      return;
    }

    var isValidPage = true;
    if (!this.kycDetailsForm.valid) {
      isValidPage = false;
    }

    var result = this.questionnaireQuestions.find(ques => ques.response == undefined);
    if (result != undefined) {
      this.toastMessageService.toast('bottom', 'center', 'danger', 'All Questionnaires are mandatory!');
      isValidPage = false;
    }

    if (!isValidPage) { return; }

    const dialogRef = this.dialog.open(ConfirmBoxComponent, {
      width: '450px',
      data: { message: "Form will not be editable once sent for approval, Do you want to proceed?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.isLoading = true;
        var kycDetailsParam = this.getKycDetailSaveParam();

        var param = {
          "Id": this.userId,
          "KycStatusId": KycStatusConstants.KYC_APPROVAL_PENDING,
          "UserKycDetail": kycDetailsParam
        };

        this.httpService.httpPost(RouteConstants.ACCOUNT_updateUserKycStatus, param).subscribe(res => {
          if (res['success'] == true) {
            this.kycStatusId = KycStatusConstants.KYC_APPROVAL_PENDING;
            this.markFormDisabled();
          }
          this.isLoading = false;
          // If Send for Approval is done by BO user, redirect the user to customer list.
          if (this.user.roleId == UserRoleConstants.USER) {
            this.location.back();
          }
        });
      }
    });
  }

  markFormDisabled(): void {

    this.isKycForApproval = true;
    this.user.kycStatusId = this.kycStatusId;
    this.kycStatus = KycStatusConstants.KYC_STATUSES.filter(m => { return m.id == this.user.kycStatusId })[0].description;
    this.userInfoService.setUser(this.user);
    Object.keys(this.kycDetailsForm.controls).forEach(key => {
      this.kycDetailsForm.get(key).disable();
    });
    var tradeRefArray = this.tradeReferencesForm.get("tradeRefArray") as FormArray
    tradeRefArray.controls.forEach(form => {
      var formGroup = form as FormGroup;
      Object.keys(formGroup.controls).forEach(key => {
        form.get(key).disable();
      });
    });

  }

  getAllCarrierMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null).subscribe(res => {
      this.carrierMasters = res['data'];
      if (this.kycApprovalForm.carrierIds.value == undefined) {
        this.approvalForm.controls.carrierIds
          .patchValue([...this.carrierMasters.map(item => item.id), 0]);
      }
    });
  }

  approveRejectKyc(statusId: number): void {
    if (statusId == KycStatusConstants.KYC_APPROVAL_REJECTED) {
      this.approvalForm.get('tierId').setValidators([]);
      this.approvalForm.get('salesPersonId').setValidators([]);
      this.approvalForm.get('carrierIds').setValidators([]);
      this.approvalForm.get('rejectionComments').setValidators([Validators.required]);
    }
    else {
      this.approvalForm.get('tierId').setValidators([Validators.required]);
      this.approvalForm.get('salesPersonId').setValidators([Validators.required]);
      this.approvalForm.get('carrierIds').setValidators([Validators.required]);
      this.approvalForm.get('rejectionComments').setValidators([]);
    }
    this.approvalForm.get('tierId').updateValueAndValidity();
    this.approvalForm.get('salesPersonId').updateValueAndValidity();
    this.approvalForm.get('carrierIds').updateValueAndValidity();
    this.approvalForm.get('rejectionComments').updateValueAndValidity();


    this.kycApprovalStatusId = statusId;
    this.approvalFormSubmitButton.nativeElement.click();
  }

  getCarrierLabel(carrierId: number): string {
    if (this.carrierMasters != undefined) {
      var carrier = this.carrierMasters.filter(m => { return m.id == carrierId });
      if (carrier.length > 0) {
        return carrier[0].carrierCode;
      }
    }
    return "";
  }

  toggleSelectionPerOpt() {
    if (this.selectAllCarrierOption.selected) {
      this.selectAllCarrierOption.deselect();
      return false;
    }
  }

  toggleAllSelection() {
    if (this.selectAllCarrierOption.selected) {
      this.approvalForm.controls.carrierIds
        .patchValue([...this.carrierMasters.map(item => item.id), 0]);
    } else {
      this.approvalForm.controls.carrierIds.patchValue([]);
    }
  }

  onApprovalFormSubmit() {
    var param = this.getKycDetailApprovalParam();
    if (param == null) {
      return;
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_updateUserKycStatus, param).subscribe(res => {
      if (res['success'] == true) {
        this.isKycForApproval = false;
        this.kycStatusId = this.kycApprovalStatusId;
      }
      this.isLoading = false;
    });
  }

  onQuestionnaireFormSubmit(qForm: NgForm) {
    this.questionnaireForm = qForm;
  }

  tierTypeaheadDisplay(tierId: number) {
    if (tierId != null && tierId != undefined && tierId != 0) {
      return this.tierMasters.filter(m => { return m.tierId == tierId })[0].tierCode;
    }
  }

  salesPersonTypeaheadDisplay(salesPersonId: number) {
    if (salesPersonId != null && salesPersonId != undefined && salesPersonId != 0) {
      var spoc = this.salesPersons.filter(m => { return m.id == salesPersonId })[0];
      return spoc.firstName + ' ' + spoc.lastName;
    }
  }

  naviagteBack(): void {
    this.location.back();
  }
}
