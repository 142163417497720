import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';

@Component({
  selector: 'inbrit-ftp-handlers',
  templateUrl: './ftp-handlers.component.html',
  styleUrls: ['./ftp-handlers.component.scss']
})
export class FtpHandlersComponent implements OnInit {

  fileToUpload: File = null;
  isLoading: boolean = false;

  constructor(private httpService: HttpService) { }

  ngOnInit() {
  }

  selectFile(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile(): void {
    if (this.fileToUpload == null) {
      return;
    }
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append('FileWrapper.File', this.fileToUpload, this.fileToUpload.name);

    this.httpService.httpPostFormData(RouteConstants.BOOKING_uploadFileToFtpServer, formData).subscribe(res => {
      this.isLoading = false;
    });
  }

  downloadFile(): void {
    this.isLoading = true;
    this.httpService.httpGet(RouteConstants.BOOKING_downloadFile, null).subscribe(res => {
      this.isLoading = false;
    });
  }

}
