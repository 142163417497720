import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';
import { debounceTime } from 'rxjs/operators';
import { CustomRateLocationComponent } from '../custom-rate-location/custom-rate-location.component';

@Component({
  selector: 'inbrit-add-new-sell-rate',
  templateUrl: './add-new-sell-rate.component.html',
  styleUrls: ['./add-new-sell-rate.component.scss']
})

export class AddNewSellRateComponent implements OnInit {
  spotRateSellCreateForm: FormGroup;
  commodityList: Array<any> = [];
  containerTypes: Array<any> = [];
  typeaheadDebounce: number = 500;
  submitted: boolean = false;
  appearance: string = 'outline';
  rows: Array<{ formId: string }> = [{ formId: 'form-1' }];
  @ViewChildren(CustomRateLocationComponent) locationComponents!: QueryList<CustomRateLocationComponent>;
  isCommodityDisabled: boolean = false;
  isFreightRate: boolean = false;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, public dialogRef: MatDialogRef<AddNewSellRateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isFreightRate = this.data != null && this.data != undefined ? this.data.isFreightRate : false;
  }

  ngOnInit() {
    this.initializeForm();
    this.getCommodityList();
    this.getContainerTypes();
  }

  initializeForm() {
    this.isCommodityDisabled = this.data.commodity != '';
    this.spotRateSellCreateForm = this.formBuilder.group({
      commodity: [{ value: this.data.commodity, disabled: this.data.commodity != '' }, [Validators.required]],
      size: ['', [Validators.required]],
      freeTime: ['', [Validators.required]],
      telexCharges: ['', [Validators.required]],
      amendmentFees: ['', [Validators.required]],
      docFees: ['', [Validators.required]],
      type: ['', [Validators.required]],
      allInPrice: ['', Validators.required],
      margin: ['', []],
      sellRate: ['',[]],
      contractNo: [{ value: this.data.contractNo, disabled: this.data.contractNo != '' }, Validators.required]
    });

    if(this.isFreightRate){
      this.spotRateSellCreateForm.controls['margin'].setValidators([Validators.required]);
    }
  }

  // convenience getter for easy access to form fields
  get sellCreateForm() { return this.spotRateSellCreateForm.controls; }

  onCreateSellRateFormSubmit(): void {
    this.locationComponents.forEach((item, index) => {
      item.btnSubmit.nativeElement.click();
      item.submitted = true;
    });

    var isAllLocationFormsValid = true;
    this.locationComponents.forEach((item, index) => {
      if (item.spotRateLocationForm.invalid) {
        isAllLocationFormsValid = false;
      }
    });

    this.submitted = true;
    if (!this.spotRateSellCreateForm.valid || !isAllLocationFormsValid) {
      return;
    }

    var locations = [];
    this.locationComponents.forEach((item, index) => {
      var locationForm = item.spotRateLocationForm.controls;
      locations.push({
        loadPoint: locationForm.loadPoint.value,
        loadpointName: locationForm.loadpointName.value,
        fromPort: locationForm.fromPort.value,
        fromPortName: locationForm.fromPortName.value,
        toPort: locationForm.toPort.value,
        toPortName: locationForm.toPortName.value,
        dischargePort: locationForm.dischargePort.value,
        dischargeName: locationForm.dischargeName.value == '' ? null : locationForm.dischargeName.value
      });
    });

    this.data = {
      locations: locations,
      // loadPoint: this.sellCreateForm.loadPoint.value,
      // loadpointName: this.sellCreateForm.loadPoint.value != '' ? this.loadpointList.filter(m => { return m.id == this.sellCreateForm.loadPoint.value })[0].locationName : '',
      // fromPort: this.sellCreateForm.fromPort.value,
      // fromPortName: this.fromPortList.filter(m => { return m.id == this.sellCreateForm.fromPort.value })[0].locationName,
      // toPort: this.sellCreateForm.toPort.value,
      // toPortName: this.toPortList.filter(m => { return m.id == this.sellCreateForm.toPort.value })[0].portName,
      // dischargePort: this.sellCreateForm.dischargePort.value,
      // dischargeName: this.sellCreateForm.dischargePort.value != '' ? this.dischargePortList.filter(m => { return m.id == this.sellCreateForm.dischargePort.value })[0].portName : '',
      commodity: this.sellCreateForm.commodity.value,
      commodityName: this.commodityList.filter(m => { return m.id == this.sellCreateForm.commodity.value })[0].commodityName,
      type: this.sellCreateForm.type.value,
      size: this.sellCreateForm.size.value,
      allInUsd: this.sellCreateForm.allInPrice.value,
      sellRate: this.isFreightRate ? this.sellCreateForm.sellRate.value : this.sellCreateForm.allInPrice.value,
      contractNo: this.sellCreateForm.contractNo.value,
      freeTime: this.sellCreateForm.freeTime.value,
      telexCharges: this.sellCreateForm.telexCharges.value,
      amendmentFees: this.sellCreateForm.amendmentFees.value,
      docFees: this.sellCreateForm.docFees.value,
      margin: this.sellCreateForm.margin.value,
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addCustomRates(): void {
    this.getCommodityList();
    this.getContainerTypes();
  }

  cancelCustomSellRatesCreate(): void {

  }

  getCommodityList() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCommodity, null, false).subscribe(res => {
      this.commodityList = res['data'];
    });
  }

  getContainerTypes() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllContainerTypes, null, false).subscribe(res => {
      this.containerTypes = [];
      var allTypes = res['data'];
      allTypes.forEach(item => {
        this.containerTypes.push(item.containerCode)
      });
    });
  }

  onLocationAdd(data: any): void {
    this.rows.push({ formId: 'form-' + (this.rows.length + 1).toString() });
  }

  onLocationDelete(data: any): void {
    if (this.rows.length > 1) {
      var deleteIndex = this.rows.indexOf({ formId: data.formId });
      this.rows.splice(deleteIndex, 1);
    }
  }

  setSellRate(){
    if(this.isFreightRate){
      let rate = Number(this.sellCreateForm.allInPrice.value) + Number(this.sellCreateForm.margin.value);
      this.sellCreateForm.sellRate.setValue(rate);
    }
  }
}