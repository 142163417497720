import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { ActivatedRoute } from "@angular/router";
import { EncryptionService } from "../services/encryption/encryption.service";
import { TitleCasePipe, Location } from "@angular/common";
import { Router } from '@angular/router';

import {
  FreightConstants, Pagination, GridColumnType,
  GridActionType
} from "../constants/constants";
import { ModalDialogService } from '../services/modal-dialog/model-dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CacheService } from '../services/cache-service/cache.service';
import { AddNewSellRateComponent } from '../spot/add-new-sell-rate/add-new-sell-rate.component';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'inbrit-freight-rate-approval',
  templateUrl: './freight-rate-approval.component.html',
  styleUrls: ['./freight-rate-approval.component.scss'],

})
export class FreightRateApprovalComponent implements OnInit {

  freightRateForm: FormGroup;
  filterMarginForm: FormGroup;
  @ViewChild('formSubmitButton') formSubmitButton;
  @Input() isFreightForApproval: boolean = false;
  freightId: string;
  submitted: boolean;
  freightStatusId: number;
  FreightConstants = FreightConstants;
  isRateSheetForApproval: boolean;
  ValidYesNo: boolean;

  ///////grid componenet//////
  freightList: Array<any>;
  freightListOriginal: Array<any>;
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  loadMaster: boolean = false;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      // { title: 'Load Point', dataField: 'loadpoint', type: GridColumnType.DATA, },
      { title: 'Load Point', dataField: 'loadpointName', type: GridColumnType.DATA, },
      { title: 'From', dataField: 'fromPortName', type: GridColumnType.DATA, },
      { title: 'To', dataField: 'toPortName', type: GridColumnType.DATA, },
      { title: 'Discharge', dataField: 'dischargeName', type: GridColumnType.DATA, },
      { title: 'Size', dataField: 'size', type: GridColumnType.DATA, },
      { title: 'Type', dataField: 'type', type: GridColumnType.DATA, },
      { title: 'All In (USD)', dataField: 'total', type: GridColumnType.DATA, },
      { title: 'Sell Rate', dataField: 'sell', type: GridColumnType.DATA, },
      { title: 'Margin', dataField: 'margin', type: GridColumnType.DATA, },
      { title: 'Valid', dataField: 'isValid', type: GridColumnType.DATA, },
      {
        title: 'Error(s)', dataField: '', type: GridColumnType.ACTION, actions: [
          {
            event: 'viewErrors', type: GridActionType.ICON, title: 'View Erros', class: 'visibility',
            conditionalDisplay: { dataField: 'isValid', value: 'No' },
          },
          {
            event: 'delete', type: GridActionType.ICON, title: 'Delete', class: 'delete_forever',
          }
        ]
      }
    ]
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'FreightRateApprovalComponent-filter';

  // Filter variables
  loadpoints = [];
  fromPorts = [];
  toPorts = [];
  discharge = [];
  containerSizes = [];
  containerTypes = [];
  allInUsd = [];
  sellRates = [];
  margins = [];
  status = ["Yes","No"];
  loadpointIds = [];
  fromPortIds = [];
  toPortIds = [];
  dischargeIds = [];
  containerSizeIds = [];
  containerTypeIds = [];
  allInUsdValue = [];
  sellRateValue = [];
  marginValue = [];
  statusValue = [];
  ///

  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private encryptionService: EncryptionService, private activatedRoute: ActivatedRoute,
    private router: Router, private modalDialogService: ModalDialogService, private dialog: MatDialog,
    private location: Location, private cacheService: CacheService,private loaderService: LoaderService) {
    this.activatedRoute.params.subscribe(params => { this.freightId = params['id'] })
  }

  ngOnInit() {

    this.initializeForm();
    this.getFreightDetails();

  }

  get regForm() { return this.freightRateForm.controls; }

  initializeForm() {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterMarginForm = this.formBuilder.group({
      max: [cachedFilter != null ? cachedFilter.value.max.value : '', []],
      min: [cachedFilter != null ? cachedFilter.value.min.value : '', []],
    });

    this.freightRateForm = this.formBuilder.group({
      carrierId: [{ value: '', disabled: true }, []],
      countryCode: [{ value: '', disabled: true }, []],
      commodityId: [{ value: '', disabled: true }, []],
      effectiveDate: [{ value: '', disabled: true }, []],
      expiryDate: [{ value: '', disabled: true }, []],
      freightStatus: [{ value: '', disabled: true }, []],
    })
  }


  fstatus(evt): void {
    this.freightStatusId = evt.status;
    this.isRateSheetForApproval = evt.status == FreightConstants.PENDING_APPROVAL ? true : false;
  }


  getFreightStatusText(StatusId: number): string {
    var result = FreightConstants.FREIGHT_STATUSES.find(m => m.id == StatusId);
    if (result != undefined) {
      return result.description;
    }
    return "";
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (!this.freightRateForm.valid) {
      return;
    }
    this.updateFreightStatus();
  }

  updateFreightStatus() {

    let param = {
      'Id': this.encryptionService.decrypt(this.freightId),
      'Status': this.freightStatusId,
    }

    let zeroAmountFreightRates = this.freightList.filter(m => { return m.sell == 0 });

    if (this.freightStatusId == FreightConstants.APPROVED && zeroAmountFreightRates.length > 0) {
      let buttons = [
        { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
        { 'title': 'No', 'result': 'no', 'type': '' }
      ];

      this.modalDialogService.openDialog('Confirm', 'The Freight rate contains sell rates with 0 amount, Are you sure you want to approve?', buttons).subscribe((res) => {
        if (res == 'yes') {
          this.httpService.httpPost(RouteConstants.FREIGHT_changeFreightStatus, param).subscribe(res => {
            if (res['success'] == true) {
              this.router.navigate(['/user/freights']);
            }
          });
        }
      });
    }
    else {
      this.httpService.httpPost(RouteConstants.FREIGHT_changeFreightStatus, param).subscribe(res => {
        if (res['success'] == true) {
          this.router.navigate(['/user/freights']);
        }
      });
    }
  }

  getFreightDetails(): void {

    this.httpService.httpPost(RouteConstants.FREIGHT_getFreightRateDetail, { 'Id': this.encryptionService.decrypt(this.freightId) }).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.dataCount = res['count'];
        this.freightList = data;
        var valid: boolean = false;


        if (this.freightList.length > 0) {

          this.freightList.forEach(function (item) {
            item['margin'] = item['margin'] == "" ? 0 : item['margin'];
            item['class'] = {
              row: item.sell == 0 || item['margin'] < 0 ? 'table-danger' : ''
            };

            item['colStyle'] = {
              isValid: {
                'background-color': (item['isValid'] == true ? 'rgba(40,167,69,.12549)' : 'rgba(255,7,58,.12549)'),
                'color': (item['isValid'] == true ? '#28a745' : '#ff073a'), 'padding': '6px 14px 6px 14px', 'word-break': 'normal', 'cursor': 'pointer', 'font-weight': 'bold'
              }
            };


            if (item['isValid'] == true)
              valid = true;

            item['isValid'] = item['isValid'] == true ? 'Yes' : 'No';

          });

          // filter array iinitialisation
          this.containerSizes = Array.from(new Set(this.freightList.map(m => m.size)));
          this.containerTypes = Array.from(new Set(this.freightList.map(m => m.type)));
          this.loadpoints = Array.from(new Set(this.freightList.map(m => m.loadpointName)))
          .map((value) => { return { title: value, hidden: false } });
          this.fromPorts = Array.from(new Set(this.freightList.map(m => m.fromPortName)))
          .map((value) => { return { title: value, hidden: false } });
          this.toPorts = Array.from(new Set(this.freightList.map(m => m.toPortName)))
          .map((value) => { return { title: value, hidden: false } });
          this.discharge = Array.from(new Set(this.freightList.map(m => m.dischargeName)))
          .map((value) => { return { title: value, hidden: false } });

          this.allInUsd = Array.from(new Set(this.freightList.map(m => m.total)));
          this.sellRates = Array.from(new Set(this.freightList.map(m => m.sell)));
          this.margins = Array.from(new Set(this.freightList.map(m => m.margin)));
          //
        }
        this.loadMaster = true;
        this.ValidYesNo = valid;
      }
      this.freightListOriginal = Object.assign([], this.freightList);
    });

  }

  gridEventHandler(evt: any): void {
    if (evt.event == 'viewErrors' && evt.data.isValid == 'No') {
      var errors = evt.data.errors;
      var errorMessages = '<ul>';
      errors.forEach(item => {
        errorMessages += '<li>' + item.errorDescription + "</li>";
      });
      errorMessages += '</ul>';
      this.modalDialogService.openDialog("Errors", errorMessages, [{ title: 'Ok', result: 'true', type: 'accent' }])
    }
    if (evt.event == 'delete') {
      if (this.freightListOriginal.length > 1) {
        let lpCd = evt.data.loadpointName != null && evt.data.loadpointName != "" && evt.data.loadpointName != undefined
          ? (evt.data.loadpointName.includes("(") ? evt.data.loadpointName.split("(")[1].split(')')[0] : evt.data.loadpointName) : "";
        let fpCd = evt.data.fromPortName != null && evt.data.fromPortName != "" && evt.data.fromPortName != undefined
          ? (evt.data.fromPortName.includes("(") ? evt.data.fromPortName.split("(")[1].split(')')[0] : evt.data.fromPortName) : "";
        let tpCd = evt.data.toPortName != null && evt.data.toPortName != "" && evt.data.toPortName != undefined
          ? (evt.data.toPortName.includes("(") ? evt.data.toPortName.split("(")[1].split(')')[0] : evt.data.toPortName) : "";
        let dpCd = evt.data.dischargeName != null && evt.data.dischargeName != "" && evt.data.dischargeName != undefined
          ? (evt.data.dischargeName.includes("(") ? evt.data.dischargeName.split("(")[1].split(')')[0] : evt.data.dischargeName) : "";
        let param = {
          FreightrateId: this.encryptionService.decrypt(this.freightId),
          LoadpointCode: lpCd,
          FromPortCode: fpCd,
          ToPortCode: tpCd,
          DischargeCode: dpCd,
          Size: evt.data.size,
          Type: evt.data.type,
          Total: evt.data.total,
        };

        this.httpService.httpPost(RouteConstants.FRIEGHT_deletefreightRateChart, param).subscribe(res => {
          if (res["success"]) {
            // window.location.reload();
            this.freightId = this.freightId;
            this.getFreightDetails();
          }
        });
      }
      else {
        this.modalDialogService.openDialog("Errors", "Can Not Delete Last Element", [{ title: 'Ok', result: 'true', type: 'accent' }])
      }
    }
  }

  approveRejectFreight(statusId: number): void {
    this.freightStatusId = statusId;
    this.updateFreightStatus();

    // this.formSubmitButton.nativeElement.click();
  }

  viewEffectedBooking(): void {

    this.router.navigate(['user/freight-booking', this.freightId]);

  }

  naviagteBack(): void {
    this.location.back();
  }

  toggleErrorRecords(isDisplayErrors: boolean): void {
    // this.freightList.splice(0,this.freightList.length);
    if (isDisplayErrors) {
      this.freightList = this.freightListOriginal.filter(rate => rate['isValid'] === 'No');
    } else {
      this.freightList = this.freightListOriginal;
    }
  };

  filterFormSubmit(): void {
    // this.getFreight();
    this.freightList = this.freightListOriginal.filter(rate => rate['margin'] >= this.filterMarginForm.controls['min'].value &&
      rate['margin'] <= this.filterMarginForm.controls['max'].value);
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterMarginForm);
    this.cacheService.set(this.uniqueCacheKey, filters);

  }

  clearFilter(): void {
    this.pageIndex = 1;

    //setting all FREIGHT_STATUSES to get entire data
    var rateStatusIds = [];
    FreightConstants.FREIGHT_STATUSES.forEach(item => {
      rateStatusIds.push(item.id);
    });
    this.filterMarginForm.patchValue({
      min: '',
      max: ''
    });

    this.freightList = this.freightListOriginal
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  addCustomRates(): void {
    let commodityId = this.freightListOriginal != undefined && this.freightListOriginal.length > 0 ? this.freightListOriginal[0]["commodityId"] : 0;
    const dialogRef = this.dialog.open(AddNewSellRateComponent, {
      width: '60%',
      data: { isFreightRate: true, commodity: commodityId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.addRatesheetNewRow(result);
      }
    });
  }

  addRatesheetNewRow(rateChart: any) {
    let param: Array<any> = [];
    let frData: Array<any> = [];

    rateChart.locations.forEach(rate => {
      let lpCd = rate.loadpointName.split("(")[1].split(')')[0];
      let fpCd = rate.fromPortName.split("(")[1].split(')')[0];
      let tpCd = rate.toPortName.split("(")[1].split(')')[0];
      let dpCd = rate.dischargeName != null && rate.dischargeName != "" && rate.dischargeName != undefined ?
        rate.dischargeName.split("(")[1].split(')')[0] : "";

      if (this.freightListOriginal.some(m => m.loadpointName == rate.loadpointName && m.fromPortName == rate.fromPortName &&
        m.toPortName == rate.toPortName && m.dischargeName == rate.dischargeName && m.size == rateChart.size &&
        m.type == rateChart.type)) {
        let msg = "Load Point: " + rate.loadpointName + ", From Port:" + rate.fromPortName + ", To Port:" + rate.toPortName +
          ", Discharge Port:" + rate.dischargeName + ", Size: " + rateChart.size + ", Type: " + rateChart.type + " already exists";

        this.modalDialogService.openDialog("Errors", msg, [{ title: 'Ok', result: 'true', type: 'accent' }]);
      }
      else {
        param.push({
          FreightrateId: this.encryptionService.decrypt(this.freightId),
          LoadpointCode: lpCd,
          FromPortCode: fpCd,
          ToPortCode: tpCd,
          DischargeCode: dpCd,
          LoadpointId: rate.loadPoint,
          FromPortId: rate.fromPort,
          ToPortId: rate.toPort,
          DischargeId: rate.dischargePort,
          Size: rateChart.size,
          Type: rateChart.type,
          // AllInUsd = rateChart.AllInUsd,
          TelexCharges: rateChart.telexCharges,
          AmendmentFees: rateChart.amendmentFees,
          DocFees: rateChart.docFees,
          CommodityId: rateChart.commodity,
          Margin: rateChart.margin,
          Total: rateChart.allInUsd,
          Sell: rateChart.sellRate,
          FreeTime: rateChart.freeTime,
        });

        frData.push({
          freighratetId: this.encryptionService.decrypt(this.freightId),
          loadpointName: rate.loadpointName,
          fromPortName: rate.fromPortName,
          toPortName: rate.toPortName,
          dischargeName: rate.dischargeName,
          loadpointCode: rate.loadPoint,
          fromPortCode: rate.fromPort,
          toPortCode: rate.toPort,
          dischargeCode: rate.dischargePort,
          size: rateChart.size,
          type: rateChart.type,
          // AllInUsd = rateChart.AllInUsd,
          telexCharges: rateChart.telexCharges,
          amendmentFees: rateChart.amendmentFees,
          docFees: rateChart.docFees,
          commodityId: rateChart.commodity,
          margin: rateChart.margin,
          total: rateChart.allInUsd,
          sell: rateChart.sellRate,
          freeTime: rateChart.freeTime,
          isValid: "Yes",
          colStyle:  {
            isValid: {
              'background-color': 'rgba(40,167,69,.12549)',
              'color':'#28a745', 
              'padding': '6px 14px 6px 14px',
              'word-break': 'normal', 
              'cursor': 'pointer', 
              'font-weight': 'bold'
            }
          }
        });
      }

    });

    if (param.length > 0) {
      this.httpService.httpPost(RouteConstants.FRIEGHT_savefreightRateChart, param).subscribe(res => {
        if (res["success"]) {
          frData.forEach(m => {
            this.freightList.push(m);
            this.freightListOriginal.push(m);
          });
          this.freightId = this.freightId;
          // window.location.reload();
        }
      });

    }


  }

  filterGrid(): void {
    // filter logic
    var _this = this;
    if (_this.freightListOriginal.length >= 800) {
      this.loaderService.display(true);
    }
    window.setTimeout(function () {
      _this.freightList = _this.freightListOriginal.filter(m => {
        return (_this.loadpointIds != undefined && _this.loadpointIds.length > 0 ? _this.loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
          (_this.fromPortIds != undefined && _this.fromPortIds.length > 0 ? _this.fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
          (_this.toPortIds != undefined && _this.toPortIds.length > 0 ? _this.toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
          (_this.dischargeIds != undefined && _this.dischargeIds.length > 0 ? _this.dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&

          (_this.containerSizeIds != undefined && _this.containerSizeIds.length > 0 ? _this.containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
          (_this.containerTypeIds != undefined && _this.containerTypeIds.length > 0 ? _this.containerTypeIds.indexOf(m.containerType) != -1 : true) &&
          (_this.allInUsdValue != undefined && _this.allInUsdValue.length > 0 ? _this.allInUsdValue.indexOf(m.total) != -1 : true) &&
          (_this.sellRateValue != undefined && _this.sellRateValue.length > 0 ? _this.sellRateValue.indexOf(m.sell) != -1 : true) &&
          (_this.marginValue != undefined && _this.marginValue.length > 0 ? _this.marginValue.indexOf(m.margin) != -1 : true) &&
          (_this.statusValue != undefined && _this.statusValue.length > 0 ? _this.statusValue.indexOf(m.isValid) != -1 : true)
      });

      if (_this.freightListOriginal.length >= 800) {
        this.loaderService.display(false);
      }
    }, 200);
    return;
  }

  toggleAllSelection(control: string, values: Array<string>, isSelectAll: boolean) {
    if (isSelectAll) {
      switch (control) {
        case 'loadpointIds':
        case 'fromPortIds':
        case 'toPortIds':
        case 'dischargeIds':
          this[control] = values.map((value, index) => { return value['title'] });
          break;
        default:
          this[control] = values;
          break;
      }
    }
    else {
      this[control] = [];
    }
    this.filterGrid();
  }

  resetFilter(): void {
    this.freightList = Object.assign([], this.freightListOriginal);
    this.loadpointIds = [];
    this.fromPortIds = [];
    this.toPortIds = [];
    this.dischargeIds = [];
    this.containerSizeIds = [];
    this.containerTypeIds = [];
    this.allInUsdValue = [];
    this.sellRateValue = [];
    this.marginValue = [];
    this.statusValue = [];
    this.filterGrid();
  }

}
