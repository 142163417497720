import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'inbrit-custom-rate-location',
  templateUrl: './custom-rate-location.component.html',
  styleUrls: ['./custom-rate-location.component.scss']
})
export class CustomRateLocationComponent implements OnInit {
  spotRateLocationForm: FormGroup;
  fromPortList: Array<any> = [];
  toPortList: Array<any> = [];
  loadpointList: Array<any> = [];
  dischargePortList: Array<any> = [];
  typeaheadDebounce: number = 500;
  locationCodeDisplay: string = '';
  dischargeCodeDisplay: string = '';
  isFromLoading: boolean = false;
  isToLoading: boolean = false;
  isLoadPointsLoading: boolean = false;
  isDischargePortsLoading: boolean = false;
  submitted: boolean = false;
  appearance: string = 'outline';
  @ViewChild('btnSubmit') btnSubmit: ElementRef;
  @Input() formId: string = '';
  @Output() locationRowAddEvent = new EventEmitter<any>();
  @Output() locationRowDeleteEvent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private httpService: HttpService) {
  }

  ngOnInit() {
    this.initializeForm();
    this.initializeTypeAheads();
  }

  initializeForm() {
    this.spotRateLocationForm = this.formBuilder.group({
      loadPoint: ['', [Validators.required]], loadpointName: [''],
      fromPort: ['', [Validators.required]], fromPortName: [''],
      toPort: ['', [Validators.required]], toPortName: [''],
      dischargePort: ['', []], dischargeName: ['']
    });
  }

  // convenience getter for easy access to form fields
  get locationForm() { return this.spotRateLocationForm.controls; }

  initializeTypeAheads() {

    // Initialize Location codes
    this.spotRateLocationForm.controls['loadPoint'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val };
        this.isLoadPointsLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllLocations, param, false).subscribe(res => {
          this.loadpointList = res['data'];
          this.isLoadPointsLoading = false;
        });
      }
    });

    // Initialize Discharge codes
    this.spotRateLocationForm.controls['dischargePort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val };
        this.isDischargePortsLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllPortcode, param, false).subscribe(res => {
          this.dischargePortList = res['data'];
          this.isDischargePortsLoading = false;
        });
      }
    });

    // Initialize From Port codes
    this.spotRateLocationForm.controls['fromPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val };
        this.isFromLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllLocations, param, false).subscribe(res => {
          this.fromPortList = res['data'];
          this.isFromLoading = false;
        });
      }
    });

    // Initialize To Port codes
    this.spotRateLocationForm.controls['toPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        var param = { "searchText": val, 'isRequestForFromPorts': false };
        this.isToLoading = true;
        this.httpService.httpGet(RouteConstants.MASTER_getAllPortcode, param, false).subscribe(res => {
          this.toPortList = res['data'];
          this.isToLoading = false;
        });
      }
    });
  }

  loadPointTypeAheadDisplay(locationId: number) {

    if (locationId != null && locationId != undefined && locationId != 0) {
      var selectedPort = this.loadpointList.filter(m => { return m.id == locationId })[0];
      var loadPoint = selectedPort.locationName + ' (' + selectedPort.locationCode + ')';
      this.locationForm.loadpointName.setValue(loadPoint);
      return loadPoint;
    }
  }

  fromPortTypeAheadDisplay(locationId: number) {

    if (locationId != null && locationId != undefined && locationId != 0) {
      var selectedPort = this.fromPortList.filter(m => { return m.id == locationId })[0];
      var fromPortCodeDisplay = selectedPort.locationName + ' (' + selectedPort.locationCode + ')';
      this.locationForm.fromPortName.setValue(fromPortCodeDisplay);
      return fromPortCodeDisplay;
    }
  }

  toPortTypeAheadDisplay(toPortId: number) {

    if (toPortId != null && toPortId != undefined && toPortId != 0) {
      var selectedPort = this.toPortList.filter(m => { return m.id == toPortId })[0];
      var toPortCodeDisplay = selectedPort.portName + ' (' + selectedPort.portCode + ')';
      this.locationForm.toPortName.setValue(toPortCodeDisplay);
      return toPortCodeDisplay;
    }
  }

  dischargePortTypeAheadDisplay(toPortId: number) {

    if (toPortId != null && toPortId != undefined && toPortId != 0) {
      var selectedPort = this.dischargePortList.filter(m => { return m.id == toPortId })[0];
      var dischargePortCodeDisplay = selectedPort.portName + ' (' + selectedPort.portCode + ')';
      this.locationForm.dischargeName.setValue(dischargePortCodeDisplay);
      return dischargePortCodeDisplay;
    }
  }

  addNewLocationRow(): void {
    this.btnSubmit.nativeElement.click();
    this.submitted = true;
    if (this.spotRateLocationForm.valid) {
      this.locationRowAddEvent.emit({ 'formId': this.formId });
    }
  }

  deleteLocationRow(): void {
    this.locationRowDeleteEvent.emit({ 'formId': this.formId });
  }

}
