import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userInfoService: UserInfoService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var user = this.userInfoService.getLoggedInUser();
    if (user != null && user != 'null' && user != undefined) {
      return true;
    }
    // User is not logged in, So redirect to login page with the return url.
    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
