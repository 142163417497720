import { Input, Pipe, PipeTransform } from '@angular/core';
import { text } from '@angular/core/src/render3/instructions';

@Pipe({
  name: 'inbritTextEllipsis'
})
export class TextEllipsisPipe implements PipeTransform {

  transform(text: any, colspan: number): any {
    if (!colspan) { return text; }
    var returnText = text;
    let stringLength = 19;
    if (colspan <= 4) {
      stringLength = 30
    }

    if (text != undefined && text.length > stringLength) {
      returnText = text.substr(0, stringLength - 3) + " ...";
    }

    return returnText;
  }
}
