import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { ModalDialogService } from "../../services/modal-dialog/model-dialog.service";
import { ErrorStateMatcher } from '@angular/material';
import { UserRoleConstants } from "../../constants/constants";
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.hasError('notSame') && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'inbrit-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  addUserForm: FormGroup;
  countries: [any];
  states: [any];
  userId: number = 0;
  submitted: boolean;
  confirmPasswordError: boolean;
  matcher = new MyErrorStateMatcher();
  countryOfRegId: number;
  countryId: number;
  stateId: number;
  countryCode: String;
  stateCode: String;
  @Input() IsRegisteredByBackOfficeUser: Boolean = false;
  @Input() userRoleId: number = UserRoleConstants.CUSTOMER;
  userTitles: Array<{ id: number, title: string, isActive: boolean }> = [];
  userRoles: Array<{ id: number, role: string, description: string }> = [];
  isLoading: boolean = false;
  showPassword: boolean = false;
  UserRoleConstants = UserRoleConstants;
  routeUrl: string;
  user: any;
  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private modalDialogService: ModalDialogService, private router: Router,
    private userInfoService: UserInfoService) { }

  ngOnInit() {
    if (this.IsRegisteredByBackOfficeUser) {
      this.user = this.userInfoService.getLoggedInUser();
    }
    this.userRoles = UserRoleConstants.USER_ROLES;
    this.initializeForm();
    this.getCountries();
    this.getUserTitles();

    if (this.IsRegisteredByBackOfficeUser == false) {
      this.addUserForm.get('roleId').setValidators([]);
      this.addUserForm.patchValue({
        roleId: UserRoleConstants.CUSTOMER
      });
      this.addUserForm.get('roleId').updateValueAndValidity();
    }

    if (this.router.url.includes('add-customer')) {
      this.routeUrl = "/user/customer-list";
    }
    else if (this.router.url.includes('add-user')) {
      this.routeUrl = "/user/user-list";
    }
  }

  // convenience getter for easy access to form fields
  get regForm() { return this.addUserForm.controls; }


  initializeForm() {
    this.addUserForm = this.formBuilder.group({
      countryOfRegistration: [{
        value: this.IsRegisteredByBackOfficeUser
          ? this.user.countryOfRegistration
          : '',
        disabled: this.IsRegisteredByBackOfficeUser
      },
      [Validators.required]],
      roleId: [{ value: parseInt(this.userRoleId.toString()), disabled: true }, [Validators.required]],
      title: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', []],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileCode: ['', [Validators.required, Validators.pattern("^[+][0-9 ][-]?[0-9 ]{0,8}$")]],
      mobileNumber: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      companyDepartment: ['', [Validators.required]],
      companyWebsite: [''],
      companyAddress: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      landlineCode: ['', [Validators.required, Validators.pattern("^[+][0-9 ][-]?[0-9 ]{0,8}$")]],
      landlineNumber: ['', [Validators.required]],
    }, { validator: this.checkPasswords });
  }

  getUserTitles() {
    this.httpService.httpGet(RouteConstants.MASTER_getUserTitleMasters, null).subscribe(res => {
      this.userTitles = res['data'];
    });
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null).subscribe(res => {
      this.countries = res['data'];
    });
  }

  setMobileCode(countryOfRegId: number) {
    var selectedCountry = this.countries.filter(function (item) { return item.id == countryOfRegId });
    if (selectedCountry.length > 0) {
      this.addUserForm.patchValue({
        mobileCode: selectedCountry[0].callingCode
      });
    }
  }

  getStates(countryId: number) {
    var selectedCountry = this.countries.filter(function (item) { return item.id == countryId });
    if (selectedCountry.length > 0) {
      this.countryCode = selectedCountry[0].countryCode;
      this.addUserForm.patchValue({
        landlineCode: selectedCountry[0].callingCode
      });
    }
    // Reset the state control to validate again.
    this.stateId = undefined;
    this.addUserForm.patchValue({
      state: null
    });
    this.httpService.httpGet(RouteConstants.MASTER_getAllStates, { 'countryId': countryId }).subscribe(res => {
      this.states = res['data'];

      if (!this.states.length) {
        this.states.push({ 'id': 0, 'stateName': 'No states found' });
        this.addUserForm.patchValue({
          state: 0,
        });
        this.stateCode = 'NA';
      }
    });
  }

  setStateCode(stateId: number): void {
    var selectedState = this.states.filter(function (item) { return item.id == stateId });
    if (selectedState.length > 0) {
      this.stateCode = selectedState[0].stateCode;
    }
  }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onFormSubmit(registrationForm: NgForm): void {
    this.submitted = true;
    if (!this.addUserForm.valid) {
      return;
    }

    if (this.regForm.password.value != this.regForm.confirmPassword.value) {
      this.confirmPasswordError = true;
      return;
    }
    else {
      this.confirmPasswordError = false;
    }
    this.isLoading = true;
    var param = {
      'Email': this.regForm.email.value
    }

    var confirmDialogButtons = [
      { 'title': 'Submit', 'result': 'yes', 'type': 'accent' },
      { 'title': 'Edit', 'result': 'no', 'type': 'warn' },
    ];

    const dialogRef = this.modalDialogService.openDialog('Confirm', 'Kindly Confirm your details and Submit', confirmDialogButtons);

    dialogRef.subscribe(result => {
      if (result == 'yes') {
        this.httpService.httpPost(RouteConstants.ACCOUNT_isUserExists, param).subscribe(res => {
          if (res['success'] == true && res['data'] == false) {
            this.registerUser(registrationForm);
          }
          else {
            this.isLoading = false;
            var buttons = [
              { 'title': 'Ok', 'result': 'yes', 'type': 'primary' },
            ];
            this.modalDialogService.openDialog('Alert', 'User alredy exists', buttons);
          }
        });
      }
      else {
        this.isLoading = false;
      }
    });


  }

  registerUser(registrationForm: NgForm): void {

    if (this.IsRegisteredByBackOfficeUser == false) {
      this.addUserForm.patchValue({
        roleId: UserRoleConstants.CUSTOMER
      })
    }
    var sha1 = require('sha1');
    var param = {
      'CountryOfRegistration': this.regForm.countryOfRegistration.value,
      'CountryCode': this.countries.find(cnt => cnt.id == this.regForm.countryOfRegistration.value).countryCode,
      'RoleId': this.regForm.roleId.value,
      'TitleId': this.regForm.title.value,
      'Password': sha1(this.regForm.password.value),
      'FirstName': this.regForm.firstName.value,
      'LastName': this.regForm.lastName.value,
      'Email': this.regForm.email.value,
      'MobileCode': this.regForm.mobileCode.value,
      'MobileNumber': this.regForm.mobileNumber.value,
      'UserCompanyDetail': {
        'CompanyName': this.regForm.companyName.value,
        'CompanyDepartment': this.regForm.companyDepartment.value,
        'Website': this.regForm.companyWebsite.value,
        'CompanyAddress': this.regForm.companyAddress.value,
        'CountryId': this.regForm.country.value,
        'CountryCode': this.countryCode,
        'StateCode': this.stateCode,
        'StateId': this.regForm.state.value,
        'City': this.regForm.city.value,
        'ZipCode': this.regForm.zipCode.value,
        'LandlineCode': this.regForm.landlineCode.value,
        'LandlineNumber': this.regForm.landlineNumber.value
      },
      'IsRegisteredByBackOfficeUser': this.IsRegisteredByBackOfficeUser
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_registerUser, param).subscribe(res => {
      this.isLoading = false;
      if (res['success'] == true) {
        if (this.IsRegisteredByBackOfficeUser == false) {
          this.router.navigate(['auth/login']);
        }
        else {
          this.resetForm(registrationForm);
          this.router.navigate([this.routeUrl]);
        }
      }
    });

  }

  resetForm(registrationForm: NgForm): void {
    this.submitted = false;
    registrationForm.resetForm();
  }

}