import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { UserRoleConstants } from "../../constants/constants";
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard implements CanActivateChild {
  constructor(private router: Router, private userInfoService: UserInfoService) { }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var user = this.userInfoService.getLoggedInUser();
    if (user.roleId == UserRoleConstants.USER) {
      return true;
    }
    return false;
  }
}
