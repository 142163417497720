import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { Router } from '@angular/router';
import { RouteConstants } from "../../constants/route-constants";
import { FreightConstants } from "../../constants/constants";
import { MatDialog } from '@angular/material';
import { DateChangePopupComponent } from 'src/app/shared/date-change-popup/date-change-popup.component';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'inbrit-freight-master',
  templateUrl: './freight-master.component.html',
  styleUrls: ['./freight-master.component.scss']
})
export class FreightMasterComponent implements OnInit {
  @Input() freightId: string = "";
  @Input() isFreightRateApproval: boolean = false;
  @Output() fstatus = new EventEmitter<any>();
  FreightConstants = FreightConstants;
  carrierId: string;
  countryCode: string;
  commodityId: string;
  expiryDate: string;
  effectiveDate: string;
  freightStatus: string;
  emailFrom: string;
  createdDate: string;
  datePipe = new DatePipe('en-US');


  constructor(private httpService: HttpService,
    private encryptionService: EncryptionService, private router: Router,private dialog: MatDialog) { }

  ngOnInit() {
    this.getFreight();
  }

  getFreight(): void {

    var param = {
      "FreightId": this.encryptionService.decrypt(this.freightId),
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_getFreightRate, param).subscribe(res => {
      if (res['success'] == true) {

        let FreightDetails = res['data'][0];

        this.carrierId = FreightDetails.carrierName;
        this.countryCode = FreightDetails.country;
        this.commodityId = FreightDetails.commodity;
        this.expiryDate = FreightDetails.expiryDate;
        this.effectiveDate = FreightDetails.effectiveDate;
        this.emailFrom = FreightDetails.emailFrom;
        this.createdDate = FreightDetails.createdDate;
        this.freightStatus = this.getFreightStatusText(FreightDetails.status);
        this.fstatus.emit({ isActive: FreightDetails.isActive, status: FreightDetails.status });
      }
    });
  }

  getFreightStatusText(StatusId: number): string {
    var result = FreightConstants.FREIGHT_STATUSES.find(m => m.id == StatusId);
    if (result != undefined) {
      return result.description;
    }
    return "";
  }

  dateChange(){
    let dialogRef =this.dialog.open(DateChangePopupComponent, {width: '450px',
    data: {"effectiveDate":  this.effectiveDate, "expiryDate": this.expiryDate}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.Success) {
        var param = {
            Id: this.encryptionService.decrypt(this.freightId),
            EffectiveDate: this.datePipe.transform(result.Data.revisedEffectiveDate, 'yyyy-MM-dd'),
            ExpiryDate: this.datePipe.transform(result.Data.revisedExpiryDate, 'yyyy-MM-dd'),
            Comments: result.Data.remark
        }
        this.httpService.httpPost(RouteConstants.FRIEGHT_updateFreightRateDates,param).subscribe(res => {
          if(res["success"]){
            this.expiryDate = result.Data.revisedExpiryDate;
            this.effectiveDate = result.Data.revisedEffectiveDate;
          }
        });
      }
    });
  }

}
