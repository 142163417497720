import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[inbritFocusFirstInvalidControl]'
})
export class FocusFirstInvalidControlDirective {

  constructor(private el: ElementRef) { }

  @HostListener('submit')
  onFormSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].focus();
      invalidElements[0].scrollIntoView();
    }
  }

}
