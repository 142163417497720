import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pagination, GridColumnType, GridColumnDataType, GridActionType, DisplayModeConstants } from 'src/app/constants/constants';
import { HttpService } from 'src/app/services/http/http.service';
import { RouteConstants } from 'src/app/constants/route-constants';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmBoxComponent } from 'src/app/shared/confirm-box/confirm-box.component';
import { SpotConstants, DateConstants } from 'src/app/constants/constants';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';

@Component({
  selector: 'inbrit-spot-sell-dashboard',
  templateUrl: './spot-sell-dashboard.component.html',
  styleUrls: ['./spot-sell-dashboard.component.scss']
})
export class SpotSellDashboardComponent implements OnInit {
  filterSpotRateSellForm: FormGroup;
  spotRateSellList: [any];
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  toggleFilterVisiblility: boolean = false;
  spotName: string;
  totalFiltersApplied: number = 0;
  SpotConstants = SpotConstants;
  datePipe = new DatePipe('en-US');
  filter: string = "";
  submitted: boolean = false;
  DateConstants = DateConstants;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Spot Name', dataField: 'rateName', type: GridColumnType.DATA, },
      // { title: 'Country Name', dataField: 'countryName', type: GridColumnType.DATA, },
      { title: 'Carrier Name', dataField: 'carrierName', type: GridColumnType.DATA, },
      { title: 'Created By', dataField: 'createdByName', type: GridColumnType.DATA },
      { title: 'Last Modification On', dataField: 'modifiedDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },
      { title: 'Status', dataField: 'active', type: GridColumnType.DATA, },
      {
        title: 'Action', dataField: 'id', type: GridColumnType.ACTION, actions: [
          {
            event: 'edit', type: GridActionType.ICON, title: 'Edit', class: 'edit',
            conditionalDisplay: { dataField: 'isEditSpot', value: true }
          },
          {
            event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility',
            // conditionalDisplay: { dataField: 'isViewSpot', value: true }
          },
          {
            event: 'deActivateSpot', type: GridActionType.ICON, title: 'Block', class: 'block',
            conditionalDisplay: { dataField: 'active', value: 'Approved' }
          },
          {
            event: 'activateSpot', type: GridActionType.ICON, title: 'Unblock', class: 'check_circle',
            conditionalDisplay: { dataField: 'active', value: 'Blocked' }
          }
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'SpotSellDashboardComponent-filter';


  constructor(private httpService: HttpService, private formBuilder: FormBuilder,
    private router: Router, private encryptionService: EncryptionService,
    private dialog: MatDialog, private userInfoService: UserInfoService,
    private cacheService: CacheService) {
  }

  get filterFormFields() { return this.filterSpotRateSellForm.controls; }


  ngOnInit() {
    this.initializeFilterForm();
    this.getSpotRateSell();

  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterSpotRateSellForm = this.formBuilder.group({
      spotrateStatusIds: [cachedFilter != null ? cachedFilter.value.spotrateStatusIds.value : '', []],
      spotrateFromDate: [cachedFilter != null ? cachedFilter.value.spotrateFromDate.value : new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      spotrateToDate: [cachedFilter != null ? cachedFilter.value.spotrateToDate.value : new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]

    });
  }

  filterFormSubmit() {
    this.submitted = true;
    if (!this.filterSpotRateSellForm.valid) {
      return;
    }
    else {
      this.getSpotRateSell();
      this.filterDialogRef.close();
    }
    var filters = this.cacheService.getFormValues(this.filterSpotRateSellForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.filterSpotRateSellForm.reset();
    this.filterSpotRateSellForm.patchValue({
      spotrateFromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      spotrateToDate: new Date(moment().format("M/D/YYYY").toString())
    })


    this.toggleFilterVisiblility = !this.toggleFilterVisiblility;
    this.getSpotRateSell();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewSpotRates(evt.data.id);
          break;
        }
      case 'edit':
        {
          this.editSpotRates(evt.data.id);
          break;
        }
      case 'deActivateSpot':
      case 'activateSpot':
        {
          this.confirmSpotActivateDeactivate(evt.data.id, !evt.data.isActive);
          break;
        }
    }
  }



  confirmSpotActivateDeactivate(spotId: number, isActive: boolean): void {
    const dialogRef = this.dialog.open(ConfirmBoxComponent, {
      width: '250px',
      data: { spotId: spotId, isBlocked: isActive, message: "The spot rate will be " + (isActive == true ? "UnBlocked" : "Blocked") }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.activateOrDeactivateSpotRate(spotId, isActive);
      }
    });
  }

  activateOrDeactivateSpotRate(spotId: number, isActive: boolean): void {
    var param = {
      "Id": spotId, "IsActive": isActive
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_updateSpotRateSell, param).subscribe(res => {
      if (res['success'] == true) {
        var spotMaster = this.spotRateSellList.filter(function (item) { return item.id == spotId })[0];
        spotMaster['isEditSpot'] = true; // res['data'].status == SpotConstants.PENDING_APPROVAL;
        spotMaster['isViewSpot'] = true; // !spotMaster['isEditSpot'];
        this.clearFilter();
      }
    });
  }

  viewSpotRates(spotId: number): void {
    this.router.navigate(['user/spot-sell', this.encryptionService.encrypt(spotId), this.encryptionService.encrypt(DisplayModeConstants.view)]);
  }

  editSpotRates(spotId: number): void {
    this.router.navigate(['user/spot-sell', this.encryptionService.encrypt(spotId), this.encryptionService.encrypt(DisplayModeConstants.edit)]);
  }

  paginationEventHandler(evt): void {

    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getSpotRateSell();
  }

  getSpotRateSell(): void {
    var spottStatusIds = [];
    this.totalFiltersApplied = 0;

    if (this.filterFormFields.spotrateStatusIds.value) {
      this.totalFiltersApplied++;
      this.filterFormFields.spotrateStatusIds.value.forEach(item => {
        spottStatusIds.push(item);
      });
    }

    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "ListStatusId": spottStatusIds,
      "FromDate": this.datePipe.transform(this.filterFormFields.spotrateFromDate.value, 'yyyy-MM-dd'),
      "ToDate": this.datePipe.transform(this.filterFormFields.spotrateToDate.value, 'yyyy-MM-dd'),
      "SearchText": this.filter
    };

    if (param.FromDate != null && param.FromDate != '' && param.ToDate != null && param.ToDate != '') {
      this.totalFiltersApplied++;
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_getAllSpotRate, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];

        this.dataCount = res['count'];
        data.forEach(item => {
          item['isEditSpot'] = true; // item['status'] == SpotConstants.PENDING_APPROVAL;
          item['isViewSpot'] = true; // !item['isEditSpot'];

          item['active'] = SpotConstants.SPOT_STATUSES.find(m => m.id == item['status']).description;

          item['class'] = {
            active: this.userInfoService.getColorClass(item.status),
          };
        });

        this.spotRateSellList = data;
      }
    });
  }

}
