import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth/auth.service';
import { EncryptionService } from '../services/encryption/encryption.service';
import { MenuItems } from '../shared/sidebar/menu-items';
import { UserRoleConstants, KycStatusConstants, ProfileStatusConstants } from '../constants/constants';
import { UserManagementService } from '../services/user-firebase-subscription/user-firebase-subscription.service';
import { UserInfoService } from '../services/userInfo/user-info.service';
import { RouteConstants } from '../constants/route-constants';
import { HttpService } from '../services/http/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  menuItems = [];
  user: any;
  userDetails: any;
  showPendingKycNote: boolean = false;
  showApprovalPendingKycNote: boolean = false;
  showUserApprovalPendingNote: boolean = false;
  kycDueInDays: number = 0;
  constructor(private router: Router, private authService: AuthService,
    private encryptionService: EncryptionService, private userManagementService: UserManagementService,
    private userInfoService: UserInfoService, private httpService: HttpService) {
  }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();

    if (user != undefined && user != null && user != 'null') {
      this.user = user;
    }

    this.getUserDetials();
    var menuItemsObj = new MenuItems(this.encryptionService, this.userInfoService);
    var menus = menuItemsObj.ROUTES;
    menus.forEach(menu => {
      if (menu.children.length == 0) {
        if (menu.dashboardIcon != '') {
          this.menuItems.push(menu);
        }
      }
      else {
        menu.children.forEach(subMenu => {
          if (subMenu.dashboardIcon != '')
            this.menuItems.push(subMenu);
        });
      }
    });

  }

  getUserDetials(): void {
    this.httpService.httpGet(RouteConstants.ACCOUNT_getUser, { 'userId': this.user.id }).subscribe(res => {
      if (res['success'] == true) {
        this.userDetails = res['data'];
        this.kycDueInDays = this.userDetails.kycDueInDays;
        this.displayKycNote();
      }
    });
  }

  displayKycNote(): void {

    if (this.userDetails != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        if (this.userDetails.kycStatusId == KycStatusConstants.KYC_PENDING) {
          this.showPendingKycNote = true;
        }
        else if (this.userDetails.kycStatusId == KycStatusConstants.KYC_APPROVAL_PENDING) {
          this.showApprovalPendingKycNote = true;
        }
        else if (this.userDetails.statusId == ProfileStatusConstants.PENDING_APPROVAL) {
          this.showUserApprovalPendingNote = true;
        }
      }
    }
  }

  Logout() {
    this.authService.signOut();
    this.userManagementService.logoutUser();
  }
}
