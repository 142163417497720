import { Component, OnInit } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../../services/encryption/encryption.service";
import {
  Pagination, GridColumnType,
  GridActionType,
  GridColumnDataType,
  DisplayModeConstants
} from "../../constants/constants";
import { TitleCasePipe } from "@angular/common";
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmBoxComponent } from 'src/app/shared/confirm-box/confirm-box.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CacheService } from 'src/app/services/cache-service/cache.service';

@Component({
  selector: 'inbrit-spot-buy-dashboard',
  templateUrl: './spot-buy-dashboard.component.html',
  styleUrls: ['./spot-buy-dashboard.component.scss']
})
export class SpotBuyDashboardComponent implements OnInit {
  spotbuyRateList: [any];
  title: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  // buyRateName: string;
  filterSpotBuyListForm: FormGroup;
  totalFiltersApplied: number = 0;
  filter: string = "";

  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [

      { title: 'Contract Name', dataField: 'spotRateName', type: GridColumnType.DATA, },
      { title: 'Carrier', dataField: 'carrierName', type: GridColumnType.DATA },
      { title: 'Aggrement', dataField: 'agreementClause', type: GridColumnType.DATA },
      { title: 'Created By', dataField: 'createdByName', type: GridColumnType.DATA },
      { title: 'Last Modification On', dataField: 'modifiedDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },

      { title: 'Status', dataField: 'active', type: GridColumnType.DATA },

      {
        title: 'Action', dataField: 'id', type: GridColumnType.ACTION, actions: [
          { event: 'editContract', type: GridActionType.ICON, title: 'Edit', class: 'edit' },
          { event: 'viewContract', type: GridActionType.ICON, title: 'View', class: 'visibility' },
          {
            event: 'deActivateTier', type: GridActionType.ICON, title: 'Deactivate', class: 'block',
            conditionalDisplay: { dataField: 'isActive', value: true }
          },
          {
            event: 'activateTier', type: GridActionType.ICON, title: 'Activate', class: 'check_circle',
            conditionalDisplay: { dataField: 'isActive', value: false }
          }


        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  uniqueCacheKey: string = 'SpotBuyDashboardComponent-filter';

  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private dialog: MatDialog,
    private formBuilder: FormBuilder, private cacheService: CacheService) { }

  ngOnInit() {

    this.initializeFilterForm();
    this.getContract();
  }


  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterSpotBuyListForm = this.formBuilder.group({
      isActive: [cachedFilter != null ? cachedFilter.value.isActive.value : '', []]
    });
  }

  get filterFormFields() { return this.filterSpotBuyListForm.controls; }

  clearFilter(): void {
    this.filterSpotBuyListForm.reset();
    this.getContract();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  filterFormSubmit() {
    this.getContract();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterSpotBuyListForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getContract();
  }



  getContract(): void {
    this.totalFiltersApplied = 0;
    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "SearchText": this.filter,// this.filterFormFields.buyRateName.value != null ? this.filterFormFields.buyRateName.value : "",
      "ListContractId": [],
      "FromDate": null,
      "ToDate": null,
    };

    if (this.filterFormFields.isActive.value != null && this.filterFormFields.isActive.value != "") {
      param["IsActive"] = this.filterFormFields.isActive.value == "true" ? true : false;
      this.totalFiltersApplied++;
    }

    // if (param.SearchText != '') {
    //   this.totalFiltersApplied++;
    // }


    this.httpService.httpPost(RouteConstants.FREIGHT_getSpotBuyRate, param).subscribe(res => {
      if (res['success'] == true) {


        var data = res['data'];

        data.forEach(item => {
          item['active'] = item['isActive'] == true ? "Active" : "Inactive";
          item['class'] = {
            active: item['isActive'] == true ? 'is-green p-2 font-bold' : 'is-red p-2 font-bold',
          };
        });


        this.dataCount = res['count'];

        this.spotbuyRateList = data;
      }
    });

  }


  viewContract(contracttId: number): void {
    this.router.navigate(['user/spot-buy', this.encryptionService.encrypt(contracttId), this.encryptionService.encrypt(DisplayModeConstants.view)]);
  }

  editContract(contracttId: number): void {
    this.router.navigate(['user/spot-buy', this.encryptionService.encrypt(contracttId), this.encryptionService.encrypt(DisplayModeConstants.edit)]);
  }

  gridEventHandler(evt): void {

    switch (evt.event) {
      case 'viewContract':
        {
          this.viewContract(evt.data.id);
          break;
        }
      case 'editContract':
        {
          this.editContract(evt.data.id);
          break;
        }
      case 'deActivateTier':
      case 'activateTier':
        {
          this.confirmTierActivateDeactivate(evt.data.id, !evt.data.isActive);
          break;
        }

    }
  }

  confirmTierActivateDeactivate(tierId: number, isActive: boolean): void {
    const dialogRef = this.dialog.open(ConfirmBoxComponent, {
      width: '450px',
      data: { tierId: tierId, isBlocked: isActive, message: "The Spot Buy Rate will be " + (isActive == true ? "Activated" : "Deactivated") }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.activateOrDeactivateSpotBuyRate(tierId, isActive);
      }
    });
  }

  activateOrDeactivateSpotBuyRate(spotBuyRateId: number, isActive: boolean): void {
    var param = {
      "Id": spotBuyRateId, "IsActive": isActive
    };
    this.httpService.httpPost(RouteConstants.FREIGHT_saveSpotBuyRateStatus, param).subscribe(res => {
      if (res['success'] == true) {
        this.clearFilter();
      }
    });
  }



}
