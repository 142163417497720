import { Component, OnInit } from '@angular/core';
import { RouteConstants } from "../../constants/route-constants";
import { UserRoleConstants, DateConstants } from "../../constants/constants";
import { Pagination, GridColumnType, GridActionType, GridColumnDataType } from "../../constants/constants";
import { HttpService } from "../../services/http/http.service";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { DatePipe } from "@angular/common"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';

@Component({
  selector: 'booking-report',
  templateUrl: './booking-report.component.html',
  styleUrls: ['./booking-report.component.scss']
})
export class BookingReportComponent implements OnInit {
  user: any;
  pageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  datePipe = new DatePipe('en-US');
  filterBookingForm: FormGroup;
  toggleFilterVisiblility: boolean = false;
  bookingHistory: [any];
  dataCount: number;
  submitted: boolean = false;
  DateConstants=DateConstants;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Quotation', dataField: 'quotationNumber', type: GridColumnType.DATA, },
      { title: 'User', dataField: 'createdByName', type: GridColumnType.DATA, },
      { title: 'From', dataField: 'fromName', type: GridColumnType.DATA, },
      { title: 'To', dataField: 'toName', type: GridColumnType.DATA, },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATETIME },
      {
        title: 'Action', dataField: 'quotationId', type: GridColumnType.ACTION, actions: [
          { event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility' }
        ]
      }
    ];
  filterDialogRef: MatDialogRef<{}, any>;
  UserRoleConstants = UserRoleConstants;
  uniqueCacheKey: string = 'BookingReportComponent-filter';

  constructor(private httpService: HttpService, private formBuilder: FormBuilder,
    private router: Router, private encryptionService: EncryptionService,
    private dialog: MatDialog, private userInfoService: UserInfoService,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.initializeForm();
    this.getAllBookings();
    var user = this.userInfoService.getLoggedInUser();
    if (user != undefined && user != null && user != 'null') {
      this.user = user;
    }
  }

  initializeForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterBookingForm = this.formBuilder.group({
      fromDate: [cachedFilter != null ? cachedFilter.value.fromDate.value : new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      toDate: [cachedFilter != null ? cachedFilter.value.toDate.value : new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterBookingForm.controls; }

  getAllBookings(): void {
    var param = {
      FromDate: this.datePipe.transform(this.filterFormFields.fromDate.value, "yyyy-MM-dd"),
      ToDate: this.datePipe.transform(this.filterFormFields.toDate.value, "yyyy-MM-dd"),
    }

    this.httpService.httpPost(RouteConstants.BOOKING_getAllBookings + "?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        data.forEach(item => {
          item['class'] = {
            statusText: this.userInfoService.getColorClass(item.status),
          };
        });
        this.bookingHistory = data;
        this.dataCount = res['count'];
      }
    });
  }

  clearFilter(): void {
    this.filterBookingForm.patchValue({
      fromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      toDate: new Date(moment().format("M/D/YYYY").toString())
    })
    this.pageIndex = 1;
    this.getAllBookings();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  filterFormSubmit(): void {
    this.submitted = true;
    if (!this.filterBookingForm.valid) {
      return;
    }
    this.getAllBookings();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterBookingForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewBookingDetail(evt.data.bookingId);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getAllBookings();
  }

  viewBookingDetail(bookingId: number): void {
    if (this.user != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        this.router.navigate(['customer/bookings-report', this.encryptionService.encrypt(bookingId)]);
      }
      else {
        this.router.navigate(['user/bookings-report', this.encryptionService.encrypt(bookingId)]);
      }
    }
  }

}