import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'inbrit-date-change-popup',
  templateUrl: './date-change-popup.component.html',
  styleUrls: ['./date-change-popup.component.scss']
})
export class DateChangePopupComponent implements OnInit {

  dateForm: FormGroup;
  submitted: boolean = false;
  heading: "Edit";

  constructor(private fb: FormBuilder,public dialogRef: MatDialogRef<DateChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.dateForm = this.fb.group({
      effectiveDate: [this.data != null ? this.data.effectiveDate : '',[]],
      expiryDate: [this.data != null ? this.data.expiryDate : '',[]],
      revisedEffectiveDate: ['',[Validators.required]],
      revisedExpiryDate: ['',[Validators.required]],
      remark: ['',[Validators.required]]
    });
  }

  onClick(val: boolean){
    this.submitted = true;
    if(val && this.dateForm.invalid){
      return;
    }
    var param = {
      Success: val,
      Data: {
        effectiveDate: this.dateForm.controls['effectiveDate'].value,
        expiryDate:this.dateForm.controls['expiryDate'].value,
        revisedEffectiveDate: this.dateForm.controls['revisedEffectiveDate'].value,
        revisedExpiryDate: this.dateForm.controls['revisedExpiryDate'].value,
        remark: this.dateForm.controls['remark'].value,
      }
    };
    this.dialogRef.close(param);
  }

}
