import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Pagination } from "../../constants/constants";

@Component({
  selector: 'inbrit-pagination-back-forth',
  templateUrl: './pagination-back-forth.component.html',
  styleUrls: ['./pagination-back-forth.component.scss']
})
export class PaginationBackForthComponent implements OnInit {

  @Input() dataCount: number;
  @Output() eventPagination = new EventEmitter<any>();
  start: number;
  end: number;
  total: number;
  pageSize: number = Pagination.PageSize;
  pageSizeOptions = Pagination.PageSizeOptions;

  constructor() { }

  ngOnInit() {
  }

  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  @HostListener('change') ngOnChanges() {
    this.pager = this.getPager(this.dataCount, 1, this.pageSize);
  }

  filterRows(evt): void {
    this.eventPagination.emit({ 'pageIndex': 1, 'pageSize': this.pageSize });
    //this.dataSource = this.pageDataOriginal.slice(0, this.pageSize);
  }

  setPage(page: number) {
    this.eventPagination.emit({ 'pageIndex': page, 'pageSize': this.pageSize });
    // get pager object 
    this.pager = this.getPager(this.dataCount, page, this.pageSize);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    //Set strt end and total record
    this.start = (currentPage - 1) * pageSize + 1;
    this.end = (this.start + (pageSize - 1)) > totalItems ? totalItems : (this.start + (pageSize - 1));
    this.total = totalItems;

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
