import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EncryptionService } from '../services/encryption/encryption.service';
import { UserInfoService } from '../services/userInfo/user-info.service';


@Component({
  selector: 'inbrit-invite-customer',
  templateUrl: './invite-customer.component.html',
  styleUrls: ['./invite-customer.component.scss']
})
export class InviteCustomerComponent implements OnInit {

  invitecustomerForm: FormGroup;
  countries: [any];
  userTitleMaster: [any];
  submitted: boolean;
  isLoading: boolean = false;
  countryOfRegId: number;
  customerId: number;
  isEdit: boolean = false;
  appearance: string = 'outline';
  user: any;

  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService,
    private userInfoService: UserInfoService) {
    var invitecustomerId = "";
    this.activatedRoute.params.subscribe(params => { invitecustomerId = params['id'] });
    if (invitecustomerId != undefined && invitecustomerId != "" && invitecustomerId != "new") {
      this.customerId = parseInt(this.encryptionService.decrypt(invitecustomerId));
      this.appearance = 'fill';
      this.isEdit = true;
    }

    this.user = this.userInfoService.getLoggedInUser();
  }

  ngOnInit() {
    this.initializeForm();
    this.getCountries();
    this.getTitle();

    if (this.isEdit) {
      this.getCustomerEditData();
    }
  }

  // convenience getter for easy access to form fields
  get regForm() { return this.invitecustomerForm.controls; }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
      this.setMobileCode(this.user.countryOfRegistration);
    });
  }

  getTitle() {
    try {
      this.httpService.httpGet(RouteConstants.MASTER_getUserTitleMasters, null, false).subscribe(res => {
        this.userTitleMaster = res['data'];
      });
    }
    catch (error) {
    }

  }


  initializeForm() {

    this.invitecustomerForm = this.formBuilder.group({
      countryOfRegistration: [{ value: this.user.countryOfRegistration, disabled: true }, [Validators.required]],
      Title: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      firstName: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      lastName: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      email: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      companyName: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      companyDepartment: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      landlineNumber: [{ value: '', disabled: this.isEdit }, [Validators.required]],
      code: [{ value: '', disabled: this.isEdit }, [Validators.required, Validators.pattern("^[+][0-9 ][-]?[0-9 ]{0,8}$")]],
    });
  }


  onFormSubmit(inviteCustForm: NgForm): void {
    this.submitted = true;
    if (!this.invitecustomerForm.valid) {
      return;
    }
    else {
      this.inviteCustomer(inviteCustForm);
    }
  }

  inviteCustomer(inviteCustForm: NgForm): void {
    this.isLoading = true;

    var param = {
      'SalutationId': this.regForm.Title.value,
      'Country': this.regForm.countryOfRegistration.value,
      'FirstName': this.regForm.firstName.value,
      'LastName': this.regForm.lastName.value,
      'Email': this.regForm.email.value,
      'CompanyName': this.regForm.companyName.value,
      'CompanyPosition': this.regForm.companyDepartment.value,
      'ContactCode': this.regForm.code.value,
      'ContactNo': this.regForm.landlineNumber.value
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_inviteCustomer, param).subscribe(res => {
      if (res['success'] == true) {
        this.resetForm(inviteCustForm);
      }
      this.isLoading = false;
    });

  }


  resetForm(inviteCustForm: NgForm): void {
    this.submitted = false;
    this.isLoading = false;
    inviteCustForm.resetForm();
    this.invitecustomerForm.patchValue({
      countryOfRegistration: this.user.countryOfRegistration
    });
    this.setMobileCode(this.user.countryOfRegistration)
  }

  setMobileCode(countryOfRegId: number) {
    var selectedCountry = this.countries.filter(function (item) { return item.id == countryOfRegId });
    if (selectedCountry.length > 0) {
      this.invitecustomerForm.patchValue({
        code: selectedCountry[0].callingCode
      });
    }
  }


  getCustomerEditData() {

    this.httpService.httpGet(RouteConstants.ACCOUNT_getInviteCustomerById, { 'customerID': this.customerId }).subscribe(res => {
      if (res['success'] == true) {

        var CustomerData = res['data'][0];

        this.invitecustomerForm.patchValue({
          Title: CustomerData.salutationId,
          firstName: CustomerData.firstName,
          lastName: CustomerData.lastName,
          countryOfRegistration: CustomerData.country,
          email: CustomerData.email,
          companyName: CustomerData.companyName,
          companyDepartment: CustomerData.companyPosition,
          code: CustomerData.contactCode,
          landlineNumber: CustomerData.contactNo,

        });
      }
    });

  }

}
