import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatFormFieldModule, MatInputModule, MatButtonModule, MatButtonToggleModule,
  MatPaginatorModule, MatTableModule, MatSortModule, MatTabsModule,
  MatDividerModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule,
  MatCardModule, MatAutocompleteModule, MatDialogModule, MatBadgeModule, MatGridListModule,
  MatSliderModule, MatRippleModule, MatExpansionModule, MatCheckboxModule, MatListModule,
  MatTooltipModule, MatIconModule, MatToolbarModule,
  MatSnackBarModule, MatSidenavModule,
  MatProgressSpinnerModule,
  MatRadioModule, MatChipsModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifyComponent } from "../shared/notify/notify.component";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BlankCalendarHeaderComponent } from '../shared/blank-calendar-header/blank-calendar-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // AdminLayoutModule,
    // NavbarModule,
    // FooterModule,
    // SidebarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule, MatButtonToggleModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatDividerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatBadgeModule,
    MatGridListModule,
    MatSliderModule,
    MatRippleModule,
    // LoginFooterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatExpansionModule,
    // EslabsDirectivesModule,
    MatCheckboxModule,
    MatRadioModule,
    MatListModule,
    MatTooltipModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule, MatSnackBarModule,
    FlexLayoutModule, MatSidenavModule,
    DragDropModule,
    MatChipsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    FormsModule, ReactiveFormsModule, // AdminLayoutModule, NavbarModule, FooterModule, SidebarModule,
    MatFormFieldModule, MatInputModule, MatButtonModule, MatButtonToggleModule, MatPaginatorModule,
    MatTableModule, MatSortModule, MatTabsModule, MatDividerModule,
    MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCardModule,
    MatAutocompleteModule, MatDialogModule, MatBadgeModule, MatGridListModule,
    MatSliderModule, MatRippleModule, OwlDateTimeModule, OwlNativeDateTimeModule,
    // LoginFooterModule, MatExpansionModule, EslabsDirectivesModule, 
    MatCheckboxModule, MatRadioModule, MatListModule, MatTooltipModule,MatExpansionModule,
    MatIconModule, MatToolbarModule, MatProgressSpinnerModule, MatSidenavModule,
    MatSnackBarModule, FlexLayoutModule, DragDropModule, MatChipsModule, TranslateModule
  ],
  declarations: [],
  entryComponents: [NotifyComponent, BlankCalendarHeaderComponent]
})
export class SharedModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}