import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class EncryptionService {
    keys = '8080808080808080';
    constructor() { }

    //The set method is use for encrypt the value.
    encrypt(value) {
        var key = CryptoJS.enc.Utf8.parse(this.keys);
        var iv = CryptoJS.enc.Utf8.parse(this.keys);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
            {
                keySize: 16,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        return encrypted.toString(CryptoJS.format.Hex);
    }

    //The get method is use for decrypt the value.
    decrypt(value) {
        var key = CryptoJS.enc.Utf8.parse(this.keys);
        var iv = CryptoJS.enc.Utf8.parse(this.keys);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 16,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            format: CryptoJS.format.Hex  // To Decrypt in Hex format
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

}