import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import {
  Pagination, GridColumnType, GridActionType,
  GridColumnDataType, FSCollections, DateFormats
} from "../../constants/constants";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { DatePipe } from "@angular/common"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Component({
  selector: 'inbrit-quotation-dashboard',
  templateUrl: './quotation-dashboard.component.html',
  styleUrls: ['./quotation-dashboard.component.scss']
})

export class QuotationDashboardComponent implements OnInit, OnDestroy {
  newPageIndex: number = 1;
  pageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  datePipe = new DatePipe('en-US');
  filterQuotationForm: FormGroup;
  toggleFilterVisiblility: boolean = false;
  quotationHistory: any = [];
  dataCount: number;
  startAfterQNo: [any]
  startAfterCDate: [any]
  endBeforeQNo: [any]
  endBeforeCDate: [any]
  user: any;
  afsSubscription: any;
  afsCountSubscription: any;
  submitted: boolean = false;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Quotation', dataField: 'quotationNumber', type: GridColumnType.DATA, },
      { title: 'User', dataField: 'createdByName', type: GridColumnType.DATA, },
      { title: 'From', dataField: 'fromPortName', type: GridColumnType.DATA, },
      { title: 'To', dataField: 'toPortName', type: GridColumnType.DATA, },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      { title: '', dataField: 'bookingDone', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      {
        title: 'Action', dataField: 'quotationId', type: GridColumnType.ACTION, actions: [
          { event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility' }
        ]
      }
    ];

  constructor(private afs: AngularFirestore, private dialog: MatDialog,
    private formBuilder: FormBuilder, private router: Router,
    private encryptionService: EncryptionService, private loaderService: LoaderService,
    private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.initializeForm();
    this.loaderService.display(true);
    this.getQuotationCount();
    this.getQuotations();
  }

  ngOnDestroy() {
    // UnSubscription of AFS is mandatory because of Once you have subscribed to AFS, it will continue
    // to listen the AFS changes even after page/url is changed. Also if the user is logged out 
    // then listening to the AFS changes will throw an exception of InSufficient permission.
    this.afsSubscription.unsubscribe();
    this.afsCountSubscription.unsubscribe();
  }

  initializeForm(): void {
    this.filterQuotationForm = this.formBuilder.group({
      fromDate: [new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      toDate: [new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]
    });

    this.user = this.userInfoService.getLoggedInUser();
  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterQuotationForm.controls; }

  getQuotationCount(): void {
    this.afsCountSubscription = this.afs.collection<any>('Counts').doc('Quotations_' + this.user.countryOfRegistration)
      .valueChanges()
      .subscribe(res => {
        this.dataCount = res['count'];
      });
  }

  getQuotations(): void {
    if (this.afsSubscription != undefined) {
      this.afsSubscription.unsubscribe();
    }

    // GETTING DATA WHEN FIRST LOAD OF SAME PAGE RELOAD
    if (this.pageIndex == this.newPageIndex) {
      this.afsSubscription = this.afs.collection<any>(FSCollections.QUOTATIONS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .limit(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
    // GETTING DATA FOR NEXT PAGE LOAD
    else if (this.newPageIndex > this.pageIndex) {
      this.afsSubscription = this.afs.collection<any>(FSCollections.QUOTATIONS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .startAfter(this.startAfterQNo)
          // .startAt(this.startAfterCDate)
          // .startAfter(this.startAfterCDate)
          // .startAfter(this.startAfterQNo)
          .limit(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
    // GETTING DATA FOR PREVIOUS PAGE LOAD
    else {
      this.afsSubscription = this.afs.collection<any>(FSCollections.QUOTATIONS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .endBefore(this.endBeforeQNo)
          .limitToLast(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
  }

  manageFSResponse(res): void {
    this.pageIndex = this.newPageIndex;

    if (res.length > 0) {
      res.forEach(element => {
        element['bookingDone'] = element.bookingId != 0 ? 'Booked' : '';
        element['createdDate'] = this.datePipe.transform(element['createdDate'].toDate(), DateFormats.STANDARD_DATE_TIME);
        element['class'] = {
          bookingDone: 'is-green p-2 font-bold'
        }
      });

      this.startAfterCDate = res[res.length - 1]["createdDate"];
      this.startAfterQNo = res[res.length - 1]["quotationNumber"];
      this.endBeforeCDate = res[0]["createdDate"];
      this.endBeforeQNo = res[0]["quotationNumber"];

      this.quotationHistory = res;
    }
    this.loaderService.display(false);
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewQuotationDetail(evt.data.quotationId);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.newPageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.loaderService.display(true);
    this.getQuotations();
  }

  viewQuotationDetail(quotationId: number): void {
    this.router.navigate(['user/quotations', this.encryptionService.encrypt(quotationId)]);
  }
}
