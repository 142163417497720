import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptionService } from "../services/encryption/encryption.service";
import { UserRoleConstants, KycStatusConstants, ProfileStatusConstants } from "../constants/constants";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'inbrit-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  updateUserForm: FormGroup;
  countries: [any];
  states: [any];
  userId: number = 0;
  submitted: boolean;
  confirmPasswordError: boolean;
  countryId: number;
  stateId: number;
  countryCode: String;
  stateCode: String;
  userRoles: Array<{ id: number, role: string, description: string }> = [];
  userTitles: Array<{ id: number, title: string, isActive: boolean }> = [];
  KycStatusConstants = KycStatusConstants;
  ProfileStatusConstants = ProfileStatusConstants;
  datePipe = new DatePipe('en-US');
  isEdit: boolean = false;
  pageTitle: string;
  routeUrl: string;
  showResendBtn: boolean = false;
  UserRoleConstants = UserRoleConstants;
  appearance: string = 'fill';
  appearanceStatus: string = 'fill';
  today: Date = new Date();
  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService, private router: Router) {
    this.activatedRoute.params.subscribe(params => { this.userId = params['id'] }
    );


    if (this.userId != 0) {
      this.isEdit = true;
      this.appearance = 'outline';
      this.appearanceStatus = 'outline';
    }
  }

  ngOnInit() {
    this.userRoles = UserRoleConstants.USER_ROLES;
    this.today = new Date(this.today.setHours(0, 0, 0, 0));

    this.initializeForm();
    this.getCountries();
    this.getUserTitles();
    this.getUserDetials();

    if (this.router.url.includes('customer-list')) {
      this.pageTitle = "Customer Detail";
      this.routeUrl = "/user/customer-list"
    }
    else if (this.router.url.includes('user-list')) {
      this.routeUrl = "/user/user-list"
      this.pageTitle = "Inbrit User Detail";
    }
  }

  // convenience getter for easy access to form fields
  get regForm() { return this.updateUserForm.controls; }


  initializeForm() {

    this.updateUserForm = this.formBuilder.group({
      countryOfRegistration: [{ value: '', disabled: true }, [Validators.required]],
      id: ['', []],
      roleId: [{ value: '', disabled: true }, [Validators.required]],
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      profilePhoto: ['', []],
      mobileCode: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      registrationStatus: [{ value: '' }, []],
      companyName: ['', [Validators.required]],
      companyDepartment: ['', [Validators.required]],
      companyWebsite: [''],
      companyAddress: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      landlineCode: ['', [Validators.required]],
      landlineNumber: ['', [Validators.required]],
      kycStatusId: ['', [Validators.required]],
      kycDueDate: ['', []]
    });

    this.regForm.kycStatusId.valueChanges.subscribe(value => {
      if ([KycStatusConstants.KYC_PENDING, KycStatusConstants.KYC_APPROVAL_PENDING,].includes(value)) {
        this.regForm.kycDueDate.setValidators([Validators.required]);
      } else {
        this.regForm.kycDueDate.setValidators(null);
      }
      this.regForm.kycDueDate.updateValueAndValidity();
    });
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
    });
  }

  getStates(countryId: number) {
    var selectedCountry = this.countries.filter(function (item) { return item.id == countryId });
    if (selectedCountry.length > 0) {
      this.countryCode = selectedCountry[0].countryCode;
    }

    this.httpService.httpGet(RouteConstants.MASTER_getAllStates, { 'countryId': countryId }, false).subscribe(res => {
      this.states = res['data'];
    });
  }

  setStateCode(stateId: number): void {
    var selectedState = this.states.filter(function (item) { return item.id == stateId });
    if (selectedState.length > 0) {
      this.stateCode = selectedState[0].stateCode;
    }
  }

  getUserTitles() {
    this.httpService.httpGet(RouteConstants.MASTER_getUserTitleMasters, null, false).subscribe(res => {
      this.userTitles = res['data'];
    });
  }

  getUserDetials(): void {
    this.httpService.httpGet(RouteConstants.ACCOUNT_getUser, { 'UserId': this.encryptionService.decrypt(this.userId) }).subscribe(res => {
      if (res['success'] == true) {
        var userDetails = res['data'];
        this.showResendBtn = userDetails.statusId == ProfileStatusConstants.PENDING_EMAIL_VERIFICATION ? true: false;
        var companyDetails = res['data']['userCompanyDetail'];

        this.updateUserForm.controls['countryOfRegistration'].disable();

        this.updateUserForm.patchValue({
          // User Details
          countryOfRegistration: userDetails.countryOfRegistration,
          id: userDetails.id,
          roleId: userDetails.roleId,
          title: userDetails.titleId,
          // userName: userDetails.userName,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          profilePhoto: userDetails.profilePhoto,
          mobileCode: userDetails.mobileCode,
          mobileNumber: userDetails.mobileNumber,
          registrationStatus: userDetails.statusId,
          kycStatusId: userDetails.kycStatusId,
          kycDueDate: userDetails.kycDueDate,

          // Company Details
          companyName: companyDetails.companyName,
          companyDepartment: companyDetails.companyDepartment,
          companyWebsite: companyDetails.website,
          companyAddress: companyDetails.companyAddress,
          country: companyDetails.countryId,
          state: companyDetails.stateId,
          city: companyDetails.city,
          zipCode: companyDetails.zipCode,
          landlineCode: companyDetails.landlineCode,
          landlineNumber: companyDetails.landlineNumber
        });

        //Disable KYC Status dropdown for Pending KYC       
        if (userDetails.kycStatusId == KycStatusConstants.KYC_PENDING) {
          this.updateUserForm.controls['kycStatusId'].disable();
          this.appearanceStatus = 'fill';

        }
        this.countryCode = companyDetails.countryCode;
        this.stateCode = companyDetails.stateCode;
        this.getStates(companyDetails.countryId);
      }
    });
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (!this.updateUserForm.valid) {
      return;
    }
    this.updateUserDetails();

  }

  updateUserDetails(): void {

    var param = {
      'Id': this.encryptionService.decrypt(this.userId),
      'CountryOfRegistration': this.regForm.countryOfRegistration.value,
      // 'UserName': this.regForm.userName.value,
      'TitleId': this.regForm.title.value,
      'FirstName': this.regForm.firstName.value,
      'LastName': this.regForm.lastName.value,
      'Email': this.regForm.email.value,
      'MobileCode': this.regForm.mobileCode.value,
      'MobileNumber': this.regForm.mobileNumber.value,
      'StatusId': this.regForm.registrationStatus.value,
      'KycStatusId': this.regForm.kycStatusId.value,
      'KycDueDate': this.datePipe.transform(this.regForm.kycDueDate.value, 'yyyy-MM-dd'),
      'UserCompanyDetail': {
        'CompanyName': this.regForm.companyName.value,
        'CompanyDepartment': this.regForm.companyDepartment.value,
        'Website': this.regForm.companyWebsite.value,
        'CompanyAddress': this.regForm.companyAddress.value,
        'CountryId': this.regForm.country.value,
        'CountryCode': this.countryCode,
        'StateCode': this.stateCode,
        'StateId': this.regForm.state.value,
        'City': this.regForm.city.value,
        'ZipCode': this.regForm.zipCode.value,
        'LandlineCode': this.regForm.landlineCode.value,
        'LandlineNumber': this.regForm.landlineNumber.value
      }
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_updateUserDetails, param).subscribe(res => {

    });

  }

  getRegistrationStatus(statusId: number): string {
    var statusText = "";
    var status = ProfileStatusConstants.PROFILE_STATUSES.filter(m => { return m.id == statusId });
    if (status.length > 0) {
      statusText = status[0].description;
    }
    return statusText;
  }

  resendEmail(){
    this.httpService.httpGet(RouteConstants.ACCOUNT_resendVerificationEmail + "?CustomerId=" +  this.encryptionService.decrypt(this.userId),null).subscribe(res =>{});
  }

}
