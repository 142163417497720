import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EncryptionService } from '../services/encryption/encryption.service';
import { UserRoleConstants } from '../constants/constants';

@Component({
  selector: 'inbrit-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  roleId: number = UserRoleConstants.CUSTOMER;
  constructor(private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService) {
    this.activatedRoute.params.subscribe(params => { this.roleId = this.encryptionService.decrypt(params['id']) });
  }

  ngOnInit() {
  }

}
