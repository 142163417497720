import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { NotificationService } from "../../services/notification/notification.service";
import { UserInfoService } from "../../services/userInfo/user-info.service";
import { RouteConstants } from "../../constants/route-constants";
import { GridColumnType, GridActionType, Pagination, GridColumnDataType, NotificationTypeConstants } from 'src/app/constants/constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmBoxComponent } from "../../shared/confirm-box/confirm-box.component"
import { FormGroup, FormBuilder } from '@angular/forms';
import { CacheService } from 'src/app/services/cache-service/cache.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'inbrit-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.scss']
})
export class NotificationDashboardComponent implements OnInit, OnDestroy {

  filterNotificationsForm: FormGroup;
  pageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  notificationList: Array<any>;
  dataCount: number = 0;
  submitted: boolean = false;
  chktoggleSelect: boolean = false;
  filterDialogRef: MatDialogRef<{}, any>;
  totalFiltersApplied: number = 0;
  user: any;
  notificationTypes: any = NotificationTypeConstants.NOTIFICATION_TYPES;
  uniqueCacheKey: string = 'NotificationDashboardComponent-filter';
  subscription: Subscription;

  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Sr #', dataField: 'srno', type: GridColumnType.DATA, },
      { title: '', dataField: 'selected', type: GridColumnType.SELECT, },
      { title: 'Title', dataField: 'notificationTitle', type: GridColumnType.DATA, },
      { title: 'Message', dataField: 'notificationMessage', type: GridColumnType.DATA, },
      { title: 'Sender', dataField: 'senderName', type: GridColumnType.DATA, },
      { title: 'Received', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      {
        title: 'Action', dataField: 'notificationParam', type: GridColumnType.ACTION, actions: [
          {
            event: 'viewDetail', type: GridActionType.ICON, title: 'View Detail', class: 'visibility',
            conditionalDisplay: { dataField: 'isValidAction', value: true }
          },
        ]
      }
    ];


  constructor(private formBuilder: FormBuilder, private httpService: HttpService,
    private notificationService: NotificationService, private dialog: MatDialog,
    private userInfoService: UserInfoService, private cacheService: CacheService) {

    this.subscription = this.notificationService.notifyNotificationChanges().subscribe(res => {
      this.getNotifications();
    });

  }

  ngOnInit() {
    this.user = this.userInfoService.getLoggedInUser();
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterNotificationsForm = this.formBuilder.group({
      isRead: [cachedFilter != null ? cachedFilter.value.isRead.value : [], []],
      notificationType: [cachedFilter != null ? cachedFilter.value.notificationType.value : [], []]
    });
    this.getNotifications();
  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }


  getNotifications(): void {
    this.totalFiltersApplied = 0;

    if (this.filterFormFields.isRead.value != null && this.filterFormFields.isRead.value != '') {
      this.totalFiltersApplied++;
    }

    if (this.filterFormFields.notificationType.value != null && this.filterFormFields.notificationType.value != '') {
      this.totalFiltersApplied++;
    }

    var param = {
      'PageIndex': this.pageIndex,
      'PageSize': this.pageSize,
      'IsRead': this.filterFormFields.isRead.value.length != 1 ? null : this.filterFormFields.isRead.value[0],
      'NotificationTypeIds': this.filterFormFields.notificationType.value
    };

    this.httpService.httpPost(RouteConstants.NOTIFICATION_getAllReceivedNotifications, param).subscribe(res => {
      if (res['success'] == true) {
        this.notificationList = res['data'];
        this.dataCount = res['count'];
        let count = 0;
        this.notificationList.forEach(item => {
          item['srno'] = this.pageSize * this.pageIndex - this.pageSize + count + 1
          item['isValidAction'] = [5001, 5002, 5003, 5004, 5005, 5006, 5009, 5010].indexOf(item['notificationType']) != -1;
          item['createdDate'] = this.userInfoService.getLocalizedFormattedHours(item['createdDate']);
          item['senderName'] = item['sender'] != null ? item.sender.firstName + ' ' + item.sender.lastName : item['senderName'];

          item['colStyle'] = {
            notificationTitle: { 'font-weight': (item['isRead'] == false ? 'bold' : 'normal)') },
            notificationMessage: { 'font-weight': (item['isRead'] == false ? 'bold' : 'normal)') },
            senderName: { 'font-weight': (item['isRead'] == false ? 'bold' : 'normal)') },
            createdDate: { 'font-weight': (item['isRead'] == false ? 'bold' : 'normal)') },
            srno: { 'font-weight': (item['isRead'] == false ? 'bold' : 'normal)') }

          };
          count += 1;
        });
      }
    });
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'viewDetail':
        {
          evt.data.roleId = this.user.roleId;
          if (this.subscription != undefined) {
            this.subscription.unsubscribe();
          }
          this.notificationService.navigateToAction(evt.data);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getNotifications();
  }

  toggleSelectAll(isCheck: boolean): void {
    this.notificationList.forEach(item => {
      item['checked'] = isCheck;
    });
    this.chktoggleSelect = isCheck;
  }

  markAsRead(): void {
    var param = [];
    var itemsToMarkAsRead = this.notificationList.filter(m => m.checked == true);
    if (itemsToMarkAsRead.length > 0) {
      this.submitted = true;
      itemsToMarkAsRead.forEach(item => {
        param.push({ 'Id': item.id, 'IsRead': true, 'IsActive': item.isActive });
      });

      this.httpService.httpPost(RouteConstants.NOTIFICATION_markReadOrDelete, param).subscribe(res => {
        this.submitted = false;
        this.chktoggleSelect = false;
        this.notificationService.notifyChanges();
      });
    }
  }

  deleteNotifications(): void {
    var param = [];
    var itemsToDelete = this.notificationList.filter(m => m.checked == true);
    if (itemsToDelete.length > 0) {
      itemsToDelete.forEach(item => {
        param.push({ 'Id': item.id, 'IsRead': item.isRead, 'IsActive': false });
      });
      this.submitted = true;
      const dialogRef = this.dialog.open(ConfirmBoxComponent, {
        width: '450px',
        data: { message: "The selected notifications will be deleted. Are you sure to continue?" }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.httpService.httpPost(RouteConstants.NOTIFICATION_markReadOrDelete, param).subscribe(res => {
            this.submitted = false;
            this.chktoggleSelect = false;
            this.notificationService.notifyChanges();
          });
        }
        else {
          this.submitted = false;
        }
      });
    }
  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterNotificationsForm.controls; }

  filterFormSubmit() {
    this.pageIndex = 1;
    this.getNotifications();
    this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterNotificationsForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.pageIndex = 1;
    this.filterNotificationsForm.patchValue({
      isRead: [false],
      notificationType: []
    })
    this.filterDialogRef.close();
    this.notificationList = [];
    this.getNotifications();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

}
