import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../services/loader/loader.service";
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { } from "rxjs";
import { DataTransferService } from "../../services/data-transfer/data-transfer.service";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";

@Component({
  selector: 'inbrit-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPassForm: FormGroup;
  submitted = false;
  error = { 'isError': false, 'message': '' };
  returnUrl: string;
  showPassword: boolean = false;
  user: any;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private loaderService: LoaderService,
    private httpService: HttpService, private dataTransferService: DataTransferService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.resetPassForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      emailOTP: ['', Validators.required],
    });

    this.user = this.dataTransferService.getData();
    if (this.user != null) {
      this.error.message = "A password reset OTP has been sent to your email id : " + this.user.email;
      this.error.isError = true;
    }
    else {
      this.router.navigate(['/login'])
    }
  }

  // convenience getter for easy access to form fields
  get formFields() { return this.resetPassForm.controls; }

  public onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    }

    let password = this.resetPassForm.controls.password.value;
    let repeatPassword = this.resetPassForm.controls.confirmPassword.value;

    if (password != repeatPassword) {
      this.resetPassForm.get('confirmPassword').setErrors({'confirmPasswordError':  repeatPassword });
      return;
    }
    else {
      this.resetPassForm.get('confirmPassword').setErrors({'confirmPasswordError': null });
    }
    this.resetPassForm.get('confirmPassword').updateValueAndValidity();

    this.resetPassword();
  }

  resetPassword(): void {
    this.loaderService.display(true);
    var sha1 = require('sha1');
    var dataParam =
    {
      "UserId": this.user.id,
      "Password": sha1(this.formFields.password.value),
      "PasswordResetToken": this.formFields.emailOTP.value
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_resetPassword, dataParam).subscribe(res => {
      var data = res['data'];
      if (res['success'] == true && data != null) {
        this.toastMessageService.toast('top', 'center', 'success', "Password has been modified successfully.");
      }
      this.loaderService.display(false);
    });
  }
}
