import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators }
  from '@angular/forms';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { GridColumnType, GridActionType, Pagination, CarrierTypeConstants, CountryConstants, DateFormats } from 'src/app/constants/constants';
import { ModalDialogService } from "../../services/modal-dialog/model-dialog.service";
import * as saveAs from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'inbrit-process-ratesheet',
  templateUrl: './process-ratesheet.component.html',
  styleUrls: ['./process-ratesheet.component.scss']
})
export class ProcessRatesheetComponent implements OnInit {
  processRatesheetForm: FormGroup;
  countries: [any];
  carriers= [];
  snapshotListOriginal: [any];
  snapshotList: any;
  filteredSnapshotList: any;
  processRatesheetErrors: any;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  isLoading: boolean = false;
  submitted: boolean = false;
  fileToUpload: File = null;
  file_path: string = '';
  carrierId: number;
  countryId: number;
  processRatesheetId: number;
  isEdit: boolean = false;
  appearance: string = 'outline';
  user: any;
  filterDialogRef: MatDialogRef<{}, any>;
  filterSnapshotListForm: FormGroup;
  totalCount: number;
  totalValidCount: number;
  totalInValidCount: number;
  datePipe = new DatePipe('en-US');


  @ViewChild('fileUploadButton') fileUploadButton;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Sheet', dataField: 'sheetName', type: GridColumnType.DATA, },
      { title: 'Type', dataField: 'type', type: GridColumnType.DATA, },
      { title: 'Valid', dataField: 'isValid', type: GridColumnType.DATA, },
      {
        title: 'View', dataField: '', type: GridColumnType.ACTION, actions: [
          {
            event: 'viewErrors', type: GridActionType.ICON, title: 'View Erros', class: 'visibility',
            conditionalDisplay: { dataField: 'isValid', value: 'No' }
          }
        ]
      }
    ];

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, private activatedRoute: ActivatedRoute,
    private modalDialogService: ModalDialogService, private encryptionService: EncryptionService, private loaderService: LoaderService,
    private userInfoService: UserInfoService, private dialog: MatDialog) {
    this.activatedRoute.params.subscribe(params => {
      this.processRatesheetId = params['id']
      var processId = "";
      this.activatedRoute.params.subscribe(params => { processId = params['id'] });

      if (processId != undefined && processId != "" && processId != "new") {
        this.processRatesheetId = parseInt(this.encryptionService.decrypt(processId));
        this.isEdit = true;
        this.appearance = 'fill';
      }

      this.user = this.userInfoService.getLoggedInUser();
    })
  }

  ngOnInit() {

    this.initializeForm();
    this.getCountries();
    this.getCarriers();

    if (this.isEdit == true) {
      this.processRatesheetEditData();
    }
  }

  // convenience getter for easy access to form fields
  get ratesheetForm() { return this.processRatesheetForm.controls; }


  initializeForm() {

    this.processRatesheetForm = this.formBuilder.group({
      countryId: [{ value: this.user.countryOfRegistration, disabled: true }, [Validators.required]],
      countryCode: ['', []],
      carrierId: [{ value: null, disabled: this.isEdit }, [Validators.required]],
      carrierCode: ['', []],
      carrierName: ['', []],
      file: ['', [Validators.required]]
    });

    this.filterSnapshotListForm = this.formBuilder.group({
      isValid: ['', []]
    });
  }

  get filterFormFields() { return this.filterSnapshotListForm.controls; }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
      this.setCountry(this.user.countryOfRegistration);
    });
  }

  getCarriers() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null, false).subscribe(res => {
          
      this.carriers= res['data'].filter(m => { return (m.id == CarrierTypeConstants.APL || m.id == CarrierTypeConstants.MAERSK || m.id == CarrierTypeConstants.MSC)  });
    
    });
  }

  setCountry(countryId: number) {
    var selectedCountry = this.countries.filter(m => { return m.id == countryId })[0];
    this.processRatesheetForm.patchValue({
      countryCode: selectedCountry.countryCode
    });
  }

  setCarrier(carrierId: number) {
    var selectedCarrier = this.carriers.filter(m => { return m.id == carrierId })[0];
    this.processRatesheetForm.patchValue({
      carrierCode: selectedCarrier.carrierCode,
      carrierName: selectedCarrier.carrierName,
    });
    this.carrierId= selectedCarrier.id;

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.submitted=false;
  }

  gridEventHandler(evt: any): void {

    switch (evt.event) {
      case 'viewErrors':
        {
          var errors = evt.data.errorMessage != null && evt.data.errorMessage != '' ? evt.data.errorMessage.split(',') : [];

          if (errors.length > 0) {

            var errorMessages = '<ul>';
            errors.forEach(item => {
              errorMessages += '<li>' + item + "</li>";
            });
            errorMessages += '</ul>';

            this.modalDialogService.openDialog("Errors", errorMessages,
              [{ title: 'Ok', result: 'true', type: 'accent' }]
            );
          }
          break;
        }
    }
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (!this.processRatesheetForm.valid) {
      
      return;
    }

    this.isLoading = true;
    this.snapshotList = null;
    this.file_path = '';
    const formData: FormData = new FormData();
    formData.append('CountryId', this.ratesheetForm.countryId.value);
    formData.append('CountryCode', this.ratesheetForm.countryCode.value);
    formData.append('CarrierId', this.ratesheetForm.carrierId.value);
    formData.append('CarrierCode', this.ratesheetForm.carrierCode.value);
    formData.append('CarrierName', this.ratesheetForm.carrierName.value);
    formData.append('File.File', this.fileToUpload, this.fileToUpload.name);
    this.processRatesheetErrors = [];
    this.httpService.httpPostFormData(RouteConstants.RATESHEET_PROCESSOR_processRatesheet, formData).subscribe(res => {
      var data = res["data"];

      if (res["code"] != 406) {
        if (data != null) {

          this.snapshotListOriginal = data["snapshotList"];
          this.dataCount = this.snapshotListOriginal.length;
          this.file_path = data['savedFilePath'] != null ? data['savedFilePath'] : '';

          this.totalCount =  this.snapshotListOriginal.length;
          this.totalValidCount = this.snapshotListOriginal.filter(x=>x.isValid == true).length;
          this.totalInValidCount = this.snapshotListOriginal.filter(x=>x.isValid != true).length;
          // Column/Addition Modification
          this.modifyDisplayColumns(this.ratesheetForm.carrierId.value, this.ratesheetForm.countryId.value);

          this.snapshotListOriginal.forEach(item => {
            this.addCellStyles(item);
            item['isValid'] = item.isValid == true ? 'Yes' : 'No';
            item['type'] = item['size'] + ' ' + item['type']
          });
          this.filteredSnapshotList = this.snapshotListOriginal;
          this.paginationEventHandler({ 'pageIndex': this.pageIndex, 'pageSize': this.pageSize });

        }
      }
      // Status 406 = NotAcceptable
      else if (res["code"] == 406) {
        this.processRatesheetErrors = [];
        var errors = res["message"] != null && res["message"] != '' ? res["message"].split(",") : [];
        errors.forEach(item => {
          this.processRatesheetErrors.push({ 'errorMessage': item });
        });
      }
      this.submitted = false;
    });
  }

  paginationEventHandler(evt: any) {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    var startItem = ((this.pageIndex - 1) * this.pageSize);
    var endItem = (this.pageSize * this.pageIndex);
    this.snapshotList = this.filteredSnapshotList.slice(startItem, endItem);
  }

  triggerFileUpload(event: any) {
    event.stopPropagation();
    this.fileUploadButton.nativeElement.click();
  }

  downloadStandardFile() {
    if (this.file_path != '')
      saveAs(this.file_path, this.file_path.split('/').pop());
  }

  downloadSampleFile()
  {
    var filepath = "/assets/excelsample/" + this.ratesheetForm.countryCode.value + "/" + this.ratesheetForm.carrierCode.value + "/" + this.ratesheetForm.carrierCode.value + ".xlsx";
    saveAs(filepath, filepath.split('/').pop());
  }

  processRatesheetEditData() {
    this.loaderService.display(true);
    var param = {
      "Id": this.processRatesheetId
    }

    this.httpService.httpGet(RouteConstants.RATESHEET_PROCESSOR_getProcessRatesheetById, param).subscribe(res => {

      if (res['success'] == true) {
        var data = res["data"];
        this.snapshotListOriginal = data["snapshotList"];
        this.dataCount = this.snapshotListOriginal.length;
        this.carrierId = parseInt(data["carrierId"]);
        this.countryId = parseInt(data["countryId"]);
        this.file_path = data['savedFilePath'] != null ? data['savedFilePath'] : '';
       
        this.totalCount =  this.snapshotListOriginal.length;
        this.totalValidCount = this.snapshotListOriginal.filter(x=>x.isValid == true).length;
        this.totalInValidCount = this.snapshotListOriginal.filter(x=>x.isValid != true).length;

        // Column/Addition Modification
        this.modifyDisplayColumns(this.carrierId, this.countryId);
        this.setCarrier(this.carrierId);
        
        this.snapshotListOriginal.forEach((item,index) => {
          this.addCellStyles(item);
          item['isValid'] = item.isValid == true ? 'Yes' : 'No'
          item['type'] = item['size'] + ' ' + item['type'],
          // Here, we are formatting only first iteration of date as this is being used for display purpose.
          item['effectiveDate'] = index == 0 ? this.formatDate(item['effectiveDate']) : item['effectiveDate'],
          item['expiryDate'] = index == 0 ? this.formatDate(item['expiryDate']) : item['expiryDate']
        });

        this.filteredSnapshotList = this.snapshotListOriginal;
        this.paginationEventHandler({ 'pageIndex': this.pageIndex, 'pageSize': this.pageSize });
      }

      this.loaderService.display(false);
    });
  }

  formatDate(date: string): string {
    var formattedDate = '';
    var splitDate = date.split("/");
    if(splitDate.length == 3) {
      var dateObj = new Date(parseInt(splitDate[2]), parseInt(splitDate[1]) -1, parseInt(splitDate[0]));
      formattedDate = this.datePipe.transform(dateObj, DateFormats.STANDARD_DATE);
      return formattedDate;
    }
    else {
      return date;
    }
  }

  addCellStyles(item: any) {
    item['colStyle'] = {
      isValid: {
        'background-color': (item['isValid'] == true ? 'rgba(40,167,69,.12549)' : 'rgba(255,7,58,.12549)'),
        'color': (item['isValid'] == true ? '#28a745' : '#ff073a'), 'padding': '6px 14px 6px 14px', 'word-break': 'normal', 'cursor': 'pointer', 'font-weight': 'bold'
      }
    };
  }

  modifyDisplayColumns(carrierId: number, countryId: number): void {
    var newColumns: Array<{ title: string, dataField: string, type: string }>;

    switch (carrierId) {
      case CarrierTypeConstants.APL:
        {
          newColumns = [
            { title: 'Place Of Receipt', dataField: 'loadpointCode', type: GridColumnType.DATA },
            { title: 'POL', dataField: 'fromPortCode', type: GridColumnType.DATA },
            { title: 'POD', dataField: 'toPortCode', type: GridColumnType.DATA },
            { title: 'Place Of Delivery', dataField: 'dischargeCode', type: GridColumnType.DATA }
          ];
          break;
        }
      case CarrierTypeConstants.MAERSK:
        {
          newColumns = [
            { title: 'Receipt', dataField: 'loadpointCode', type: GridColumnType.DATA },
            { title: 'Load Port', dataField: 'fromPortCode', type: GridColumnType.DATA },
            { title: 'Discharge Port', dataField: 'toPortCode', type: GridColumnType.DATA },
            { title: 'Delivery', dataField: 'dischargeCode', type: GridColumnType.DATA }
          ];
          break;
        }
      case CarrierTypeConstants.MSC:
        {
          if (countryId == CountryConstants.UK) {
            newColumns = [
              { title: 'Load Point', dataField: 'loadpointCode', type: GridColumnType.DATA },
              { title: 'From Port Code', dataField: 'fromPortCode', type: GridColumnType.DATA },
              { title: 'To Port Code', dataField: 'toPortCode', type: GridColumnType.DATA },
              { title: 'Discharge Port', dataField: 'dischargeCode', type: GridColumnType.DATA }
            ];
          }
          else if (countryId == CountryConstants.US) {
            newColumns = [
              { title: 'Origin', dataField: 'loadpointCode', type: GridColumnType.DATA },
              { title: 'PORT of Load', dataField: 'fromPortCode', type: GridColumnType.DATA },
              { title: 'Port of Discharge', dataField: 'toPortCode', type: GridColumnType.DATA }
            ];
          }
          break;
        }
    }
    var insertIndex = 2;
    newColumns.forEach(col => {
      this.columns.splice(insertIndex++, 0, col);
    });
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  filterFormSubmit() {
    this.filterSnapshotList();
    this.filterDialogRef.close();
  }

  clearFilter(): void {
    this.filterSnapshotListForm.reset();
    this.filterSnapshotList();
  }

  filterSnapshotList(): void {
    var param = this.filterFormFields.isValid.value;
    if (param != null && param != "null") {
      this.filteredSnapshotList = this.snapshotListOriginal.filter(m => { return m.isValid == (param == "true" ? "Yes" : "No") });
      this.dataCount = this.filteredSnapshotList.length;
    }
    else {
      this.filteredSnapshotList = this.snapshotListOriginal;
      this.dataCount = this.filteredSnapshotList.length;
    }
    this.filteredSnapshotList;
    this.pageIndex = 1;
    this.paginationEventHandler({ 'pageIndex': this.pageIndex, 'pageSize': this.pageSize });
  }
}
