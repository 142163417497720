export class Utils {
  public static groupBy(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  public static calculateSellRate(allInUsd: string, margin: string, operation: string): string {
    var result;
    switch (operation) {
      case '+':
        {
          result = (parseInt(allInUsd) + parseInt(margin));
          break;
        }
      case '-':
        {
          result = (parseInt(allInUsd) - parseInt(margin));
          break;
        }
      case '*':
        {
          result = (parseInt(allInUsd) * parseInt(margin));
          break;
        }
    }
    var lastDigit = result % 10;
    if (lastDigit > 0 && lastDigit < 5) {
      result += (5 - lastDigit);
    }
    else if (lastDigit > 5 && lastDigit <= 9) {
      result += (10 - lastDigit);
    }
    return result.toString();
  }
}