import { UserRoleConstants } from "src/app/constants/constants";
import { EncryptionService } from "src/app/services/encryption/encryption.service";
import { UserInfoService } from "src/app/services/userInfo/user-info.service";

interface ChildRoute {
    path: string;
    title: string;
    icon: string;
    class: string;
    dashboardIcon?: string;
  }
  export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    dashboardIcon?: string;
    showChildren?: boolean;
    children: ChildRoute[]
  }
  
  export class MenuItems {
    public ROUTES: RouteInfo[] = [
    ];
  
    public constructor(private encryptionService: EncryptionService, private userInfoService: UserInfoService) {
      var user = this.userInfoService.getLoggedInUser();
  
      if (user.roleId == UserRoleConstants.USER) {
        this.ROUTES = [
          { path: '/user/dashboard', title: 'Home', icon: 'home', class: '', dashboardIcon: '', showChildren: false, children: [] },
          { path: '/user/user-profile/' + this.encryptionService.encrypt(user.id), title: 'Profile', icon: 'perm_identity', class: '', dashboardIcon: 'profile', showChildren: false, children: [] },
          { path: '/user/quotation-request', title: 'Check Rate', icon: 'help', class: '', dashboardIcon: 'check_rate', showChildren: false, children: [] },
          {
            path: '', title: 'Dashboard', icon: 'dashboard', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/quotations', title: 'Quotation Dashboard', icon: 'equalizer', class: '', dashboardIcon: 'quotations' },
              { path: '/user/bookings', title: 'Booking Dashboard', icon: 'assessment', class: '', dashboardIcon: 'bookings' },
            ]
          },
          {
            path: '', title: 'Customer Onboarding', icon: 'input', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/invite-customer', title: 'Invite Customer', icon: 'person_add', class: '', dashboardIcon: 'invite_customer'},
              { path: '/user/pending-approval', title: 'Pending Approval', icon: 'update', class: '', dashboardIcon: 'pending_approval' },
              { path: '/user/pending-confirmation', title: 'Pending Confirmation', icon: 'check_circle', class: '', dashboardIcon: 'pending_confirmation' },
              { path: '/user/pending-kyc', title: 'Pending KYC Approval', icon: 'assignment', class: '', dashboardIcon: 'kyc' },
            ]
          },
          {
            path: '', title: 'Admin', icon: 'input', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/customer-list', title: 'Customer List', icon: 'supervised_user_circle', class: '', dashboardIcon: 'customer_list' },
              { path: '/user/user-list', title: 'Inbrit User List', icon: 'supervised_user_circle', class: '', dashboardIcon: 'user_list' },          ]
          },
          {
            path: '', title: 'Freight Sheet', icon: 'note_add', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/freights', title: 'Freight Rate Sheet', icon: 'rate_review', class: '', dashboardIcon: 'frieght_sheet' },
              { path: '/user/process-ratesheets', title: 'Process Rate Sheets', icon: 'library_books', class: '', dashboardIcon: 'process_rate_sheet' }
            ]
          },
          {
            path: '', title: 'Rate', icon: 'receipt', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/tiers', title: 'Tier Dashboard', icon: 'launch', class: '', dashboardIcon: 'tier' },
              { path: '/user/spot-buy', title: 'Spot Buy Dashboard', icon: 'book', class: '', dashboardIcon: '' },
              { path: '/user/spot-sell', title: 'Spot Rate Dashboard', icon: 'monetization_on', class: '', dashboardIcon: 'spot_rate' },
            ]
          },
          {
            path: '', title: 'Reports', icon: 'sort', class: '', dashboardIcon: '', showChildren: false,
            children: [
              { path: '/user/quotations-report', title: 'Quotation Report', icon: 'trending_up', class: '', dashboardIcon: '' },
              { path: '/user/bookings-report', title: 'Booking Report', icon: 'shopping_cart', class: '', dashboardIcon: '' },
              { path: '/user/tier-report', title: 'Tier Archive Report', icon: 'launch', class: '', dashboardIcon: '' },
            ]
          }
        ]
      }
      else if (user.roleId == UserRoleConstants.CUSTOMER) {
        this.ROUTES = [
          { path: '/customer/dashboard', title: 'Home', icon: 'home', class: '', dashboardIcon: '', children: [] },
          { path: '/customer/user-profile/' + this.encryptionService.encrypt(user.id), title: 'Profile', icon: 'perm_identity', class: '', dashboardIcon: 'profile', children: [] },
          { path: '/customer/kyc-details', title: 'KYC Details', icon: 'assignment', class: '', dashboardIcon: 'kyc', children: [] },
          { path: '/customer/quotation-request', title: 'Check Rate', icon: 'help', class: '', dashboardIcon: 'check_rate', children: [] },
          { path: '/customer/quotations-report', title: 'My Quotations', icon: 'trending_up', class: '', dashboardIcon: 'quotations', children: [] },
          { path: '/customer/bookings-report', title: 'My Bookings', icon: 'shopping_cart', class: '', dashboardIcon: 'bookings', children: [] },
        ]
      }
    }
  }
  