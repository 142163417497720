import {
  Component, OnInit, Input, Output, EventEmitter,
  ComponentFactoryResolver, ViewChild, EmbeddedViewRef
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { GridActionType, GridColumnDataType, GridColumnType, Pagination } from "../../constants/constants";
import { DynamicComponentContainerDirective } from '../../directives/dynamic-component-container/dynamic-component-container.directive';

@Component({
  selector: 'inbrit-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() dataSource: [any];
  @Input() isFirebaseTable: boolean = false;
  @Input() columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, rowChild?: { component: any, show: boolean },
    actions?: Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }>;
  @Input() totalPageCount: number;
  @Input() pageIndex: number;
  @Input() displaySrno: boolean = true;
  @Input() isFreightRate: boolean = false;
  @Output() gridEventHandler = new EventEmitter<any>();
  @Output() paginationEventHandler = new EventEmitter<any>();
  pageSize = Pagination.PageSize;
  pageDataOriginal: any;
  GridActionType = GridActionType;
  GridColumnDataType = GridColumnDataType;
  GridColumnType = GridColumnType;
  tableColSpan: number;
  @ViewChild(DynamicComponentContainerDirective) dynamicComponentContainer: DynamicComponentContainerDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.pageDataOriginal = this.dataSource;
    this.tableColSpan = this.columns.filter(x => x.actions == null).length;

    // Initialize the rowChild property for visibility
    if (this.dataSource != undefined && this.dataSource.length > 0) {

      this.dataSource.forEach(row => {
        row['selected'] = false;
        if (row.hasOwnProperty('rowChild')) {
          row.rowChild['show'] = false;
        }
      });
    }
  }

  gridEvent(row: any, evt: string, index: number) {
    // If row has a child then open the child element
    if (row.hasOwnProperty('rowChild')) {

      row.rowChild['show'] = !row.rowChild['show'];

      if (row.rowChild['show'] && !document.getElementById('row_' + index).hasChildNodes()) {

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(row.rowChild.component);

        let viewContainerRef = this.dynamicComponentContainer.viewContainerRef;

        // clear() method is used to clear the previous rendered element.
        // viewContainerRef.clear();

        let componentRef = viewContainerRef.createComponent(componentFactory);
        (<any>componentRef.instance).rowData = row;

        // Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;

        // Append DOM element to the specified row.
        document.getElementById('row_' + index).appendChild(domElem);
      }

    }


    this.gridEventHandler.emit({ 'data': row, 'event': evt });
  }

  paginationEvent(evt): void {
    this.paginationEventHandler.emit({ 'pageIndex': evt.pageIndex, 'pageSize': evt.pageSize });
  }

  sort(order: string, fieldType: string) {
    if (order != "desc") {
      this.dataSource.sort((a, b) => {
        if (!isNaN(a[fieldType]) && !isNaN(b[fieldType])) {
          return a[fieldType] - b[fieldType];
        }
        return a[fieldType] > b[fieldType] ? 1 : (b[fieldType] > a[fieldType] ? -1 : 0)
      });
    }
    else {
      this.dataSource.sort((a, b) => {
        if (!isNaN(a[fieldType]) && !isNaN(b[fieldType])) {
          return b[fieldType] - a[fieldType];
        }
        return b[fieldType] > a[fieldType] ? 1 : (a[fieldType] > b[fieldType] ? -1 : 0)
      });
    }
  }

}
