import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'inbrit-remark-popup',
  templateUrl: './remark-popup.component.html',
  styleUrls: ['./remark-popup.component.scss']
})
export class RemarkPopupComponent implements OnInit {

  message: string = "";
  heading: string = "";
  label: string = "";
  isDisabled: boolean = false;

  constructor(public dialogRef: MatDialogRef<RemarkPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.heading = data.heading;
      this.label = data.label;
      this.message = data.remark == null ? '' : data.remark;
      this.isDisabled = data.isDisabled
     }

  ngOnInit() {
  }

  onClick(val: boolean): void {
    var param = {
      Success: val && this.message != null && this.message != undefined && this.message != "",
      message: this.message
    };
    this.dialogRef.close(param);
  }

}
