import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from "../../services/loader/loader.service";
import { HttpService } from "../../services/http/http.service";
import { DeviceInfoService } from "../../services/device-info/device-info.service";
import { RouteConstants } from "../../constants/route-constants";
import { } from "rxjs";
import { UserRoleConstants } from 'src/app/constants/constants';
import { AuthService } from '../../services/auth/auth.service';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { CacheService } from 'src/app/services/cache-service/cache.service';
import { EncryptionService } from 'src/app/services/encryption/encryption.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'inbrit-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  error = { 'isError': false, 'message': '' };
  returnUrl: string;
  showPassword: boolean = false;
  countries: [any];
  profilePrefx = '/user';
  deviceInfo: any;
  uniqueCacheKey: string = 'LoginComponent-loginModel';
  cacheService: CacheService;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private loaderService: LoaderService,
    private httpService: HttpService, private deviceInfoService: DeviceInfoService,
    public authService: AuthService, private userInfoService: UserInfoService,
    private encryptionService: EncryptionService,
    @Inject(LOCAL_STORAGE) private storageService: StorageService) {
    this.deviceInfoService.initializeUserTrackingDetail();
    this.cacheService = new CacheService(this.storageService);
  }

  ngOnInit() {
    var cachedLoginModel = this.cacheService.get(this.uniqueCacheKey);
    this.loginForm = this.formBuilder.group({
      countryOfRegistration: [cachedLoginModel != null ? cachedLoginModel.value.countryOfRegistration.value : '', Validators.required],
      userName: [cachedLoginModel != null ? cachedLoginModel.value.userName.value : '', Validators.required],
      password: [cachedLoginModel != null ? this.encryptionService.decrypt(cachedLoginModel.value.password.value) : '', Validators.required],
      rememberMe: [cachedLoginModel != null ? cachedLoginModel.value.rememberMe.value : false, []]
    });

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || this.profilePrefx + '/dashboard';
    this.deviceInfo = this.deviceInfoService.getUserTrackingDetail();

    // Check if user is already logged in
    var user = this.userInfoService.getLoggedInUser();
    this.profilePrefx = '/user';
    if (user != undefined && user != null && user != 'null') {

      if (user.roleId == UserRoleConstants.CUSTOMER) {
        this.profilePrefx = '/customer';
      }
      // this.returnUrl = this.returnUrl || this.profilePrefx + '/user-profile/' + this.encryptionService.encrypt(JSON.parse(user)['id']);
      this.router.navigateByUrl(this.returnUrl).then(
        success => {
          if (!success)
            this.router.navigateByUrl(this.profilePrefx + '/dashboard');
        });
    }
    else {
      this.getCountries();
    }
  }

  // convenience getter for easy access to form fields
  get formFields() { return this.loginForm.controls; }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null).subscribe(res => {
      this.countries = res['data'];
    });
  }

  public onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.userLogin();
  }

  userLogin(): void {
    this.loaderService.display(true);
    var sha1 = require('sha1');
    var dataParam =
    {
      "user": {
        "CountryOfRegistration": this.formFields.countryOfRegistration.value,
        'CountryCode': this.countries.find(cnt => cnt.id == this.formFields.countryOfRegistration.value).countryCode,
        "Email": this.formFields.userName.value.trim(),
        "Password": sha1(this.formFields.password.value)
      },
      "traceUser": {
        "UserName": this.formFields.userName.value.trim(),
        "LoginFrom": 'web',
        "Os": this.deviceInfo.os,
        "OsVersion": this.deviceInfo.os_version,
        "Client": this.deviceInfo.browser,
        "IpAddress": this.deviceInfo.ipAddress
      }
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_userLogin, dataParam).subscribe(res => {
      var data = res['data'];
      if (data != null) {
        if (data['token'] == '' || data['roleId'] == 0) {
          this.error.isError = true;
          this.error.message = "Unauthorized, Contact admin.";
          this.loaderService.display(false);
        }
        else {
          this.userInfoService.setUser(data);
          this.authService.anonymousLogin();
          this.loaderService.display(false);
          if (data.roleId == UserRoleConstants.CUSTOMER) {
            this.profilePrefx = '/customer';
          }
          else {
            this.profilePrefx = '/user';
          }
          
          if (this.formFields.rememberMe.value) {
            // Encrypt the password before saving it to local storage object.
            this.loginForm.patchValue({
              password: this.encryptionService.encrypt(this.formFields.password.value)
            });

            var loginForm = this.cacheService.getFormValues(this.loginForm);
            this.cacheService.set(this.uniqueCacheKey, loginForm);
          }
          else {
            this.cacheService.delete(this.uniqueCacheKey);
          }

          this.router.navigateByUrl(this.returnUrl).then(
            success => {
              if (!success)
                this.router.navigateByUrl(this.profilePrefx + '/dashboard');
            });   
        }

      }
      else {
        this.error.isError = true;
        this.error.message = "Username and Password did not match.";
      }
      this.loaderService.display(false);
    });
  }
}
