import { I } from '@angular/cdk/keycodes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(items: any[], term: string, order: string): any {
    if (order != "Desc") {
      if (term != null && term != undefined && term != "") {

        items.sort((a, b) => {
          if (!isNaN(a[term]) && !isNaN(b[term])) {
            return a[term] - b[term];
          }
          return a[term] > b[term] ? 1 : (b[term] > a[term] ? -1 : 0)
        });
      }
      else {
        items.sort();
      }
    }
    else {
      if (term != null && term != undefined && term != "") {
        items.sort((a, b) => {
          if (!isNaN(a[term]) && !isNaN(b[term])) {
            return b[term] - a[term];
          }
          return b[term] > a[term] ? 1 : (a[term] > b[term] ? -1 : 0)
        });
      } else {
        items.sort();
        items.reverse();
      }
    }
    return items;
  }

}
