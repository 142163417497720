import { Injectable } from '@angular/core';
import { HttpService } from "../http/http.service";
import { EncryptionService } from "../encryption/encryption.service";
import { Observable, Subject } from 'rxjs';
import { RouteConstants } from 'src/app/constants/route-constants';
import { Router } from "@angular/router";
import { UserRoleConstants, NotificationTypeConstants } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subject = new Subject<any>();
  private routerLink: string = '';

  constructor(private httpService: HttpService, private encryptionService: EncryptionService,
    private router: Router) { }

  navigateToAction(notification: any) {
    var param = { 'Id': notification.id, 'IsRead': true };
    this.httpService.httpPost(RouteConstants.NOTIFICATION_updateNotificationMessages, param, false).subscribe(res => {

      if (notification.notificationParam != 0) {
        var appendParam = true;

        if (notification.roleId == UserRoleConstants.USER) {
          switch (notification.notificationType) {
            case NotificationTypeConstants.customer_Registered:
              this.routerLink = "user/pending-approval";
              break;
            case NotificationTypeConstants.status_Changed:
              this.routerLink = "user/customer-list";
              break;
            case NotificationTypeConstants.quote_Requested:
              this.routerLink = "user/quotations";
              break;
            case NotificationTypeConstants.booking:
              this.routerLink = "user/bookings";
              break;
            case NotificationTypeConstants.tier_setup:
              this.routerLink = "user/tiers/tier-setup";
              break;
            case NotificationTypeConstants.ratesheet_approval:
              this.routerLink = "user/freight-approval";
              break;
            case NotificationTypeConstants.KYC:
              this.routerLink = "user/pending-kyc";
              break;
          }
        }
        else {
          switch (notification.notificationType) {
            case NotificationTypeConstants.status_Changed:
              this.routerLink = "customer/user-profile";
              break;
            case NotificationTypeConstants.KYC:
              appendParam = false;
              this.routerLink = "customer/kyc-details";
              break;
            case NotificationTypeConstants.spot_rate_assigned:
              appendParam = false;
              this.routerLink = "customer/quotation-request";
              break;
          }
        }

        this.subject.next({ data: notification });
        if (this.routerLink != '') {
          if (appendParam) {
            this.router.navigate([this.routerLink, this.encryptionService.encrypt(notification.notificationParam)]);
          }
          else {
            this.router.navigate([this.routerLink]);
          }
        }
      }
    });
  }

  notifyChanges() {
    this.subject.next({ data: null });
  }

  notifyNotificationChanges(): Observable<any> {
    return this.subject.asObservable();
  }


}