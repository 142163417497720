import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core'; 
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRouterModule } from './router-module/router-module.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { from } from 'rxjs';
import { HttpService } from "./services/http/http.service";
import { LoaderService } from "./services/loader/loader.service";
import { ToastMessageService } from "./services/toast-message/toast-message.service";
import { JwtInterceptor } from "./helpers/jwtInterceptor";
import { ErrorInterceptor } from "./helpers/errorInterceptor";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { DataTransferService } from "./services/data-transfer/data-transfer.service";
import { ModalDialogService } from "./services/modal-dialog/model-dialog.service";
import { ModelDialogComponent } from "./shared/model-dialog/model-dialog.component";
import { BlockUnblockUserComponent } from "./shared/block-unblock-user/block-unblock-user.component";
import { ConfirmBoxComponent } from "./shared/confirm-box/confirm-box.component";
import { FreightDifferenceComponent } from "./freight-difference/freight-difference.component";
import { Ng2DeviceService } from 'ng2-device-detector';
import { AuthService } from './services/auth/auth.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { RequestContainersSettingsComponent } from "./freights/request-containers-settings/request-containers-settings.component";
import { AddNewSellRateComponent } from './spot/add-new-sell-rate/add-new-sell-rate.component';
import { AddCompareComponent } from './freights/compare-ratesheets/add-compare/add-compare.component';
import { FilterCompareComponent } from './freights/compare-ratesheets/filter-compare/filter-compare.component';
import { CustomRateLocationComponent } from './spot/custom-rate-location/custom-rate-location.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CkEditorComponent } from './freights/ck-editor/ck-editor.component';
import { RemarkPopupComponent } from './shared/remark-popup/remark-popup.component';
import { DateChangePopupComponent } from './shared/date-change-popup/date-change-popup.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    ModelDialogComponent,
    BlockUnblockUserComponent,
    ConfirmBoxComponent,
    FreightDifferenceComponent,
    AddCompareComponent,
    FilterCompareComponent,
    CkEditorComponent,
    RemarkPopupComponent,
    DateChangePopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRouterModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    CKEditorModule
  ],
  providers: [
    HttpService, LoaderService, ToastMessageService, DataTransferService,
    ModalDialogService, Ng2DeviceService, AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  entryComponents: [
    ModelDialogComponent, BlockUnblockUserComponent,
    ConfirmBoxComponent, FreightDifferenceComponent,
    RequestContainersSettingsComponent, AddNewSellRateComponent,
    CustomRateLocationComponent, AddCompareComponent, FilterCompareComponent,
    CkEditorComponent,RemarkPopupComponent,DateChangePopupComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { 
  
}
