export class KycAddressTypeConstants {
    public static REGISTERED = 1;
    public static TRADING = 2
} 

export class UserRoleConstants {
    public static USER_ROLES: Array<{ id: number, role: string, description: string }> = [
        { id: 1, role: 'Customer', description: 'Role for Inbrit customers' },
        { id: 2, role: 'User', description: 'Role for Inbrit Backoffice Users' }];

    public static CUSTOMER = 1;
    public static USER = 2;
}

export class ProfileStatusConstants {
    public static PENDING_EMAIL_VERIFICATION = 6001;
    public static PENDING_APPROVAL = 6002;
    public static APPROVAL_REJECTED = 6003;
    public static PENDING_CONFIRMATION = 6004;
    public static CONFIRMATION_REJECTED = 6005;
    public static CONFIRMED = 6006;
    public static PROFILE_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6001, description: 'Pending Email Verification' },
        { id: 6002, description: 'Pending Approval' },
        { id: 6003, description: 'Rejected' },
        { id: 6004, description: 'Pending Confirmation' },
        { id: 6005, description: 'Confirmation Rejected' },
        { id: 6006, description: 'Confirmed' },
    ];
}

export class KycStatusConstants {
    public static KYC_PENDING = 6007;
    public static KYC_APPROVAL_PENDING = 6008;
    public static KYC_APPROVAL_REJECTED = 6009;
    public static KYC_SUSPENDED = 6010;
    public static KYC_CONFIRMED = 6006;
    public static KYC_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6007, description: 'Pending' },
        { id: 6008, description: 'Pending Approval' },
        { id: 6009, description: 'Rejected' },
        { id: 6010, description: 'Suspended' },
        { id: 6006, description: 'Confirmed' }
    ];
}

export class Pagination {
    public static PageSize = 20;
    public static PageSizeOptions: Array<{ "key": number, "value": number }> =
        [
            { "key": 20, "value": 20 },
            { "key": 40, "value": 40 },
            { "key": 60, "value": 60 },
            { "key": 80, "value": 80 },
            { "key": 100, "value": 100 }
        ];
}

export class GridColumnType {
    public static DATA = "DATA";
    public static ACTION = "ACTION";
    public static SELECT = "SELECT";
}

export class GridColumnDataType {
    public static TEXT = "TEXT";
    public static DATE = "DATE";
    public static DATETIME = "DATETIME";
    public static TIME = "TIME";
}

export class GridActionType {
    public static BUTTON = "BUTTON";
    public static ICON = "ICON";
}

export class FreightConstants {

    public static PENDING_APPROVAL = 6002;
    public static APPROVAL_REJECTED = 6003;
    public static APPROVED = 6012;
    public static EXPIRED = 6011;
    public static FREIGHT_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6002, description: 'Pending' },
        { id: 6003, description: 'Rejected' },
        { id: 6012, description: 'Approved' },
        { id: 6011, description: 'Expired' },
    ];
}


export class SpotConstants {

    public static PENDING_APPROVAL = 6002;
    public static APPROVAL_REJECTED = 6003;
    public static EXPIRED = 6011;
    public static APPROVED = 6012;
    public static BLOCKED = 6018;
    public static SPOT_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6002, description: 'Pending' },
        { id: 6003, description: 'Rejected' },
        { id: 6011, description: 'Expired' },
        { id: 6012, description: 'Approved' },
        { id: 6018, description: 'Blocked' }
    ];
}

export class TierConstants {

    public static PENDING_APPROVAL = 6002;
    public static APPROVAL_REJECTED = 6003;
    public static EXPIRED = 6011;
    public static APPROVED = 6012;
    public static BLOCKED = 6018;

    public static TIER_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6002, description: 'Pending' },
        { id: 6003, description: 'Rejected' },
        { id: 6011, description: 'Expired' },
        { id: 6012, description: 'Approved' },
        { id: 6018, description: 'Blocked' },
    ];
}

export class BookingStatusConstants {
    public static PENDING_CONFIRMATION = 6004;
    public static CONFIRMED = 6006;
    public static PENDING_SHIPINGLINE_CONFIRMATION = 6013;
    public static REJECTED = 6014;
    public static CANCELLED = 6015;
    public static BOOKING_AMENDED = 6016;
    public static PENDING_MODIFICATION_CONFIRMATION = 6017;

    public static BOOKING_STATUSES: Array<{ id: number, description: string }> = [
        { id: 6004, description: 'Pending Confirmation' },
        { id: 6006, description: 'Confirmed' },
        { id: 6013, description: 'Pending Shiping Line Confirmation' },
        { id: 6014, description: 'Rejected' },
        { id: 6015, description: 'Cancelled' },
        { id: 6016, description: 'Booking Amended' },
        { id: 6017, description: 'Pending Modification Confirmation' }
    ];
}

export class FSCollections {
    public static QUOTATIONS = "Quotations";
    public static BOOKINGS = "Bookings";
    public static USERS = "Users"
}

export class DateConstants {

    public static MaxDate = new Date;
    public static MinDate = new Date("01-Jan-2020");

}

export class DateFormats {
    public static STANDARD_DATE = "dd-MMM-yyyy";
    public static STANDARD_DATE_TIME = "dd-MMM-yyyy HH:mm";
    public static MOMENT_STANDARD_DATE = "DD-MMM-YYYY";
    public static MOMENT_STANDARD_DATE_TIME = "DD-MMM-YYYY HH:mm";
    public static MOMENT_STANDARD_TIME = "HH:mm";
}

export class CarrierTypeConstants {
    public static APL = 1;
    public static MAERSK = 8;
    public static MSC = 9;
}

export class CountryConstants {
    public static UK = 146;
    public static US = 147;
}

export class NotificationTypeConstants {
    static status_Changed = 5001;
    static customer_Registered = 5002;
    static quote_Requested = 5003;
    static booking = 5004;
    static tier_setup = 5005;
    static ratesheet_approval = 5006;
    static KYC = 5009;
    static spot_rate_assigned = 5010;

    public static NOTIFICATION_TYPES: Array<{ typeId: number, description: string }> = [
        { typeId: 5001, description: 'Status Changes' },
        { typeId: 5002, description: 'Customer Registered' },
        { typeId: 5003, description: 'Quotation Request' },
        { typeId: 5004, description: 'Booking' },
        { typeId: 5005, description: 'New Tier' },
        { typeId: 5006, description: 'Ratesheet Approval' },
        { typeId: 5009, description: 'Pending KYC' },
        { typeId: 5010, description: 'Spot rates assigned' }
    ];
  }
  
  export class DisplayModeConstants {
    static view = 'View';
    static edit = 'Edit';
  }
  export class Commodity {
    static Metal_Scrap = 1;
    static Waste_Paper = 2;
    static Forest_Products = 18;
    static Wood_logs = 19;
  }