import { Component, OnInit } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { RouteConstants } from "../../constants/route-constants";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { TierConstants, DateFormats, FreightConstants, DisplayModeConstants } from 'src/app/constants/constants';
import { ModalDialogService } from "../../services/modal-dialog/model-dialog.service";
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Utils } from 'src/app/helpers/utils';
import { CkEditorComponent } from '../ck-editor/ck-editor.component';
import { MatDialog } from '@angular/material';
import { RemarkPopupComponent } from 'src/app/shared/remark-popup/remark-popup.component';

@Component({
  selector: 'inbrit-tier-setup',
  templateUrl: './tier-setup.component.html',
  styleUrls: ['./tier-setup.component.scss']
})

export class TierSetupComponent implements OnInit {
  createTierForm: FormGroup;

  rateSheet = [];
  rateSheetOriginal: Array<any> = [];
  rateSheetEdit = [];
  countries: [any];
  carriers = [];
  commodities = [];
  loadpoints = [];
  fromPorts = [];
  toPorts = [];
  discharge = [];
  containerSizes = [];
  containerTypes = [];
  countryIds = [];
  carrierIds = [];
  commodityIds = [];
  loadpointIds = [];
  fromPortIds = [];
  toPortIds = [];
  dischargeIds = [];
  containerSizeIds = [];
  containerTypeIds = [];
  countryCode: string;
  tierStatusId: number;
  submitted: boolean = false;
  isLoading: boolean = false;
  groupedFreightRates = Array<{
    id: number, freightRateId: number, margin: string, backColor: string, freightRates: any,
    isExpired: boolean, isActive: boolean, tradeNotice: string, isRateLoading: boolean, carrierCode: string, carrierId: number,
    effectiveDate: string, expiryDate: string, commodityName: string, commodityId: number, createdDate: string, remarks: string, carrierName: string
  }>();
  originalGroupedFreightRates = Array<{
    id: number, freightRateId: number, margin: string, backColor: string, freightRates: any,
    isExpired: boolean, isActive: boolean, tradeNotice: string, isRateLoading: boolean, carrierCode: string, carrierId: number,
    effectiveDate: string, expiryDate: string, commodityName: string, commodityId: number, createdDate: string, remarks: string, carrierName: string
  }>();
  // groupedFreightRatesEdit = Array<{ id: number,freightRateId: number, margin: string, backColor: string, freightRates: any, 
  //     isExpired: boolean, isActive: boolean, tradeNotice: string,isRateLoading: boolean,carrierCode:string,
  //     effectiveDate: string,expiryDate: string,commodityName: string,createdDate: string,remarks: string}>();
  datePipe = new DatePipe('en-US');
  // gridBackColors = ['#e6ffe6', '#ffff99'];
  tierMasterId: number = 0;
  isEdit: boolean = false;
  TierConstants = TierConstants;
  autoTierUpdate: boolean;
  margin: string = "+0";
  marginOld: string = "+0";
  user: any;
  isDisabled: boolean = false;
  appearance: string = 'outline';
  tierStatus: number;
  updatedStatusId: number;
  isUpdateTierStatus: boolean = false;
  displayMode: string = DisplayModeConstants.view;
  DisplayModeConstants = DisplayModeConstants;
  tierType: string = "";
  localFilters: Array<{
    loadpoints: Array<any>, loadpointIds: Array<number>, fromPorts: Array<any>, fromPortIds: Array<number>,
    toPorts: Array<any>, toPortIds: Array<number>, discharge: Array<any>, dischargeIds: Array<number>,
    containerSizes: Array<any>, containerSizeIds: Array<number>, containerTypes: Array<any>, containerTypeIds: Array<number>,
    // freightRates: Array<any>
  }> = [];
  isRateLoading: boolean = true;
  tierStatusList = [TierConstants.APPROVAL_REJECTED, TierConstants.BLOCKED, TierConstants.EXPIRED, TierConstants.APPROVED];
  isGobalMarginEdit: boolean = false;
  addToSellMargin: boolean = false;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, private encryptionService: EncryptionService,
    private router: Router, private modalDialogService: ModalDialogService,
    private userInfoService: UserInfoService, private toastMessageService: ToastMessageService,
    private loaderService: LoaderService, private dialog: MatDialog) {

    var tierMasterId = "", displayMode = "";
    this.activatedRoute.params.subscribe(params => { tierMasterId = params['id'] });
    this.activatedRoute.params.subscribe(params => { displayMode = params['displayMode'] });
    this.user = this.userInfoService.getLoggedInUser();

    if (displayMode != undefined && displayMode != "") {
      this.displayMode = this.encryptionService.decrypt(displayMode);
    }
    else {
      this.displayMode = DisplayModeConstants.edit;
    }

    if (tierMasterId != undefined && tierMasterId != "") {
      this.tierMasterId = parseInt(this.encryptionService.decrypt(tierMasterId));
      this.isEdit = true;
      this.appearance = 'fill';
    }

  }

  ngOnInit() {
    let routeArr = this.router.url.split('/');
    this.tierType = routeArr.length > 2 ? routeArr[2] : routeArr[0];
    this.createTierForm = this.formBuilder.group({
      country: [{ value: this.user.countryOfRegistration, disabled: true }, [Validators.required]],
      tierCode: [{ value: '', disabled: this.isEdit }, [Validators.minLength(4), Validators.required]],
      comments: [{ value: '', disabled: this.displayMode == this.DisplayModeConstants.view }, []]
    })

    this.getCountries();
    this.createTierForm.patchValue({
      country: this.user.countryOfRegistration
    });

    if (this.isEdit == true) {
      this.getTierEditData();
    }

  }

  // convenience getter for easy access to form fields
  get tierForm() { return this.createTierForm.controls; }

  getTierEditData() {
    var param = {
      "TierId": this.tierMasterId
    }

    let apiUrl = this.tierType == 'tier-report' ? RouteConstants.FRIEGHT_getTierArchiveRateChart : RouteConstants.FRIEGHT_getTierArchiveRateChartHeader;
    this.httpService.httpPost(apiUrl, param).subscribe(res => {
      if (res['success'] == true) {
        var tierMasterData = res['data'];
        this.originalGroupedFreightRates = res['data']['tierRateChartList'];
        this.groupedFreightRates = JSON.parse(JSON.stringify(this.originalGroupedFreightRates));
        // this.groupedFreightRatesEdit =  this.groupedFreightRates;
        this.autoTierUpdate = tierMasterData.autoUpdate;
        this.tierStatus = res['data'].status;

        var countryId = 0;
        this.countryCode = tierMasterData.countryCode;
        countryId = tierMasterData.countryCode == 'US' ? 147 : 146;
        this.tierStatusId = tierMasterData.status;
        this.margin = tierMasterData.margin;
        this.marginOld = tierMasterData.margin;
        this.isDisabled = this.displayMode == DisplayModeConstants.view ? true : [TierConstants.APPROVAL_REJECTED, TierConstants.BLOCKED, TierConstants.EXPIRED].includes(tierMasterData.status) ? true : false;


        this.createTierForm.patchValue({
          tierCode: tierMasterData.tierCode,
          country: countryId,
          comments: tierMasterData.comments
        });

        this.carriers = this.groupedFreightRates.map(m => { return { title: m.carrierCode, value: m.carrierId } });
        this.carriers = this.filterUnquie(this.carriers);
        this.commodities = this.groupedFreightRates.map(m => { return { title: m.commodityName, value: m.commodityId } });
        this.commodities = this.filterUnquie(this.commodities);
        this.loadpoints = res['data'].loadpoints;
        this.fromPorts = res['data'].fromPorts;
        this.toPorts = res['data'].toPorts;
        this.discharge = res['data'].discharge;
        this.containerSizes = res['data'].containerSizes;
        this.containerTypes = res['data'].containerTypes;
        // this.carrierIds = [...this.carriers];
        // this.commodityIds = [...this.commodities];
        let len = this.groupedFreightRates.length;
        for (let i = 0; i < len; i++) {
          this.localFilters[i] = {
            loadpoints: [], loadpointIds: [], fromPorts: [], fromPortIds: [],
            toPorts: [], toPortIds: [], discharge: [], dischargeIds: [],
            containerSizes: [], containerSizeIds: [], containerTypes: [], containerTypeIds: [],
            // freightRates: []
          };
        }
      }
    });
  }

  getRateByFreight(index: number) {
    let { freightRateId, freightRates } = this.groupedFreightRates[index];
    var param = {
      "FreightRateId": freightRateId,
      "TierId": this.tierMasterId
    }
    let orignalFr = this.originalGroupedFreightRates.find(m => m.freightRateId == freightRateId);
    if (freightRates == null || freightRates == undefined || freightRates.length <= 0) {
      this.groupedFreightRates[index]['isRateLoading'] = true;
      let apiUrl = this.tierType == 'tier-report' ? RouteConstants.FRIEGHT_getTierArchiveRateChart :
        orignalFr.id == 0 ? RouteConstants.FRIEGHT_getFreightRatesChartTier : RouteConstants.FRIEGHT_getTierRateChartByFreightId;

      this.httpService.httpPost(apiUrl, param, false).subscribe(res => {
        if (res['success'] == true) {
          orignalFr.freightRates = res['data'];
          if (orignalFr.id == 0) {
            this.setSellRate(orignalFr, index,"+0");
            let { filter } = res['data'][0];
            if (this.localFilters[index] == undefined) {
              this.localFilters.push(filter);
            }
            else {
              this.localFilters[index] = filter;
            }
          }
          else {
            this.groupedFreightRates[index].freightRates = this.indiviualFilter(orignalFr);
            //set local filter
            let filter = {
              loadpoints: this.groupedFreightRates[index].freightRates.reduce((prev, { loadpointName, loadpointId }) => prev.some(x => x.value === loadpointId) ? prev : [...prev, { title: loadpointName, value: loadpointId }], []),
              toPorts: this.groupedFreightRates[index].freightRates.reduce((prev, { toPortName, toPortId }) => prev.some(x => x.value === toPortId) ? prev : [...prev, { title: toPortName, value: toPortId }], []),
              discharge: this.groupedFreightRates[index].freightRates.reduce((prev, { dischargeName, dischargeId }) => prev.some(x => x.value === dischargeId) ? prev : [...prev, { title: dischargeName, value: dischargeId }], []),
              fromPorts: this.groupedFreightRates[index].freightRates.reduce((prev, { fromPortName, fromPortId }) => prev.some(x => x.value === fromPortId) ? prev : [...prev, { title: fromPortName, value: fromPortId }], []),
              containerSizes: Array.from(new Set(this.groupedFreightRates[index].freightRates.map(m => m.containerSize))),
              containerTypes: Array.from(new Set(this.groupedFreightRates[index].freightRates.map(m => m.containerType))),
              loadpointIds: [],
              toPortIds: [],
              dischargeIds: [],
              fromPortIds: [],
              containerSizeIds: [],
              containerTypeIds: [],
            };
            if (this.localFilters[index] == undefined) {
              this.localFilters.push(filter);
            }
            else {
              this.localFilters[index] = filter;
            }
          }

          this.groupedFreightRates[index]['isRateLoading'] = false;
          // let { filter } = res['data'][0];

          // this.initializeGlobalFilters(filter);
          // if (this.localFilters[index] == undefined) {
          //   this.localFilters.push(filter);
          // }
          // else {
          //   this.localFilters[index] = filter;
          // }


        }
      });
    }

  }

  setSellRate(orignalFr: any, index: number,defaultMargin: string) {
    let earlierRate = JSON.parse(JSON.stringify(this.groupedFreightRates));
    this.groupedFreightRates[index].freightRates = this.indiviualFilter(orignalFr);
    var operation = defaultMargin[0];
    var margin = defaultMargin.substr(1, defaultMargin.length - 1);
    this.groupedFreightRates[index].freightRates.forEach(m => {
      m.sellRate = Utils.calculateSellRate(m.sellRate, margin, operation);
    });
  }

  initializeGlobalFilters({ loadpoints, fromPorts, toPorts, discharge,
    containerSizes, containerTypes }) {

    this.loadpoints.push(...loadpoints);
    this.loadpoints = this.filterUnquie(this.loadpoints);

    this.fromPorts.push(...fromPorts)
    this.fromPorts = this.filterUnquie(this.fromPorts);

    this.toPorts.push(...toPorts)
    this.toPorts = this.filterUnquie(this.toPorts);

    this.discharge.push(...discharge)
    this.discharge = this.filterUnquie(this.discharge);


    this.containerSizes.push(containerSizes.map(x => x.title));
    this.containerSizes = Array.from(new Set(this.containerSizes));


    this.containerTypes.push(containerTypes.map(x => x.title));
    this.containerTypes = Array.from(new Set(this.containerTypes));
  }

  filterUnquie(array: Array<any>): Array<any> {
    let unquie = [], len = array.length, temp = [];
    for (let i = 0; i < len; i++) {
      if (unquie[array[i].value]) continue;
      unquie[array[i].value] = true;
      temp.push(array[i]);
    }
    return temp;
  }

  initializeFilters(): void {
    let filterCarrierID = this.rateSheet.map(m => m.carrierCode).filter((n, i) => this.rateSheet.map(m => m.carrierCode).indexOf(n) === i);
    let filterCommodityIds = this.rateSheet.map(m => m.commodityName).filter((n, i) => this.rateSheet.map(m => m.commodityName).indexOf(n) === i);
    let filterLoadpointIds = this.rateSheet.map(m => m.loadpointName).filter((n, i) => this.rateSheet.map(m => m.loadpointName).indexOf(n) === i);
    let filterFromPortIds = this.rateSheet.map(m => m.fromPortName).filter((n, i) => this.rateSheet.map(m => m.fromPortName).indexOf(n) === i);
    let filterToPortIds = this.rateSheet.map(m => m.toPortName).filter((n, i) => this.rateSheet.map(m => m.toPortName).indexOf(n) === i);
    let filterDischargeIds = this.rateSheet.map(m => m.dischargeName).filter((n, i) => this.rateSheet.map(m => m.dischargeName).indexOf(n) === i);
    let filterContainerSizeIds = this.rateSheet.map(m => m.containerSize).filter((n, i) => this.rateSheet.map(m => m.containerSize).indexOf(n) === i);
    let filterContainerTypeIds = this.rateSheet.map(m => m.containerType).filter((n, i) => this.rateSheet.map(m => m.containerType).indexOf(n) === i);

    this.carrierIds = Array.from(new Set(filterCarrierID));
    this.commodityIds = Array.from(new Set(filterCommodityIds));

    this.loadpointIds = Array.from(new Set(filterLoadpointIds));
    var isNullLoadPoints = this.loadpointIds.filter(m => m == null);
    if (isNullLoadPoints) {
      this.loadpointIds = this.loadpointIds.filter(m => m != null);
      this.loadpointIds.splice(0, 0, 'null');
    }

    this.fromPortIds = Array.from(new Set(filterFromPortIds));
    var isNullFromPorts = this.fromPortIds.filter(m => m == null);
    if (isNullFromPorts) {
      this.fromPortIds = this.fromPortIds.filter(m => m != null);
      this.fromPortIds.splice(0, 0, 'null');
    }

    this.toPortIds = Array.from(new Set(filterToPortIds));
    var isNullToPorts = this.toPortIds.filter(m => m == null);
    if (isNullToPorts) {
      this.toPortIds = this.toPortIds.filter(m => m != null);
      this.toPortIds.splice(0, 0, 'null');
    }

    this.dischargeIds = Array.from(new Set(filterDischargeIds));
    var isNullDischargeCodes = this.dischargeIds.filter(m => m == null);
    if (isNullDischargeCodes) {
      this.dischargeIds = this.dischargeIds.filter(m => m != null);
      this.dischargeIds.splice(0, 0, 'null');
    }

    this.containerSizeIds = Array.from(new Set(filterContainerSizeIds));
    this.containerTypeIds = Array.from(new Set(filterContainerTypeIds));
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (this.isEdit == false && this.createTierForm.valid == false) {
      return;
    }


    this.countryCode = this.countries.filter(m => m.id == this.createTierForm.controls['country'].value)[0].countryCode;
    var param = {
      CountryCode: this.countryCode
    }

    this.httpService.httpPost(RouteConstants.FRIEGHT_getFreightRatesForTier, param).subscribe(res => {
      if (res['success'] && res['data'] != null) {
        this.originalGroupedFreightRates = res['data'].freightRate;
        this.groupedFreightRates = JSON.parse(JSON.stringify(this.originalGroupedFreightRates));

        this.carriers = this.groupedFreightRates.map(m => { return { title: m.carrierCode, value: m.carrierId } });
        this.carriers = this.filterUnquie(this.carriers);
        this.commodities = this.groupedFreightRates.map(m => { return { title: m.commodityName, value: m.commodityId } });
        this.commodities = this.filterUnquie(this.commodities);
        this.loadpoints = res['data'].filter.loadpoints;
        this.fromPorts = res['data'].filter.fromPorts;
        this.toPorts = res['data'].filter.toPorts;
        this.discharge = res['data'].filter.discharge;
        this.containerSizes = res['data'].filter.containerSizes;
        this.containerTypes = res['data'].filter.containerTypes;

        let len = this.groupedFreightRates.length;
        for (let i = 0; i < len; i++) {
          this.localFilters[i] = {
            loadpoints: [], loadpointIds: [], fromPorts: [], fromPortIds: [],
            toPorts: [], toPortIds: [], discharge: [], dischargeIds: [],
            containerSizes: [], containerSizeIds: [], containerTypes: [], containerTypeIds: [],
          };
        }

        if (this.isEdit) {
          this.filterGrid();
        }
      }

    });
  }

  initializeFilterDDLs(): void {
    this.carriers = Array.from(new Set(this.rateSheet.map(m => m.carrierCode)));
    this.commodities = Array.from(new Set(this.rateSheet.map(m => m.commodityName)));

    this.loadpoints = Array.from(new Set(this.rateSheet.map(m => m.loadpointName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullLoadPoints = this.loadpoints.filter(m => m.title == null);
    if (isNullLoadPoints) {
      this.loadpoints = this.loadpoints.filter(m => m.title != null);
      // this.loadpoints.sort();
      this.loadpoints.splice(0, 0, { title: 'null', hidden: false });
    }

    this.fromPorts = Array.from(new Set(this.rateSheet.map(m => m.fromPortName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullFromPorts = this.fromPorts.filter(m => m.title == null);
    if (isNullFromPorts) {
      this.fromPorts = this.fromPorts.filter(m => m.title != null);
      // this.fromPorts.sort();
      this.fromPorts.splice(0, 0, { title: 'null', hidden: false });
    }

    this.toPorts = Array.from(new Set(this.rateSheet.map(m => m.toPortName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullToPorts = this.toPorts.filter(m => m.title == null);
    if (isNullToPorts) {
      this.toPorts = this.toPorts.filter(m => m.title != null);
      // this.toPorts.sort();
      this.toPorts.splice(0, 0, { title: 'null', hidden: false });
    }

    this.discharge = Array.from(new Set(this.rateSheet.map(m => m.dischargeName))).map((value, index) => { return { title: value, hidden: false } });
    var isNullDischargePorts = this.discharge.filter(m => m.title == null);
    if (isNullDischargePorts) {
      this.discharge = this.discharge.filter(m => m.title != null);
      // this.discharge.sort();
      this.discharge.splice(0, 0, { title: 'null', hidden: false });
    }

    this.containerSizes = Array.from(new Set(this.rateSheet.map(m => m.containerSize)));
    this.containerTypes = Array.from(new Set(this.rateSheet.map(m => m.containerType)));
    this.initializeGroupedRateSheets();
  }

  getFilterCount(): number {
    var filterCount = 0;
    filterCount += this.carrierIds.length > 0 ? 1 : 0;
    filterCount += this.commodityIds.length > 0 ? 1 : 0;
    filterCount += this.loadpointIds.length > 0 ? 1 : 0;
    filterCount += this.fromPortIds.length > 0 ? 1 : 0;
    filterCount += this.toPortIds.length > 0 ? 1 : 0;
    filterCount += this.dischargeIds.length > 0 ? 1 : 0;
    filterCount += this.containerSizeIds.length > 0 ? 1 : 0;
    filterCount += this.containerTypeIds.length > 0 ? 1 : 0;
    return filterCount;
  }

  showFiltersApplied(): boolean {
    var isFilterApplied = !(this.carrierIds.length == this.carriers.length && this.commodityIds.length == this.commodities.length &&
      this.loadpointIds.length == this.loadpoints.length && this.fromPortIds.length == this.fromPorts.length &&
      this.toPortIds.length == this.toPorts.length && this.dischargeIds.length == this.discharge.length &&
      this.containerSizeIds.length == this.containerSizes.length && this.containerTypeIds.length == this.containerTypes.length);

    if (this.getFilterCount() > 0 && isFilterApplied) {
      return true;
    }
    else {
      return false;
    }
  }

  initializeGroupedRateSheets(): void {
    // this.groupedFreightRates = new Array<{ freightRateId: number, margin: string, backColor: string, freightRates: any, isExpired: boolean, isActive: boolean, tradeNotice: string,isRateLoading: boolean}>();
    var groupedFreightRateIds = Array.from(new Set(this.rateSheet.map(m => m.freightRateId)));
    this.localFilters = [];

    groupedFreightRateIds.forEach((id, index) => {
      var freightRates = this.rateSheet.filter(m => { return m.freightRateId == id });
      if (freightRates.length > 0) {
        // this.groupedFreightRates.push({
        //   freightRateId: id,
        //   margin: '+0',
        //   backColor: this.gridBackColors[index % 2],
        //   freightRates: this.rateSheet.filter(m => {
        //     m['sellRate'] = Utils.calculateSellRate(m['sellRate'], "0", '+');
        //     return m.freightRateId == id
        //   }),
        //   isExpired: freightRates[0].status == FreightConstants.EXPIRED,
        //   isActive: freightRates[0].isActive,
        //   tradeNotice: freightRates[0].tradeNotice,
        //   isRateLoading: false
        // });
      }
      // Create/Reset local filters
      let loadpoints = Array.from(new Set(freightRates.map(m => m.loadpointName))).map((value, index) => { return { title: value, hidden: false } });
      var isNullLoadPoints = loadpoints.filter(m => m.title == null);
      if (isNullLoadPoints) {
        loadpoints = loadpoints.filter(m => m.title != null);
        // loadpoints.sort();
        loadpoints.splice(0, 0, { title: 'null', hidden: false });
      }

      let fromPorts = Array.from(new Set(freightRates.map(m => m.fromPortName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullFromPorts = fromPorts.filter(m => m.title == null);
      if (isNullFromPorts) {
        fromPorts = fromPorts.filter(m => m.title != null);
        // fromPorts.sort((a,b) => a.title >  b.title ? 1 : (b.title >  a.title ? -1 : 0));
        fromPorts.splice(0, 0, { title: 'null', hidden: false });
      }

      let toPorts = Array.from(new Set(freightRates.map(m => m.toPortName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullToPorts = toPorts.filter(m => m.title == null);
      if (isNullToPorts) {
        toPorts = toPorts.filter(m => m.title != null);
        // toPorts.sort();
        toPorts.splice(0, 0, { title: 'null', hidden: false });
      }

      let discharge = Array.from(new Set(freightRates.map(m => m.dischargeName))).map((value, index) => { return { title: value, hidden: false } });
      let isNullDischargePorts = discharge.filter(m => m.title == null);
      if (isNullDischargePorts) {
        discharge = discharge.filter(m => m.title != null);
        // discharge.sort();
        discharge.splice(0, 0, { title: 'null', hidden: false });
      }

      let containerSizes = Array.from(new Set(freightRates.map(m => m.containerSize)));
      let containerTypes = Array.from(new Set(freightRates.map(m => m.containerType)));
      if (index == 0) {
        this.localFilters = [{
          loadpoints: loadpoints, loadpointIds: [],
          fromPorts: fromPorts, fromPortIds: [],
          toPorts: toPorts, toPortIds: [],
          discharge: discharge, dischargeIds: [],
          containerSizes: containerSizes, containerSizeIds: [],
          containerTypes: containerTypes, containerTypeIds: [],
          // freightRates: freightRates
        }]
      }
      else {
        this.localFilters.push({
          loadpoints: loadpoints, loadpointIds: [],
          fromPorts: fromPorts, fromPortIds: [],
          toPorts: toPorts, toPortIds: [],
          discharge: discharge, dischargeIds: [],
          containerSizes: containerSizes, containerSizeIds: [],
          containerTypes: containerTypes, containerTypeIds: [],
          // freightRates: freightRates
        });
      }
    });
  }

  filterGrid(): void {
    let totalLen = this.originalGroupedFreightRates.map(x => { return x.freightRates != undefined ? x.freightRates.length : 0 }).reduce((a, b) => a + b);
    if (totalLen >= 800) {
      this.loaderService.display(true);
    }
    // window.setTimeout(function () {
    this.groupedFreightRates = [];
    this.localFilters = [];
    this.originalGroupedFreightRates.forEach(rate => {
      if ((this.carrierIds != undefined && this.carrierIds.length > 0 ? this.carrierIds.indexOf(rate.carrierId) != -1 : true) &&
        (this.commodityIds != undefined && this.commodityIds.length > 0 ? this.commodityIds.indexOf(rate.commodityId) != -1 : true)) {
        let freightRates = this.indiviualFilter(rate);
        this.groupedFreightRates.push({
          id: rate.id, freightRateId: rate.freightRateId, carrierName: rate.carrierName,
          margin: rate.margin, backColor: rate.backColor, freightRates: freightRates,
          isExpired: rate.isExpired, isActive: rate.isActive, tradeNotice: rate.tradeNotice,
          isRateLoading: rate.isRateLoading, carrierCode: rate.carrierCode, carrierId: rate.carrierId,
          effectiveDate: rate.effectiveDate, expiryDate: rate.expiryDate, commodityName: rate.commodityName,
          createdDate: rate.createdDate, remarks: rate.remarks, commodityId: rate.commodityId
        });
        //set local filter
        let filter = {
          loadpoints: freightRates.reduce((prev, { loadpointName, loadpointId }) => prev.some(x => x.value === loadpointId) ? prev : [...prev, { title: loadpointName, value: loadpointId }], []),
          toPorts: freightRates.reduce((prev, { toPortName, toPortId }) => prev.some(x => x.value === toPortId) ? prev : [...prev, { title: toPortName, value: toPortId }], []),
          discharge: freightRates.reduce((prev, { dischargeName, dischargeId }) => prev.some(x => x.value === dischargeId) ? prev : [...prev, { title: dischargeName, value: dischargeId }], []),
          fromPorts: freightRates.reduce((prev, { fromPortName, fromPortId }) => prev.some(x => x.value === fromPortId) ? prev : [...prev, { title: fromPortName, value: fromPortId }], []),
          containerSizes: Array.from(new Set(freightRates.map(m => m.containerSize))),
          containerTypes: Array.from(new Set(freightRates.map(m => m.containerType))),
          loadpointIds: [],
          toPortIds: [],
          dischargeIds: [],
          fromPortIds: [],
          containerSizeIds: [],
          containerTypeIds: [],
        };
        this.localFilters.push(filter);
      }
    });


    // _this.initializeGroupedRateSheets();
    if (totalLen >= 800) {
      this.loaderService.display(false);
    }
    // }, 200);
    return;
  }

  indiviualFilter(rate) {
    var _this = this;
    if (rate.freightRates == undefined || rate.freightRates == null) {
      return [];
    }
    return rate.freightRates.filter(m => {
      return (_this.loadpointIds != undefined && _this.loadpointIds.length > 0 ? _this.loadpointIds.indexOf(m.loadpointId != null ? m.loadpointId : 'null') != -1 : true) &&
        (_this.fromPortIds != undefined && _this.fromPortIds.length > 0 ? _this.fromPortIds.indexOf(m.fromPortId != null ? m.fromPortId : 'null') != -1 : true) &&
        (_this.toPortIds != undefined && _this.toPortIds.length > 0 ? _this.toPortIds.indexOf(m.toPortId != null ? m.toPortId : 'null') != -1 : true) &&
        (_this.dischargeIds != undefined && _this.dischargeIds.length > 0 ? _this.dischargeIds.indexOf(m.dischargeId != null ? m.dischargeId : 'null') != -1 : true) &&

        (_this.containerSizeIds != undefined && _this.containerSizeIds.length > 0 ? _this.containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
        (_this.containerTypeIds != undefined && _this.containerTypeIds.length > 0 ? _this.containerTypeIds.indexOf(m.containerType) != -1 : true)
    });
  }
  // filterGrid(): void {
  //   var _this = this;
  //   if (_this.rateSheetOriginal.length >= 800) {
  //     this.loaderService.display(true);
  //   }
  //   window.setTimeout(function () {
  //     _this.rateSheet = _this.rateSheetOriginal.filter(m => {
  //       return (_this.countryIds != undefined && _this.countryIds.length > 0 ? _this.countryIds.indexOf(m.country) != -1 : true) &&
  //         (_this.carrierIds != undefined && _this.carrierIds.length > 0 ? _this.carrierIds.indexOf(m.carrierCode != null ? m.carrierCode : 'null') != -1 : true) &&
  //         (_this.commodityIds != undefined && _this.commodityIds.length > 0 ? _this.commodityIds.indexOf(m.commodityName) != -1 : true) &&

  //         (_this.loadpointIds != undefined && _this.loadpointIds.length > 0 ? _this.loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
  //         (_this.fromPortIds != undefined && _this.fromPortIds.length > 0 ? _this.fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
  //         (_this.toPortIds != undefined && _this.toPortIds.length > 0 ? _this.toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
  //         (_this.dischargeIds != undefined && _this.dischargeIds.length > 0 ? _this.dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&

  //         (_this.containerSizeIds != undefined && _this.containerSizeIds.length > 0 ? _this.containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
  //         (_this.containerTypeIds != undefined && _this.containerTypeIds.length > 0 ? _this.containerTypeIds.indexOf(m.containerType) != -1 : true)
  //     });
  //     _this.initializeGroupedRateSheets();
  //     if (_this.rateSheetOriginal.length >= 800) {
  //       _this.loaderService.display(false);
  //     }
  //   }, 200);
  //   return;
  // }
  // filterGrid(): void {
  //   var _this = this;
  //   if (_this.rateSheetOriginal.length >= 800) {
  //     this.loaderService.display(true);
  //   }
  //   window.setTimeout(function () {
  //     _this.rateSheet = _this.rateSheetOriginal.filter(m => {
  //       return (_this.countryIds != undefined && _this.countryIds.length > 0 ? _this.countryIds.indexOf(m.country) != -1 : true) &&
  //         (_this.carrierIds != undefined && _this.carrierIds.length > 0 ? _this.carrierIds.indexOf(m.carrierCode != null ? m.carrierCode : 'null') != -1 : true) &&
  //         (_this.commodityIds != undefined && _this.commodityIds.length > 0 ? _this.commodityIds.indexOf(m.commodityName) != -1 : true) &&

  //         (_this.loadpointIds != undefined && _this.loadpointIds.length > 0 ? _this.loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
  //         (_this.fromPortIds != undefined && _this.fromPortIds.length > 0 ? _this.fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
  //         (_this.toPortIds != undefined && _this.toPortIds.length > 0 ? _this.toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
  //         (_this.dischargeIds != undefined && _this.dischargeIds.length > 0 ? _this.dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&

  //         (_this.containerSizeIds != undefined && _this.containerSizeIds.length > 0 ? _this.containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
  //         (_this.containerTypeIds != undefined && _this.containerTypeIds.length > 0 ? _this.containerTypeIds.indexOf(m.containerType) != -1 : true)
  //     });
  //     _this.initializeGroupedRateSheets();
  //     if (_this.rateSheetOriginal.length >= 800) {
  //       _this.loaderService.display(false);
  //     }
  //   }, 200);
  //   return;
  // }

  resetFilter(): void {
    // this.groupedFreightRates.forEach(rate => { rate.freightRates = [...rate.originalFreightRates]});
    // this.initializeFilters();
    this.carrierIds = [];
    this.commodityIds = [];
    this.loadpointIds = [];
    this.fromPortIds = [];
    this.toPortIds = [];
    this.dischargeIds = [];
    this.containerSizeIds = [];
    this.containerTypeIds = [];

    this.filterGrid();
  }

  deleteGroups(): void {
    let selectedGroups = this.groupedFreightRates.filter(m => { return m['checked'] == true });
    let _thisRef = this;

    if (selectedGroups.length == this.groupedFreightRates.length) {
      let buttons = [
        { 'title': 'OK', 'result': 'yes', 'type': 'accent' }
      ];
      this.modalDialogService.openDialog('Error', 'You cannot delete all the ratesheets', buttons).subscribe((res) => { });
    }
    else if (selectedGroups.length == 0) {
      let buttons = [
        { 'title': 'OK', 'result': 'yes', 'type': 'accent' }
      ];
      this.modalDialogService.openDialog('Error', 'No item selected for deletion', buttons).subscribe((res) => { });
    }
    else {
      let buttons = [
        { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
        { 'title': 'No', 'result': 'no', 'type': 'default' }
      ];
      this.modalDialogService.openDialog('Confirm', 'Are you sure you want to delete ratesheets?', buttons).subscribe((res) => {
        if (res == 'yes') {
          selectedGroups.forEach(item => {
            let deleteIndex = _thisRef.groupedFreightRates.indexOf(item);
            _thisRef.groupedFreightRates.splice(deleteIndex, 1);
            _thisRef.rateSheet = _thisRef.rateSheet.filter(m => { return m.freightRateId != item.freightRateId });
            _thisRef.rateSheetOriginal = _thisRef.rateSheetOriginal.filter(m => { return m.freightRateId != item.freightRateId });
            _thisRef.localFilters.splice(deleteIndex, 1);
          });
        }
      });
    }
  }

  resetLocalFilter(index: number, group: any): void {
    // let groupFreightRates = Object.assign([], this.localFilters[index].freightRates);
    this.localFilters[index].loadpointIds = [];
    this.localFilters[index].fromPortIds = [];
    this.localFilters[index].toPortIds = [];
    this.localFilters[index].dischargeIds = [];
    this.localFilters[index].containerSizeIds = [];
    this.localFilters[index].containerTypeIds = [];
    // this.localFilters[index].freightRates = groupFreightRates;

    this.filterLocalGrid(index, group);
  }

  filterLocalGrid(index: number, group: any): void {
    let fRate = this.originalGroupedFreightRates.find(m => m.freightRateId == this.groupedFreightRates[index].freightRateId)
    let { freightRates } = fRate;

    this.loadpointIds = this.loadpointIds == undefined ? [] : this.loadpointIds;
    this.fromPortIds = this.fromPortIds == undefined ? [] : this.fromPortIds;
    this.toPortIds = this.toPortIds == undefined ? [] : this.toPortIds;
    this.dischargeIds = this.dischargeIds == undefined ? [] : this.dischargeIds;
    this.containerSizeIds = this.containerSizeIds == undefined ? [] : this.containerSizeIds;
    this.containerTypeIds = this.containerTypeIds == undefined ? [] : this.containerTypeIds;

    let { loadpointIds, fromPortIds, toPortIds, dischargeIds, containerSizeIds, containerTypeIds } = this.localFilters[index];
    loadpointIds = loadpointIds == undefined ? Array.from(new Set([...this.loadpointIds])) : Array.from(new Set([...this.loadpointIds, ...loadpointIds]));
    fromPortIds = fromPortIds == undefined ? Array.from(new Set([...this.fromPortIds])) : Array.from(new Set([...this.fromPortIds, ...fromPortIds]));
    toPortIds = toPortIds == undefined ? Array.from(new Set([...this.toPortIds])) : Array.from(new Set([...this.toPortIds, ...toPortIds]));
    dischargeIds = dischargeIds == undefined ? Array.from(new Set([...this.dischargeIds])) : Array.from(new Set([...this.dischargeIds, ...dischargeIds]));
    containerSizeIds = containerSizeIds == undefined ? Array.from(new Set([...this.containerSizeIds])) : Array.from(new Set([...this.containerSizeIds, ...containerSizeIds]));
    containerTypeIds = containerTypeIds == undefined ? Array.from(new Set([...this.containerTypeIds])) : Array.from(new Set([...this.containerTypeIds, ...containerTypeIds]));

    this.groupedFreightRates[index].freightRates = freightRates.filter(m => {
      return (loadpointIds != undefined && loadpointIds.length > 0 ? loadpointIds.indexOf(m.loadpointId != null ? m.loadpointId : 'null') != -1 : true) &&
        (fromPortIds != undefined && fromPortIds.length > 0 ? fromPortIds.indexOf(m.fromPortId != null ? m.fromPortId : 'null') != -1 : true) &&
        (toPortIds != undefined && toPortIds.length > 0 ? toPortIds.indexOf(m.toPortId != null ? m.toPortId : 'null') != -1 : true) &&
        (dischargeIds != undefined && dischargeIds.length > 0 ? dischargeIds.indexOf(m.dischargeId != null ? m.dischargeId : 'null') != -1 : true) &&
        (containerSizeIds != undefined && containerSizeIds.length > 0 ? containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
        (containerTypeIds != undefined && containerTypeIds.length > 0 ? containerTypeIds.indexOf(m.containerType) != -1 : true)
    });

  }
  // filterLocalGrid(index: number, group: any): void {
  //   let {originalFreightRates,freightRates} = this.groupedFreightRates[index];

  //   let groupRates = this.rateSheet.filter(m => { return m.freightRateId == group.freightRateId });

  //   // Remove all items
  //   groupRates.forEach(item => {
  //     let deleteIndex = this.rateSheet.indexOf(item);
  //     if (deleteIndex != -1) {
  //       this.rateSheet.splice(deleteIndex, 1);
  //     }
  //   });

  //   // Add filtered Items
  //   group.freightRates = this.localFilters[index].freightRates.filter(m => {
  //     return (this.localFilters[index].loadpointIds != undefined && this.localFilters[index].loadpointIds.length > 0 ? this.localFilters[index].loadpointIds.indexOf(m.loadpointName != null ? m.loadpointName : 'null') != -1 : true) &&
  //       (this.localFilters[index].fromPortIds != undefined && this.localFilters[index].fromPortIds.length > 0 ? this.localFilters[index].fromPortIds.indexOf(m.fromPortName != null ? m.fromPortName : 'null') != -1 : true) &&
  //       (this.localFilters[index].toPortIds != undefined && this.localFilters[index].toPortIds.length > 0 ? this.localFilters[index].toPortIds.indexOf(m.toPortName != null ? m.toPortName : 'null') != -1 : true) &&
  //       (this.localFilters[index].dischargeIds != undefined && this.localFilters[index].dischargeIds.length > 0 ? this.localFilters[index].dischargeIds.indexOf(m.dischargeName != null ? m.dischargeName : 'null') != -1 : true) &&
  //       (this.localFilters[index].containerSizeIds != undefined && this.localFilters[index].containerSizeIds.length > 0 ? this.localFilters[index].containerSizeIds.indexOf(m.containerSize) != -1 : true) &&
  //       (this.localFilters[index].containerTypeIds != undefined && this.localFilters[index].containerTypeIds.length > 0 ? this.localFilters[index].containerTypeIds.indexOf(m.containerType) != -1 : true)
  //   });

  //   group.freightRates.forEach(item => {
  //     this.rateSheet.push(item);
  //   });

  // }

  toggleAllSelection(control: string, values: Array<string>, isSelectAll: boolean) {
    if (isSelectAll) {
      switch (control) {
        case 'loadpointIds':
        case 'fromPortIds':
        case 'toPortIds':
        case 'dischargeIds':
          this[control] = values.map((value, index) => { return value['title'] });
          break;
        default:
          this[control] = values;
          break;
      }
    }
    else {
      this[control] = [];
    }
    this.filterGrid();
  }

  getCountries() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCountries, null, false).subscribe(res => {
      this.countries = res['data'];
    });
  }

  updateSellRate(isFieldValid: boolean, addToMargin: boolean): void {

    if (isFieldValid && this.margin.length > 0) {

      let buttons = [
        { 'title': 'Yes', 'result': 'yes', 'type': 'primary' },
        { 'title': 'No', 'result': 'no', 'type': 'accent' }
      ];

      this.modalDialogService.openDialog(
        'Confirm',
        'Are you sure you want to update all margin?',
        buttons).subscribe((res) => {
          if (res == 'yes') {
            this.isGobalMarginEdit = true;
            this.addToSellMargin = addToMargin;
            let marginText = this.margin;
            if (marginText.length > 0) {
              let operation = marginText[0];
              let margin = marginText.substr(1, marginText.length - 1);
              this.marginOld = this.margin;
              if (['+', '-', '*'].includes(operation)) {
                this.groupedFreightRates.forEach(m => {
                  m.margin = marginText
                  if (m.freightRates != undefined && m.freightRates != null) {
                    m.freightRates.forEach(item => {
                      if (parseFloat(item['sellRate']) > 0) {
                        item['sellRate'] = Utils.calculateSellRate(addToMargin ? item['sellRate'] : item['total'], margin, operation);
                      }
                    });
                  }
                });
              }
              // if (['+', '-', '*'].includes(operation)) {
              //   this.rateSheet.forEach(item => {
              //     if (parseFloat(item['sellRate']) > 0) {
              //       item['sellRate'] = Utils.calculateSellRate(addToMargin ? item['sellRate'] : item['total'], margin, operation);
              //     }
              //   });
              // }
            }
            this.toastMessageService.toast('top', 'center', 'success', 'Global margin applied successfully');
          } else {
            this.margin = this.marginOld;

          }
        });
    }


  }


  updateMarginSellRate(freightRateId: number, margin: string, isFieldValid: boolean, addToMargin: boolean): void {

    let marginText = margin;
    if (isFieldValid && marginText.length > 0) {
      let operation = marginText[0];
      let margin = marginText.substr(1, marginText.length - 1);
      if (['+', '-', '*'].includes(operation)) {
        this.rateSheet.filter(m => { return m.freightRateId == freightRateId }).forEach(item => {
          if (parseFloat(item['sellRate']) > 0) {
            item['sellRate'] = Utils.calculateSellRate(addToMargin ? item['sellRate'] : item['total'], margin, operation);
          }
        });
      }
      this.toastMessageService.toast('top', 'center', 'success', 'Margin applied successfully');
    }
  }

  getGroupDetails(group: any) {
    // var group = this.rateSheetOriginal.filter(m => { return m.freightRateId == freightRateId });
    if (group != null) {
      return group.carrierName + "<br>" +
        "Effective Date : " + this.datePipe.transform(group.effectiveDate, DateFormats.STANDARD_DATE) + "<br>" +
        "Expiry Date : " + this.datePipe.transform(group.expiryDate, DateFormats.STANDARD_DATE) + "<br>" +
        "Commodity : " + group.commodityName + "<br>" +
        "Created Date : " + (group.createdDate != null && group.createdDate != undefined ? this.datePipe.transform(group.createdDate, DateFormats.STANDARD_DATE) : "") +
        ((group.remarks == null || group.remarks.length == 0) ? "" : "<br> Remarks : " + group.remarks);
    }
  }

  getGroupCarrierDetails(group: any) {
    if (group != null && group != undefined) {
      return 'assets/images/carriers/' + group.carrierCode + '/' + group.carrierCode + '1.png';
    }
  }

  saveTierRates(): void {
    var tierParam = {
      'TierId': this.tierMasterId,
      'TierCode': this.createTierForm.controls['tierCode'].value,
      'Margin': this.margin,
      'IsMargin': this.isGobalMarginEdit,
      'AddToSellMargin': this.addToSellMargin,
      'IsNewRates': this.submitted,
      'CountryCode': this.countryCode,
      'AutoUpdate': this.autoTierUpdate,
      'Comments': this.createTierForm.controls['comments'].value,
      'CarrierIds': this.carrierIds == undefined ? [] : this.carrierIds,
      'CommodityIds': this.commodityIds == undefined ? [] : this.commodityIds,
    }

    var tierSellRate = [];

    this.loadpointIds = this.loadpointIds == undefined ? [] : this.loadpointIds;
    this.fromPortIds = this.fromPortIds == undefined ? [] : this.fromPortIds;
    this.toPortIds = this.toPortIds == undefined ? [] : this.toPortIds;
    this.dischargeIds = this.dischargeIds == undefined ? [] : this.dischargeIds;
    this.containerSizeIds = this.containerSizeIds == undefined ? [] : this.containerSizeIds;
    this.containerTypeIds = this.containerTypeIds == undefined ? [] : this.containerTypeIds;
    this.groupedFreightRates.forEach((item, index) => {
      let { loadpointIds, fromPortIds, toPortIds, dischargeIds, containerSizeIds, containerTypeIds } = this.localFilters[index];

      tierSellRate.push({
        'Id': item.id,
        'FreightRateId': item.freightRateId,
        'FreightRateChartList': item.freightRates == undefined || item.freightRates == null ? [] : item.freightRates.map(m =>{ return{FreightRateChartId: m.freightRateChartId,SellRate: m.sellRate}}),
        'EffectiveDate': item.effectiveDate,
        'ExpiryDate': item.expiryDate,
        'LoadpointIds': loadpointIds == undefined ? Array.from(new Set([...this.loadpointIds])) : Array.from(new Set([...this.loadpointIds, ...loadpointIds])),
        'FromPortIds': fromPortIds == undefined ? Array.from(new Set([...this.fromPortIds])) : Array.from(new Set([...this.fromPortIds, ...fromPortIds])),
        'ToPortIds': toPortIds == undefined ? Array.from(new Set([...this.toPortIds])) : Array.from(new Set([...this.toPortIds, ...toPortIds])),
        'DischargeIds': dischargeIds == undefined ? Array.from(new Set([...this.dischargeIds])) : Array.from(new Set([...this.dischargeIds, ...dischargeIds])),
        'ContainerSizeIds': containerSizeIds == undefined ? Array.from(new Set([...this.containerSizeIds])) : Array.from(new Set([...this.containerSizeIds, ...containerSizeIds])),
        'ContainerTypeIds': containerTypeIds == undefined ? Array.from(new Set([...this.containerTypeIds])) : Array.from(new Set([...this.containerTypeIds, ...containerTypeIds])),

      });
    });

    tierParam['TierRateChartList'] = tierSellRate;
    this.isLoading = true;

    this.httpService.httpPost(RouteConstants.FRIEGHT_saveTierFreightRatesNew, tierParam).subscribe(res => {
      this.isLoading = false;
      if (res['success'] == true) {
        if (this.isUpdateTierStatus) {
          this.isUpdateTierStatus = false;
          return this.changeTierStatus();
        }
        else {
          this.router.navigate(['/user/tiers']);
          return true;
        }
      }
      else {
        return false;
      }
    });

  }
  // saveTierRates(): void {
  //   var tierParam = {
  //     'TierId': this.tierMasterId,
  //     'TierCode': this.createTierForm.controls['tierCode'].value,
  //     'Margin': this.margin,
  //     'CountryCode': this.countryCode,
  //     'AutoUpdate': this.autoTierUpdate,
  //     'Comments': this.createTierForm.controls['comments'].value
  //   }

  //   var tierSellRate = [];
  //   this.rateSheet.forEach(item => {
  //     tierSellRate.push({
  //       'TierMasterId': item.tierMasterId,
  //       'CountryCode': tierParam.CountryCode,
  //       'FreightRateId': item.freightRateId,
  //       'FreightRateChartId': item.freightRateChartId,
  //       'EffectiveDate': item.effectiveDate,
  //       'ExpiryDate': item.expiryDate,
  //       'LoadpointId': item.loadpointId,
  //       'FromPortId': item.fromPortId,
  //       'ToPortId': item.toPortId,
  //       'DischargeId': item.dischargeId,
  //       'CommodityId': item.commodityId,
  //       'CarrierId': item.carrierId,
  //       'ContainerType': item.containerType,
  //       'ContainerSize': item.containerSize,
  //       'AllInUsd': item.allInUsd,
  //       'SellRate': item.sellRate,
  //       'TransitTimeIndays': item.transitTimeIndays,
  //       'Bas': item.bas,
  //       'Ihc': item.ihc,
  //       'Baf': item.baf,
  //       'Cfd': item.cfd,
  //       'Cfo': item.cfo,
  //       'Ddf': item.ddf,
  //       'Dhc': item.dhc,
  //       'Dpa': item.dpa,
  //       'Ebs': item.ebs,
  //       'Exp': item.exp,
  //       'Odf': item.odf,
  //       'Ohc': item.ohc,
  //       'Pss': item.pss,
  //       'Docs': item.docs,
  //       'FreeTime': item.freeTime,
  //       'TelexCharges': item.telexCharges,
  //       'AmendmentFees': item.amendmentFees,
  //       'DocFees': item.docFees,
  //       '_40stdFee': item._40stdFee,
  //       'WarRisk': item.warRisk,
  //       'Ecu': item.ecu,
  //       'Lsc': item.lsc,
  //       'Wha': item.wha,
  //       'NycPortFee': item.nycPortFee,
  //       'Total': item.total,
  //       'Vgm': item.vgm,
  //       'Comments': item.comments,
  //       'SailingDay': item.sailingDay,
  //       'Route': item.route,
  //     });
  //   });

  //   tierParam['TierRateChartList'] = tierSellRate;
  //   this.isLoading = true;

  //   this.httpService.httpPost(RouteConstants.FREIGHT_saveTierFreightRates, tierParam).subscribe(res => {
  //     this.isLoading = false;
  //     if (res['success'] == true) {
  //       if (this.isUpdateTierStatus) {
  //         this.isUpdateTierStatus = false;
  //         return this.changeTierStatus();
  //       }
  //       else {
  //         this.router.navigate(['/user/tiers']);
  //         return true;
  //       }
  //     }
  //     else {
  //       return false;
  //     }
  //   });

  // }

  deleteRatesheet(freightRateId: number, deleteIndex: number): void {
    var _thisRef = this;
    var buttons = [
      { 'title': 'Yes', 'result': 'yes', 'type': 'accent' },
      { 'title': 'No', 'result': 'no', 'type': 'default' }
    ];

    this.modalDialogService.openDialog('Confirm', 'Are you sure you want to delete ratesheet?', buttons).subscribe((res) => {
      if (res == 'yes') {
        var filteredGroups = _thisRef.groupedFreightRates.filter(m => { return m.freightRateId == freightRateId });
        if (filteredGroups.length == 1) {
          // let deleteRate = this.originalGroupedFreightRates.filter(m => { return m.freightRateId == freightRateId });
          _thisRef.groupedFreightRates.splice(_thisRef.groupedFreightRates.indexOf(filteredGroups[0]), 1);
          // this.originalGroupedFreightRates.splice(this.originalGroupedFreightRates.indexOf(filteredGroups[0]), 1);
          // _thisRef.rateSheet = _thisRef.rateSheet.filter(m => { return m.freightRateId != freightRateId });
          // _thisRef.rateSheetOriginal = _thisRef.rateSheetOriginal.filter(m => { return m.freightRateId != freightRateId });
        }
        _thisRef.localFilters.splice(deleteIndex, 1);
      }
    });
  }

  approveRejectTier(statusId: number): void {

    this.updatedStatusId = statusId;
    this.updateTierStatus();
  }

  changeTierStatus(): boolean {
    var param = {
      'TierId': this.tierMasterId,
      "Status": this.updatedStatusId
    }
    this.httpService.httpPost(RouteConstants.FREIGHT_changeTierStatus, param).subscribe(res => {
      this.isLoading = false;
      if (res['success'] == true) {
        this.router.navigate(['/user/tiers']);
        return true;
      }
      else {
        return false;
      }
    });

    return false;
  }

  updateTierStatus(): boolean {
    this.isLoading = true;
    this.isUpdateTierStatus = true;
    this.saveTierRates();///check if page has changed

    return true;
  }


  returnTierStatusText(status: number) {
    if (status > 0)
      return TierConstants.TIER_STATUSES.find(m => m.id == status).description;
  }

  notifyCustomers() {
    let htmlContent = "";
    let param = {};
    const dialogRef = this.dialog.open(CkEditorComponent, {});

    dialogRef.afterClosed().subscribe(res => {
      htmlContent = res;
      param = {
        'TierId': this.tierMasterId,
        'HtmlContent': htmlContent
      }
      if (htmlContent != null) {
        this.httpService.httpPost(RouteConstants.FRIEGHT_tierRateChangeEmail, param).subscribe(res => { });
      }
      else { return }
    });
  }

  tradeNotice(freightId: number, index: number, tradeNotice: string) {
    const dialogRef = this.dialog.open(RemarkPopupComponent, {
      width: '450px',
      data: { heading: "Trade Notice", label: "Remark", remark: tradeNotice, isDisabled: this.isDisabled }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != undefined && res.Success) {
        var param = {
          FreightId: freightId,
          TierId: this.tierMasterId,
          Comments: res.message,
        }
        this.httpService.httpPost(RouteConstants.FRIEGHT_updateTradeNotice, param).subscribe(result => {
          if (result["success"]) {
            this.groupedFreightRates[index].tradeNotice = res.message;
          }
        });
      }
    });
  }
}