import { Component, OnInit } from '@angular/core';
import { HttpService } from "../services/http/http.service";
import { RouteConstants } from "../constants/route-constants";
import { Router } from '@angular/router';
import { EncryptionService } from "../services/encryption/encryption.service";
import { UserInfoService } from "../services/userInfo/user-info.service";
import { ActivatedRoute } from "@angular/router";
import {
  ProfileStatusConstants, Pagination, GridColumnType,
  GridColumnDataType, GridActionType
} from "../constants/constants";
import { TitleCasePipe } from "@angular/common"

@Component({
  selector: 'inbrit-pending-profiles',
  templateUrl: './pending-profiles.component.html',
  styleUrls: ['./pending-profiles.component.scss']
})
export class PendingProfilesComponent implements OnInit {
  statusId: number;
  customerList: [any];
  title: string;
  icon: string;
  dataCount: number;
  pageIndex = 1;
  pageSize = Pagination.PageSize;
  titleCasePipe = new TitleCasePipe();
  filter: string = '';
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Name', dataField: 'fullName', type: GridColumnType.DATA, },
      { title: 'Company', dataField: 'companyName', type: GridColumnType.DATA, },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      { title: 'Pending Since', dataField: 'pendingSince', type: GridColumnType.DATA, },
      {
        title: 'Action', dataField: 'email', type: GridColumnType.ACTION, actions: [
          { event: 'viewProfile', type: GridActionType.ICON, title: 'View Profile', class: 'visibility' },
        ]
      }
    ]


  constructor(private httpService: HttpService, private router: Router,
    private encryptionService: EncryptionService, private activatedRoute: ActivatedRoute,
    private userInfoService: UserInfoService) {
    // this.activatedRoute.params.subscribe(params => { this.statusId = params['id'] });
  }

  ngOnInit() {
    var statusId = 0;
    if (this.router.url == '/user/pending-approval') {
      statusId = ProfileStatusConstants.PENDING_APPROVAL;
      this.title = "Pending Approval";
      this.icon = "update";
    }
    else if (this.router.url == '/user/pending-confirmation') {
      statusId = ProfileStatusConstants.PENDING_CONFIRMATION;
      this.title = "Pending Confirmation";
      this.icon = "check_circle";
    }
    this.statusId = this.encryptionService.encrypt(statusId);    

    this.getCustomers();
  }

  getCustomers(): void {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": this.pageSize,
      "StatusIds": [parseInt(this.encryptionService.decrypt(this.statusId))]

    };
    this.httpService.httpPost(RouteConstants.ACCOUNT_getUsers + "?searchText=" + this.filter, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];
        this.dataCount = res['count'];
        data.forEach(item => {
          item['fullName'] = this.titleCasePipe.transform(item['firstName'] + ' ' + item['lastName']);
          item['companyName'] = this.titleCasePipe.transform(item['userCompanyDetail']['companyName']);
          item['pendingSince'] = this.getFormattedHours(item['createdDate']);
        });
        this.customerList = data;
      }
    });
  }

  viewProfile(customerId: number): void {
    if (this.router.url == '/user/pending-approval') {
      this.router.navigate(['user/pending-approval', this.encryptionService.encrypt(customerId)]);
    }
    else if (this.router.url == '/user/pending-confirmation') {
      this.router.navigate(['user/pending-confirmation', this.encryptionService.encrypt(customerId)]);
    }

  }

  gridEventHandler(evt): void {
    this.viewProfile(evt.data.id);
  }

  paginationEventHandler(evt): void {
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.getCustomers();
  }

  getFormattedHours(createdDate: any): string {
    return this.userInfoService.getLocalizedFormattedHours(createdDate);
  }

}
