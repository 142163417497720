
import { AngularFireAuth } from 'angularfire2/auth';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from "../../services/loader/loader.service";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";
import { UserManagementService } from '../user-firebase-subscription/user-firebase-subscription.service';
import { CacheService } from '../cache-service/cache.service';


@Injectable()
export class AuthService {

  authState: any = null;

  constructor(private router: Router, private loaderService: LoaderService,
    private afAuth: AngularFireAuth, private toastMessageService: ToastMessageService,
    private userManagementService: UserManagementService, private cacheService: CacheService) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  anonymousLogin() {
    this.cacheService.deleteAll();
      return this.afAuth.auth.signInAnonymously()
        .then((user) => {
          this.authState = user;
          this.loaderService.display(false);
          this.userManagementService.subscribeToFirebase();
        })
        .catch(error => {
          this.toastMessageService.toast('top', 'center', 'danger', error);
          this.loaderService.display(false);
        });
  }

  signOut(): void {
    this.afAuth.auth.signOut();
  }
}