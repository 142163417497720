import { Component, OnInit, Input } from '@angular/core';
import { RouteConstants } from '../../constants/route-constants';
import { HttpService } from '../../services/http/http.service';
import { EncryptionService } from '../../services/encryption/encryption.service';
import { GridColumnType, GridColumnDataType, GridActionType, UserRoleConstants } from 'src/app/constants/constants';
import { LoginTraceDetailsComponent } from '../login-trace-details/login-trace-details.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { UserManagementService } from 'src/app/services/user-firebase-subscription/user-firebase-subscription.service';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Component({
  selector: 'inbrit-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.scss']
})
export class LoginHistoryComponent implements OnInit {
  loginHistory: [any];
  dataCount: number;
  pageIndex: number = 1;
  user: any;
  UserRoleConstants = UserRoleConstants;
  @Input() userId: number;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Date', dataField: 'loginDate', type: GridColumnType.DATA, dataType: GridColumnDataType.DATE },
      { title: 'Count', dataField: 'loginCount', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      {
        title: 'Action', dataField: 'email', type: GridColumnType.ACTION, actions: [
          { event: 'viewDetails', type: GridActionType.ICON, title: 'View Detail',class: 'visibility' },
        ]
      }
    ];

  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute,
    private encryptionService: EncryptionService, private dialog: MatDialog,
    private userManagementService: UserManagementService, private toastMessageService: ToastMessageService,
    private userInfoService: UserInfoService) {
    this.activatedRoute.params.subscribe(params => { this.userId = this.encryptionService.decrypt(params['id']) });
  }

  ngOnInit() {
    this.user = this.userInfoService.getLoggedInUser();
    this.getLoginTraceDetails();
  }

  getLoginTraceDetails(): void {
    var param = { 'userId': this.userId }
    this.httpService.httpGet(RouteConstants.ACCOUNT_getLoginTraceDetails, param).subscribe(res => {
      this.loginHistory = res['data'];
      this.loginHistory.forEach(item => {
        item['userId'] = this.userId;
      });
      this.dataCount = res['count'];
    });
  }

  gridEventHandler(evt: any): void {
    switch (evt.event) {
      case 'viewDetails':
        {
          this.showLoginTraceDetails(evt.data);
          break;
        }
    }
  }

  showLoginTraceDetails(user: any): void {
    const dialogRef = this.dialog.open(LoginTraceDetailsComponent, {
      width: '70%',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  logoutFromAllDevices(): void {
    this.userManagementService.logoutFromAllDevices(this.userId.toString(), false);
    this.toastMessageService.toast('top', 'center', 'success', "User logged out from devices.");
  }

  isLogoutAllDeviceVisible(): boolean {
    if (this.user.roleId == UserRoleConstants.USER) {
      return true;
    } else if (this.loginHistory && (this.loginHistory.length > 1 || this.loginHistory[0]['loginCount'] > 1)) {
      return true;
    }
    else {
      return false;
    }
  }
}
