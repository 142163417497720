import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { FreightConstants } from 'src/app/constants/constants';
import { RouteConstants } from 'src/app/constants/route-constants';
import { HttpService } from 'src/app/services/http/http.service';
import { TypeaheadValidator } from 'src/app/shared/custom-validators/typeahead-validator';

@Component({
  selector: 'inbrit-add-compare',
  templateUrl: './add-compare.component.html',
  styleUrls: ['./add-compare.component.scss']
})
export class AddCompareComponent implements OnInit {
  carrierMasters: Array<any>;
  commoditMasters: Array<any>;
  rateSheetMasters: Array<any>;
  compareRateSheetForm: FormGroup;
  submitted: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddCompareComponent>,
    private formBuilder: FormBuilder, private httpService: HttpService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.compareRateSheetForm = this.formBuilder.group({
      carrier: [''],
      rateSheet: ['', [Validators.required]]
    });
    this.getAllCarrierMaster();
    this.getAllCommodityMaster();
  }

  get compareRSheetform() { return this.compareRateSheetForm.controls; }

  getAllCarrierMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null).subscribe(res => {
      this.carrierMasters = res['data'];
    });
  }

  getAllCommodityMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCommodity, null).subscribe(res => {
      this.commoditMasters = res['data'];
    });
  }

  getRatesheets(event: any) {
    var freightStatusIds = [FreightConstants.APPROVED];

    var param = {
      "StatusIds": freightStatusIds,
      "CarrierId": event.value
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_getfreightrates, param).subscribe(res => {
      if (res['success'] == true) {
        this.rateSheetMasters = res['data'];
        this.rateSheetMasters.forEach(m => {
          let commodity = this.commoditMasters.find(cmd => cmd.id == m.commodityId);
          m["commodityName"] = commodity != null && commodity != undefined ? commodity.commodityName : "";
        });
        this.compareRateSheetForm.controls.rateSheet.setValidators([Validators.required,TypeaheadValidator.typeaheadValidator(this.rateSheetMasters,'id')])
      }
    });
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (!this.compareRateSheetForm.valid) {
      return;
    }
    var carrierId = parseInt(this.compareRateSheetForm.controls['carrier'].value);
    var carrier = this.carrierMasters.find(m => { return m.id == carrierId });
    var freightId = parseInt(this.compareRateSheetForm.controls['rateSheet'].value);
    var freight = this.rateSheetMasters.find(m => { return m.id == freightId });
    var result = {
      carrierCode: carrier.carrierCode,
      freightId: freightId,
      effectiveDate: freight.effectiveDate,
      expiryDate: freight.expiryDate
    }
    this.dialogRef.close(result);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  rateSheetDisplay(id){
    if(id != "" && id != null && id != undefined && this.rateSheetMasters != undefined && this.rateSheetMasters.length > 0){
      let rateSheet = this.rateSheetMasters.filter(m => { return m.id == id })[0];
      return  rateSheet.commodityName
      // "Commodity : " +  this.rateSheet1.commodityName + " Effective Date : " + 
      // this.rateSheet1.effectiveDate + " Expiry Date : " + this.rateSheet1.expiryDate + " Remarks : " + this.rateSheet1.remarks;
    }
  }
}