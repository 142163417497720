import { Component, OnInit, ElementRef, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { UserInfoService } from "../../services/userInfo/user-info.service";
import { Subscription } from 'rxjs';

import { UserRoleConstants } from 'src/app/constants/constants';
import { AuthService } from '../../services/auth/auth.service';
import { UserManagementService } from 'src/app/services/user-firebase-subscription/user-firebase-subscription.service';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    public user: any;
    private menuToggle: boolean = true;
    @Input() menu: any;
    subscription: Subscription;

    constructor(location: Location, private element: ElementRef, private router: Router,
        private encryptionService: EncryptionService, private userInfoService: UserInfoService,
        private authService: AuthService, private userManagementService: UserManagementService) {
        this.location = location;
        this.sidebarVisible = false;

        this.subscription = this.userInfoService.getUserChanges().subscribe(userObj => {
            if (userObj) {
                var user = this.userInfoService.getLoggedInUser();
                this.user = user;
            }
        });
    }

    ngOnInit() {
        // Get logged in user
        this.user = this.userInfoService.getLoggedInUser();
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }

    toggler() {
        var context = this;
        this.menu.toggle();
        var $panel: any = document.getElementsByClassName('main-panel')[0];


        if (!context.menu.opened) {
            setTimeout(function () {
                $panel.classList.add('full-width');
            }, 300);
        } else {
            $panel.classList.remove('full-width');
        }
    }

    navigateToProfile() {
        var initialPath = '/user/';
        if (this.user.roleId == UserRoleConstants.CUSTOMER) {
            initialPath = '/customer/'
        }
        this.router.navigate([initialPath + 'user-profile', this.encryptionService.encrypt(this.user.id)]);
    }

    navigateToLoginHistory(): void {
        var initialPath = '/user/';
        if (this.user.roleId == UserRoleConstants.CUSTOMER) {
            initialPath = '/customer/'
        }
        this.router.navigate([initialPath + 'login-history', this.encryptionService.encrypt(this.user.id)]);
    }

    Logout() {
        this.authService.signOut();
        this.userManagementService.logoutUser();
    }
}