import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { NotificationService } from "../../services/notification/notification.service";
import { UserInfoService } from "../../services/userInfo/user-info.service";
import { RouteConstants } from "../../constants/route-constants";
import { Router } from '@angular/router';
import { UserRoleConstants } from 'src/app/constants/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'inbrit-nav-bar-notification',
  templateUrl: './nav-bar-notification.component.html',
  styleUrls: ['./nav-bar-notification.component.scss']
})
export class NavBarNotificationComponent implements OnInit, OnDestroy {
  user: any;
  notificationList = [];
  notificationCount: number = 0;
  profilePrefix: string = '/user';
  subscription: Subscription;
  
  constructor(private httpService: HttpService, private userInfoService: UserInfoService,
    private router: Router, private notificationService: NotificationService) {

    var user = this.userInfoService.getLoggedInUser();
    if (user != null) {
      this.user = user;

      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        this.profilePrefix = '/customer';
      }
    }

    this.subscription = this.notificationService.notifyNotificationChanges().subscribe(notification => {
      this.getNotifications();
    });

  }

  ngOnInit() {
    this.getNotifications();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  getNotifications(): void {
    var param = { 'PageIndex': 1, 'PageSize': 5, 'IsRead': false };
    this.httpService.httpPost(RouteConstants.NOTIFICATION_getAllReceivedNotifications, param, false).subscribe(res => {
      if (res['success'] == true) {
        this.notificationList = res['data'];
        this.notificationCount = res['count'];
        this.notificationList.forEach(item => {
          item['received'] = this.userInfoService.getLocalizedFormattedHours(item['createdDate']);
        });
      }
    });
  }

  navigateUserToActionPage(notification: any): void {
    notification.roleId = this.user.roleId;
    this.notificationService.navigateToAction(notification);
  }

  seeAllNotifications(): void {
    this.router.navigate([this.profilePrefix + '/notifications']);
  }
}