import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratesOnlyfilter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], term: string): any {
    if (isNaN(parseInt(term)) && term != '') {
      items.forEach(item => {
        item['hidden'] = !item['title'].toString().toLowerCase().includes(term.toLowerCase())
      });
      return items;
    }
    else {
      items.forEach(item => {
        item['hidden'] = false
      });
      return items;
    }
  }

}
