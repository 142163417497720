import { Component, OnInit } from '@angular/core';
import { LoaderService } from './services/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from './services/user-firebase-subscription/user-firebase-subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Inbrit';
  showLoader: boolean = false;  

  constructor(private loaderService: LoaderService, private translate: TranslateService,
    private userManagementService: UserManagementService) {
    this.translate.setDefaultLang('en-us');
  }

  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.showLoader = val;
      }, 0);
    });

    this.userManagementService.subscribeToFirebase();
  }
}
