export class RouteConstants {
    public static ACCOUNT_userLogin = "api/account/userlogin";
    public static ACCOUNT_requestResetPassword = "api/account/requestresetpassword";
    public static ACCOUNT_resetPassword = "api/account/resetpassword";
    public static ACCOUNT_isUserExists = "api/account/isuserexists";
    public static ACCOUNT_registerUser = "api/account/registeruser";
    public static ACCOUNT_getUser = "api/account/getuser";
    public static ACCOUNT_getUsers = "api/account/getusers";
    public static ACCOUNT_getTierUsers = "api/account/gettierusers";
    public static ACCOUNT_getLoginTraceDetails = "api/account/getlogintracedetails";
    public static ACCOUNT_getLoginTraceDetailsByDate = "api/account/getlogintracedetailsbydate";

    public static ACCOUNT_updateUser = "api/account/updateuser";
    public static ACCOUNT_updateUserProfilePhoto = "api/account/UpdateUserProfilePhoto";
    public static ACCOUNT_updateUserDetails = "api/account/updateuserdetails";
    public static ACCOUNT_blockOrUnblockUser = "api/account/blockorunblockuser";
    public static ACCOUNT_updateUserKycStatus = "api/account/updateuserkycstatus";
    public static ACCOUNT_inviteCustomer = "api/account/invitecustomer";
    public static ACCOUNT_getInviteCustomer = "api/account/getinvitecustomers";
    public static ACCOUNT_getInviteCustomerById = "api/account/getinvitecustomerbyid";
    public static ACCOUNT_resendVerificationEmail = "api/account/ResendVerificationEmail";
    

    public static MASTER_getKycMasters = "api/master/getkycmasters";
    public static MASTER_getAllCountries = "api/master/getallcountries";
    public static MASTER_getAllStates = "api/master/getallstates";
    public static MASTER_getUserTitleMasters = "api/master/getusertitlemasters";
    public static MASTER_getAllTierMaster = "api/master/getalltiermaster";
    public static MASTER_getApprovedTierMaster = "api/master/getapprovedtiermaster";

    public static MASTER_getAllCarrierMaster = "api/master/getallcarriermaster";
    public static MASTER_getAllLoadingPoints = "api/master/getallloadingpoints";
    public static MASTER_getAllCommodity = "api/master/getallcommodity";
    public static MASTER_getAllPortcode = "api/master/getallports";
    public static MASTER_getAllLocations = "api/master/getalllocations";
    public static MASTER_getAllContainerTypes = "api/master/getallcontainertypes";
    public static MASTER_getBookingDetailMasters = "api/master/getBookingDetailMasters";

    public static NOTIFICATION_getAllReceivedNotifications = "api/Notification/getallreceivednotifications";
    public static NOTIFICATION_updateNotificationMessages = "api/Notification/updatenotificationmessages";
    public static NOTIFICATION_markReadOrDelete = "api/Notification/markreadordelete";

    public static CustomerKyc_getKycDetails = "api/customerkyc/getkycdetails";
    public static CustomerKyc_saveKycDetails = "api/customerkyc/savekycdetails";

    public static FREIGHT_getFreightRate = "api/FreightRate/getpendingratesheet";
    public static FREIGHT_getTierFreightRates = "api/freightrate/gettierfreightrates";
    public static FREIGHT_getAllTierMaster = "api/freightrate/getalltiermaster";
    public static FREIGHT_updateTierMaster = "api/freightrate/updatetiermaster";
    public static FREIGHT_getTierRateChart = "api/freightrate/gettierratechart";
    public static FREIGHT_saveTierFreightRates = "api/freightrate/savetierfreightrates";
    public static FREIGHT_saveTierUserMapping = "api/freightrate/savetierusermapping";
    public static FREIGHT_getFreightRateDetail = "api/FreightRate/getfreightratedetails";
    public static FREIGHT_changeTierStatus = "api/FreightRate/updatetierStatus";
    public static FREIGHT_changeFreightStatus = "api/FreightRate/changefreightstatus";
    public static FREIGHT_getFreightRateDiff = "api/FreightRate/getratesheetdifference";
    public static FREIGHT_getfreightrates = "api/FreightRate/getfreightrates";
    public static FREIGHT_getBookingFreightRate = "api/FreightRate/getbookingfreightrates";
    public static FRIEGHT_tierRateChangeEmail = "api/FreightRate/tierratechangemail";
    public static FRIEGHT_updateFreightRateDates = "api/FreightRate/updateFreightRateDates";
    public static FRIEGHT_updateTradeNotice = "api/FreightRate/updateTradeNotice";
    public static FRIEGHT_getTierArchiveRateChart = "api/FreightRate/getTierArchiveRateChart";
    public static FRIEGHT_getAllTierArchiveMaster = "api/FreightRate/getAllTierArchiveMaster";
    public static FRIEGHT_savefreightRateChart = "api/FreightRate/savefreightRateChart";
    public static FRIEGHT_deletefreightRateChart = "api/FreightRate/deletefreightRateChart";
    public static FRIEGHT_getTierArchiveRateChartHeader = "api/FreightRate/getTierRateChartHeader";
    public static FRIEGHT_getTierRateChartByFreightId = "api/FreightRate/getTierRateChartByFreightId";
    public static FRIEGHT_getFreightRatesForTier = "api/FreightRate/getFreightRatesForTier";
    public static FRIEGHT_getFreightRatesChartTier = "api/FreightRate/getFreightRatesChartTier";
    public static FRIEGHT_saveTierFreightRatesNew = "api/FreightRate/saveTierFreightRatesNew";

    public static FREIGHT_saveSpotBuyRate = "api/SpotRate/savespotbuyrates";
    public static FREIGHT_saveSpotBuyRateStatus = "api/SpotRate/SaveSpotBuyRateStatus";
    public static FREIGHT_saveSpotBuyRateAttachments = "api/SpotRate/savespotbuyratesattachments";
    public static FREIGHT_deleteSpotBuyRateAttachments = "api/SpotRate/deletespotbuyratesattachments";

    public static FREIGHT_getSpotBuyRate = "api/SpotRate/getspotbuyrates";
    public static FREIGHT_getSpotBuyRateById = "api/SpotRate/getspotbuyratesbyid";

    public static FREIGHT_getallSpotBuyRate = "api/SpotRate/getallspotbuyrate";
    public static FREIGHT_getSpotSellTierFreightRates = "api/SpotRate/getspotfreightrates";
    public static FREIGHT_saveSpotFreightRates = "api/SpotRate/savespotfreightrates";
    public static FREIGHT_updateSpotRateSell = "api/SpotRate/updatespotrate";
    public static FREIGHT_getAllSpotRate = "api/SpotRate/getallspotrate";
    public static FREIGHT_getSpotSellChartRates = "api/SpotRate/getspotsellchartrate";
    public static FREIGHT_changeSpotRateStatus = "api/SpotRate/changespotratestatus";
    public static FREIGHT_saveSpotUserMapping = "api/SpotRate/savespotrateusermapping";
    public static FREIGHT_getUnassignedCustomer = "api/SpotRate/getunassignedspotusers";
    public static FREIGHT_getBuyCountryCarrier = "api/SpotRate/getbuycountrycarrier";


    public static QUOTATION_getcustomerwithtier = "api/quotation/getcustomerwithtier";
    public static QUOTATION_request = "api/quotation/getquotation";
    public static QUOTATION_getAllQuotations = "api/quotation/getallquotations";
    public static QUOTATION_getQuotationById = "api/quotation/getquotationbyid";
    public static QUOTATION_sendCheckRateMail = "api/quotation/sendCheckRateMail";

    public static BOOKING_saveBooking = "api/booking/savebooking";
    public static BOOKING_getAllBookings = "api/booking/getallbookings";
    public static BOOKING_getBookingById = "api/booking/getbookingbyid";
    public static BOOKING_uploadFileToFtpServer = "api/booking/uploadfiletoftpserver";
    public static BOOKING_downloadFile = "api/booking/downloadfile";


    public static RATESHEET_PROCESSOR_processRatesheet = "api/RatesheetProcessor/processratesheet";
    public static RATESHEET_PROCESSOR_dashboard = "api/RatesheetProcessor/dashboardratesheet";
    public static RATESHEET_PROCESSOR_getProcessRatesheetById = "api/RatesheetProcessor/getprocessratesheetbyid";

    public static ACCESS_CONTROL_getPersonaUsers = "api/AccessControl/getPersonaUsers";
    public static ACCESS_CONTROL_updateUserPersonas = "api/AccessControl/updateUserPersonas";
    public static ACCESS_CONTROL_assignDocument = "api/AccessControl/assignDocument";

    public static GROUP_GetGroups = "api/Group/GetGroups";
    public static GROUP_GetGroupDetails = "api/Group/GetGroupDetails";
    public static GROUP_AddGroup = "api/Group/AddGroup";
    public static GROUP_UpdateGroup = "api/Group/UpdateGroup";
    public static GROUP_DeleteGroup = "api/Group/DeleteGroup";
    public static GROUP_AddGroupUser = "api/Group/AddGroupUser";
    public static GROUP_DeleteGroupUser = "api/Group/DeleteGroupUser";

    public static MEARKS_Upload_Haulage="api/Mearks/importmearkshaulage"
    public static MEARKS_Get_Haulage="api/Mearks/getmearkshaulage"
    public static AUTO_GEN_DATA = "api/Quotation/GetQuotationExcelData?pageIndex=1&pageSize=100"
}