import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import { Ng2DeviceService } from 'ng2-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  deviceInfo: any = { 'ipAddress': '' };

  constructor(private http: HttpClient, private deviceService: Ng2DeviceService) {
  }

  //Get IP Adress using http://freegeoip.net/json/?callback
  //1. https://ipapi.co/json/ -- Gives the public ip address
  //2. https://jsonip.com/?callback=? -- Gives the public ip address PLUS some
  // advertisements in property 'reject-fascism'
  private getIpAddress() {
    return this.http.get('https://ipapi.co/json/').map(response => response);
  }

  initializeUserTrackingDetail() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    var _this = this;
    this.getIpAddress().subscribe(res => {
      _this.deviceInfo.ipAddress = res['ip'];
    });
  }

  getUserTrackingDetail() {
    return this.deviceInfo;
  }
}
