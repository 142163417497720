import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastMessageService } from '../services/toast-message/toast-message.service';
import { LoaderService } from '../services/loader/loader.service';
declare var $: any;
import { Router } from '@angular/router';
import { map, filter, tap } from 'rxjs/operators';
import { UserInfoService } from '../services/userInfo/user-info.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    constructor(private toastMessageService: ToastMessageService, private loaderService: LoaderService,
        private router: Router, private userInfoService: UserInfoService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.userInfoService.getLoggedInUser();
        var autoLoader = request.headers.get('Loader');
        if(autoLoader == 'true') {
            this.loaderService.display(true);
        }
        
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(tap(event => {
            // Intercept the response and show the notification accordingly.
            if (event instanceof HttpResponse) {
                // Check body.Message with undefined to verify the property
                // exists in returned response.
                if (event.body.message !== undefined && event.body.message !== '' && event.body.message !== null) {
                    if (event.body.success === true) {
                        this.toastMessageService.toast('top', 'center', 'success', event.body.message);
                    } else {
                        this.toastMessageService.toast('bottom', 'center', 'danger', event.body.message);
                    }
                }
                if(autoLoader == 'true') {
                    this.loaderService.display(false);
                }

                if (event.body.code !== undefined && event.body.code == 401) {
                    localStorage.setItem('currentUser', null);
                    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                }
            }
            return event;
        }));
    }
}
