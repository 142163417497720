import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inbrit-kyc-approval',
  templateUrl: './kyc-approval.component.html',
  styleUrls: ['./kyc-approval.component.scss']
})
export class KycApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
