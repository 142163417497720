import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { HttpService } from "../../services/http/http.service";
import { ToastMessageService } from "../../services/toast-message/toast-message.service";
import { RouteConstants } from "../../constants/route-constants";
import { UserRoleConstants } from '../../constants/constants';
import { TitleCasePipe } from '@angular/common';
import { TierConstants } from 'src/app/constants/constants';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CacheService } from 'src/app/services/cache-service/cache.service'; 

@Component({
  selector: 'inbrit-tier-user-mapping',
  templateUrl: './tier-user-mapping.component.html',
  styleUrls: ['./tier-user-mapping.component.scss']
})
export class TierUserMappingComponent implements OnInit {
  masterTierList: [any];
  unAssignedCustomers = [];
  customerList = [];
  pageIndex = 1;
  masterTierContainers = [];
  tierUserMappingDelta = [];
  defaultTierId: number = 0;
  titleCasePipe = new TitleCasePipe();
  TierConstants = TierConstants;
  isLoading: boolean = false;
  filterDialogRef: MatDialogRef<{}, any>;
  totalFiltersApplied: number = 0;
  filterTierUserLinkForm: FormGroup;
  uniqueCacheKey: string = 'TierUserMappingComponent-filter';
  tierSearchText: string = '';
  customerSearchText: string = '';
  tierStatusIds = [];
  toggle={};
  
  constructor(private httpService: HttpService, private toastMessageService: ToastMessageService,
    private userInfoService: UserInfoService, private formBuilder: FormBuilder, private dialog: MatDialog,
    private cacheService: CacheService, private cdr: ChangeDetectorRef) { }

  get filterFormFields() { return this.filterTierUserLinkForm.controls; }

  ngOnInit() {
    this.initializeFilterForm();
    this.getAllTiers();
  }

  initializeFilterForm(): void {
    var cachedFilter = this.cacheService.get(this.uniqueCacheKey);
    this.filterTierUserLinkForm = this.formBuilder.group({
      tierStatusIds: [cachedFilter != null ? cachedFilter.value.tierStatusIds.value : [], []]
    });
  }

  getstatus(strStatus: number) {
    return TierConstants.TIER_STATUSES.find(m => m.id == strStatus).description;
  }

  getClassColor(strStatus: number) {
    return this.userInfoService.getColorClass(strStatus);
  }

  getAllTiers() {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": 0,
      "SearchText": ""
    }

    this.httpService.httpPost(RouteConstants.FREIGHT_getAllTierMaster, param).subscribe(res => {
      if (res['success'] == true) {
        this.masterTierList = res['data'];
        this.getCustomers();
      }
    });
  }

  getCustomers(): void {
    var param = {
      "PageIndex": this.pageIndex, "PageSize": 0,
      "StatusIds": [],
      "KycStatusIds": [],
      "RoleIds": [UserRoleConstants.CUSTOMER]
    }

    this.httpService.httpPost(RouteConstants.ACCOUNT_getTierUsers, param).subscribe(res => {
      if (res['success'] == true) {
        var data = res['data'];

        data.forEach(item => {
          item['fullName'] = this.titleCasePipe.transform(item['firstName'] + ' ' + item['lastName']);
          item['class'] = '';
          item['isHidden'] = false;
        });
        // this.customerListOriginal = data;
        this.customerList = data;
        this.extractTierUsers();
      }
    });
  }


  toggleSelection(customer: any) {
    customer['class'] = customer['class'] == '' ? 'selected' : '';
  }
  
  openAccordion(index){
    this.toggle[index]=!this.toggle[index];
  }

  extractTierUsers() {
    this.unAssignedCustomers = this.customerList.filter(m => { return m.tierId == null || m.tierId == 0 });
    // this.masterTierContainers.push('cdk-drop-list-0');
    this.masterTierList.forEach(tier => {
      this.masterTierContainers.push('cdk-drop-list-' + tier.tierId.toString());
      tier['customerList'] = this.customerList.filter(m => { return m.tierId == tier.tierId });
      tier['isHidden'] = false;
    });
    this.applyFilters();
  }

  drop(event: CdkDragDrop<string[]>) {  
    let trimtext: any = event.container.id;
    this.toggle[trimtext.split("-").pop()]=true;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Check if user has selected multiple items to drag
      var selectedItems = event.previousContainer.data.filter(m => { return m['class'] == 'selected' });
      if (selectedItems.length > 0) {
        var prevItemIndex = 0;

        selectedItems.forEach(item => {
          prevItemIndex = event.previousContainer.data.indexOf(item);

          // Update the delta list first and then move the item
          this.updateMappingDelta(event.container.id, item);

          transferArrayItem(event.previousContainer.data,
            event.container.data,
            prevItemIndex,
            event.currentIndex);

          // removed the selection
          item['class'] = '';
        });

      }
      else {

        // Update the delta list first and then move the item
        this.updateMappingDelta(event.container.id, event.previousContainer.data[event.previousIndex]);

        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
    this.cdr.detectChanges();
  }


  updateMappingDelta(containerId: string, customer: any): void {
    var containerId = containerId;
    var arr = containerId.split('-');
    var newTierId = parseInt(arr[arr.length - 1]);
    var newItem = { 'TierId': newTierId, 'UserId': customer.id }
    var existingData = this.tierUserMappingDelta.filter(m => { return m.UserId == customer.id });

    if (existingData.length > 0) {
      this.tierUserMappingDelta.splice(this.tierUserMappingDelta.indexOf(existingData[0]), 1);
    }

    this.tierUserMappingDelta.push(newItem);
  }

  saveTierUserMappings(): void {
    if (this.tierUserMappingDelta.length == 0) {
      this.toastMessageService.toast('bottom', 'center', 'danger', "No change detected in mappings.");
      return;
    }
    this.isLoading = true;
    var param = { 'Users': this.tierUserMappingDelta.map(m => { return { 'Id': m.UserId, 'TierId': m.TierId } }) };
    this.httpService.httpPost(RouteConstants.FREIGHT_saveTierUserMapping, param).subscribe(res => {
      this.tierUserMappingDelta = [];
      this.isLoading = false;
    });
  }

  openFilter(filterForm): void {
    this.filterDialogRef = this.dialog.open(filterForm, {
      width: '250px',
      panelClass: 'filter-popup'
    });
  }

  filterFormSubmit() {
    this.tierSearchText = '';
    this.applyFilters();
    // this.filterDialogRef.close();
    var filters = this.cacheService.getFormValues(this.filterTierUserLinkForm);
    this.cacheService.set(this.uniqueCacheKey, filters);
  }

  clearFilter(): void {
    this.filterTierUserLinkForm.reset();
    this.filterTierUserLinkForm.patchValue({
      tireStatusIds: []
    });
    this.applyFilters();
    this.cacheService.delete(this.uniqueCacheKey);
  }

  applyFilters(): void {
    this.tierStatusIds = [];
    this.totalFiltersApplied = 0;

    if (this.filterFormFields.tierStatusIds.value) {
      this.totalFiltersApplied++;
      this.filterFormFields.tierStatusIds.value.forEach(item => {
        this.tierStatusIds.push(item);
      });
    }
    this.masterTierList.forEach(tier => {
      if (this.tierStatusIds.indexOf(tier.status) == -1 && this.tierStatusIds.length > 0) {
        tier['isHidden'] = true;
      } else {
        tier['isHidden'] = false;
      }
    });
  }

  searchTiers(): void {
    var searchText = this.tierSearchText;
    this.masterTierList.filter(m => { return (this.tierStatusIds.length > 0 ? this.tierStatusIds.includes(m.status) : true) }).forEach(tier => {
      if (!tier.tierCode.toLowerCase().includes(searchText.toLowerCase())) {
        tier['isHidden'] = true;
      }
      else {
        tier['isHidden'] = false;
      }
    });
  }

  searchCustomers(): void {
    var searchText = this.customerSearchText;
    this.masterTierList.forEach(spot =>
      spot.customerList.forEach(customer => {
        customer['isHidden'] = false;
        if (!(customer.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          customer['isHidden'] = true;
        }
      })
    );

    this.unAssignedCustomers.forEach(customer => {
      customer['isHidden'] = false;
      if (!(customer.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchText.toLowerCase()))
      ) {
        customer['isHidden'] = true;

      }
    });
  }

  removeCustomer(tierMaster: any, customer: any): void {
    var deleteIndex = tierMaster.customerList.indexOf(customer);
    tierMaster.customerList.splice(deleteIndex, 1);
    this.unAssignedCustomers.push(customer);
    this.updateMappingDelta('cdk-drop-list-0', customer);
  }
}