import { Component, OnInit, Input, Inject } from '@angular/core';
import { RouteConstants } from '../../constants/route-constants';
import { HttpService } from '../../services/http/http.service';
import { GridColumnType, GridColumnDataType, DateFormats } from 'src/app/constants/constants';
import { TransformDatePipe } from 'src/app/directives/transform-date/transform-date.pipe';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'inbrit-login-trace-details',
  templateUrl: './login-trace-details.component.html',
  styleUrls: ['./login-trace-details.component.scss'],
  providers: [TransformDatePipe]
})

export class LoginTraceDetailsComponent implements OnInit {
  traceDetails: [any];
  dataCount: number;
  pageIndex: number = 1;
  DateFormats = DateFormats;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Login From', dataField: 'loginFrom', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      { title: 'Time', dataField: 'loginDate', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      { title: 'OS', dataField: 'os', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      { title: 'Client', dataField: 'client', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      { title: 'Ip Address', dataField: 'ipAddress', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
    ];

  constructor(private httpService: HttpService, private transformDatePipe: TransformDatePipe,
    public dialogRef: MatDialogRef<LoginTraceDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.getLoginTraceDetailsByDate();
  }

  getLoginTraceDetailsByDate(): void {
    var param = { 'userId': this.data.userId, 'loginDate': this.data.loginDate };
    this.httpService.httpGet(RouteConstants.ACCOUNT_getLoginTraceDetailsByDate, param).subscribe(res => {
      var data = res['data'];
      data.forEach(item => {
        item['loginDate'] = this.transformDatePipe.transform(item['loginDate'], GridColumnDataType.TIME);
      });
      this.traceDetails = data;
      this.dataCount = res['count'];
    });
  }

  gridEventHandler(evt: any): void {
  }

  closePopup(): void {
    this.dialogRef.close();
  }

}
