import { Component, OnInit } from '@angular/core';
import { HttpService } from "../../services/http/http.service";
import { FreightConstants } from "../../constants/constants";
import { RouteConstants } from "../../constants/route-constants";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms/src/directives';
import { MatDialog } from '@angular/material/dialog';
import { AddCompareComponent } from './add-compare/add-compare.component';
import { FilterCompareComponent } from './filter-compare/filter-compare.component';
import * as XLSX from 'xlsx';
import { TypeaheadValidator } from "../../shared/custom-validators/typeahead-validator";


@Component({
  selector: 'inbrit-compare-ratesheets',
  templateUrl: './compare-ratesheets.component.html',
  styleUrls: ['./compare-ratesheets.component.scss']
})
export class CompareRatesheetsComponent implements OnInit {
  carrierMasters: [any];
  rateSheetMasters1: [any];
  rateSheetMasters2: [any];
  compareRateSheetForm: FormGroup;
  freightListOriginal = [];
  freightCompareList = [];
  submitted: boolean = false;
  rateSheet1: any = null;
  rateSheet2: any = null;
  isShowNoRecordSection: boolean = false;
  freightRates: Array<{ carrierCode: string, freightId: number, effectiveDate: string, expiryDate: string }> = [];
  freightRateIndexes: Array<number> = [0, 1, 2, 3, 4];
  ratesheetFilter: { compareList: Array<any>, fromPort?: string, toPort?: string, size?: string, type?: string } = { compareList: [] };
  commoditMasters: Array<any> = [];

  constructor(private formBuilder: FormBuilder, private httpService: HttpService, public dialog: MatDialog) { }

  ngOnInit() {
    this.initializeForm();
    this.getAllCarrierMaster();
    this.getAllCommodityMaster();
  }

  initializeForm() {
    this.compareRateSheetForm = this.formBuilder.group({
      carrier1: ['', []],
      rateSheet1: ['', [Validators.required]],
      carrier2: ['', []],
      rateSheet2: ['', [Validators.required]]
    });

  }

  get compareRSheetform() { return this.compareRateSheetForm.controls; }


  openDialogCompare() {
    const dialogRef = this.dialog.open(AddCompareComponent, {
      width: '50%',
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != null) {
        this.freightRates.push({
          carrierCode: res.carrierCode, freightId: res.freightId,
          effectiveDate: res.effectiveDate, expiryDate: res.expiryDate
        });

        this.getFreightRateDiff();
      }
    });
  }

  openCompareFilter() {
    const dialogRef = this.dialog.open(FilterCompareComponent, {
      width: '50%',
      data: {
        compareList: this.freightListOriginal,
        fromPort: this.ratesheetFilter.fromPort,
        toPort: this.ratesheetFilter.toPort,
        size: this.ratesheetFilter.size,
        type: this.ratesheetFilter.type
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      let list = [];
      this.freightListOriginal.forEach(item => {
        list.push(item);
      });
      if (res != null) {
        this.ratesheetFilter = {
          compareList: this.freightCompareList,
          fromPort: res.fromPort, toPort: res.toPort,
          size: res.size, type: res.type
        }

        this.freightCompareList = list.filter(m => {
          return 1 == 1 &&
            (this.ratesheetFilter.fromPort != null && this.ratesheetFilter.fromPort != '' ? m.fromPort == this.ratesheetFilter.fromPort : true) &&
            (this.ratesheetFilter.toPort != null && this.ratesheetFilter.toPort != '' ? m.toPort == this.ratesheetFilter.toPort : true) &&
            (this.ratesheetFilter.size != null && this.ratesheetFilter.size != '' ? m.size == this.ratesheetFilter.size : true) &&
            (this.ratesheetFilter.type != null && this.ratesheetFilter.type != '' ? m.type == this.ratesheetFilter.type : true)
        });
      }
      else {
        this.ratesheetFilter = {
          compareList: this.freightCompareList,
          fromPort: null, toPort: null,
          size: null, type: null
        }
        this.freightCompareList = list;
      }
    })
  }

  getAllCarrierMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCarrierMaster, null).subscribe(res => {
      this.carrierMasters = res['data'];
    });
  }

  getAllCommodityMaster() {
    this.httpService.httpGet(RouteConstants.MASTER_getAllCommodity, null).subscribe(res => {
      this.commoditMasters = res['data'];
    });
  }

  getRatesheets(event: any) {
    var freightStatusIds = [FreightConstants.APPROVED];

    var param = {
      "StatusIds": freightStatusIds,
      "CarrierId": event.value
    };

    this.httpService.httpPost(RouteConstants.FREIGHT_getfreightrates, param).subscribe(res => {
      if (res['success'] == true) {
        if (event.source.id == "carrier1") {
          this.rateSheetMasters1 = res['data'];
          this.compareRateSheetForm.controls.rateSheet1.setValidators([Validators.required,TypeaheadValidator.typeaheadValidator(this.rateSheetMasters1,'id')])
          this.rateSheetMasters1.forEach(m => {
            let commodity = this.commoditMasters.find(cmd => cmd.id == m.commodityId);
            m["commodityName"] = commodity != null && commodity != undefined ? commodity.commodityName : "";
          });
          this.rateSheet1 = null;
          this.compareRateSheetForm.patchValue({ rateSheet1: '' });
        }
        else if (event.source.id == "carrier2") {
          this.rateSheetMasters2 = res['data'];
          this.compareRateSheetForm.controls.rateSheet2.setValidators([Validators.required,TypeaheadValidator.typeaheadValidator(this.rateSheetMasters2,'id')])
          this.rateSheetMasters2.forEach(m => {
            let commodity = this.commoditMasters.find(cmd => cmd.id == m.commodityId);
            m["commodityName"] = commodity != null && commodity != undefined ? commodity.commodityName : "";
          });
          this.rateSheet2 = null;
          this.compareRateSheetForm.patchValue({ rateSheet2: '' });
        }
      }
    });
  }

  rateSheet1Display(id){
    if(id != "" && id != null && id != undefined && this.rateSheetMasters1 != undefined && this.rateSheetMasters1.length > 0){
      this.rateSheet1 = this.rateSheetMasters1.filter(m => { return m.id == id })[0];
      return  this.rateSheet1.commodityName
      // "Commodity : " +  this.rateSheet1.commodityName + " Effective Date : " + 
      // this.rateSheet1.effectiveDate + " Expiry Date : " + this.rateSheet1.expiryDate + " Remarks : " + this.rateSheet1.remarks;
    }
  }

  rateSheet2Display(id){
    if(id != "" && id != null && id != undefined && this.rateSheetMasters2 != undefined && this.rateSheetMasters2.length > 0){
      this.rateSheet2 = this.rateSheetMasters2.filter(m => { return m.id == id })[0];
      return  this.rateSheet2.commodityName
      // "Commodity : " +  this.rateSheet1.commodityName + " Effective Date : " + 
      // this.rateSheet1.effectiveDate + " Expiry Date : " + this.rateSheet1.expiryDate + " Remarks : " + this.rateSheet1.remarks;
    }
  }

  setDetailedDisplay(event: any): void {
    switch (event.source.id) {
      case 'rateSheet1':
        {
          this.rateSheet1 = this.rateSheetMasters1.filter(m => { return m.id == event.value })[0];
          break;
        }

      case 'rateSheet2':
        {
          this.rateSheet2 = this.rateSheetMasters2.filter(m => { return m.id == event.value })[0];
          break;
        }
    }
  }

  formSubmit(): void {
    this.submitted = true;
    if (!this.compareRateSheetForm.valid) {
      return;
    }
    this.freightRates = [];
    var carrierId1 = parseInt(this.compareRateSheetForm.controls['carrier1'].value);
    var carrier1 = this.carrierMasters.find(m => { return m.id == carrierId1 });
    this.freightRates.push({
      carrierCode: carrier1.carrierCode, freightId: this.rateSheet1.id,
      effectiveDate: this.rateSheet1.effectiveDate, expiryDate: this.rateSheet1.expiryDate
    });
    var carrierId2 = parseInt(this.compareRateSheetForm.controls['carrier2'].value);
    var carrier2 = this.carrierMasters.find(m => { return m.id == carrierId2 });
    this.freightRates.push({
      carrierCode: carrier2.carrierCode, freightId: this.rateSheet2.id,
      effectiveDate: this.rateSheet2.effectiveDate, expiryDate: this.rateSheet2.expiryDate
    });

    this.getFreightRateDiff();
  }

  clearFilter(form: NgForm): void {
    this.compareRateSheetForm.reset();
    form.resetForm();
    this.rateSheet1 = this.rateSheet2 = null;
    this.isShowNoRecordSection = false;
    this.freightListOriginal = [];
  }

  deleteCompareItem(index: number): void {
    this.freightRates.splice(index, 1);
    this.getFreightRateDiff();
  }

  getFreightRateDiff(): void {
    // var param = {
    //   'FreightId': this.compareRSheetform.rateSheet1.value,
    //   'CompareFreightId': this.compareRSheetform.rateSheet2.value
    // };
    var param = {
      CompareFreightIds: []
    };
    this.freightRates.forEach(item => {
      param.CompareFreightIds.push(item.freightId);
    });
    this.freightListOriginal = [];
    this.httpService.httpPost(RouteConstants.FREIGHT_getFreightRateDiff, param).subscribe(res => {
      if (res['success'] == true) {
        this.freightListOriginal = res['data'];
        this.isShowNoRecordSection = this.freightListOriginal.length == 0;
        // this.createCompareListData(this.freightListOriginal);
        this.freightCompareList = res['data'];
        this.ratesheetFilter = { compareList: this.freightCompareList }
      }
    });
  }

  // createCompareListData(data: any): void {
  //   this.freightCompareList = [];
  //   if (this.compareRSheetform.rateSheet1.value == this.compareRSheetform.rateSheet2.value) {
  //     data.forEach(item => {
  //       var exists = this.freightCompareList.filter(m => { return m.main.id == item.id });
  //       if (exists.length == 0) {
  //         this.freightCompareList.push({ main: item, compare: item });
  //       }
  //     });
  //   }
  //   else {
  //     var mainData = data.filter(m => { return m.freightrateId == this.compareRSheetform.rateSheet1.value });
  //     var comp = data.filter(m => { return m.freightrateId == this.compareRSheetform.rateSheet2.value });

  //     mainData.forEach(item => {
  //       var compared = comp.filter(m => {
  //         return m.loadpoint == item.loadpoint && m.fromPort == item.fromPort &&
  //           m.toPort == item.toPort &&
  //           m.discharge == item.discharge && m.size == item.size && m.type == item.type
  //       });
  //       this.freightCompareList.push({ main: item, compare: (compared.length > 0 ? compared[0] : null) });
  //     });


  //     var notFound = comp.filter(m => {
  //       return !((this.freightCompareList.map(x => x.compare)).map(y => y.id).some(x => x === m.id))
  //     });

  //     notFound.forEach(item => {
  //       this.freightCompareList.push({ main: null, compare: item });
  //     }
  //     );

  //   }
  // }

  getDifference(freight: any, freightRates: Array<any>): string {
    var diffrence = 'N/A';
    var ids = freightRates.map(m => m.freightId);
    if (freight.freightRateAllInUSDs != null && freight.freightRateAllInUSDs != undefined) {
      var rateDiff = freight.freightRateAllInUSDs[ids[1]] - freight.freightRateAllInUSDs[ids[0]];

      if (rateDiff == 0) {
        diffrence = "N/A"
      }
      else if (rateDiff < 0) {
        diffrence = "+ " + (rateDiff * -1)
      }
      else {
        diffrence = "- " + rateDiff
      }
    }
    return diffrence;
  }

  // toggleCommonRecords(isHideCommon: boolean): void {

  //   var list = [];
  //   this.freightListOriginal.forEach(item => {
  //     list.push(item);
  //   });
  //   if (isHideCommon) {
  //     this.freightCompareList.forEach(freight => {
  //       if (freight.main != null && freight.compare != null) {
  //         if (parseFloat(freight.main.allInUsd) == parseFloat(freight.compare.allInUsd)) {
  //           var itemMain = list.filter(m => { return m.id == freight.main.id })[0];
  //           var indexMain = list.indexOf(itemMain);
  //           if (indexMain != -1) {
  //             list.splice(indexMain, 1);
  //           }

  //           var itemCompare = list.filter(m => { return m.id == freight.compare.id })[0];
  //           var indexCompare = list.indexOf(itemCompare, 0);
  //           if (indexCompare != -1) {
  //             list.splice(indexCompare, 1);
  //           }
  //         }
  //       }
  //     });
  //   }
  //   this.createCompareListData(list);
  // }

  toggleCommonRecords(isHideCommon: boolean): void {

    var list = [];
    this.freightListOriginal.forEach(item => {
      list.push(item);
    });
    if (isHideCommon) {
      this.freightCompareList = [];
      list.forEach(freight => {
        var rates = Object.values(freight.freightRateAllInUSDs) as Array<number>;
        var distinctRates = rates.filter((value, index) => rates.indexOf(value) === index);
        if (distinctRates.length != 1) {
          this.freightCompareList.push(freight);
        }
      });
    }
    else {
      this.freightCompareList = list;
    }
    this.ratesheetFilter = { compareList: this.freightCompareList }
  }

  getFreightPrice(item: any, freightrateId: number): string {
    let result = 'N/A';
    var priceObj = Object.keys(item.freightRateAllInUSDs).find(m => { return m == freightrateId.toString() });
    if (priceObj != undefined) {
      let price = item.freightRateAllInUSDs[freightrateId]
      result = price == 0 ? 'N/A' : price;
    }
    else {
      item.freightRateAllInUSDs[freightrateId] = 0;
    }
    // for (let key of Object.keys(item.freightRateAllInUSDs)) {
    //   if (freightrateId == parseInt(key)) {
    //     result = item.freightRateAllInUSDs[key];
    //     break;
    //   }
    // }
    return result;
  }

  ExportToExcel(): void {
    let data: Array<any> = [];

    this.freightCompareList.forEach(freight => {
      
      data.push({
        Loadpoint: freight.loadpoint,
        fromPort: freight.fromPort,
        discharge: freight.discharge,
        toPort: freight.toPort,
        size: freight.size,
        type: freight.type
      });
      
      for(let i in this.freightRateIndexes){
        if(this.freightRates.length >= Number(i) + 1){
          let price = this.getFreightPrice(freight, this.freightRates[i].freightId);
          var carrierKey = this.freightRates[i].carrierCode;
          // Check if key(carrierCode) already exists
          carrierKey = this.checkIfKeyExists(carrierKey,data[data.length - 1],0);
          data[data.length - 1][carrierKey] = price == "N/A" ? "0" : price;
        }
      }

    });


    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, `FreightRate.xlsx`);


  }

  checkIfKeyExists(key:string, object:any,len:Number){
    if(!(key in object)){
      return key
    }
    key = key.split("_")[0];
    return this.checkIfKeyExists(`${key}_${Number(len) + 1}`,object,Number(len) + 1);
  }

}
