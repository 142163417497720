import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Component({
  selector: 'inbrit-request-containers-settings',
  templateUrl: './request-containers-settings.component.html',
  styleUrls: ['./request-containers-settings.component.scss']
})
export class RequestContainersSettingsComponent implements OnInit {
  containerSettingsForm: FormGroup;
  user: any;
  submitted: boolean = false;
  today: Date = new Date();
  datePipe = new DatePipe('en-US');
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<RequestContainersSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private userInfoService: UserInfoService) {
  }

  ngOnInit() {
    var user = this.userInfoService.getLoggedInUser();;
    if (user != undefined && user != null && user != 'null') {
      this.user = user;
    }
    this.today = new Date(this.today.setHours(0, 0, 0, 0));
    this.initializeForm();
  }

  initializeForm() {
    this.containerSettingsForm = this.formBuilder.group({
      quantity: ['', [Validators.required]],
      cutOffDate: ['', []],
      loadDate: ['', []],
      startTime: ['', [Validators.required]],
      frequency: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      retainPreviousEntries: [true, []],
      loadAddress: ['', [Validators.required]],
      loadReference: ['', [Validators.required]],
    });

    if (this.user.countryOfRegistration == 147) {
      // USA
      this.containerSettingsForm.get('cutOffDate').setValidators([Validators.required]);
      this.containerSettingsForm.get('loadDate').setValidators([]);
      this.containerSettingsForm.get('startTime').setValidators([]);
      this.containerSettingsForm.get('frequency').setValidators([]);
      this.containerSettingsForm.get('endTime').setValidators([]);
      this.containerSettingsForm.get('loadAddress').setValidators([]);
      this.containerSettingsForm.get('loadReference').setValidators([]);
    }

    this.containerSettingsForm.get('cutOffDate').updateValueAndValidity();
    this.containerSettingsForm.get('loadDate').updateValueAndValidity();
    this.containerSettingsForm.get('startTime').updateValueAndValidity();
    this.containerSettingsForm.get('frequency').updateValueAndValidity();
    this.containerSettingsForm.get('endTime').updateValueAndValidity();
    this.containerSettingsForm.get('loadAddress').updateValueAndValidity();
    this.containerSettingsForm.get('loadReference').updateValueAndValidity();

  }

  get settingsForm() { return this.containerSettingsForm.controls; }

  submitContainerSettings(): void {
    if (this.user.countryOfRegistration == 146) {
      this.containerSettingsForm.get('loadDate').setValidators([Validators.required]);
      this.containerSettingsForm.get('loadDate').updateValueAndValidity();
    }
    this.submitted = true;
    
    if (!this.containerSettingsForm.valid) {
      return;
    }
    var bookingDetailId = this.data.bookingDetailId;

    if (bookingDetailId != undefined && bookingDetailId > 0) {

      this.data = {
        'bookingDetailId': bookingDetailId,
        'quantity': this.settingsForm.quantity.value,
        'cutOffDate': this.settingsForm.cutOffDate.value,
        'loadDate': this.settingsForm.loadDate.value,
        'startTime': this.settingsForm.startTime.value,
        'frequency': this.settingsForm.frequency.value,
        'endTime': this.settingsForm.endTime.value,
        'loadAddress': this.settingsForm.loadAddress.value,
        'loadReference': this.settingsForm.loadReference.value,
        'retainPreviousEntries': this.settingsForm.retainPreviousEntries.value
      }

    }
    else {
      this.data = undefined;
    }

    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
