import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'inbrit-filter-compare',
  templateUrl: './filter-compare.component.html',
  styleUrls: ['./filter-compare.component.scss']
})
export class FilterCompareComponent implements OnInit {
  fromPortOriginalList: Array<any> = [];
  toPortOriginalList: Array<any> = [];
  fromPortList: Array<any> = [];
  toPortList: Array<any> = [];
  containerSizes = [20, 40, 45];
  containerTypes = [];// ['STD', 'HC', 'OT', 'FR', 'FB', 'RE', 'TK', 'RF'];
  filterForm: FormGroup;
  typeaheadDebounce: number = 500;
  filter: { compareList: Array<any>, fromPort?: string, toPort?: string, size?: string, type?: string } = { compareList: [] }

  constructor(public dialogRef: MatDialogRef<FilterCompareComponent>,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: Array<any>) {
    this.dialogRef.disableClose = true;

    var data = this.data['compareList'] as Array<any>;
    this.filter.compareList = data.filter(m => { return m.fromPort != null && m.toPort != null });
    this.filter.fromPort = this.data['fromPort'];
    this.filter.toPort = this.data['toPort'];
    this.filter.size = this.data['size'];
    this.filter.type = this.data['type'];
  }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      fromPort: [this.filter.fromPort], toPort: [this.filter.toPort],
      size: [this.filter.size], type: [this.filter.type],
    });
    this.fromPortOriginalList = Array.from(new Set(this.filter.compareList.map(m => m.fromPort)));
    this.toPortOriginalList = Array.from(new Set(this.filter.compareList.map(m => m.toPort)));
    this.containerTypes = Array.from(new Set(this.filter.compareList.map(m => m.type)))
    this.initializeTypeAheads();
  }

  initializeTypeAheads() {
    this.filterForm.controls['fromPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        this.fromPortList = this.fromPortOriginalList.filter(m => { return m.toLowerCase().includes(val.toLowerCase()) });
      }
    });

    this.filterForm.controls['toPort'].valueChanges.pipe(debounceTime(this.typeaheadDebounce)).subscribe(val => {
      if (typeof val === 'string' && val.length >= 3) {
        this.toPortList = this.toPortOriginalList.filter(m => { return m.toLowerCase().includes(val.toLowerCase()) });
      }
    });
  }

  onFormSubmit(): void {
    var result = {
      compareList: this.filter.compareList,
      fromPort: this.filterForm.controls['fromPort'].value,
      toPort: this.filterForm.controls['toPort'].value,
      size: this.filterForm.controls['size'].value,
      type: this.filterForm.controls['type'].value
    };

    this.filter = result;
    this.dialogRef.close(result);
  }

  closePopup(): void {
    let result = {
      compareList: this.filter.compareList,
      fromPort: this.filter.fromPort, toPort: this.filter.toPort,
      size: this.filter.size, type: this.filter.type
    }
    this.dialogRef.close(result);
  }

  resetFilter(): void {
    this.filterForm.patchValue({
      fromPort: null, toPort: null, size: null, type: null
    })

    this.dialogRef.close(null);
  }
}