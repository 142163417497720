import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[inbritDynamicComponentContainer]'
})
export class DynamicComponentContainerDirective {

  constructor(private elementRef: ElementRef, public viewContainerRef: ViewContainerRef) {
  }

}
