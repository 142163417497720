import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { UserRoleConstants, FSCollections } from "../../constants/constants";
import {
  Pagination, GridColumnType, GridActionType,
  GridColumnDataType, DateFormats
}
  from "../../constants/constants";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { DatePipe } from "@angular/common"
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UserInfoService } from 'src/app/services/userInfo/user-info.service';

@Component({
  selector: 'inbrit-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.scss']
})
export class BookingHistoryComponent implements OnInit, OnDestroy {
  user: any;
  pageIndex: number = 1;
  newPageIndex: number = 1;
  pageSize: number = Pagination.PageSize;
  startAfterBNo: [any]
  startAfterCDate: [any]
  endBeforeBNo: [any]
  endBeforeCDate: [any]
  afsSubscription: any;
  afsCountSubscription: any;
  datePipe = new DatePipe('en-US');
  filterBookingForm: FormGroup;
  toggleFilterVisiblility: boolean = false;
  bookingHistory: any = [];
  dataCount: number;
  submitted: boolean = false;
  columns: Array<{
    title: string, dataField: string, type: string, dataType?: string, actions?:
    Array<{ event: string, type: string, title: string, class: string, conditionalDisplay?: { dataField: string, value: any } }>
  }> = [
      { title: 'Quotation', dataField: 'quotationNumber', type: GridColumnType.DATA, },
      { title: 'User', dataField: 'createdByName', type: GridColumnType.DATA, },
      { title: 'From', dataField: 'fromPortName', type: GridColumnType.DATA, },
      { title: 'To', dataField: 'toPortName', type: GridColumnType.DATA, },
      { title: 'Request Date', dataField: 'createdDate', type: GridColumnType.DATA, dataType: GridColumnDataType.TEXT },
      {
        title: 'Action', dataField: 'quotationId', type: GridColumnType.ACTION, actions: [
          { event: 'view', type: GridActionType.ICON, title: 'View', class: 'visibility' }
        ]
      }
    ]

  constructor(private afs: AngularFirestore, private formBuilder: FormBuilder,
    private router: Router, private encryptionService: EncryptionService,
    private loaderService: LoaderService, private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.initializeForm();

    var user = this.userInfoService.getLoggedInUser();
    if (user != null) {
      this.user = user;
    }
    this.loaderService.display(true);
    this.getBookingCount();
    this.getAllBookings();
  }

  ngOnDestroy() {
    // UnSubscription of AFS is mandatory because of Once you have subscribed to AFS, it will continue
    // to listen the AFS changes even after page/url is changed. Also if the user is logged out 
    // then listening to the AFS changes will throw an exception of InSufficient permission.
    this.afsSubscription.unsubscribe();
    this.afsCountSubscription.unsubscribe();
  }

  initializeForm(): void {
    this.filterBookingForm = this.formBuilder.group({
      fromDate: [new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()), [Validators.required]],
      toDate: [new Date(moment().format("M/D/YYYY").toString()), [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get filterFormFields() { return this.filterBookingForm.controls; }

  getBookingCount(): void {
    this.afsCountSubscription = this.afs.collection<any>('Counts').doc('Bookings_' + this.user.countryOfRegistration)
      .valueChanges()
      .subscribe(res => {
        this.dataCount = res['count'];
      });
  }

  getAllBookings(): void {
    // var param = {
    //   FromDate: this.datePipe.transform(this.filterFormFields.fromDate.value, 'short'),
    //   ToDate: this.datePipe.transform(this.filterFormFields.toDate.value, 'short')
    // }

    if (this.afsSubscription != undefined) {
      this.afsSubscription.unsubscribe();
    }

    // GETTING DATA WHEN FIRST LOAD OF SAME PAGE RELOAD
    if (this.pageIndex == this.newPageIndex) {
      this.afsSubscription = this.afs.collection<any>(FSCollections.BOOKINGS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .limit(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
    // GETTING DATA FOR NEXT PAGE LOAD
    else if (this.newPageIndex > this.pageIndex) {
      this.afsSubscription = this.afs.collection<any>(FSCollections.BOOKINGS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .startAfter(this.startAfterBNo)
          // .startAt(this.startAfterCDate)
          // .startAfter(this.startAfterCDate)
          // .startAfter(this.startAfterBNo)
          .limit(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
    // GETTING DATA FOR PREVIOUS PAGE LOAD
    else {
      this.afsSubscription = this.afs.collection<any>(FSCollections.BOOKINGS, enbloc =>
        enbloc.where("country", "==", this.user.countryOfRegistration)
          // .where('createdDate', '>=', this.filterFormFields.fromDate.value)
          // .where('createdDate', '<=', this.filterFormFields.toDate.value)
          // .orderBy('createdDate', 'desc')
          .orderBy('quotationNumber', 'desc')
          .endBefore(this.endBeforeBNo)
          .limitToLast(this.pageSize))
        .valueChanges()
        .subscribe(res => {
          this.manageFSResponse(res);
        });
    }
  }

  manageFSResponse(res): void {
    this.pageIndex = this.newPageIndex;

    if (res.length > 0) {
      res.forEach(element => {
        element['createdDate'] = this.datePipe.transform(element['createdDate'].toDate(), DateFormats.STANDARD_DATE_TIME);
        element['class'] = {
          statusText: this.userInfoService.getColorClass(element.status),
        };
      });

      this.startAfterCDate = res[res.length - 1]["createdDate"];
      this.startAfterBNo = res[res.length - 1]["quotationNumber"];
      this.endBeforeCDate = res[0]["createdDate"];
      this.endBeforeBNo = res[0]["quotationNumber"];

      this.bookingHistory = res;
    }
    this.loaderService.display(false);
  }

  clearFilter(): void {
    this.filterBookingForm.patchValue({
      fromDate: new Date(moment().add(-7, 'days').format("M/D/YYYY").toString()),
      toDate: new Date(moment().format("M/D/YYYY").toString())
    })
    this.newPageIndex = 1;
    this.getAllBookings();
  }

  filterFormSubmit(): void {
    this.submitted = true;
    if (!this.filterBookingForm.valid) {
      return;
    }
    this.getAllBookings();
  }

  gridEventHandler(evt): void {
    switch (evt.event) {
      case 'view':
        {
          this.viewBookingDetail(evt.data.bookingId);
          break;
        }
    }
  }

  paginationEventHandler(evt): void {
    this.newPageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.loaderService.display(true);
    this.getAllBookings();
  }

  viewBookingDetail(bookingId: number): void {
    if (this.user != undefined) {
      if (this.user.roleId == UserRoleConstants.CUSTOMER) {
        this.router.navigate(['customer/bookings', this.encryptionService.encrypt(bookingId)]);
      }
      else {
        this.router.navigate(['user/bookings', this.encryptionService.encrypt(bookingId)]);
      }
    }
  }

}
