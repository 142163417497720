import { Pipe, PipeTransform } from '@angular/core';
import { GridColumnDataType } from 'src/app/constants/constants';
import { DateFormats } from '../../constants/constants';
import * as moment from 'moment';

@Pipe({
  name: 'transformDate'
})

export class TransformDatePipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (!format) { return value; }
    var returnDate = '';
    switch (format) {
      case GridColumnDataType.DATE:
        returnDate = this.getLocalizedFormattedHours(value, DateFormats.MOMENT_STANDARD_DATE);
        break;
      case GridColumnDataType.DATETIME:
        returnDate = this.getLocalizedFormattedHours(value, DateFormats.MOMENT_STANDARD_DATE_TIME);
        break;
      case GridColumnDataType.TIME:
        returnDate = this.getLocalizedFormattedHours(value, DateFormats.MOMENT_STANDARD_TIME);
        break;
      default:
        returnDate = value;
        break;
    }
    return returnDate;
  }

  getLocalizedFormattedHours(createdDate: any, dateFormat: string): string {
    var requestedDate = new Date(createdDate);
    var dateNow = new Date();
    var diffFromUtc = dateNow.getTimezoneOffset();
    var minutes = requestedDate.getMinutes();
    requestedDate.setMinutes(minutes - diffFromUtc);
    return moment(requestedDate).format(dateFormat).toString();
  }
}
